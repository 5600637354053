import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import searchEngine from "../Asset/ESO/search-engine.svg";
import FileNamesvg from "../Asset/esocardicon/FileAccessibilityReport/FileName.svg"
import Scoresvg from "../Asset/esocardicon/FileAccessibilityReport/Score.svg"
const WordFile = (props) => {
  const [childcardData, setChildcardData] = useState([]);
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const { jobid } = useParams();

  useEffect(() => {
    setChildcardData(props.cardData);
  }, [props.cardData]);

  const successRows = useMemo(() => (
    childcardData.detailed_data?.success?.map((row, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{row}</td>
      </tr>
    ))
  ), [childcardData.detailed_data?.success]);

  const failureRows = useMemo(() => (
    childcardData.detailed_data?.failure?.map((row, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{row}</td>
      </tr>
    ))
  ), [childcardData.detailed_data?.failure]);

  return (
    <div className="cnt_hdr_file_access">

      <div className="common-card-container" style={{ justifyContent: "center" }}>
        <div className={`common-card-wrapper-style cardactivecontainer`} >
          <div className={`common-card-icon-container`}>
            <div className={`common-card-icons`}>
              <img src={FileNamesvg} className={`common-card-icon-img`} alt="FileNamesvg" />
            </div>
          </div>
          <div className={`common-card-subtitle cardcontentactivecontainer`}>
            <span className="common-card-title">File Name</span>
            <span className="common-card-value">{childcardData.file_name}</span>
          </div>
        </div>
        <div className={`common-card-wrapper-style cardactivecontainer`} >
          <div className={`common-card-icon-container`}>
            <div className={`common-card-icons`}>
              <img src={Scoresvg} className={`common-card-icon-img`} alt="Scoresvg" />
            </div>
          </div>
          <div className={`common-card-subtitle cardcontentactivecontainer`}>
            <span className="common-card-title">Score</span>
            <span className="common-card-value">{childcardData.score}</span>
          </div>
        </div>
      </div>


      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap" style={{ marginLeft: "30px" }}>
          <div className="hor_line">
            <h3>Success</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {successRows}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cnt_hdr_content_table fileaccessreport_inner">
        <div className="box_wrap" style={{ marginLeft: "30px" }}>
          <div className="hor_line">
            <h3>Failure</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {failureRows}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WordFile;
