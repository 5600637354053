import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Loader from "./../../CommonLoader";
import DatagridComp from "../../DatagridComp";
import { CommonErrorAlert } from "../../../../Commonalert/commonErrorAlert";

export default function ImageData({ datavalue, JobId, SubmitTriggered, SetSubmitTriggered, domainsection }) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = React.useState(false)
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [detailurlid, setDetailurid] = useState(1);
  const [autoheightval, SetAutoheightval] = useState(true)
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apiJobId, setapiJobId] = useState();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  if (detailurlid === 1)
    var detailurl = `${process.env.REACT_APP_DOMAIN}image-detail/`;
  else
    var detailurl = `${process.env.REACT_APP_DOMAIN}missing-image-detail/`;

  useEffect(() => {
    setapiJobId(JobId)
    callUserdataApi(datavalue);
  }, [datavalue]);
  useEffect(() => {
    callUserdataApi(datavalue)

  }, [domainsection])

  useEffect(() => {

    if (SubmitTriggered === true) {
      callUserdataApi(datavalue)
    }

    if (parseInt(datavalue) === 11) {
      SetColumnTitlePage('URL of the web page')
    }
    if (parseInt(datavalue) === 12) {
      SetColumnTitlePage('URL of the web page')
    }



  }, [SubmitTriggered])

  const [ColumnTitlePage, SetColumnTitlePage] = useState('')

  const columns =  [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      description: 'Index of the row',
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "page_url",
      headerName: "Page URL",
      description: ColumnTitlePage,
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        let baseUrl = cardData.row.page_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.page_url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.page_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.page_url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },



    },
    {
      field: "view",
      headerName: "View Report",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: 'hide_option',

      renderCell: (cardData) => {
        return <a href={detailurl + `${cardData.row.page_url_id}` + "/" + `${apiJobId}`}>
          <button className="view-report-button">
            View Report
          </button>
        </a>
      },
    },
  ];
  const rows = cardData ?? [];
  const callUserdataApi = async (missingdatas) => {
    setLoading(true)
    setCardData([])
    var dataurls = ""
    if (missingdatas == 11) {
      dataurls = `${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsection}&job_id=${JobId}&field_type=images`
      setDetailurid(1)
    }
    else if (missingdatas == 12) {
      dataurls = `${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsection}&job_id=${JobId}&field_type=missing_alt`
      setDetailurid(2)
    }
    const datavalue = await axios
      .get(
        `${dataurls}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    const dataval = datavalue?.data?.table?.urls

    if (dataval) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }
    setLoading(false)
    SetSubmitTriggered(false)
    setapiJobId(datavalue?.data?.job_id)

  };


  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        marginRight: "40px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
        {loading === true ? <Loader /> : null}
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />
      </div>
    </div>
  );
}
