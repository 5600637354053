import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Followsvg from "../Asset/esocardicon/BacklinkTrends/Follow.svg"
import Nofollowsvg from "../Asset/esocardicon/BacklinkTrends/Nofollow.svg"
import TotalBacklinkssvg from "../Asset/esocardicon/BacklinkTrends/TotalBacklinks.svg"
import DatagridComp from "../DatagridComp";
import Commoncard from "../Commoncard";

export default function BacklinksTrend(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [TotalBacklinkCount, SetTotalBacklinkCount] = useState(0)
  const [FollowCount, SetFollowCount] = useState(0)
  const [NofollowCount, SetNofollowCount] = useState(0)
  const navigate = useNavigate()
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const fillColor = "rgb(255,174,31)";
  const [columns, Setcolumns] = useState([])
  const columnsIni = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 80,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows = cardData ?? [];
  const ColorObj = [{
    borderColor: "orange",
    backgroundColor: "rgb(255, 235, 219)",
    fill: {
      target: "origin",
      above: "rgba(255, 235, 219, 0.3)"
    }

  }]
  const LoadData = async (tableid = '') => {
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    setLoading(true)
    try {
      const datavalueKpi = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_count/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalueKpi?.data) {
        if (datavalueKpi?.data?.total_backlinks_count) {
          SetTotalBacklinkCount(datavalueKpi.data.total_backlinks_count)
        }
        if (datavalueKpi?.data?.total_do_follow_count) {
          SetFollowCount(datavalueKpi.data.total_do_follow_count)
        }
        if (datavalueKpi?.data?.total_no_follow_count) {
          SetNofollowCount(datavalueKpi.data.total_no_follow_count)
        }
      }
    } catch (err) {
      console.log(err)
    }
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var ResultData = [];
      if (datavalue?.data) {
        if (datavalue?.data?.result) {
          ResultData = datavalue.data.result
        }
      }
      const labelsIn = ResultData.map(entry => entry.Month.toString());
      const arrayOfKeys = ResultData.map(obj => Object.keys(obj));
      var FrameDataSetKey = arrayOfKeys;
      const FrameDataSetValue = [];
      if (FrameDataSetKey) {
        FrameDataSetKey = FrameDataSetKey[0]
        FrameDataSetKey = FrameDataSetKey.filter(item => item !== 'Month');
        FrameDataSetKey.forEach(element => {
          const arrayValues = ResultData.map(obj => obj[element]);
          const Cobj = ColorObj[0];
          const object1 = {
            label: element,
            data: arrayValues,
          }
          const mergedObject = { ...object1, ...Cobj };
          FrameDataSetValue.push(object1)
        });

      }
      SetLabelData(labelsIn)
      props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      SetDataSetsValue(FrameDataSetValue)
    } catch (err) {
      SetLabelData([])
      console.log(err)
    }
    try {
      const datavalueTable = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var labelsIn = []
      if (datavalueTable?.data?.table_headers) {
        labelsIn = datavalueTable.data.table_headers
      }
      var ColumnsMerge = [];
      labelsIn.forEach(element => {
        var Height = 200;
        if (element === 'Domains') {
          Height = 300
        }
        ColumnsMerge.push({
          field: element,
          headerName: element,
          minWidth: Height,
          flex: 1,
          disableClickEventBubbling: true,
          headerAlign: "center",
          align: "center",
        })
      });
      ColumnsMerge = columnsIni.concat(ColumnsMerge)
      Setcolumns(ColumnsMerge)
      const dataval = datavalueTable?.data?.table_body;
      if (dataval) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setCardData(newData);
        SetAutoheightval(false)
      }
      else {
        setCardData([]);
        SetAutoheightval(true)
      }
    } catch (err) {
      setCardData([])
      console.log(err)
    }
    setLoading(false)
  }
  const [LabelData, SetLabelData] = useState([])
  const [DataSetsValue, SetDataSetsValue] = useState([])
  const chartData = {
    labels: LabelData,
    datasets: DataSetsValue
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };


  const cardDatas = [
    { title: "Total Backlinks", value: TotalBacklinkCount, iconSrc: TotalBacklinkssvg, altText: "Total Backlinks" },
    { title: "Follow", value: FollowCount, iconSrc: Followsvg, altText: "Follow" },
    { title: "No follow", value: NofollowCount, iconSrc: Nofollowsvg, altText: "No follow" }
  ];


  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }
  }, [props])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Trend</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="common-card-container" style={{ justifyContent: "center" }}>
        {cardDatas.map(({ title, value, iconSrc, altText }) => (
          <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
        ))}
      </div>
      <div className="site_regress_chart_info" style={{ marginBottom: "30px" }}>
        <div className="site_Chart_info_inner">
          <Line data={chartData} options={chartOptions} />
          <p style={{ color: "#ff601f" }}>Backlinks Trends</p>
        </div>
      </div>

      <DatagridComp
        columns={columns}
        rows={rows}
        autoheightval={autoheightval}
        datagridval={2}
      />

    </>
  );
}
