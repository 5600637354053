import React, { useState, useEffect, useContext, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import "react-datepicker/dist/react-datepicker.css";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Pageindexlists() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagename, setPagename] = useState("Page Index Report")
  const [indexurl, setIndexurl] = useState();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [LabelData, SetLabelData] = useState([])
  const [jobDate, setjobDate] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [pageIndexReportPlan, setpageIndexReportPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const [esoindexData, SetesoindexData] = useState([])
  const [googleindexData, SetgoogleindexData] = useState([])
  const [sitemapData, SetsitemapData] = useState([])
  const [fourxxData, SetfourxxData] = useState([])
  const [fivexxData, SetfivexxData] = useState([])
  const [NoindexData, SetNoindexData] = useState([])
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  const navigate = useNavigate();
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];





  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;

  const checkUserPlan = async () => {
    const pageIndexReportP = await Utils("page_index", ApiToken);
    if (pageIndexReportP.status) {
      callUserdataApi();
      faqdatafun()
      LoadData()
    }
    setpageIndexReportPlan(pageIndexReportP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);

  const rows = cardData ?? [];
  const LoadData = async (tableid = '') => {
    setLoading(true)
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&chart_data=True`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      var ResultData = [];

      if (datavalue.data) {
        if (datavalue.data.chart_data) {
          ResultData = datavalue.data.chart_data
        }
      }

      const labelsIn = ResultData.map(entry => entry.date.toString());
      SetLabelData(labelsIn)
      const esoindexDataIn = ResultData.map(entry => entry.eso_indexed_pages);
      SetesoindexData(esoindexDataIn)
      const googleindexDataIn = ResultData.map(entry => entry.google_indexed_pages);
      SetgoogleindexData(googleindexDataIn)
      const sitemapDataIn = ResultData.map(entry => entry.Sitemap_pages_count);
      SetsitemapData(sitemapDataIn)
      const fourxxDataIn = ResultData.map(entry => entry.fourxx_pages_count);
      SetfourxxData(fourxxDataIn)
      const fivexxDataIn = ResultData.map(entry => entry.fivexx_pages_count);
      SetfivexxData(fivexxDataIn)
      const NoindexDataIn = ResultData.map(entry => entry.Noindex_pages_count);
      SetNoindexData(NoindexDataIn)
    } catch (err) {

      SetLabelData([])
      SetesoindexData([])
      SetgoogleindexData([])
      SetsitemapData([])
      SetfourxxData([])
      SetfivexxData([])
      SetNoindexData([])
      console.log(err)
    }



    setLoading(false)

  }
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));
      if (datavalue?.data?.result) {
        if (datavalue?.data?.result) {
          const newData = datavalue?.data?.result.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setIndexurl(datavalue?.data?.domain_url)

          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };


  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "header",
      headerName: "Description",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "count",
      headerName: "Count",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: 'Website Url that have been added for the domain',
      disableClickEventBubbling: true,
    },

  ];



  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=53`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const chartData = {
    labels: LabelData,
    datasets: [
      {
        label: "Pages crawled by ESO",
        data: esoindexData,
        borderColor: "orange",
      },
      {
        label: "Pages indexed by Google",
        data: googleindexData,
        borderColor: "Yellow",
      },
      {
        label: "Pages in the Sitemap",
        data: sitemapData,
        borderColor: "#00E1FF",
      },
      {
        label: "Pages with 4xx error",
        data: fourxxData,
        borderColor: "green",
      },
      {
        label: "Pages with 5xx error",
        data: fivexxData,
        borderColor: "red",
      },
      {
        label: "Pages with noindex tag",
        data: NoindexData,
        borderColor: "blue",
      },
    ]
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(

        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Pageindex", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);

  };
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/csv",
            Authorization: `${ApiToken}`,
          },
        }
      );
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Pageindex", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {

        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  };
  return (
    <>
      {loading === true ? <Loader /> : null}
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page Index Report</title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Page Index Report</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {pageIndexReportPlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading} csvdown={csvdown} pdfdown={pdfdown} />

                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>{pagename}</h2>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
          </div>

          {pageIndexReportPlan.status ?
            <div>
              <div className="site_regress_chart_info">
                <div className="site_Chart_info_inner">


                  <Line className="linechartdesign" data={chartData} options={chartOptions} />
                  <p>Page Index Report</p>

                  <div className="rounded-dot-row">
                    <div className="rounded-dot-col">
                      <div class="rounded-dot orange"></div><div>Pages crawled By ESO</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot yellow"></div><div>Pages Indexed By Google</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot blue"></div><div>Pages In The Sitemap</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot green"></div><div>Pages With 4xx Error</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot red" style={{ "background-color": "red" }}></div><div>Pages With 5xx Error</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot red" style={{ "background-color": "blue" }}></div><div>Pages With Noindex Tag</div>
                    </div>
                  </div>


                </div>

              </div>
              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
            </div> : <Freeaudit planStatus={pageIndexReportPlan} />
          }

          {!openCommonAlerts &&
            <CommonErrorAlert
              data={{
                apiopen: apiopen,
                apiErrorMsg: apierrorMsg,
                isIconStyled: true
              }}
              closeAlert={apihandleClose} />
          }

        </div>

      </div>

    </>
  );
}
