import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./style.css";
import pdficon from './../Asset/PDF.png'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import fav_icon from './../Asset/New_Assert/ESO_ICON.png';
import { red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import searchEngine from "../Asset/ESO/search-engine.svg";
import WarningCountimage from "../Asset/esocardicon/SchemaDetail/WarningCount.svg"
import ErrorCountimage from "../Asset/esocardicon/SchemaDetail/ErrorCount.svg"
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Schemadetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [domainsecdata, setDomainsecdata] = useState("")
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [schemaAnalysisPlan, setschemaAnalysisPlan] = useState({ status: true, error: "" });

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value

  var hasNumber = /\d/;


  const [jobDate, setjobDate] = useState("");

  let querystring = useParams();
  let plag_id = querystring.id;
  let jobdataid = querystring.jobid;


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: ""
  });
  let errors = { ...validation };
  let subtitle;


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  /* */
  useEffect(() => {

    setTimeout(() => {

      const keyElements = document.querySelectorAll('.schemaOutputeso .key_val_row .key');

      keyElements.forEach(element => {
        if (element.textContent.trim() !== '') {
          element.classList.add('newClass');
        }
      });

    }, 2000);

  }, []);
  /* */

  const arr = [];

  const checkUserPlan = async () => {
    const schemaAnalysisP = await Utils("schema_analysis", ApiToken);
    if (schemaAnalysisP.status) {
      callUserdataApi();
    }
    setschemaAnalysisPlan(schemaAnalysisP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);
  const columns = [

    {
      field: 'links',
      headerName: 'Page URL',
      width: 600,
      sortable: false,
      disableClickEventBubbling: true,
    },
    {
      field: 'results',
      headerName: 'Status',
      width: 300,
      renderCell: (cardData) => { if (cardData.row.result == "failed") { return (<p className="read_score_org_faile" style={{ color: red }}>{`Fail`}</p>); } else { return (<p className="read_score_gry_success">{`Pass`}</p>); } }
    },
    {
      field: '',
      headerName: 'View',
      width: 300,
      renderCell: (cardData) => { return (<button className="view-report-button">View Report</button>); }
    },

  ];

  const rows = cardData


  const [SchemaUrl, SetSchemaUrl] = useState('')
  const [ErrorCount, SetErrorCount] = useState(0)
  const [WarningCount, SetWarningCount] = useState(0)





  const callUserdataApi = async () => {
    setLoading(true);

    SetSchemaUrl('')
    SetErrorCount(0)
    SetWarningCount(0)

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}schema/schema_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobdataid}&id=${plag_id}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
      if (datavalue?.data) {
        const dataval = datavalue?.data;
        setCardData(dataval);
        SetSchemaUrl(dataval?.table_data[0]?.link__url)
        SetErrorCount(dataval?.table_data[0]?.error_count)
        SetWarningCount(dataval?.table_data[0]?.warning_count)
        var schemaOutputElement = document.getElementById('schemaOutput');
        schemaOutputElement.innerHTML = generateSchemaHTML(dataval?.table_data[0]?.schema_error_json);
      }

    } catch {

    }
    setLoading(false);
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1
    let errors = {};
    if (startDate == "" || endDate == "") {
      errors.dateerror = "This field is required.";
      errs = 2
    }
    else {
      errors.dateerror = "";
      errs = 1
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    var start_date = moment(startDate).format('YYYY-MM-DD')
    var end_date = moment(endDate).format('YYYY-MM-DD')

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/analysis_summary_crawl_dates/?start_date=${start_date}&end_date=${end_date}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    if (datavalue?.data?.result?.length > 0) {
      const dataval = datavalue?.data?.result[0]?.job_id;
      setjobidData(dataval);
      callUserdataApi()

    }
    else {
      handleClickOpen()
    }

  }


  function openModal() {
    setIsOpen(true);
  }



  function closeModal() {
    setIsOpen(false);
  }


  function generateSchemaHTML(schemaData) {




    var output = "<ul>";
    for (var key in schemaData) {
      if (typeof schemaData[key] === 'object') {
        output += "<li class='parent'>" + '' + " " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
      } else {


        if (key === 'required') {

          output += "<li style='color:#ffae1f'><div data-key=" + key + " class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else if (key === 'recommended') {

          output += "<li style='color:#3547d3'><div data-key=" + key + " class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else {

          if (key.startsWith("warning_")) {
            output += "<li style='color:#3547d3'><div data-key=" + key + " class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
          } else {

            if (hasNumber.test(key)) {
              output += "<li><div data-key=" + key + " class='key_val_row'><div class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
            else {
              output += "<li><div data-key=" + key + " class='key_val_row'><div class='key'>" + key + "</div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }

          }
        }

      }
    }
    output += "</ul>";
    return output;
  }

  function generateSchemaHTMLChild(schemaData) {

    var output = "<ul class='child' style='padding: 0px'>";
    for (var key in schemaData) {
      if (typeof schemaData[key] === 'object') {

        if (key === 'required') {
          output += "<li style='color:#ffae1f' data-key=" + key + ">" + generateSchemaHTMLChild(schemaData[key]) + "</li>";
        }
        else if (key === 'recommended') {
          output += "<li style='color:#3547d3' data-key=" + key + ">" + generateSchemaHTMLChild(schemaData[key]) + "</li>";
        }
        else {

          if (key.startsWith("warning_")) {
            output += "<li style='color:#3547d3' data-key=" + key + "> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
          }
          else {

            if (hasNumber.test(key)) {
              output += "<li  data-key=" + key + "> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
            } else {
              output += "<li  data-key=" + key + "> <span class='tle_key'>" + key + "</span> " + generateSchemaHTMLChild(schemaData[key]) + "</li>";
            }
          }



        }



      } else {



        if (key === 'required') {

          output += "<li style='color:#ffae1f' data-key=" + key + "><div class='key_val_row'><div data-key=" + key + " class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else if (key === 'recommended') {

          output += "<li style='color:#3547d3' data-key=" + key + "><div class='key_val_row'><div data-key=" + key + " class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
        }
        else {

          if (key.startsWith("warning_")) {
            output += "<li style='color:#3547d3' data-key=" + key + "><div class='key_val_row'><div data-key=" + key + " class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
          }
          else {

            if (hasNumber.test(key)) {
              output += "<li data-key=" + key + "><div class='key_val_row'><div data-key=" + key + " class='key'></div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
            else {
              output += "<li data-key=" + key + "><div class='key_val_row'><div data-key=" + key + " class='key'>" + key + "</div> <div class='val'>" + schemaData[key] + "</div></div></li>";
            }
          }
        }




      }
    }
    output += "</ul>";
    return output;

  }
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_detaildata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&id=${plag_id}&domain_section_id=${domainsecdata}&job_id=${jobdataid}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SchemaDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(true);

  }

  const cardDatas = [
    { title: "Error Count", value: ErrorCount, iconSrc: ErrorCountimage, altText: "ErrorCount" },
    { title: "Warning Count", value: WarningCount, iconSrc: WarningCountimage, altText: "High Impact Issues" }
  ];

  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schema Detail</title>
      </Helmet>

      <div className="cnt_audit_outer_wrap">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Link to="/schema-analysis">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Schema Analysis</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Schema Analysis Detail </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            {schemaAnalysisPlan.status &&
              <>
                <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
              </>
            }
          </div>

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Schema Checker Analysis Detail  </h2>
            <a target="_blank" href={SchemaUrl} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="spanlink" style={{ flexDirection: "column" }}>
                {SchemaUrl}
              </span>
            </a>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>


          </div>
        </div>


        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h1></h1>
              <p> </p>
            </div>

            <div className="common-card-container" style={{ justifyContent: "center" }}>
              {cardDatas.map(({ title, value, iconSrc, altText }) => (
                <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
              ))}
            </div>

          </div>
        </div>

        <div className="cnt_hdr_content_table">
          <div className="box_wrap schemaOutputeso" id="schemaOutput">

          </div>
        </div>






      </div>

      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: true
        }}
        closeAlert={apihandleClose} />
    </div>
  );
}