import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import "./style.css";
import calendarpng from './Asset/New_Assert/ESO_Icon/Filter.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from "react-modal";
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import fav_icon from './Asset/New_Assert/ESO_ICON.png';
import { red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "./RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "./CommonLoader";
import { AppContext } from "../../Context/Context";
import { Commonfun } from './Commonfun';
import { timezonechange } from './Commonfun';
import { timezoneval } from './Commonfun';
import { datezonechange } from "./Commonfun"
import { Utils, freePlanMsg } from "../../Utils/Utils";
import Freeaudit from "../../Utils/Freeaudit";
import CommonAlert from "../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Select from 'react-select';
import { Breadcrumbs, Typography } from "@mui/material";
import { customDropdownStyles } from "../../Commonalert/commonDropdown";
import { Link } from "react-router-dom";
import searchEngine from "./Asset/ESO/search-engine.svg";
import WarningCount from "./Asset/esocardicon/SchemaDetail/WarningCount.svg"
import ErrorCount from "./Asset/esocardicon/SchemaDetail/ErrorCount.svg"
import { useMediaQuery } from "react-responsive";
import DatagridComp from "./DatagridComp";
import PdfandCsv from "./PdfandCsv";
import Commoncard from "./Commoncard";
import { CommonErrorAlert } from "../../Commonalert/commonErrorAlert";


export default function Schemaanalysis() {

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const detailurl = '/schema-detail/';
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [schemaAnalysisPlan, setschemaAnalysisPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const [jobDate, setjobDate] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)

  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [fataloutCount, setfataloutCount] = useState();
  const [erroroutCount, seterroroutCount] = useState();
  const [warningoutCount, setwarningoutCount] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: ""
  });
  let errors = { ...validation };
  let subtitle;


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };




  const arr = [];
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value)
    callUserdataApi("", passvalue.value);
  }

  const checkUserPlan = async () => {
    const schemaAnalysisP = await Utils("schema_analysis", ApiToken);
    if (schemaAnalysisP.status) {
      FetchJobDates();
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
    }
    setschemaAnalysisPlan(schemaAnalysisP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const columns = [

    {
      field: 'sid',
      headerName: '#',
      description: 'Index of the row',
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: 'link__url',
      headerName: 'URL',
      description: 'Web page URL where the rich snippet is implemented',
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        let baseUrl = cardData.row.link__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external02">
                <a target="_blank" className="table-link" href={cardData?.row?.link__url} >
                  <span className="spanlink" style={{ color: "#044792" }}>
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>

          </>
        );

      },

    },
    {
      field: 'warning_count',
      headerName: 'Warnings',
      description: 'Warning for missing properties or item types in the rich snippets implemented.',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: 'error_count',
      headerName: 'Errors',
      description: 'Error for missing properties or item type in the rich snippets implemented.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: 'view',
      headerName: 'View',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + `${jobidData}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        );
      },
    },


  ];

  const rows = cardData
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "")
      jobid = jobidData;
    setIsOpen(false);
    setCardData([])
    setLoading(true);

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (datavalue?.data) {
        const dataval = datavalue?.data?.table_data;

        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setwarningoutCount(datavalue?.data?.warning_count)
        seterroroutCount(datavalue?.data?.error_count)
        setCardData(newData);
        SetAutoheightval(false)
        setjobidData(datavalue?.data?.job_id)
        setSelectval(datavalue?.data?.job_id)
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }

    } catch (err) {
      console.log('err', err)
    }




    setLoading(false)

  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1
    let errors = {};


    if (startDate == "" || endDate == "") {
      errors.dateerror = "This field is required.";
      errs = 2
    }
    else {
      errors.dateerror = "";
      errs = 1
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    var start_date = moment(startDate).format('YYYY-MM-DD')
    var end_date = moment(endDate).format('YYYY-MM-DD')

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/analysis_summary_crawl_dates/?start_date=${start_date}&end_date=${end_date}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    if (datavalue?.data?.result.length > 0) {
      const dataval = datavalue?.data.result[0].job_id;
      setjobidData(dataval);
      callUserdataApi()

    }
    else {
      handleClickOpen()
    }

  }


  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setSelectval('')
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);
  }

  const FetchJobDates = async () => {


    try {

      const completevalues = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=schema&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var Selectedmets = [];

      if (completevalues?.data?.jobs.length > 0) {
        completevalues?.data?.jobs.forEach((element) => {
          Selectedmets.push({
            value: element.id,
            label: datezonechange(element.completed_at),
          });
        });
        SetdEngineoption(Selectedmets[0])
        setprevdEngineoption(Selectedmets[0]);
        setcompletedate(Selectedmets)
      }

    } catch (err) {
      console.log('err', err)
    }





  }




  const FetchList = () => {

    var errs = 1;
    let errors = {};

    setprevdEngineoption(dEngineoption);

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    var domainsec = "";
    callUserdataApi(selectval?.value, domainsec = "");

  }



  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('schema')
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Schemaanalysis", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Schemaanalysis", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {

        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=18`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const cardDatas = [
    { title: "Error Count", value: erroroutCount ? erroroutCount : 0, iconSrc: ErrorCount, altText: "Error Count" },
    { title: "Warning Count", value: warningoutCount ? warningoutCount : 0, iconSrc: WarningCount, altText: "Warning Count" },
  ];


  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Schema Analysis</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to={"/"}>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>
                Dashboard
              </Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Schema Analysis</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {schemaAnalysisPlan.status &&
              <>
                <PdfandCsv
                  Downloadval={1}
                  isDownloadingcsv={isDownloadingcsv}
                  isDownloading={isDownloading}
                  csvdown={csvdown}
                  pdfdown={pdfdown}
                />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Schema Analysis </h2>
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={openModal} />
              </span>
            </div>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div title="Domain Section">
              <Select
                options={SectionOptions}
                name="name"
                onChange={SetSectionNameHandle}
                placeholder="Domain Section"
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                classNamePrefix="common-domain-dropdown"
              />
            </div>
            <button
              title="Run Report"
              className="runreportnew over_all_accessibility"
              onClick={() => {
                if (first.statusDomain) {
                  setcheckD(first.statusDomain);
                } else {
                  setfaqData(2)
                  setToggle(false)
                  SetRunReportPopShow(true);
                }
              }}
              style={{ letterSpacing: "0.8px", width: "150px", lineHeight: "12px" }}
              disabled={!schemaAnalysisPlan.status}
            >
              Run Report Now
            </button>
          </div>
        </div>

        {schemaAnalysisPlan.status ?

          <div>
            <div className="cnt_hdr_content">
              <div className="box_wrap">
                <div>
                  <h1></h1>
                  <p></p>
                </div>

                <div className="common-card-container" style={{ justifyContent: "center" }}>
                  {cardDatas.map(({ title, value, iconSrc, altText }) => (
                    <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
                  ))}
                </div>

              </div>
            </div>
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </div> : <Freeaudit planStatus={schemaAnalysisPlan} />

        }


        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="">
                    <div>
                      <span>Completed Date</span>

                      <Select
                        value={dEngineoption}
                        options={completedate}
                        placeholder="Select..."
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          setSelectval(values)
                          SetdEngineoption(values);
                        }}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    </div>


                  </div>

                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>


                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button className="form_submit_btn" onClick={FetchList} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog>




        {!openCommonAlerts && <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>}
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
            <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
          </DialogActions>
        </Dialog>

      </div>

      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />

    </div>
  );
}