import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


export const CommonErrorAlert = ({data, closeAlert}) => {

    return (
        <div>
            <Dialog
                open={data.apiopen}
                onClose={closeAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        borderRadius: "18px !important",
                        top: "10px !important",
                    },
                }}

            >
                <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}>
                     { data.isIconStyled ? <ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /> : 'Alert!!!' }
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {data.apiErrorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
                    <Button onClick={closeAlert}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};