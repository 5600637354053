import React, { useState, useEffect, useRef, useContext } from "react";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Siteauditlists from "./Siteauditlists";
import Onpagecrawllist from "./Onpagecrawllist";
import Pagehealthlists from "./Pagehealthlists";
import CompetitorTest from "./CompetitorTest";
import { Link, useParams } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { ContactPageSharp } from "@mui/icons-material";
import { AppContext } from "../../../Context/Context";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils, freePlanMsg } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Sitecompetoranalysis() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [menubutval, setMenubutval] = useState("1")
  const [buttnval, setButtnval] = useState("1")
  const [buttnclickval, setButtnclickval] = useState("1")
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [pagename, setPagename] = useState("Competitor Analysis")
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [jobDate, setJobdate] = React.useState(false);
  const [competitorTestPlan, setcompetitorTestPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [jobIdvalue, setJobIdvalue] = React.useState("");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [CompetitorDomainId, SetCompetitorDomainId] = useState(null);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  let querystring = useParams();
  let domain_id = querystring.id;
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const menuChange = e => {
    e.preventDefault();
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if (e.target.id == 1)
      setPagename("Site Audit")
    else if (e.target.id == 2)
      setPagename("On Page Crawl")
    else if (e.target.id == 3)
      setPagename("Page Health")
    else if (e.target.id == 4)
      setPagename("Competitor Analysis")
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;

  const checkUserPlan = async () => {
    const competitorTestP = await Utils("competitor_analysis", ApiToken);
    if (competitorTestP.status) {
      faqdatafun()
    }
    setcompetitorTestPlan(competitorTestP);
  };

  useEffect(() => {
    checkUserPlan();
    setMenubutval(domain_id)
    setButtnval(domain_id)
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    var compDomainId;
    if (CompetitorDomainId != null) {
      compDomainId = CompetitorDomainId;
    } else {
      compDomainId = esodata.competitordomainids.value[0]?.value;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/competitor_analysis/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobIdvalue}&is_format=pdf&competitor_domain_id=${compDomainId}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("CompetitorTest", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      if (error?.status == 599 || error?.status == 500 || error?.status == 400 || error?.status == 404) {
        error.json().then(data => {
          setapiOpen(true);
          setapierrorMsg(data?.error)
        })
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
    }
    setLoading(false);
    setIsDownloading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    var compDomainId;
    if (CompetitorDomainId != null) {
      compDomainId = CompetitorDomainId;
    } else {
      compDomainId = esodata.competitordomainids.value[0]?.value;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/competitor_analysis/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobIdvalue}&is_format=csv&competitor_domain_id=${compDomainId}&timezone=${timezoneval()}`, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("CompetitorTest", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      if (error?.status == 599 || error?.status == 500 || error?.status == 400 || error?.status == 404) {
        error.json().then(data => {
          setapiOpen(true);
          setapierrorMsg(data?.error)
        })
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
  };
  function openModal() {
    if (competitorTestPlan.status) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }
  const renderSwitch = (params) => {
    switch (params) {
      case "1":
        return <Siteauditlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} onbutnvaldata={setButtnclickval} setJobdate={setJobdate} jobDate={jobDate} />
      case "2":
        return <Onpagecrawllist modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
      case "3":
        return <Pagehealthlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
      case "4":
        return competitorTestPlan.status ? <CompetitorTest modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobIdvalue={setJobIdvalue} SetCompetitorDomainId={SetCompetitorDomainId} setJobdate={setJobdate} jobDate={jobDate}
          compId1={esodata?.competitorinjobids?.value[0]?.value || false} compId2={esodata?.competitorjobids?.value[0]?.value || false} compId3={esodata?.competitordomainids?.value[0]?.value || false} /> : <Freeaudit planStatus={competitorTestPlan} />
    }
  }
  const onpageref = useRef(null)
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('site_audit')
  const faqdatafun = async (menubutval = "") => {

    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=17`


    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pagename}</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Typography sx={{ color: 'grey' }}>Site Analysis</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Competitor Analysis</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {competitorTestPlan.status &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Competitor Analysis</h2>
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={openModal} />
              </span>
            </div>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <Link to={competitorTestPlan.status ? "/competitor-analysis-ignore-metrics/4" : null} >
              <button className="runreportnew over_all_accessibility runreportnew" disabled={!competitorTestPlan.status}>
                Ignored Metrics
              </button></Link>
            <button
              title="Run Report"
              className="runreportnew over_all_accessibility runreportnew"
              onClick={() => {
                if (first.statusDomain) {
                  setcheckD(first.statusDomain);
                } else {
                  SetRunReportPopShow(true)
                }
              }}

              disabled={!competitorTestPlan.status}
            >
              Run Report Now
            </button>
          </div>
        </div>

        <div className="link_cnt_hdr_blw_wrap googleanlytics">
          <Link to="/site-audit/1/" ><button type="button" id={1} className={`runreportnews ${buttnval === "1" ? 'active' : ""}`}  >Site Audit</button></Link>

          <Link to="/onpage-crawl-data/2/1" > <button type="button" ref={onpageref} id={2} className={`runreportnews ${buttnval === "2" ? 'active' : ""}`}  >On Page Crawl Data</button></Link>

          <Link to="/page-health/3/" > <button type="button" id={3} className={`runreportnews ${buttnval === "3" ? 'active' : ""}`} >Page Health</button></Link>

          <Link to="/competitor-analysis/4/" ><button type="button" id={4} className={`runreportnews ${buttnval === "4" ? 'active' : ""}`}  >Competitor Analysis</button></Link>
        </div>

        {!openCommonAlerts &&

          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: false
            }}
            closeAlert={apihandleClose} />

        }
        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
            <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
          </DialogActions>
        </Dialog>
      </div>
      {renderSwitch(menubutval)}
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </div>
  );
}
