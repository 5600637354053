import React, { useState, useEffect, useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Organicsearchtraffic from "./Organicsearchtraffic";
import Trafficacquisition from "./Trafficacquisition";
import Demographicdetails from "./Demographicdetails";
import TechDetails from "./TechDetails";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function OrganicsearchClicks(props) {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [dimendataval, setDimendataval] = useState("");
  const [selmetricval, setSelmetricval] = useState("");
  const [loading, setLoading] = useState(false);



  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value

  const [CurrentMenu, SetCurrentMenu] = useState('organic_search_traffic')
  const [CurrentMenuName, SetCurrentMenuName] = useState('Google Organic Search Traffic')
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();


  const [rankSummaryPlan, setrankSummaryPlan] = useState({ status: false, error: "" });
  const [rankVisibilityScorePlan, setrankVisibilityScorePlan] = useState({ status: false, error: "" });
  const [rankComparisonPlan, setrankComparisonPlan] = useState({ status: false, error: "" });
  const [keywordTrendPlan, setkeywordTrendPlan] = useState({ status: false, error: "" });

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);



  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SwitchMenu = (e) => {

    const Id = e.target.getAttribute('data-id')
    const Name = e.target.getAttribute('data-name')
    SetCurrentMenu(Id)
    SetCurrentMenuName(Name)
    faqdatafun(Name)
  }
  const handleClose = () => {
    setOpen(false);
  };


  const [FilterButtonTriggered, SetFilterButtonTriggered] = useState(false)



  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const FilterFunction = () => {
    SetFilterButtonTriggered(true)
  }
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    if (CurrentMenu === 'organic_search_traffic')
      var urldata = "google_organic_search_traffic";
    else if (CurrentMenu === 'traffic_acquisition')
      var urldata = "google_traffic_acquisition";
    else if (CurrentMenu === 'demographic_details')
      var urldata = "demographic_details";
    else if (CurrentMenu === 'tech_details')
      var urldata = "tech_details";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/${urldata}/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${dimendataval}&selected_metrics=${selmetricval}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (CurrentMenu === 'organic_search_traffic')
          var googledata = "Google Organic Search Traffic";
        else if (CurrentMenu === 'traffic_acquisition')
          var googledata = "Traffic Acquisition";
        else if (CurrentMenu === 'demographic_details')
          var googledata = "Demographic Details";
        else if (CurrentMenu === 'tech_details')
          var googledata = "Tech Details";
        var filedata = await Commonfun(googledata, "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    if (CurrentMenu === 'organic_search_traffic')
      var urldata = "google_organic_search_traffic";
    else if (CurrentMenu === 'traffic_acquisition')
      var urldata = "google_traffic_acquisition";
    else if (CurrentMenu === 'demographic_details')
      var urldata = "demographic_details";
    else if (CurrentMenu === 'tech_details')
      var urldata = "tech_details";
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/${urldata}/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${dimendataval}&selected_metrics=${selmetricval}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (CurrentMenu === 'organic_search_traffic')
          var googledata = "Google Organic Search Traffic";
        else if (CurrentMenu === 'traffic_acquisition')
          var googledata = "Traffic Acquisition";
        else if (CurrentMenu === 'demographic_details')
          var googledata = "Demographic Details";
        else if (CurrentMenu === 'tech_details')
          var googledata = "Tech Details";
        var filedata = await Commonfun(googledata, "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  useEffect(() => {
    var currentMenus = CurrentMenu;
    faqdatafun(currentMenus)
  }, [dimendataval, selmetricval])
  
  const faqdatafun = async (menubutval = "") => {
    if (menubutval === 'organic_search_traffic')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=33`
    else if (menubutval === 'traffic_acquisition')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=34`
    else if (menubutval === 'demographic_details')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=35`
    else if (menubutval === 'tech_details')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=36`


    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };


  const freeAudit = async () => {


    const rankSummaryPlan = await Utils("organic_search", ApiToken);
    const rankVisibilityScorePlan = await Utils("traffic_acquisition", ApiToken);
    const rankComparisonPlan = await Utils("demographic", ApiToken);
    const keywordTrendPlan = await Utils("tech_details", ApiToken);
    setrankSummaryPlan(rankSummaryPlan);
    setrankVisibilityScorePlan(rankVisibilityScorePlan);
    setrankComparisonPlan(rankComparisonPlan);
    setkeywordTrendPlan(keywordTrendPlan);


  }


  useEffect(() => {

    freeAudit();


  }, [])


  const [checkingDiv, setcheckingDiv] = useState();
  const [checkingDiv1, setcheckingDiv1] = useState();
  const [checkingDiv2, setcheckingDiv2] = useState();
  const [checkingDiv3, setcheckingDiv3] = useState();


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Google Organic Search Traffic</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {loading === true ? <Loader /> : null}

      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: grey }}>Google Analytics</Typography>
            <Typography sx={{ color: grey }}>Analytics Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>{CurrentMenuName}</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {(CurrentMenu === 'organic_search_traffic' && rankSummaryPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
            {(CurrentMenu === 'traffic_acquisition' && rankComparisonPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
            {(CurrentMenu === 'demographic_details' && rankVisibilityScorePlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
            {(CurrentMenu === 'tech_details' && keywordTrendPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">{CurrentMenuName}</h2>
          </div>

          {(CurrentMenu === 'organic_search_traffic' && rankSummaryPlan.status) && checkingDiv}
          {(CurrentMenu === 'traffic_acquisition' && rankComparisonPlan.status) && checkingDiv1}
          {(CurrentMenu === 'demographic_details' && rankVisibilityScorePlan.status) && checkingDiv2}
          {(CurrentMenu === 'tech_details' && keywordTrendPlan.status) && checkingDiv3}
        </div>

        <div className="link_cnt_hdr_blw_wrap googleanlytics">

          <button data-id="organic_search_traffic" className={CurrentMenu === 'organic_search_traffic' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Organic Search Traffic'>
            Organic Search Traffic
          </button>

          <button data-id="traffic_acquisition" className={CurrentMenu === 'traffic_acquisition' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Traffic Acquisition'>
            Traffic Acquisition
          </button>
          <button data-id="demographic_details" className={CurrentMenu === 'demographic_details' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Demographic Details'>
            Demographic Details
          </button>
          <button data-id="tech_details" className={CurrentMenu === 'tech_details' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Tech Details'>
            Tech Details
          </button>


        </div>

        {

          CurrentMenu === 'organic_search_traffic' ? (rankSummaryPlan.status ? <Organicsearchtraffic setcheckingDiv={setcheckingDiv} RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval} /> : <Freeaudit planStatus={rankSummaryPlan} />) : null
        }

        {

          CurrentMenu === 'traffic_acquisition' ? (rankComparisonPlan.status ? <Trafficacquisition setcheckingDiv1={setcheckingDiv1} RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval} /> : <Freeaudit planStatus={rankComparisonPlan} />) : null

        }

        {

          CurrentMenu === 'demographic_details' ? (rankVisibilityScorePlan.status ? <Demographicdetails setcheckingDiv2={setcheckingDiv2} RankJob={selectval} setSelectval={setSelectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval} /> : <Freeaudit planStatus={rankVisibilityScorePlan} />) : null

        }

        {

          CurrentMenu === 'tech_details' ? (keywordTrendPlan.status ? <TechDetails setcheckingDiv3={setcheckingDiv3} RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} dimendataval={dimendataval} setDimendataval={setDimendataval} selmetricval={selmetricval} setSelmetricval={setSelmetricval} /> : <Freeaudit planStatus={keywordTrendPlan} />) : null

        }
      </div>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />
      }
    </div>
  );
}
