import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Stepperformhead(props) {

    const [activeStep, setActiveStep] = useState(0);

    const [first, setfirst] = useState(false);

    const steps = activeStep < 2 || first
        ? [
            "USER DETAILS",
            "IMPORT OR ADD DOMAIN",
            "GOOGLE ANALYTICS",
        ]
        : [
            "USER DETAILS",
            "IMPORT OR ADD DOMAIN",
            "DOMAIN DETAILS",
            "DOMAIN OWNERSHIP VERIFICATION",
            "GOOGLE ANALYTICS",
        ];

    useEffect(() => {
        if (props.setActiveStep !== undefined) {
            setActiveStep(props.setActiveStep);
            if(props.setfirst){
                setfirst(props.setfirst);
            }
        }
    }, [props.setActiveStep]);

    

    const navigate = useNavigate();


    return (
        <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: "66px",
            marginBottom: "10px",
            flexWrap: "wrap",
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: "40px 0px",
            '@media (max-width: 1024px)': {
                justifyContent: 'center',
                gap: '10px'
            }

        }} >
            <Box></Box>
            <Box
                className="stepperFormclass"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    gap: '10px'
                }}
            >
                {steps.map((label, index) => (
                    <React.Fragment key={index}>
                        <Box
                            sx={(theme) => ({
                                padding: "1px 32px",
                                backgroundColor: activeStep >= index ? "#FF601F" : "white",
                                color: activeStep >= index ? "white" : "black",
                                borderRadius: "20px",
                                fontWeight: activeStep >= index ? "bold" : "normal",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "0 8px",
                                cursor: "auto",
                                border: activeStep >= index ? "none" : "1px solid #ddd",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                textAlign: "center",
                                height: "40px",
                                zIndex: 1,
                                gap: "10px",
                                fontSize: "14px",
                                [theme.breakpoints.down("sm")]: {
                                    padding: "1px 16px",
                                    fontSize: "12px",
                                    margin: "4px",
                                },
                            })}
                        >
                            {label}
                        </Box>

                        {index < steps.length - 1 && (
                            <Box
                                sx={(theme) => ({
                                    mx: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderBottom: activeStep > index ? "3px solid #FF601F" : "3px dotted #ddd",
                                    width: "30px",
                                    height: "2px",
                                    zIndex: 0,
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                    [theme.breakpoints.down("sm")]: {
                                        width: "20px",
                                    },
                                })}
                            />
                        )}
                    </React.Fragment>
                ))}

            </Box>
        </Box>
    )
}

export default Stepperformhead