import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LiquidFillGauge from "react-liquid-gauge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import { SetredirectValue } from "../../../Store/PageredirectSlice";
import { Commonfun, datezonechange } from './../Commonfun';

import Loader from "../CommonLoader";
import Tooltip from "@mui/material/Tooltip";
import Onpagecrawllist from "../Auditsummary";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from "@mui/material/Button";
import useMediaQuery from '@mui/material/useMediaQuery';
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';
import plagiarismscanreport_total_wordson_pagesvg from "../Asset/esocardicon/plagiarism/plagiarismscanreport_total_wordson_page.svg"
import plagiarism_totalmatching_urlsvg from "../Asset/esocardicon/plagiarism/plagiarism_scan_report _total_matching_word.svg"
import plagiarismreport_percentage_matchingsvg from "../Asset/esocardicon/plagiarism/plagiarismreport_percentage_matching.svg"
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
export default function Siteauditlists(props) {
  const isMobile = useMediaQuery('(max-width:480px)');

  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [serveritydata, setServeritydata] = useState([]);
  const [value, setValue] = useState("1");
  const [sitechartvalue, setSiteChartvalue] = useState("4");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [autoheightval, SetAutoheightval] = useState(true)
  const [autoheightval1, SetAutoheightval1] = useState(true)
  const [autoheightval2, SetAutoheightval2] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [JobId, SetJobId] = useState("");


  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const apihandleClose = () => {
    setapiOpen(false);
  };


  const handleChange = (event, newValue) => {
    const scrollY = window.scrollY;
    setValue(newValue);
    setTimeout(() => {
      window.scrollTo({ top: scrollY, behavior: "smooth" });
    }, 10);
  };
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#0b2033";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const handlechartChange = (event, newValue) => {
    setSiteChartvalue(newValue);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const arr = [];

  const SeoMetricsArray = [

    {
      key: 'no_index_robot',
      value: 'The web pages must be accessible to Search engines for better SEO results. This metric will check if the web page is accessible to search engines'
    },
    {
      key: 'missing_canonical',
      value: 'Canonical URL informs search engines that a specific URL represents the source of the content for a web page. This metrics will check for a canonical URL or tag on the web page'
    },
    {
      key: 'missing_titles',
      value: 'The title tag specifies the web page title. This metrics will show the total of webpages which have missing title'
    },
    {
      key: 'duplicate_h1_tags',
      value: 'H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have multiple H1 tags'
    },
    {
      key: 'missing_h1_tags',
      value: 'H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have missing H1 tags'
    },
    {
      key: 'hreflang',
      value: "Hreflang is an HTML attribute used to specify a web page's language and geographic target. This metrics displays the total count of webpages using the hreflang attribute"
    },
    {
      key: 'pagination_prev_or_next',
      value: "This metrics displays the count of Page URLs which have next and previous URLs"
    },
    {
      key: 'duplicate_titles',
      value: "The title tag specifies the web page title. This metrics will show the total of webpages which have same title"
    },
    {
      key: 'duplicate_descriptions',
      value: "The description tag specifies the description of the web page. This metrics populate the total number of webpages that have the same description"
    },
    {
      key: 'missing_descriptions',
      value: "The description tag specifies the description of the web page. This metrics populate the total number of webpages which have missing description"
    },
    {
      key: 'missing_alt_tags',
      value: "The ALT tag describes the presence or function of an image on a web page. This metrics will show the total count of images on a web page which has missing ALT tags"
    },
    {
      key: 'h1_tags_available',
      value: "H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have an H1 tag"
    },
    {
      key: 'images_available',
      value: "Images Available	This metrics provides the total number of images available in the domain"
    },
    {
      key: 'server_error_5xx_link',
      value: "5xx status code means that the server failed to complete a request. This metrics displays the count of  web pages which return 5xx status code"
    },
    {
      key: 'broken_links_4xx_link',
      value: "4xx status code means that the request for a web page or other resource contains a bad syntax. This metrics displays the count of  web pages which return 4xx status code"
    }


  ];

  function findObjectByKeyValue(array, key, value) {
    return array.find(obj => obj[key] === value);
  }
  const pageRedirectfun = (e) => {
    localStorage.setItem("eso_redirect_value", e.currentTarget.id);
    dispatch(SetredirectValue(e.currentTarget.id));
    navigate('/internal-link-checker')

  }
  const onbuttonClick = (e) => {
    props.onbutnvaldata(e.currentTarget.id);
    setIsOpen(true);
  }
  useEffect(() => {
    CloseModalTrigger();
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));
    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify === 'true' ? true : false);
    callUserdataApi();
  }, []);
  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description: "On-page SEO elements are present on the website.",
      minWidth: 350,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        var ToolTipTitle = '';
        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.seo_metrics);
        let baseUrl = cardData.row.seo_metrics.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.seo_metrics.split("/").slice(3).join("/");

        if (result !== undefined) {
          ToolTipTitle = result.value
        }
        if (ToolTipTitle != '') {
          if (cardData.row.seo_metrics === "Missing ALT Tags") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external02">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/18"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Hreflang") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/9"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Missing Canonical") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/14"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Duplicate Titles") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/4"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Duplicate Descriptions") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/5"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Missing H1 Tags") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/16"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Missing Descriptions") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/15"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Multiple H1 Tags") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/19"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Duplicate H1 Tags") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/7"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Missing Titles") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/2"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Mismatch Canonical") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/3"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "No-index robot") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/17"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.seo_metrics === "Pagination Next or Prev") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <Link to="/onpage-crawl-data/2/8"> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else {
            return (
              <Tooltip title={ToolTipTitle}>
                <p>{cardData.row.seo_metrics}</p>
              </Tooltip>
            );
          }

        }
        if (cardData.row.seo_metrics === "Missing ALT Tags") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/18">
                <LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p>
              </Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Hreflang") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/9">
                <LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p>
              </Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Missing Canonical") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/14"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Duplicate Titles") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/4"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Duplicate Descriptions") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/5"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Missing H1 Tags") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/16"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Missing Descriptions") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/15"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Multiple H1 Tags") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/19"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Duplicate H1 Tags") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/7"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Missing Titles") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/2"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Mismatch Canonical") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/3"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "No-index robot") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/17"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else if (cardData.row.seo_metrics === "Pagination Next or Prev") {
          return (
            <div className="prefered_url_external">
              <Link to="/onpage-crawl-data/2/8"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.seo_metrics}</p></Link>
            </div>
          );
        }
        else {
          return (
            <p>{cardData.row.seo_metrics}</p>
          );
        }

      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: "SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific Occurrences present on the website.",
      minWidth: 350,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
  ];
  const column1 = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description: "Website metrics are present on the website.",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        var ToolTipTitle = '';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.site_metrics);

        if (result !== undefined) {
          ToolTipTitle = result.value
        }

        if (ToolTipTitle != '') {
          if (cardData.row.site_metrics === "Images Available") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <Link to="/onpage-crawl-data/2/6"><LinkIcon /> <p className="linkdata_value table_links">{cardData.row.site_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.site_metrics === "H1 Tags Available") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <Link to="/onpage-crawl-data/2/20"><LinkIcon /> <p className="linkdata_value table_links"> {cardData.row.site_metrics}</p></Link>
                </div>
              </Tooltip>
            );
          }
          else {
            return (
              <Tooltip title={ToolTipTitle}>
                <p className="linkdata_value">{cardData.row.site_metrics}</p>
              </Tooltip>
            );
          }
        }
        else {
          if (cardData.row.site_metrics === "Images Available") {
            return (
              <div className="prefered_url_external">
                <Link to="/onpage-crawl-data/2/6"><LinkIcon /> <p className="linkdata_value table_links"> {cardData.row.site_metrics}</p></Link>
              </div>
            );
          }
          else if (cardData.row.site_metrics === "H1 Tags Available") {
            return (
              <div className="prefered_url_external">
                <Link to="/onpage-crawl-data/2/20"><LinkIcon /> <p className="linkdata_value table_links"> {cardData.row.site_metrics}</p></Link>
              </div>
            );
          }
          else {
            <p className="linkdata_value">{cardData.row.site_metrics}</p>
          }


        }

      },

    },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific Occurrences present on the website.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  const column2 = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: "It displays server error links (5xx) and broken links (4xx) on the website.",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {

        var ToolTipTitle = '';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.internal_links);

        if (result !== undefined) {
          ToolTipTitle = result.value
        }



        if (ToolTipTitle != '') {
          if (cardData.row.internal_links === "Broken Links 4xx Link") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <p className="linkdata_value table_links">{cardData.row.internal_links}</p>
                </div>
              </Tooltip>
            );
          }
          else if (cardData.row.internal_links === "Server Error 5xx Link") {
            return (
              <Tooltip title={ToolTipTitle}>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <p className="linkdata_value table_links">{cardData.row.internal_links}</p>
                </div>
              </Tooltip>
            );
          }
        }

        else {
          if (cardData.row.internal_links === "Broken Links 4xx Link") {
            return (
              <>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <p className="linkdata_value table_links" onClick={pageRedirectfun} id="1">{cardData.row.internal_links}</p>
                </div>
              </>
            );
          }
          else if (cardData.row.internal_links === "Server Error 5xx Link") {
            return (
              <>
                <div className="prefered_url_external">
                  <LinkIcon />
                  <p className="linkdata_value table_links" onClick={pageRedirectfun} id="2">{cardData.row.internal_links}</p>
                </div>
              </>
            );
          }

        }

      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: "SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific Occurrences present on the website.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  const rows = cardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    const completevalues = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    if (completevalues?.data?.jobs.length > 0) {
      var jobSections = [];
      completevalues?.data?.jobs.forEach((element) => {
        jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
      });
      setcompletedate(jobSections);
      if (jobidData === "") {
        SetJobId(jobSections[0].value);
        SetdEngineoption(jobSections[0])
        setprevdEngineoption(jobSections[0])
      }
    }

    const chartvalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/siteaudit/graph/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    if (datavalue?.data) {
      const dataval = datavalue?.data?.seo_metrics_list;
      const dataval1 = datavalue?.data?.site_metrics_list;
      const dataval2 = datavalue?.data?.internal_links_list;
      const chartval = chartvalue?.data?.issue_graph;
      const severity_dataarrvals = [];
      severity_dataarrvals[0] = chartvalue?.data?.high_serverity_graph;
      severity_dataarrvals[1] = chartvalue?.data?.medium_severity_graph;
      severity_dataarrvals[2] = chartvalue?.data?.low_severity_graph;
      severity_dataarrvals[3] = chartvalue?.data?.high_count;
      severity_dataarrvals[4] = chartvalue?.data?.medium_count;
      severity_dataarrvals[5] = chartvalue?.data?.low_count;
      setServeritydata(severity_dataarrvals);
      const seoindex = chartval?.map(function (val, index) {
        return val.seo_metrics;
      });
      const seooccurence = chartval?.map(function (val, index) {
        return val.occurences;
      });


      setSeoindexData(seoindex);
      setSeooccurData(seooccurence);


      if (dataval) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });

        if (dataval && dataval.length > 0) {
          SetAutoheightval(false)
        }
        else {
          SetAutoheightval(true)
        }

        setCardData(newData);
        props.setJobdate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      }

      if (dataval1) {
        const newData1 = dataval1.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (dataval1 && dataval1.length > 0) {
          setSitecardData(newData1);
          SetAutoheightval1(false)
        }
        else {
          setSitecardData("");
          SetAutoheightval1(true)
        }
      }

      if (dataval2) {
        const newData2 = dataval2.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (dataval2 && dataval2.length > 0) {
          setInternalcardData(newData2);
          SetAutoheightval2(false)
        }
        else {
          setInternalcardData("");
          SetAutoheightval2(true)
        }
      }

      setLoading(false);
    };

  }

  const preprocessData = (data) => {
    var percentage = (Math.min(...data) / Math.max(...data)) * 100;
    if (percentage < 1) {
      return data.map((value) => {
        var valuePercentage = (value / Math.max(...data)) * 100;
        if (valuePercentage > 0 && valuePercentage < 1) {
          var onePercent = (1 / 100) * Math.max(...data);
          return onePercent;
        }
        return value;
      });
    } else {
      return data;
    }
  };

  const processedData = preprocessData(seooccurData);

  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: "Occurrence",
        data: processedData,
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: "#FE6020"
      },
    ],
  };

  const CloseModalTrigger = () => {
    props.setIsOpen(false);
    SetdEngineoption(prevdEngineoption);
  };

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (JobId === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }

    if (JobId !== "") {
      callUserdataApi();
      props.setIsOpen(false);
      setprevdEngineoption(dEngineoption);
      if (props.setJobdatesval)
        props.setJobdatesval(JobId);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Site Audit</title>
      </Helmet>
      <p className=" dummysiteheader" >
      </p>

      <div className="tabs_graph_outer_wrap">
        <TabContext value={sitechartvalue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handlechartChange}
              aria-label="lab API tabs example"
            >
              <Tooltip title="Clicking on this option displays an issue-based chart for the SEO elements">
                <Tab
                  className="tabbutton"
                  id={sitechartvalue === "4" ? "activetabj" : ""}
                  label="By Issue"
                  value="4"
                />
              </Tooltip>

              <Tooltip title="Clicking on this option, the current Severity based percentage pie chart comparison will be displayed">
                <Tab
                  className="tabbutton"
                  id={sitechartvalue === "5" ? "activetabj" : ""}
                  label="By Severity"
                  value="5"
                />
              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="4">

            <div className="site_regress_chart_info">
              <div className="site_Chart_info_inner">
                <Bar
                  type="bar"
                  width={130}
                  height={isMobile ? 150 : 50}
                  data={barChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: (context) => {
                            return `Value: ${seooccurData[context.dataIndex]}`;
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Site Audit Summary by Issues",
                        color: "#0b2033",
                        font: {
                          size: 18,
                          family: 'Poppins',
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          suggestedMin: 0,
                          stacked: true,
                          color: "#0b2033",
                          font: {
                            size: 14,
                            family: 'Poppins',
                          },
                          beginAtZero: true,
                        },
                      },
                      x: {
                        ticks: {
                          color: "#0b2033",
                          font: {
                            size: 12,
                            family: 'Poppins',
                          },
                          beginAtZero: true,
                        },
                      },
                    },
                  }}
                />
                <p style={{ color: "black" }} >SEO Metrics</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="5">
            <div className="common-card-container" style={{ justifyContent: "center" }}>
              <div className={`common-card-wrapper-style cardactivecontainer`}>
                <div className={`common-card-icon-container`}>
                  <div className={`common-card-icons`}>
                    <img src={plagiarismscanreport_total_wordson_pagesvg} className={`common-card-icon-img`} alt=" Total Words on Pages" />
                  </div>
                </div>
                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                  <span className="common-card-title">  High Impact Issue Occurrences -- {serveritydata[3]} </span>
                  <span className="common-card-value">
                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={50}
                      height={50}
                      value={serveritydata[0]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: "#0b2033",
                      }}
                      waveStyle={{
                        fill: "rgb(255,174,31)",
                      }}
                      textStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className={`common-card-wrapper-style cardactivecontainer`}>
                <div className={`common-card-icon-container`}>
                  <div className={`common-card-icons`}>
                    <img src={plagiarism_totalmatching_urlsvg} className={`common-card-icon-img`} alt="  Total Words" />
                  </div>
                </div>
                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                  <span className="common-card-title">     Medium Impact Issue Occurrences -- {serveritydata[4]}</span>
                  <span className="common-card-value">

                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={50}
                      height={50}
                      value={serveritydata[1]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: "#0b2033",
                      }}
                      waveStyle={{
                        fill: "rgb(255,174,31)",
                      }}
                      textStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </span>
                </div>
              </div>

              <div className={`common-card-wrapper-style cardactivecontainer`}>
                <div className={`common-card-icon-container`}>
                  <div className={`common-card-icons`}>
                    <img src={plagiarismreport_percentage_matchingsvg} className={`common-card-icon-img`} alt="  Total Words" />
                  </div>
                </div>
                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                  <span className="common-card-title"> Low Impact Issue Occurrences -- {serveritydata[5]} </span>
                  <span className="common-card-value">

                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={50}
                      height={50}
                      value={serveritydata[2]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: "#0b2033",
                      }}
                      waveStyle={{
                        fill: "rgb(255,174,31)",
                      }}
                      textStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#0b2033").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>

          </TabPanel>
        </TabContext>
      </div>
      <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
        <TabContext value={value} >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" TabIndicatorProps={{ style: { display: "none" } }}>
              <Tooltip title="On-page SEO elements present in the website">
                <Tab
                  label="SEO Metrics"
                  id={value === "1" ? "activetabj" : ""}
                  value="1"
                />
              </Tooltip>

              <Tooltip title="Website Metrics present in the website">
                <Tab
                  label="Site Metrics"
                  id={value === "2" ? "activetabj" : ""}
                  value="2"
                />
              </Tooltip>

              <Tooltip title="It displays Server Error Links (5xx) and Broken Links (4xx) in the website">
                <Tab
                  label="Internal Links"
                  id={value === "3" ? "activetabj" : ""}
                  value="3"
                />
              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="1" >
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </TabPanel>
          <TabPanel value="2">
            <DatagridComp
              columns={column1}
              rows={rows1}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </TabPanel>
          <TabPanel value="3">
            <DatagridComp
              columns={column2}
              rows={rows2}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </TabPanel>
        </TabContext>
      </div>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      value={dEngineoption}
                      options={completedate}
                      placeholder="Select..."
                      onChange={(values) => {
                        SetJobId(values?.value)
                        props.setJobIdvalue(values?.value)
                        setjobidData(values.value);
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />

                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button
            className="form_submit_btn"
            onClick={FetchCompetitorList}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalTrigger}>
            close
          </button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts &&


        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />

      }
    </>
  );
}
