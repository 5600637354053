import React, { useState, useEffect, useContext, useMemo } from "react";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import "./Performancestyle.css"
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import trueimages from "./../Asset/true-images.png";
import failimages from "./../Asset/fail-images.png";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Select from 'react-select';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import { datezonechange } from "./../Commonfun";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import CircleIcon from '@mui/icons-material/Circle';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Link } from "react-router-dom";
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import searchEngine from "../Asset/ESO/search-engine.svg";
import RankedKeywordssvg from "../Asset/esocardicon/FileAccessibilityReport/Score.svg";
import { useMediaQuery } from "react-responsive";
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
export default function Performancelist() {

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [value, onChange] = useState();
  const [valueOutside, onChangeOutsidde] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [initialDateval, SetInitialDateval] = useState([]);
  const [fataloutCount, setfataloutCount] = useState();
  const [erroroutCount, seterroroutCount] = useState();
  const [warningoutCount, setwarningoutCount] = useState();
  const [performancePlan, setperformancePlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;


  let detailurl = `${process.env.REACT_APP_DOMAIN}performance-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  const checkUserPlan = async () => {
    const performanceP = await Utils("performance", ApiToken);
    if (performanceP.status) {
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
    }
    setperformancePlan(performanceP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  const columns = [

    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "link__url",
      headerName: "Page URL",
      description: 'The URL of the web page to monitor usability, accessibility, UX, and performance under URL management settings.',
      minWidth: 320,
      flex: 1,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        let baseUrl = cardData.row.link__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external02">
                <a target="_blank" className="table-link" href={cardData?.row?.link__url}>
                  <span className="spanlink" style={{ display: 'flex', alignItems: 'baseline' }}>
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },
    },
    {
      field: "UX_score",
      headerName: "UX Score",
      description: 'UX score for the specific URL or web page',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        return cardData.row.UX_score + "%"
      },
    },
    {
      field: "seo_impact",
      headerName: "Seo Impact",
      description: 'SEO Impact on the specific URL or web page',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "responsiveness",
      headerName: "Responsiveness",
      description: 'Responsiveness of the URL or web page',
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "url_check",
      headerName: "URL Check",
      description: 'URL Accessibility and friendliness check for the URL or web page',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.url_check === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (

          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "html_element",
      headerName: "HTML Element",
      description: 'HTML Elements check for the URL or web page',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.html_element === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "security",
      headerName: "Security",
      description: 'Secured HTTPS connection check',
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.security === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "amp",
      headerName: "AMP",
      description: 'AMP (Accelerated Mobile Pages) Compliance check',
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.amp === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "content",
      headerName: "Content",
      description: 'Content metrics check for a URL or web page.',
      minWidth: 140,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.content === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (
          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "performance",
      headerName: "Performance",
      description: 'Performance metrics check for a URL or web page.',
      minWidth: 160,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        return cardData.row.performance === true ? (
          <img
            src={trueimages}
            className="view_per_img"
            alt="view report icon"

          />

        ) : (

          <img
            src={failimages}
            className="view_per_img"
            alt="view report icon"
          />

        );
      },
    },
    {
      field: "",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.link__id}` + "/" + `${jobidData}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        );
      },
    },
  ]

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "")
      jobid = jobidData;
    setLoading(true);
    setCardData([])
    try {


      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=performance&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });
        var Selectedmets = [];
        if (completevalues?.data) {
          completevalues?.data?.jobs.forEach((element) => {
            Selectedmets.push({
              value: element.id,
              label: datezonechange(element.completed_at),
            });
          });
          SetInitialDateval(Selectedmets[0]);
          setSelectval(Selectedmets[0]?.value);
          SetdEngineoption(Selectedmets[0]);
          setprevdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets);
        }

      }
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (datavalue?.data) {
        const dataval = datavalue.data.table_data;
        var newData = "";
        if (dataval && dataval.length > 0) {
          SetAutoheightval(false)
          const newData = dataval.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setfataloutCount(datavalue?.data?.report_ux_score)
          setCardData(newData);

          setjobidData(datavalue?.data?.job_id)
          setSelectval(datavalue?.data?.job_id);
        }
        else {
          setCardData("");
          SetAutoheightval(true)

        }

      }
    }
    catch (err) {
      console.log('err', err)
    }
    setLoading(false);

  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
      setLoading(false);
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval?.value);
    setIsOpen(false);
    setprevdEngineoption(dEngineoption);
    var domainsec = "";
    callUserdataApi(selectval?.value, domainsec = "");
  };
  function openModal() {
    if (performancePlan.status) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('performance')
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&domain_section_id=${Domainsectiondata}&job_id=${jobidData}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Performancelist", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&domain_section_id=${Domainsectiondata}&job_id=${jobidData}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Performancelist", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value)
    callUserdataApi("", passvalue.value);
  }
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=19`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const cardDatas = [
    { title: "Total UX Score", value: fataloutCount, iconSrc: RankedKeywordssvg, altText: "Total UX Score" },
  ];



  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Performance </title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <div className="cnt_audit_outer_wrap">


        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to={"/"}>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>
                Dashboard
              </Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Performance</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {performancePlan.status &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Performance</h2>
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={openModal} />
              </span>
            </div>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div title="Domain Section">
              <Select
                options={SectionOptions}
                name="name"
                onChange={SetSectionNameHandle}
                placeholder="Domain Section"
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                classNamePrefix="common-domain-dropdown"
              />
            </div>
            <button
              title="Run Report"
              className="runreportnew over_all_accessibility"
              onClick={() => {
                if (first.statusDomain) {
                  setcheckD(first.statusDomain);
                } else {
                  setfaqData(2)
                  setToggle(false)
                  SetRunReportPopShow(true);
                }
              }}
              disabled={!performancePlan.status}
              style={{ letterSpacing: "0.8px", width: "150px", lineHeight: "12px" }}
            >
              Run Report Now
            </button>
          </div>
        </div>

        {performancePlan.status ?
          <div>

            <div className="common-card-container" style={{ justifyContent: "center" }}>
              {cardDatas.map(({ title, value, iconSrc, altText }) => (
                <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
              ))}
            </div>


            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </div> : <Freeaudit planStatus={performancePlan} />
        }
      </div>
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      value={dEngineoption}
                      options={completedate}
                      placeholder="Select..."
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>

      {!openCommonAlerts &&
        <>
          <CommonErrorAlert
            data={{
              apiopen: open,
              apiErrorMsg: 'No Data Found',
              isIconStyled: true
            }}
            closeAlert={handleClose} />
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        </>
      }
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please verify your domain to access these features
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </ div>
  );
}
