import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AppleLogin from "react-apple-login";
import AppleIcon from '@mui/icons-material/Apple';
import { jwtDecode } from "jwt-decode";



const AppleLoginCom = () => {


    const [AppleId, SetAppleId] = useState('kavitha@egrovesys.com')
    const appleResponse = (e) => {
        if (!(e.user)) {
            const idToken = jwtDecode(e.authorization.id_token);
        }
    }

    useEffect(() => {

    }, [AppleId])


    return (
        <div>
            <AppleLogin


                clientId={process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID}
                redirectURI={process.env.REACT_APP_APPLE_LOGIN_REDIRECT_PATH}
                usePopup={true}
                callback={appleResponse}
                responseType={"code"}
                scope="name email"
                responseMode="query"
                render={renderProps => (
                    <button class="apple-sign-in" onClick={renderProps.onClick}>
                        <AppleIcon />
                        <span>Sign in with Apple</span>
                    </button>
                )}

            />
        </div>
    )
}
export default AppleLoginCom