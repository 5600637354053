import React, { useEffect, useState, useContext, useMemo } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorGWT from "./ErrorGWT";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    title="Select a Start Date"
    readOnly
    style={{ cursor: "pointer" }}
  />
));
const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    title="Select a End Date"
    readOnly
    style={{ cursor: "pointer" }}
  />
));
const TopPerformingPages = () => {

  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [buttnval, setMenubutval] = useState('brand')

  const SevenDaysBefore = moment().subtract(7, 'd').toDate();


  const [startDate, setStartDate] = useState(SevenDaysBefore);
  const [EndDate, SetEndDate] = useState(new Date());
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];

  const [loading, setLoading] = useState(false);
  const [ShowMainAlert, SetShowMainAlert] = useState(true)
  const [TableErrorMessage, SetTableErrorMessage] = useState('')
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);


  const navigate = useNavigate();

  function roundToTwoDecimalPlaces(number) {
    return parseFloat(number.toFixed(2));
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "page_url",
      headerName: "Page URL",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: "Top performing web page URLs",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <>
            <div className="table-link-url">
              <span className="spanlink">
                <LinkIcon />
              </span>
              <a className="table_links" target="_blank" href={cardData.row.page_url}>{cardData.row.page_url}</a>
            </div>
          </>
        );

      }
    },
    {
      field: "impressions",
      headerName: "Impressions",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: "It is counted each time your ad or webpage link is shown on a search result page",
    },
    {
      field: "clicks",
      headerName: "Clicks",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      description: "Every click by the viewer on the website available in organic search results is counted as a click.",
    },
    {
      field: "ctr",
      headerName: "CTR %",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      description: "It means number of clicks that your website receives divided by the number of impressions your website is shown (clicks/impression).",
      renderCell: (cardData) => {

        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.ctr)
        return (
          <>{RoundedVal}%</>
        )

      }

    },
    {
      field: "position",
      headerName: "Average Position",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      description: "Average position (ranking) of the keywords that was added to the campaign through campaign settings.",
      renderCell: (cardData) => {

        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.position)
        return (
          <>{RoundedVal}</>
        )

      }

    },
  ];

  const GetAPIData = async (type = '') => {
    setCardData([])
    setLoading(true)
    SetShowMainAlert(false)
    SetTableErrorMessage('')
    SetTotalImpressions(0)
    SetTotalClicks(0)
    SetAverageCTR(0)
    SetAverageRank(0)
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/topperformingpages/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.top_performing_pages) {
        if (datavalue?.data?.top_performing_pages) {
          if (datavalue?.data.top_performing_pages.length > 0) {
            const newData = datavalue.data.top_performing_pages.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
            setCardData(newData);
            SetAutoheightval(false)
          }
          else {
            var ErrorMessage;
            ErrorMessage = 'Data Not Available For This date Range'
            SetTableErrorMessage(ErrorMessage)
            SetAutoheightval(true)
          }
        }
      }
      if (datavalue?.data?.status_code) {
        SetShowMainAlert(true)
      }
    } catch (err) {
      setLoading(false)
      if (err.response) {
        if (err.response.data) {
          SetTableErrorMessage(err.response.data.error)
        }
        else {
          SetTableErrorMessage('')
        }
      }
      else {
        SetTableErrorMessage('')
      }
    }
    setLoading(false)
  }

  const [BrandPercentage, SetBrandPercentage] = useState(0)
  const [BrandLabel, SetBrandLabel] = useState('')
  const [NonBrandPercentage, SetNonBrandPercentage] = useState(0)
  const [NonBrandLabel, SetNonBrandLabel] = useState('')
  const [ChartType, SetChartType] = useState('impressions')
  const [TotalImpressions, SetTotalImpressions] = useState(0)
  const [TotalClicks, SetTotalClicks] = useState(0)
  const [AverageCTR, SetAverageCTR] = useState(0)
  const [AverageRank, SetAverageRank] = useState(0)
  const data = [
    { value: BrandPercentage, label: BrandLabel },
    { value: NonBrandPercentage, label: NonBrandLabel },

  ];

  const [ClicksSeries, SetClicksSeries] = useState([])
  const [ImpressionSeries, SetImpressionSeries] = useState([])
  const [SeriesDate, SetSeriesDate] = useState([])

  const GetGraphdata = async (type = '') => {

    setLoading(true)
    SetSeriesDate([])
    SetClicksSeries([])
    SetImpressionSeries([])

    SetBrandPercentage(0)
    SetNonBrandPercentage(0)


    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/topperformingpages/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&page=chart`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false)

      if (datavalue?.data) {
        if (datavalue?.data?.chart_data) {


          const datesArray = datavalue.data.chart_data.map(item => item.date);
          const clicksArray = datavalue.data.chart_data.map(item => item.clicks);
          const impressionsArray = datavalue.data.chart_data.map(item => item.impressions);
          SetSeriesDate(datesArray)
          SetClicksSeries(clicksArray)
          SetImpressionSeries(impressionsArray)
        }
      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    GetAPIData('brand')
    GetGraphdata('impressions')
    faqdatafun()

  }, [])

  const SubmitData = () => {
    GetAPIData(buttnval)
    GetGraphdata(ChartType)
  }

  const chartData = {
    labels: SeriesDate,
    datasets: [
      {
        label: 'Impressions',
        data: ImpressionSeries,
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
      {
        label: 'Clicks',
        data: ClicksSeries,
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const date = new Date(context.parsed.x);
            const formattedDate = date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
            const value = context.parsed.y;
            return `${label}: ${value} on ${formattedDate}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM dd, yyyy',
        },
        title: {
          display: true,
          text: 'Date',
          font: { size: 12 },
          padding: 10,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          maxRotation: 0,
          minRotation: 0,
          callback: function (value, index, values) {
            return new Date(values[index].value).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values',
          font: { size: 12 },
        },
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };
  const pdfdown = async () => {
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/topperformingpages/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&formats=pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("TopPerformingpage", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);

  }
  const csvdown = async () => {
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/topperformingpages/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&formats=csv`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("TopPerformingpage", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=39`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const freeAudit = async () => {
    const check = await Utils("top_performing_pages", ApiToken);
    setfreesiteaudit(check);
  }

  useEffect(() => {
    freeAudit();
  }, [buttnval])

  return (
    <div className={`over_all_container brand_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Performing Pages</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Google Analytics</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Top Performing Pages</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
            {freesiteaudit.status && buttnval === "brand" &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Top Performing Pages</h2>
          </div>
          {freesiteaudit.status && <div className="wrap_trans_back">
            <div className="daterangepicker_wrap">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Start Date"
                customInput={<CustomInput />} />
              <DatePicker selected={EndDate} onChange={(date) => SetEndDate(date)} placeholderText="End Date" customInput={<CustomInput1 />} />
              <button className="runreportnew" onClick={() => { SubmitData() }}>Submit</button>
            </div>
          </div>}
        </div>

        {
          loading === true ? <Loader /> : null
        }

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {
          (SeriesDate.length > 0 && ShowMainAlert === false && freesiteaudit.status) ? <div className="google_area_chart_wrap" style={{ marginTop: '30px' }}>
            <div className="site_regress_chart_info">
              <div className="site_Chart_info_inner" >
                <div style={{ width: '100%', height: '400px', margin: '0 auto' }}>
                  <Line options={options} data={chartData} />
                </div>
              </div>
            </div>
          </div> : null

        }
        {
          freesiteaudit.status && <div className="table_error_message">{TableErrorMessage}</div>
        }
        {
          (ShowMainAlert === false && cardData.length > 0) ? <>
            {freesiteaudit.status && freesiteaudit.status ? <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            /> : <Freeaudit planStatus={freesiteaudit} />} </>
            : null
        }
        {
          ShowMainAlert === true && freesiteaudit.status ?

            <ErrorGWT /> : null
        }
      </div>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </div>
  )

}
export default TopPerformingPages