import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./DomainLogindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CommonAlert from "../../../Commonalert/commonalert";
import Loader from "../CommonLoader";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";



function EditDomaindata(props) {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [onUsername, SetonUsername] = useState("")
  const [onPassword, SetonPassword] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [onUserError, SetonUserError] = useState("")
  const [onPasswordError, SetonPasswordError] = useState("")
  const [onPageError, SetonPageError] = useState("")
  const [userdomaindata, SetUserdomaindata] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  let domain_id = props.selectedRows;
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value === "Yes" ? setShowResults(true) : setShowResults(false)
    e.target.value === "No" && SetonUsername(""); SetonPassword("");
  }
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e => {
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e => {
    SetonPassword(e.target.value)
  }
  useEffect(() => {
    callUserdataApi();
  }, []);
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain/?user_domain_id=${domain_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      if (datavalue?.data) {

        SetWebsiteName(datavalue?.data?.queryset_values[0]?.domain__name)
        SetwebLink(datavalue?.data?.queryset_values[0]?.domain__url)
        SetonUsername(datavalue?.data?.queryset_values[0]?.domain__http_username)
        SetonPassword(datavalue?.data?.queryset_values[0]?.domain__http_password)
        SetUserdomaindata(datavalue?.data?.queryset_values[0]?.id)
        if (datavalue?.data?.queryset_values[0]?.domain__has_http_auth === true) {
          Setwebauthenticate("Yes")
          setShowResults(true)
        }
        else {
          Setwebauthenticate("No")
        }
        if (datavalue.data.queryset_values[0].domain__site_type === "html") {
          Setwebtype("HTML")
        }
        else {
          Setwebtype("JS")
        }
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };

  const finalreportCancel = async () => {
    props.setAddEditDomainPopup(false);

  }
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetonUserError("")
    SetonPasswordError("")
    setLoading(true);
    var data_val = 1;
    if (websiteName === "") {
      SetWebnameError("Enter Website Name")
      data_val = 2;
    }
    if (webLink === "") {
      SetweblinkError("Please enter the competitor URL address")
      data_val = 2;
    }
    if (webLink !== "") {
      if (!isURL(webLink)) {
        SetweblinkError("Enter Valid Link Address")
        data_val = 2;
      }
    }

    if (webauthenticate == "Yes") {
      if (onUsername == "") {
        SetonUserError("Enter User Name")
        data_val = 2;
      }
      if (onPassword == "") {
        SetonPasswordError("Enter Password")
        data_val = 2;
      }
    }
    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {
        setLoading(true);
        if (webauthenticate == "Yes")
          var webauthenticates = true;
        else
          var webauthenticates = false;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}domain/edit_domain/`,
          {
            domain_name: websiteName,
            domain_url: webLink,
            domain_type: webtype === "NOT SURE" ? "JS" : webtype,
            domain_has_http: webauthenticates,
            domain_http_username: onUsername,
            domain_http_password: onPassword,
            user_domain_id: userdomaindata,
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        if (response?.data?.status_code == 200) {
          if (response?.data?.domain_verification_required == false) {
            window.location.reload();
          } else {
            props.setAddEditDomainPopup(false);
          }
        }
      } catch (err) {
        setLoading(false);
        SetonPageError(err.response.data.error)
      }
    }

  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true && !openCommonAlerts ? <Loader /> : null}
      <div className="">
        <div className="">
          <span className="error-value">{onPageError}</span>

          <form className="frm_container">
            <div className="campaigns-addedit-row">
              <div className="keyword-col1-fields">
                <div>
                  <label>
                    Website Name :
                  </label>
                  <input
                    title="This name is used as an alias to manage your domain."
                    type="text"
                    name="websitename"
                    onChange={onwebName}
                    defaultValue={websiteName}
                    placeholder=" Enter Website Name"
                  />
                </div>
                <div>
                  <span className="error-value">{webnameError}</span>
                </div>
              </div>
              <div className="keyword-col2-fields">
                <div>
                  <label>
                    URL :
                  </label>
                  <input
                    type="text"
                    title="URL of your website (eg: www.example.com)"
                    name="websitelink"
                    onChange={onwebLink}
                    defaultValue={webLink}
                    placeholder="   Enter Website URL" />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{weblinkError}</span>
                </div>
              </div>
            </div>
            <div className="campaigns-addedit-row">
              <div className="keyword-col1-fields" style={{ placeContent: "end" }}>
                <div>
                  <label>
                    Website Type :
                  </label>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginLeft: "20px" }}>
                    <div /*className="websitetype_inner"*/>
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                        checked={webtype === "HTML"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="html">HTML</label>
                    </div>
                    <div /*className="websitetype_inner"*/>
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="JS"
                        checked={webtype === "JS"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="css">JS</label>
                    </div>
                    <div /*className="websitetype_inner"*/>
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="NOT SURE"
                        checked={webtype === "NOT SURE"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="css">NOT SURE</label>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div className="keyword-col2-fields">
                <div>
                  <label>
                    Does your website need authentication to access? :
                  </label>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="yes"
                        name="webauthenticate"
                        value="Yes"
                        checked={webauthenticate === "Yes"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="no"
                        name="webauthenticate"
                        value="No"
                        checked={webauthenticate === "No"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
            {showResults &&
              <>
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label>
                        Username :
                      </label>
                      <input
                        type="text"
                        id="textField"
                        title="Username"
                        value={onUsername}
                        onChange={onWebnameChange}
                      />
                    </div>
                    <div>
                      <span className="error-value">{onUserError}</span>
                    </div>
                  </div>
                  <div className="keyword-col2-fields">
                    <div>
                      <label>
                        Password :
                      </label>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={onpasswordChange}
                          className="domain-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={showPassword ? 'hide the password' : 'display the password'}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{onPasswordError}</span>
                    </div>
                  </div>
                </div>
              </>
            }
            <div className="keyword-dialog-buttons">
              <button
                className="keyword-cancel"
                type="button"
                onClick={finalreportCancel}
              >
                Close
              </button>
              <button
                className="keyword-submit"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>
        </div>

      </div>
      {!openCommonAlerts &&
        

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />

      }
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>

  );
}

export default EditDomaindata;


