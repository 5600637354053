import React, { useEffect, useState, useContext, useMemo } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Loader from "../CommonLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorGWT from "./ErrorGWT";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import ApexCharts from "react-apexcharts";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from "react-router-dom";
import AverageRanksvg from "../Asset/esocardicon/Branded_NonBranded/AverageRank.svg"
import AvgCTRsvg from "../Asset/esocardicon/Branded_NonBranded/AvgCTR.svg"
import TotalClickssvg from "../Asset/esocardicon/Branded_NonBranded/TotalClicks.svg"
import TotalImpressionssvg from "../Asset/esocardicon/Branded_NonBranded/TotalImpressions.svg"
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    title="Select a Start Date"
    readOnly
    style={{ cursor: "pointer" }}
  />
));
const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    title="Select a End Date"
    readOnly
    style={{ cursor: "pointer" }}
  />
));
const GoogleConsole = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [buttnval, setMenubutval] = useState('brand')
  const SevenDaysBefore = moment().subtract(7, 'd').toDate();
  const [startDate, setStartDate] = useState(SevenDaysBefore);
  const [EndDate, SetEndDate] = useState(new Date());
  const [cardData, setCardData] = useState([]);
  const rows = cardData;
  const [loading, setLoading] = useState(false);
  const [ShowMainAlert, SetShowMainAlert] = useState(true)
  const [TableErrorMessage, SetTableErrorMessage] = useState('')
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [brandType, setBrandType] = useState({ label: 'impressions', value: 'Impressions' });
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:900px)');


  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });

  const [freesiteaudit1, setfreesiteaudit1] = useState({ status: false, error: "" });

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const navigate = useNavigate();
  function roundToTwoDecimalPlaces(number) {
    return parseFloat(number.toFixed(2));
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "keys",
      headerName: "Search Query/Keyword",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: "The search terms or keywords through which the user has reached your website.",
      renderCell: (cardData) => {



      }
    },
    {
      field: "impressions",
      headerName: "Impressions",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: "It is counted each time your ad or webpage link is shown on a search result page.",

    },
    {
      field: "clicks",
      headerName: "Clicks",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      description: "Every click by the viewer on the website available on the organic search results is counted as a click.",

    },
    {
      field: "ctr",
      headerName: "CTR %",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      description: "It means the number of clicks that your website receives divided by the number of impressions of your website (clicks/impression).",
      renderCell: (cardData) => {
        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.ctr)
        return (
          <>{RoundedVal}%</>
        )
      }
    },
    {
      field: "position",
      headerName: "Average Position",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      description: "Average position (ranking) of the keywords that were added to the campaign through campaign settings.",

      renderCell: (cardData) => {

        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.position)
        return (
          <>{RoundedVal}</>
        )
      }

    },
  ];

  const menuChange = (e) => {
    setMenubutval(e.target.id)
    GetAPIData(e.target.id)
    faqdatafun(e.target.id)
  }


  const GetAPIData = async (type = '') => {
    setCardData("")
    setLoading(true)
    SetShowMainAlert(false)
    SetTableErrorMessage('')
    SetTotalImpressions(0)
    SetTotalClicks(0)
    SetAverageCTR(0)
    SetAverageRank(0)
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=${type}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (datavalue?.data?.queries) {
        SetTotalImpressions(datavalue.data.queries.impressions)
        SetTotalClicks(datavalue.data.queries.clicks)
        SetAverageCTR(datavalue.data.queries.ctr + '%')
        SetAverageRank(datavalue.data.queries.rank)
      }

      if (datavalue.data.queries) {
        if (datavalue.data.queries.keywords) {
          if (datavalue.data.queries.keywords.length > 0) {
            const newData = datavalue.data.queries.keywords.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
            setCardData(newData);
            SetAutoheightval(false)
          }
          else {
            var ErrorMessage;
            if (type == 'brand') {
              SetAutoheightval(true)
              setCardData("");
            }
            else if (type == 'non-brand') {
              ErrorMessage = 'Non-branded keywords not available for this domain'
            }
            SetTableErrorMessage(ErrorMessage)
            SetAutoheightval(true)
          }
        }
      }
      if (datavalue.data.status_code) {
        SetShowMainAlert(true)
      }
    } catch (err) {
      setLoading(false)
      if (err.response) {
        if (err.response.data) {
          SetTableErrorMessage(err.response.data.error)
        }
        else {
          SetTableErrorMessage('')
        }
      }
      else {
        SetTableErrorMessage('')
      }
    }
    setLoading(false)
  }

  const [BrandPercentage, SetBrandPercentage] = useState(0)
  const [BrandLabel, SetBrandLabel] = useState('')
  const [NonBrandPercentage, SetNonBrandPercentage] = useState(0)
  const [NonBrandLabel, SetNonBrandLabel] = useState('')
  const [ChartType, SetChartType] = useState('impressions')
  const [TotalImpressions, SetTotalImpressions] = useState(0)
  const [TotalClicks, SetTotalClicks] = useState(0)
  const [AverageCTR, SetAverageCTR] = useState(0)
  const [AverageRank, SetAverageRank] = useState(0)

  const GetGraphdata = async (type = '') => {
    setLoading(true)
    SetBrandPercentage(0)
    SetNonBrandPercentage(0)
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&chart_option=${type}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false)
      if (datavalue?.data) {
        if (datavalue?.data?.chart_data) {
          SetChartType(datavalue.data.chart_data.chart_option)
          SetBrandPercentage(datavalue.data.chart_data.brand_percentage)
          SetNonBrandPercentage(datavalue.data.chart_data.non_brand_percentage)
          SetBrandLabel('Branded-' + datavalue.data.chart_data.brand_percentage + '%' + ' (' + datavalue.data.chart_data.brand_value + ')')
          SetNonBrandLabel('Non Branded-' + datavalue.data.chart_data.non_brand_percentage + '%' + ' (' + datavalue.data.chart_data.non_brand_value + ')')
        }
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    GetAPIData('brand')
    GetGraphdata('impressions')
    faqdatafun()
  }, [])

  const SubmitData = () => {
    GetAPIData(buttnval)
    GetGraphdata(ChartType)
  }


  const CheckPieRender = (brandpercentage, nonbrandpercentage) => {

    if (brandpercentage == 0 && nonbrandpercentage == 0) {
      return false
    }
    else {
      return true
    }



  }

  const SetChartTypeTrigger = (value) => {
    SetChartType(value.value)
    GetGraphdata(value.value)
    setBrandType(value);

  }
  const pdfdown = async () => {
    if (buttnval == "brand")
      var keytype = "brand";
    else if (buttnval == "non-brand")
      var keytype = "non-brand";
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=${keytype}&chart_option=${ChartType}&formats=pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Branded vs Non Branded", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);

  }
  const csvdown = async () => {
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=brand&chart_option=${ChartType}&formats=csv`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Branded vs Non Branded", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }
  
  const faqdatafun = async (menubutval = "") => {
    if (menubutval == "brand" || menubutval == "")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=37`
    else if (menubutval == "non-brand")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=38`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const chartOptions = {
    chart: {
      type: "donut",
      height: 350,

    },
    labels: [BrandLabel, NonBrandLabel],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: isMobile || isTablet ? "bottom" : "right",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
    title: {
      text: "Brand Vs Non-Brand",
      align: "center",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
  };

  const chartSeries = [BrandPercentage, NonBrandPercentage];

  const freeAudit = async () => {

    if (buttnval === "brand") {
      const check = await Utils("branded_keywords", ApiToken);
      setfreesiteaudit(check);
      setfreesiteaudit1({ status: false, error: "" });
    } else if (buttnval === "non-brand") {
      const check1 = await Utils("non_branded_keywords", ApiToken);
      setfreesiteaudit1(check1);
      setfreesiteaudit({ status: false, error: "" });

    }
  }

  useEffect(() => {
    freeAudit();
  }, [buttnval])


  const cardDatas = [
    { title: "Total Impressions", value: TotalImpressions, iconSrc: TotalImpressionssvg, altText: "Total Impressions" },
    { title: "Total Clicks", value: TotalClicks, iconSrc: TotalClickssvg, altText: "Total Clicks" },
    { title: "Avg CTR", value: AverageCTR, iconSrc: AvgCTRsvg, altText: "Avg CTR" },
    { title: "Average Rank", value: AverageRank, iconSrc: AverageRanksvg, altText: "Average Rank" }
  ];


  return (
    <div className={`over_all_container brand_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`} >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Branded VS Non-Branded</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Google Analytics</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Brand Vs Non Brand</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
            {freesiteaudit.status && buttnval === "brand" &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
            {freesiteaudit1.status && buttnval === "non-brand" &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
        </div>

        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Branded Vs Non Branded</h2>
          </div>
          {buttnval === "brand" && freesiteaudit.status && <div className="wrap_trans_back">
            <div className="daterangepicker_wrap">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Start Date"
                customInput={<CustomInput />} />
              <DatePicker selected={EndDate} onChange={(date) => SetEndDate(date)} placeholderText="End Date" customInput={<CustomInput1 />} />
              <button className="runreportnew" onClick={() => { SubmitData() }}>Submit</button>
              <Select
                value={brandType}
                options={[
                  { value: 'impressions', label: 'Impressions' },
                  { value: 'clicks', label: 'Clicks' },
                  { value: 'ctr', label: 'CTR' },
                  { value: 'rank', label: 'Rank' },
                  { value: 'ranked-terms', label: 'Ranked terms' },
                  { value: '1st-position', label: '1st Position' },
                  { value: '1st-page', label: '1st Page' }
                ]}
                placeholder="Select..."
                onChange={(e) => { SetChartTypeTrigger(e) }}
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                classNamePrefix="common-domain-dropdown"
              />
            </div>
          </div>}

          {buttnval === "non-brand" && freesiteaudit1.status && <div className="wrap_trans_back">
            <div className="daterangepicker_wrap">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              <DatePicker selected={EndDate} onChange={(date) => SetEndDate(date)} />
              <button className="google_analytics_submit daterangepicker_wrap_submit" onClick={() => { SubmitData() }}>Submit</button>
              <Select
                value={brandType}
                options={[
                  { value: 'impressions', label: 'Impressions' },
                  { value: 'clicks', label: 'Clicks' },
                  { value: 'ctr', label: 'CTR' },
                  { value: 'rank', label: 'Rank' },
                  { value: 'ranked-terms', label: 'Ranked terms' },
                  { value: '1st-position', label: '1st Position' },
                  { value: '1st-page', label: '1st Page' }
                ]}
                placeholder="Select..."
                onChange={(e) => { SetChartTypeTrigger(e) }}
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                classNamePrefix="common-domain-dropdown"
              />
            </div>
          </div>}
        </div>

        {
          loading === true ? <Loader /> : null
        }
        {ShowMainAlert === false && CheckPieRender(BrandPercentage, NonBrandPercentage) && freesiteaudit.status && buttnval === "brand" ? (
          <div className="pie-chart-outer">
            <div className="pie-chart-inner">
              <ApexCharts options={chartOptions} series={chartSeries} type="donut" height={350} />
            </div>
          </div>
        ) : null}

        {ShowMainAlert === false && CheckPieRender(BrandPercentage, NonBrandPercentage) && freesiteaudit1.status && buttnval === "non-brand" ? (
          <div className="pie-chart-outer">
            <div className="pie-chart-inner">
              <ApexCharts options={chartOptions} series={chartSeries} type="donut" height={350} />
            </div>
          </div>
        ) : null}

        <div /*className="site_cnt_hdr_blw_wrap"*/ style={{ marginTop: "15px" }} className="googleanaclass">
          <button type="button" id='brand' className={`runreportnews  ${buttnval === "brand" ? 'active' : ""}`} onClick={e => menuChange(e)} >Branded Keyword</button>
          <button type="button" id='non-brand' className={`runreportnews  ${buttnval === "non-brand" ? 'active' : ""}`} onClick={e => menuChange(e)} >Non Branded Keyword</button>
        </div>

        {
          ShowMainAlert === false && freesiteaudit.status && buttnval === "brand" ?

            <div className="common-card-container">
              {cardDatas.map(({ title, value, iconSrc, altText }) => (
                <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
              ))}
            </div> : null
        }
        {
          ShowMainAlert === false && freesiteaudit1.status && buttnval === "non-brand" ?

            <div className="common-card-container">
              {cardDatas.map(({ title, value, iconSrc, altText }) => (
                <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
              ))}
            </div> : null
        }

        {
          (ShowMainAlert === false /*&& cardData.length > 0 */ && freesiteaudit.status && buttnval === "brand") ?
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            /> : null
        }

        {
          (ShowMainAlert === false /*&& cardData.length > 0 */ && freesiteaudit1.status && buttnval === "non-brand") ?
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            /> : null
        }

        {
          ShowMainAlert === true && freesiteaudit.status && buttnval === "brand" ?

            <ErrorGWT /> : null
        }
        {
          ShowMainAlert === true && freesiteaudit1.status && buttnval === "non-brand" ?

            <ErrorGWT /> : null
        }

        {
          !freesiteaudit.status && buttnval === "brand" ? <Freeaudit planStatus={freesiteaudit} /> : null

        }

        {
          !freesiteaudit1.status && buttnval === "non-brand" ? <Freeaudit planStatus={freesiteaudit1} /> : null

        }

      </div>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </div>
  )

}
export default GoogleConsole