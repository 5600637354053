import { createSlice } from "@reduxjs/toolkit";

if(localStorage.getItem('header_selected')==null || localStorage.getItem('header_selected')==""){
    localStorage.setItem('header_selected',"")
}
const esoDataSlice=createSlice({
    name:'esodata',
    initialState:{value:{header_selected:localStorage.getItem('header_selected')}},
    reducers:{
        SetDomainHeader:(state,action)=>{
           localStorage.setItem("header_selected", action.payload.header_selected);
           state.value=action.payload
        }
    }
})


export const {SetDomainHeader}=esoDataSlice.actions


export default esoDataSlice.reducer