import React, { useState, useEffect, useContext, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Select from 'react-select';
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";

import { Link, useParams } from "react-router-dom";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import ReactSelect from 'react-select';
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { datezonechange } from "./../Commonfun"

import { AppContext } from "../../../Context/Context";
import zIndex from "@mui/material/styles/zIndex";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function AccessibilityAxe() {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [accessbilityScorePlan, setaccessbilityScorePlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [accessbilityModel, setaccessbilityModel] = useState(false);
  const [contentType, setcontentType] = useState([]);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);

  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value
  let detailurl = `${process.env.REACT_APP_DOMAIN}accessibility-axe-detail/`;

  const contentTypeOptions = [
    { label: 'WCAG', value: 'wcagtype' },
    { label: 'Section type 508', value: '508Type' },
    { label: 'ADA', value: 'adaType' }
  ]
  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=64`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const checkUserPlan = async () => {
    const accessbilityScoreP = await Utils("accessibility_score", ApiToken);
    if (accessbilityScoreP.status) {
      GetFilterOptions();
      LoadAddFormFilterOptions();
      faqdatafun()
    }
    setaccessbilityScorePlan(accessbilityScoreP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "link__url",
      headerName: "URL",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: 'The web page URL being audited.',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        let baseUrl = cardData.row.link__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.link__url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },
    },
    {
      field: "passed_count",
      headerName: "Passed Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      disableClickEventBubbling: true,
      description: 'The total number of accessibility checks that passed.'

    },
    {
      field: "failed_count",
      headerName: "Failed Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: 'The total number of accessibility checks that failed.'

    },
    {
      field: "view",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {


        return (
          <a href={detailurl + `${cardData.row.id}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        );
      },
    },

  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", SelectedWcagType = "", domainsecdata = "") => {
    console.log("domainsecdata", domainsecdata);
    setLoading(true);
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "") {
      jobid = selectval;
    }

    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/accessibility_score_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const dataval = datavalue?.data?.table_data;
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (dataval && dataval.length > 0) {
        SetAutoheightval(false)
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });

        console.log("newData", newData)

        setCardData(newData);
        setjobValue(datavalue?.data?.job_id)

      }
      else {
        setCardData("")
        SetAutoheightval(true)
      }
    }
    catch (err) {
      console.log('err', err)
    }


    setLoading(false);
  };

  const reportNow = async () => {
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "All Fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }

    setIsOpen(false);
    setprevdEngineoption(dEngineoption);
    setLoading(true);


    var domainsec = "";
    callUserdataApi("", "", domainsec = "")

  };
  function openModal() {
    if (accessbilityScorePlan.status) {
      setfaqData(2)
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);


  }


  const GetFilterOptions = async () => {

    setcompletedate([])
    try {
      const FetchList = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=accessibility_axe`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (FetchList?.data) {
        var Selectedmets = [];
        if (FetchList?.data?.jobs.length > 0) {
          FetchList?.data?.jobs.forEach((element) => {
            Selectedmets.push({ value: element?.id, label: datezonechange(element?.completed_at) });
          });


          SetdEngineoption(Selectedmets[0])
          setprevdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets)
          setSelectval(Selectedmets[0]?.value)
          callUserdataApi(Selectedmets[0]?.value, 'AA', '');
        }
        else {
          setapiOpen(true);
          setapierrorMsg("No report available")
        }
      }


    } catch (err) {
      console.log('err', err)
    }

  }

  const OnsetSelectval = (values) => {
    setSelectval(values.value)

  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('accessibility')

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_score_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityList", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_score_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityList", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value)
    callUserdataApi("", "", passvalue.value);
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };


  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  return (
    <>
      {loading === true ? <Loader /> : null}

      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Accessibility Score</title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Axe</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />
              </button>
              {accessbilityScorePlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading} csvdown={csvdown} pdfdown={pdfdown} />
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Accessibility Axe</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              {accessbilityScorePlan.status &&
                <>
                  {/* <Select
                    classNamePrefix="access-score-dropdown"
                    options={WcagType}
                    placeholder="Select..."
                    value={DefaultSelectedWcagType}
                    onChange={(values) => OnSetSelectedWcagType(values)}
                    isSearchable="true"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                  /> */}
                  {SectionOptions?.length > 0 && (
                    <div title="Domain Section">
                      <Select
                        options={SectionOptions}
                        name="name"
                        onChange={SetSectionNameHandle}
                        placeholder="Domain Section"
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        classNamePrefix="common-domain-dropdown"
                      />
                    </div>
                  )}
                </>
              }
              <button
                title="Run Report"
                className="runreportnew over_all_accessibility runreportnew"
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    SetRunReportPopShow(true);
                  }
                }}

                disabled={!accessbilityScorePlan.status}
              >
                Run Report Now
              </button>
            </div>
          </div>

          <div className="center_tab accessbility_button_tap">
            <Link to="/accessibility-axe" ><button type="button" className={`runreportnews active`}  >Accessibility Axe</button></Link>
            <Link to="/accessibility-score" ><button type="button" className={`runreportnews`}  >Score</button></Link>
            <Link to="/accessibility-summary" ><button type="button" className={`runreportnews`}  >Summary</button></Link>
            <Link to="/accessibility-report" ><button type="button" className={`runreportnews`}  >Report</button></Link>
            <Link to="/accessibility-file" ><button type="button" className={`runreportnews`}  >File Accessibility</button></Link>
          </div>
          {accessbilityScorePlan.status ?
            <>
              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
            </> : <Freeaudit planStatus={accessbilityScorePlan} />
          }




          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter"
          >
            <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">

                  <div>

                    <div className="" style={{ marginBottom: '10px' }}>
                      <div>
                        <span>Completed Date </span>
                        <Select
                          value={dEngineoption}
                          options={completedate}
                          placeholder="Select..."
                          labelField="completed_at"
                          valueField="id"
                          onChange={(values) => {
                            OnsetSelectval(values)
                            SetdEngineoption(values);
                          }}
                          styles={customDropdownStyles()}
                          menuPortalTarget={document.body}
                        />
                      </div>


                    </div>



                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>



                  </div>

                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
                Submit
              </button>
              <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
            </DialogActions>
          </Dialog>



          {!openCommonAlerts &&
            <>
              <CommonErrorAlert
                data={{
                  apiopen: open,
                  apiErrorMsg: 'No Data Found',
                  isIconStyled: true
                }}
                closeAlert={handleClose} />
              <CommonErrorAlert
                data={{
                  apiopen: apiopen,
                  apiErrorMsg: apierrorMsg,
                  isIconStyled: true
                }}
                closeAlert={apihandleClose} />
            </>
          }
          
          <Dialog
            open={checkD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Please verify your domain to access these features
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
              <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
            </DialogActions>
          </Dialog>
        </div>
        <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      </div>
    </>
  );
}
