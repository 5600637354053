import React, { useState, useEffect, useRef, useContext } from "react";
import { Helmet } from 'react-helmet';
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Siteauditlists from "./Siteauditlists";
import Onpagecrawllist from "./Onpagecrawllist";
import Pagehealthlists from "./Pagehealthlists";
import CompetitorTest from "./CompetitorTest";
import { Link, useParams } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { AppContext } from "../../../Context/Context";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils, freePlanMsg } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Siteonanalysis() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [fielddata, setFielddata] = useState("1");
  const [menubutval, setMenubutval] = useState("1")
  const [buttnval, setButtnval] = useState("1")
  const [buttnclickval, setButtnclickval] = useState("1")
  const [dataurl, setdataurl] = useState("1")

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [pagename, setPagename] = useState("On Page Crawl Data")
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [jobDate, setJobdate] = React.useState(false);
  const [jobDateval, setJobdateval] = useState("");
  const [ignoreval, setIgnoreval] = useState(2);
  const [onpagecrawlPlan, setonpagecrawlPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [domainSecvalue, setDomainSecvalue] = React.useState("");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  let querystring = useParams();
  let domain_id = querystring.id;

  const navigate = useNavigate();

  const menuChange = e => {
    e.preventDefault();
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if (e.target.id == 1)
      setPagename("Site Audit")
    else if (e.target.id == 2)
      setPagename("On Page Crawl")
    else if (e.target.id == 3)
      setPagename("Page Health")
    else if (e.target.id == 4)
      setPagename("Competitor Analysis")
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;

  useEffect(() => {
    const checkUserPlan = async () => {
      const onpagecrawlP = await Utils("on_page_crawl", ApiToken);
      if (onpagecrawlP.status) {
        if (fielddata == 1)
          setFielddata("all");
        else if (fielddata == 2)
          setFielddata("missing_title")
        else if (fielddata == 14)
          setFielddata("missing_canonical")
        else if (fielddata == 15)
          setFielddata("missing_description")
        else if (fielddata == 3)
          setFielddata("mismatch_canonical")
        else if (fielddata == 4)
          setFielddata("duplicate_title")
        else if (fielddata == 5)
          setFielddata("duplicate_description")
        else if (fielddata == 6)
          setFielddata("images")
        else if (fielddata == 18)
          setFielddata("missing_alt")
        else if (fielddata == 16)
          setFielddata("missing_h1")
        else if (fielddata == 7)
          setFielddata("duplicate_h1")
        else if (fielddata == 19)
          setFielddata("multiple_h1")
        else if (fielddata == 20)
          setFielddata("h1_tags_available")
        else if (fielddata == 17)
          setFielddata("no_index_robot")
        else if (fielddata == 8)
          setFielddata("pagination_prev_or_next")
        else if (fielddata == 9)
          setFielddata("hreflang")
      }
      setonpagecrawlPlan(onpagecrawlP);
    };
    checkUserPlan();

  }, [fielddata])
  useEffect(() => {
    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify === "true" ? true : false);
    setMenubutval(domain_id)
    setButtnval(domain_id)
    const checkUserPlan = async () => {
      const onpagecrawlP = await Utils("on_page_crawl", ApiToken);
      if (onpagecrawlP.status) {
        faqdatafun()
        onPagecrawllists()
      }
      setonpagecrawlPlan(onpagecrawlP);
    };
    checkUserPlan();
    GetuserDatavalue();

  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
  }, [jobDateval])
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
  };
  function openModal() {
    if (onpagecrawlPlan.status) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=${fielddata}&job_id=${jobDateval}&is_format=pdf&timezone=${timezoneval()}&domain_section_id=${domainSecvalue}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("onPagecrawl", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobDateval}&field_type=${fielddata}&is_format=csv&timezone=${timezoneval()}&domain_section_id=${domainSecvalue}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("onPagecrawl", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const renderSwitch = (params) => {
    switch (params) {
      case "1":
        return <Siteauditlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} onbutnvaldata={setButtnclickval} setJobdate={setJobdate} jobDate={jobDate} />
      case "2":
        return onpagecrawlPlan.status ? <Onpagecrawllist modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} fielddata={fielddata} setFielddata={setFielddata} setJobdateval={setJobdateval} jobDateval={jobDateval} setJobdate={setJobdate} jobDate={jobDate} setDomainSecvalue={setDomainSecvalue} domainSecvalue={domainSecvalue} /> : <Freeaudit planStatus={onpagecrawlPlan} />
      case "3":
        return <Pagehealthlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
      case "4":
        return <CompetitorTest modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
    }
  }
  const onpageref = useRef(null)
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('site_audit')
  const onPagecrawllists = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}technical/on_page_crawl_filter/?user_domain_id=${esodata.domaindata.value.header_selected}&ignore=ignore`
        , {
          headers: {
            Authorization: `${ApiToken}`,
          },
        });
      const domainsectiondatavalue = response.data;
      if (domainsectiondatavalue.count > 0)
        setIgnoreval(1)
      else
        setIgnoreval(0)
    } catch (error) {
      if (
        [599, 500, 400, 404].includes(error?.response?.status)
      ) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error || "An error occurred");
      } else if (error?.code === "ERR_NETWORK") {
        navigate('/errorpages');
      } else {
        console.error("Unhandled error:", error);
      }
    }
  };
  
  
  const faqdatafun = async (menubutval = "") => {

    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=15`


    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pagename}</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="cnt_audit_outer_wrap">


          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: 'grey' }}>Site Analysis</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>On Page Crawl Data</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {onpagecrawlPlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                    csvdown={csvdown} pdfdown={pdfdown} />
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>On Page Crawl Data</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              {ignoreval == 1 &&
                <Link to={onpagecrawlPlan.status ? "/on-page-crawl-ignore-metrics/2" : null} >
                  <button className="runreportnew over_all_accessibility runreportnew" disabled={!onpagecrawlPlan.status}>
                    Ignored Metrics
                  </button></Link>}
              <button
                title="Run Report"
                className="runreportnew over_all_accessibility runreportnew"
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    SetRunReportPopShow(true)
                  }
                }}
                disabled={!onpagecrawlPlan.status}>
                Run Report Now
              </button>
            </div>
          </div>

          <div className="link_cnt_hdr_blw_wrap googleanlytics">
            <Link to="/site-audit/1/" ><button type="button" id={1} className={`runreportnews ${buttnval === "1" ? 'active' : ""}`}  >Site Audit</button></Link>
            <Link to="/onpage-crawl-data/2/1" > <button type="button" ref={onpageref} id={2} className={`runreportnews ${buttnval === "2" ? 'active' : ""}`}  >On Page Crawl Data</button></Link>
            <Link to="/page-health/3/" > <button type="button" id={3} className={`runreportnews ${buttnval === "3" ? 'active' : ""}`} >Page Health</button></Link>
            <Link to="/competitor-analysis/4/" ><button type="button" id={4} className={`runreportnews ${buttnval === "4" ? 'active' : ""}`}  >Competitor Analysis</button></Link>
          </div>
        </div>
      </div>
      {renderSwitch(menubutval)}
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      {!openCommonAlerts &&


        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
