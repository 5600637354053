import React, { useState, useRef,useEffect,useContext }  from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {  useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import errorimages from "../Asset/errorsimage.png"
import successimages from "../Asset/true-images.png"
import { AppContext } from '../../../Context/Context';
const primary = "#FFFFFF";

export default function ConfirmError() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apiserrorMsg,setapiserrorMsg]=useState("");
const [apierrorMsg, setApiErrorMsg] = useState("");

const [alertapiopen, setAlertapiOpen] = useState(false);
const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
let querystring = useParams();
  let uid = querystring.uid;
  
 const navigate = useNavigate();
  useEffect(() => {
    callUserdataApi()
  }, []);
  const callUserdataApi = async () => {
    const Response = await axios.post(
      `${process.env.REACT_APP_API_URL}settings/user_management_add_user_confirmation/`, 
      {
        uid: uid
      },  
      {
        headers: { Authorization: `${ApiToken}` },
      } 
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapiserrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
    });
  }
  return (
    <div
    className={`over_all_container  error_page ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: '#0b2033' }}>
      <img src={successimages} alt="errimg" title="Successimage"/>
      </Typography>
      <Typography variant="h6" style={{ color: '#0b2033',"text-align":'center',"margin":"0px 4px" }}>
       Thank you ! User Added Successfully

      </Typography>
      <p>Further queries please contact <b>support@elitesiteoptimizer.com</b></p>
      <Link to="/"> <Button variant="contained" sx={{
       textDecoration:"none",
      }}>Back Home</Button></Link>
    </Box>
    </div>
  );
}
