import React, { useState, useEffect, useRef, useContext } from "react";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Siteauditlists from "./Siteauditlists";
import Onpagecrawllist from "./Onpagecrawllist";
import Pagehealthlists from "./Pagehealthlists";
import CompetitorTest from "./CompetitorTest";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import CircleIcon from '@mui/icons-material/Circle';
import { AppContext } from "../../../Context/Context";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import CommonAlert from "../../../Commonalert/commonalert";
import { Utils } from "../../../Utils/Utils";
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Siteanalysis() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [JobId, SetJobId] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [menubutval, setMenubutval] = useState("1")
  const [buttnval, setButtnval] = useState("1")
  const [buttnclickval, setButtnclickval] = useState("1")
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [pagename, setPagename] = useState("Site Audit")
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [jobDate, setJobdate] = React.useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [siteauditPlan, setsiteauditPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);


  let querystring = useParams();
  let domain_id = querystring.id;
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();

  const menuChange = e => {
    e.preventDefault();
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if (e.target.id == 1)
      setPagename("Site Audit")
    else if (e.target.id == 2)
      setPagename("On Page Crawl")
    else if (e.target.id == 3)
      setPagename("Page Health")
    else if (e.target.id == 4)
      setPagename("Competitor Analysis")
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;

  const checkUserPlan = async () => {
    const siteauditP = await Utils("site_audit", ApiToken);
    if (siteauditP.status) {
      faqdatafun()
    }
    setsiteauditPlan(siteauditP);
  };

  useEffect(() => {
    checkUserPlan();
    setMenubutval(domain_id)
    setButtnval(domain_id)
  }, []);
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/ignore_siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&is_format=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SiteAudit", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/ignore_siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&is_format=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SiteAudit", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    SetJobId(selectval[0].id);
    closeModal();
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const renderSwitch = (params) => {
    switch (params) {
      case "1":
        return <Siteauditlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} onbutnvaldata={setButtnclickval} setJobdate={setJobdate} jobDate={jobDate} SetJobId={SetJobId} JobId={JobId} />
      case "2":
        return <Onpagecrawllist modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
      case "3":
        return <Pagehealthlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
      case "4":
        return <CompetitorTest modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setJobdate={setJobdate} jobDate={jobDate} />
    }
  }
  const onpageref = useRef(null)
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('site_audit')

  
  const faqdatafun = async (menubutval = "") => {

    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=14`


    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="cnt_audit_outer_wrap ">



          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: 'grey' }}>Site Analysis</Typography>
              <Link to="/site-audit/1">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Site Audit Report</Typography>
              </Link>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Ignored Metrics</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {siteauditPlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                    csvdown={csvdown} pdfdown={pdfdown} />
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Site Audit Report - Ignored Metrics</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
          </div>

        </div>

        {renderSwitch(menubutval)}
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }

    </div>
  );
}
