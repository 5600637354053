import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import RunReportNow from "../RunReportNow";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate, Link } from "react-router-dom";
import { Commonfun } from "./../Commonfun";
import { AppContext } from "../../../Context/Context";
import { timezoneval } from "./../Commonfun";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Breadcrumbs } from "@mui/material";
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import NewLoader from "../NewLoader"
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Plagiarisamlist() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [AvailablePoints, SetAvailablePoints] = useState(0);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2");
  const [sizedata, setSizedata] = useState(550);
  const [autoheightval, SetAutoheightval] = useState(true);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apiStatusCode, setApiStatusCode] = React.useState(null);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [errmsg, setErrmsg] = useState("");
  const [plagiarismPlan, setplagiarismPlan] = useState({
    status: true,
    error: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });
  const [newloading, setnewLoading] = useState(false);

  const navigate = useNavigate();
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "#0b2033";

  const apihandleClose = () => {
    SetAnalyzeUrl("");
    setapiOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const checkUserPlan = async () => {
    const plagiarismP = await Utils("plagiarism", ApiToken);
    if (plagiarismP.status) {
      callUserdataApi();
      faqdatafun();
    }
    setplagiarismPlan(plagiarismP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Index of the row",
    },
    {
      field: "dateOfRequest",
      headerName: "Date of Request",
      description: "The date on which the report has been initiated.",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return moment(cardData.row.date_of_request).format("MMM DD,YYYY ");
      },
    },
    {
      field: "request_url",
      headerName: "URL Checked",
      description:
        "The web page URL for which the content is to be checked for plagiarism or duplicates.",
      minWidth: 320,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let wholedata = "";
        firstEightLetters = cardData?.row?.request_url;
        wholedata = firstEightLetters;
        let baseUrl =
          cardData.row.request_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.request_url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData.row.request_url}>
              <div className="prefered_url_external02">
                <a
                  className="table_links"
                  target="_blank"
                  href={cardData.row.request_url}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink">{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "total_words",
      headerName: "Total Words",
      description: "The total number of word counts is shown.",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "similarity_found",
      headerName: "Total Similarity Words",
      description: "It displays a total number of similarity words found.",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "matching_words",
      headerName: "Total Matched Words",
      description: "A total number of matching words will be found.",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "similarity_score",
      headerName: "Similarity Score",
      description:
        "The percentage of content similarity for the content used in a page from the internet resources.",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        var sr = cardData.row.similarity_score;
        var s = sr.split("%");
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s[0]}
            percent="%"
            textSize={1.2}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: textfillColor,
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      description: "Click below Button to view detailed report",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        if (cardData.row.status !== "completed") return cardData.row.status;

        return (
          <a href={detailurl + `${cardData.row.id}`}>
            <button className="view-report-button">View Report</button>
          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          },
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      if (jobidData == "") {
        const completevalues = await axios
          .get(
            `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=plagiarism&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            },
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/errorpages");
            }
          });
        setcompletedate(completevalues.data.jobs);
      }
      const dataval = datavalue?.data?.result;
      setjobDate(
        moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"),
      );

      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          return {
            ...obj,
            sid: index + 1,
            dateOfRequest: moment(obj.date_of_request).format(
              "MMMM DD,YYYY HH:mm",
            ),
          };
        });

        setCardData(newData);

        SetAutoheightval(false);
      } else {
        setCardData("");
        SetAutoheightval(true);
      }

      const Pointsdatavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}content/v2/get_plagiarism_points/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          },
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (Pointsdatavalue.data) {
        SetAvailablePoints(Pointsdatavalue.data.available_points);
      }

    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
  };

  const reportNow = async () => {
    var errs = 1;
    let errors = {};
    if (selectval == "") {
      errors.dateerror = "Enter URL";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setLoading(true);
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };

  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }

  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const AnalyzeReport = async () => {
    setErrmsg("");
    setnewLoading(true);
    if (AnalyzeUrl !== "") {
      try {
        const datavalue = await axios
          .get(
            `${process.env.REACT_APP_API_URL}content/run_plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&url=${AnalyzeUrl}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            },
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/errorpages");
            }
          });

        if (datavalue?.data) {
          if (datavalue?.data?.status_code == 200) {
            setapiOpen(true);
            setApiStatusCode(200);
            setapierrorMsg(datavalue?.data?.message);
          }
        }
      } catch (err) {
        if (err.response.data.error) {
          SetErrorMsg(err.response.data.error);
        } else {
          SetErrorMsg("Something went wrong while analysing the report!");
        }
      }
    } else {
      setErrmsg("This field is required.");
    }
    setnewLoading(false);
  };

  const [RunReportPopShow, SetRunReportPopShow] = useState(false);
  const [RunReportType, SetRunReportType] = useState("plagiarism");

  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(
          "plagiarism-checker",
          "pdf",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(
          "plagiarism-checker",
          "csv",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  };
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`;

    try {
      const doaminsectiondatavalue = await axios.get(datavar, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      });
      if (doaminsectiondatavalue.data) {
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input" ||
      event.target.classList[0] === "run_rpt_btn" ||
      event.target.classList[0] === "urlcheck" || event.target.classList[0] !== "MuiSvgIcon-root" 
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });
  return (
    <>
      <div
        className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plagiarism </title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {loading === true ? <Loader /> : null}
        {
          newloading === true ? <NewLoader /> : null
        }
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography
                  sx={{
                    color: "#ff601f",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Dashboard
                </Typography>
              </Link>
              <Typography sx={{ color: "grey" }}>Content</Typography>
              <Typography
                sx={{
                  color: "#ff601f",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Plagarism Checker
              </Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button
                onClick={handleToggle}
                className="faq_class exportpdf"
                title="Frequently Asked Question"
              >
                <QuestionMarkIcon />
              </button>
              {plagiarismPlan.status && (
                <>
                  <PdfandCsv
                    Downloadval={1}
                    isDownloadingcsv={isDownloadingcsv}
                    isDownloading={isDownloading}
                    csvdown={csvdown}
                    pdfdown={pdfdown}
                  />
                </>
              )}
            </div>
            {toggle && (
              <Keywordbar
                close={() => setToggle(false)}
                initialDateval={initialDateval}
              />
            )}
          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Plagiarism Checker</h2>
              <p>Available points : {AvailablePoints}</p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              {plagiarismPlan.status && (
                <div className="frm_containers plagarisminputbutton">
                  <input
                    type="text"
                    value={AnalyzeUrl}
                    onChange={(e) => {
                      SetAnalyzeUrl(e.target.value);
                    }}
                    placeholder="Enter Domain URL"
                    className="campaign-name"
                    style={{ width: 250 }}
                  />
                  <p style={{ color: "red" }}>{errmsg}</p>
                </div>
              )}
              <div className="button_col">
                <button
                  class="analyzbutton runreportnew"
                  onClick={AnalyzeReport}
                  style={{ padding: "10px 15px" }}
                >
                  Analyze
                </button>
              </div>
            </div>
          </div>

          {plagiarismPlan.status ? (
            <>
              <div className="error_plagarism_report">{ErrorMsg}</div>
              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
            </>
          ) : (
            <Freeaudit planStatus={plagiarismPlan} />
          )}
          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter job_date_filter"
          >
            <DialogTitle className="keyword-dialog-header">
              {"Filter By"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">
                  <div>
                    <div className="">
                      <div>
                        <span>Completed Date</span>
                        <Select
                          options={completedate}
                          labelField="completed_at"
                          valueField="id"
                          onChange={(values) => setSelectval(values)}
                        />
                      </div>
                    </div>

                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <button
                className="form_submit_btn"
                onClick={reportNow}
                style={{ cursor: "pointer" }}
              >
                Submit
              </button>
              <button style={{ cursor: "pointer" }} onClick={closeModal}>
                close
              </button>
            </DialogActions>
          </Dialog>

          {!openCommonAlerts &&
            <>
              <CommonErrorAlert
                data={{
                  apiopen: open,
                  apiErrorMsg: 'No Data Found',
                  isIconStyled: true
                }}
                closeAlert={handleClose} />
              <CommonErrorAlert
                data={{
                  apiopen: apiopen,
                  apiErrorMsg: apierrorMsg,
                  isIconStyled: true
                }}
                closeAlert={apihandleClose} />
            </>
          }
          <Dialog
            open={checkD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    lineHeight: "1px",
                    width: "max-content",
                  }}
                >
                  <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                  <a href={domainNameUrl.domain_url}>
                    {domainNameUrl.domain_url}
                  </a>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button
                onClick={() => {
                  navigate(first.path);
                }}
              >
                {first.name}
              </Button>
              <Button onClick={() => setcheckD(false)}>
                {first.closeName}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <RunReportNow
        RunReportPopShow={RunReportPopShow}
        SetRunReportPopShow={SetRunReportPopShow}
        RunReportType={RunReportType}
      />
    </>
  );
}
