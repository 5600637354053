import React, { useState, useEffect, useRef, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import { useMediaQuery } from "react-responsive";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import SquareIcon from '@mui/icons-material/Square';


export default function DatagridComp({ columns, rows, autoheightval, datagridval }) {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );


  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const CustomToolbar1 = () => {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "space-between", padding: "8px", alignItems: "end" }}>
        <div className="missed_below_note_ranking">
          <div className="below_note_ranking">
            *Note  :   N/A is Not Available

          </div>
          <div className="below_note_ranking icon">
            <div className="green">
              <ArrowDropUpSharpIcon />-  Gained
            </div>

            <div className="rose">
              <ArrowDropDownIcon /> -   Dropped
            </div>

            <div className="orange">
              <SquareIcon />-  No Change
            </div>


          </div>
        </div>
        <GridToolbarQuickFilter
          className="datagrid-search"
        />
      </GridToolbarContainer>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "space-between", padding: "8px", alignItems: "end" }}>
        {/* Custom Left Side Content */}
        <div className="below_note_ranking icon over_all_accessibility">
          {/* Very easy - Dark Green */}
          <div style={{ color: "#006400" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Very easy</span>
          </div>

          {/* Easy - Green */}
          <div style={{ color: "#008000" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Easy</span>
          </div>

          {/* Fairly easy - Orange */}
          <div style={{ color: "#FFA500" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Fairly easy</span>
          </div>

          {/* Okay - Light Orange */}
          <div style={{ color: "#FFD700" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Okay</span>
          </div>

          {/* Fairly difficult - Light brown */}
          <div style={{ color: "#D2B48C" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Fairly difficult</span>
          </div>

          {/*  Difficult -  brown */}
          <div style={{ color: "#A52A2A" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Difficult</span>
          </div>

          {/* Very Confusing - Light brown */}
          <div style={{ color: "#FF0000" }}>
            {" "}
            <CircleIcon /><span style={{ color: "#0b2033" }}>Very Confusing</span>
          </div>
        </div>

        {/* Default Search Box (Quick Filter) */}
        <GridToolbarQuickFilter
          className="datagrid-search"
        />
      </GridToolbarContainer>
    );
  };
  return (
    <>
      <div
        className="data_table analysis_summary_table analysed_table_Data over_all_accessibility"
        style={{ width: "100%" }}
      >
        <div style={{ height: rows.length < 5 ? 'unset' : isMobile ? 800 : 550, width: "100%" }} className="over_all_accessibility analysissummary_table">
          <DataGrid
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: datagridval == 1 ? CustomToolbar : datagridval == 3 ? CustomToolbar1 : datagridval == 2 ? GridToolbar : null,
              noResultsOverlay: MyCustomNoRowsOverlay,
              footer: () => (
                <Box className="table-footer-container">
                  <Box className="table-footer-pagination">
                    <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                  </Box>
                  <MSelect
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    size="small"
                    className="table-footer-select"
                  >
                    <MenuItem value={10}>10 per page</MenuItem>
                    <MenuItem value={30}>30 per page</MenuItem>
                    <MenuItem value={50}>50 per page</MenuItem>
                    <MenuItem value={75}>75 per page</MenuItem>
                    <MenuItem value={100}>100 per page</MenuItem>
                  </MSelect>
                </Box>
              ),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
            columns={columns}
            getRowId={(row) => row.sid}
            getRowHeight={() => "auto"}
            autoHeight={autoheightval ? true : false}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
          />
        </div>
      </div>
    </>
  )
}
