import React, { useEffect, useState } from 'react';
import GetAppIcon from "@mui/icons-material/GetApp";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import axios from "axios";


export default function PdfandCsv({ Downloadval, isDownloadingcsv, isDownloading, pdfdown, csvdown }) {
  const navigate = useNavigate();
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status === 599 || error?.response?.status === 500 || error?.response?.status === 400 || error?.response?.status === 404) {
          navigate('/errorpages');
        } else if (error?.code === "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetuserDatavalue();
  }, []);

  return (
    <>
      {Downloadval === 1 || Downloadval === 3 ?
        <button
          className="exportpdf"
          alt="csv icon"
          onClick={() => {
            if (first.statusDomain) {
              setcheckD(true);
            } else if (!isDownloadingcsv) {
              csvdown();
            }
          }}
          title="CSV Download"
        >
          <GetAppIcon />
          CSV
        </button> : ""
      }
      {Downloadval === 1 || Downloadval === 2 ?
        <button
          className="exportpdf"
          alt="pdf icon"
          onClick={() => {
            if (first.statusDomain) {
              setcheckD(true);
            } else if (!isDownloading) {
              pdfdown();
            }
          }}
          title="PDF Download"
        >
          <GetAppIcon />
          PDF
        </button> : ""
      }

      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path); }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


