import React from "react";
import loadinggif from "./Asset/Loading.gif";

const Loader=()=>{
    
    return (
        <div class="overlay">
    <div class="overlay__inner">
        <div class="overlay__content">
            <img src={loadinggif} alt="loaderlogo"/>
        </div>
    </div>
</div>
    )
}
export default Loader