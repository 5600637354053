

export const customDropdownStyles = () => {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(255, 96, 31)" : "white",
            color: state.isFocused ? "white" : "black",
        }),
        control: (base) => {
            const isMobile = typeof window !== "undefined" && window.innerWidth < 500;
            return {
                ...base,
                borderColor: "rgb(255, 96, 31)",
                padding: "8px",
                "&:hover": {
                    borderColor: "rgb(255, 96, 31)",
                },
                boxShadow: "none",
                height: "37px",
                minHeight: "37px",
                alignContent: "center",
                width: isMobile ? "200px" : "269px",
                fontSize: isMobile ? "smaller" : "13px"
            }
        },
        input: (base) => ({
            ...base,
            textAlign: 'left',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "rgb(255, 96, 31)",
            "&:hover": {
                color: "rgb(255, 96, 31)",
            },
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "150px",
            zIndex: 9999,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        })
    };
};

export const customMultiDropdownStyles = () => {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(255, 96, 31)" : "white",
            color: state.isFocused ? "white" : "black",
        }),
        control: (base) => {
            const isMobile = typeof window !== "undefined" && window.innerWidth < 500;
            return {
                ...base,
                borderColor: "rgb(255, 96, 31)",
                "&:hover": {
                    borderColor: "rgb(255, 96, 31)",
                },
                boxShadow: "none",
                height: "37px",
                minHeight: '37px',
                alignContent: "center",
                width: isMobile ? "200px" : "269px",
                fontSize: isMobile ? "smaller" : "15px"
            }
        },
        input: (base) => ({
            ...base,
            textAlign: 'left',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "rgb(255, 96, 31)",
            "&:hover": {
                color: "rgb(255, 96, 31)",
            },
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "150px",
            zIndex: 9999,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
        })
    }
};