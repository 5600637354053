import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./../style.css";
import "./linkeddomainstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function LinkedDomainList(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  useEffect(() => {
    callUserdataApi();
  }, []);
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 80,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "domain_name",
      headerName: "Domain Name",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: 'Name provided for the domain.',
    },
    {
      field: "da",
      headerName: "DA",
      minWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: 'Domain InLink Rank (Domain Authority) of the referring domain.',
    },
    {
      field: "total_backlinks_percentage",
      headerName: "Total Backlink(from this domain)",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: 'The total number of backlinks pointing to the target from this domain.',
      renderCell: (cardData) => {
        var datawidth = cardData.row.total_backlinks_percentage;
        var percentdata = datawidth + "%";
        return (
          <div className=" dt-left" id="td-backlink-0" data-sort={1}><span>{cardData.row.total_backlinks}</span>
            <div className="stacked-bar-graph">
              <span style={{ width: percentdata }} className="bardata" />
            </div>
            <span style={{ textAlign: 'left' }}>{percentdata}</span>
          </div>
        );
      },
    },
    {
      field: "do_follow_backlinks_percentage",
      headerName: "Do Follow Backlinks",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: 'The number of backlinks from this domain that are not marked as nofollow.',
      renderCell: (cardData) => {
        var datawidth = cardData.row.do_follow_backlinks_percentage;
        var percentdata = datawidth + "%";
        return (
          <div className=" dt-left" id="td-backlink-0" data-sort={1}><span>{cardData.row.do_follow_backlinks}</span>
            <div className="stacked-bar-graphs">
              <span style={{ width: percentdata }} className="bardata" />
            </div>
            <span style={{ textAlign: 'left' }}>{percentdata}</span>
          </div>
        );
      },
    },
    {
      field: "first_seen",
      headerName: "First Seen",
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'The date when the backlink was discovered and added to the index.',
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}backlinks/v2/linked_domain_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    const dataval = datavalue?.data?.linked_domain_table_data;
    if (dataval) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }
    props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
    setLoading(false);
  };

  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      callUserdataApi()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Linked Domain List</title>
      </Helmet>
      <div >
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={4}
        />

        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
      </div>
    </div>
  );
}
