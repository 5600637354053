import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinkIcon from '@mui/icons-material/Link';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
export default function InternallinkSource() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [jobDate, setjobDate] = useState("");

  const [tablecardData, setTableCardData] = useState([]);
  const [LinkUrl, SetLinkUrl] = useState('')
  let querystring = useParams();
  const [autoheightval, SetAutoheightval] = useState(true)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);


  let spell_job_id = querystring.jobid;
  let data_job_id = querystring.datajobid


  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const callUserdataApi = async () => {
    setLoading(true);

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      SetLinkUrl(datavalue?.data?.link_url)




      const dataval = datavalue?.data?.source_links[0];

      var CardArray = [];
      setjobDate(datavalue?.data?.job_date)
      if (dataval && dataval.length > 0) {
        SetAutoheightval(false);
        dataval.forEach((element, index) => {
          CardArray.push({ sid: index + 1, url: element })
        });
        setCardData(CardArray);

      }
      else {
        setCardData("");
        SetAutoheightval(true);
      }









    } catch (err) {
      console.log('err', err)
    }





    setLoading(false);

  };
  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "url",
      headerName: "Source URL",
      minWidth: 900,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cardData) => {

        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");


        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>

            </Tooltip>

          </>
        );

      },
    }



  ];
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const rows = cardData ?? [];
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );



  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Internallinksource.pdf';

        
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(true);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Internallinksource.csv';

        
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(true);
  }
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=22`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };


  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Link to="/internal-link-checker">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Internal Link Checker</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Internal source links </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            <>
              <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Internal source links</h2>
            </div>
            <a target="_blank" href={LinkUrl} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="spanlink">
                &nbsp; {LinkUrl}
              </span>
            </a>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
        </div>

        <div className="data_table internal_source_table" style={{ width: "100%" }}>
          <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
            <DataGrid
              getRowId={(row) => row.sid}
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay,
                footer: () => (
                  <Box className="table-footer-container">
                    <Box className="table-footer-pagination">
                      <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                    </Box>
                    <MSelect
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                      size="small"
                      className="table-footer-select"
                    >
                      <MenuItem value={10}>10 per page</MenuItem>
                      <MenuItem value={30}>30 per page</MenuItem>
                      <MenuItem value={50}>50 per page</MenuItem>
                      <MenuItem value={75}>75 per page</MenuItem>
                      <MenuItem value={100}>100 per page</MenuItem>
                    </MSelect>
                  </Box>
                ),
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
              columns={columns}
              pagination
              getRowHeight={() => 'auto'}
              autoHeight={autoheightval ? true : false}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
            />
          </div>
        </div>
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      </div>
    </div>
  );
}
