import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "chartjs-adapter-moment";
import { AppContext } from "../../../Context/Context";
import Loader from "../CommonLoader";
import NewLoader from "../NewLoader";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Keywordbar from "../Rightsidebar/Keywordbar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const ErrorReport = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [ReportSectionOptions, SetReportSectionOptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState();
  const [earlierdomainsectiondata, Setearlierdomainsectiondata] = useState();
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [apiopen, setapiOpen] = useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [errors, setErrors] = useState({});
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [jobidval, Setjobidval] = useState("");
  const [earlierjobidval, Setearlierjobidvall] = useState("");
  const [consterrreport, Setconsterrreport] = useState([])
  const [datavalError, SetdatavalError] = useState([])
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [reportdata, setReportdata] = useState([]);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };




  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=63`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    customreportdatedata();
    reportError();
    faqdatafun();
    GetuserDatavalue();
  }, []);

  const customreportdatedata = async () => {
    const completevalues = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_all_type_jobs/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (
          error?.response?.status == 599 ||
          error?.response?.status == 400 ||
          error?.response?.status == 500
        ) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (
          error?.code == "ERR_NETWORK" ||
          error?.response?.status == 404
        ) {
          navigate("/errorpages");
        }
      });
    var Selectedmets = [];
    completevalues?.data?.forEach((element) => {
      Selectedmets.push({
        id: element.id,
        job_date: element.job_date,
      });
    });
    SetSectionOptions(Selectedmets);
    setReportdata(Selectedmets)
  };
  const SetSectionNameHandles = async (passvalue) => {
    Setearlierdomainsectiondata({ id: passvalue.id, job_date: passvalue.label })
    Setearlierjobidvall(passvalue.value);
    RegressionListval("greaterthan", passvalue?.value)
  };
  const SetSectionNameHandle = async (passvalue) => {
    SetDomainsectiondata({ id: passvalue.id, job_date: passvalue.label })
    Setjobidval(passvalue.value);
    RegressionListval("lessThan", passvalue?.value)
  };

  const RegressionListval = async (regressionval, valuedata) => {
    if (regressionval == "lessThan")
      var datavalueurl = `${process.env.REACT_APP_API_URL}domain/get_all_type_jobs/?user_domain_id=${esodata.domaindata.value.header_selected}&less_than_job_id=${valuedata}&greater_than_job_id=`
    else
      var datavalueurl = `${process.env.REACT_APP_API_URL}domain/get_all_type_jobs/?user_domain_id=${esodata.domaindata.value.header_selected}&less_than_job_id=&greater_than_job_id=${valuedata}`
    const datedatavalue = await axios
      .get(
        datavalueurl,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
    if (regressionval == "lessThan")
      setReportdata([])
    else
      SetSectionOptions([])
    if (datedatavalue?.data) {
      var Selectedmets = [];
      datedatavalue?.data.forEach((element) => {
        Selectedmets.push({ id: element?.id, job_date: element?.job_date });
      });
      if (regressionval == "lessThan") {
        setReportdata(Selectedmets)
      }
      else {
        SetSectionOptions(Selectedmets)
      }

    }
  }

  const validateFields = () => {
    const newErrors = {};
    if (!jobidval) newErrors.section = "Select Current Completed Date.";
    if (!earlierjobidval) newErrors.columns = "Select Previous Completed Date.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async () => {
    setLoading(true);
    if (!validateFields()) {
      setLoading(false);
      return;

    }
    reportError();
  };
  const reportError = async () => {
    try {
      setLoading(true);
      setIsCardVisible(false);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/consolidate_error_table/?user_domain_id=${esodata.domaindata.value.header_selected}&latest_job_id=${jobidval}&earlier_job_id=${earlierjobidval}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      Setconsterrreport(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
      if (
        error?.response?.status === 599 ||
        error?.response?.status === 400 ||
        error?.response?.status === 500
      ) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error);
      } else if (
        error?.code === "ERR_NETWORK" ||
        error?.response?.status === 404
      ) {
        navigate("/errorpages");
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setLoading(false);
    }
  }

  const labels = datavalError
    ? Object.keys(datavalError).map(key => key.replace(/_/g, ' '))
    : [];

  const currentData = datavalError
    ? Object.values(datavalError).map(item => item.current_occurance)
    : [];

  const previousData = datavalError
    ? Object.values(datavalError).map(item => item.previous_occurance)
    : [];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Previous",
        backgroundColor: "#103658",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        data: previousData,
      },
      {
        label: "Current",
        backgroundColor: "#ff601f",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        data: currentData,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Audit Metrics Comparison",
      },
    },
  };
  return (
    <div
      className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""
        }`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consolidated Error Report</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {
        loading === true ? <NewLoader /> : null
      }
      <div className="over_all_inner">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Consolidated Error Report
            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap" >
            <h2 className="heading_font" style={{ color: "#000" }}>Consolidated Error Report</h2>
          </div>

          <Helmet>
            <meta charSet="utf-8" />
            <title>     Consolidated Error Report                </title>
          </Helmet>
        </div>
        {isCardVisible ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff601f" }}
            onClick={toggleCardVisibility}
          >
            Hide Filters
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff601f" }}
            onClick={toggleCardVisibility}
          >
            Show Filters
          </Button>
        )}
        {loading === true ? <Loader /> : null}
        {isCardVisible && (
          <div className="card_custom">
            <div className="filter-section">
              <div className="filter-item">
                <span className="listdropdown_titl">Current Completed Date</span>
                <Select
                  className="dropdown-style"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  id="section-dropdown"
                  options={SectionOptions.map((option) => ({
                    value: option.id,
                    label: option.job_date,
                  }))}
                  placeholder={Domainsectiondata ? "" : "Current Completed Date"}
                  onChange={SetSectionNameHandle}
                  defaultValue={
                    Domainsectiondata
                      ? { value: Domainsectiondata.id, label: Domainsectiondata.job_date }
                      : null
                  }
                />
                {errors.section && (
                  <div className="customerrormsg">{errors.section}</div>
                )}
              </div>
              <div className="filter-item">
                <span className="listdropdown_titl">Previous Completed Date</span>
                <Select
                  className="dropdown-style"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  id="section-dropdown"
                  options={reportdata.map((option) => ({
                    value: option.id,
                    label: option.job_date,
                  }))}
                  placeholder={Domainsectiondata ? "" : "Previous Completed Date"}
                  onChange={SetSectionNameHandles}
                  defaultValue={
                    earlierdomainsectiondata
                      ? { value: earlierdomainsectiondata.id, label: earlierdomainsectiondata.job_date }
                      : null
                  }
                />
                {errors.columns && (
                  <div className="customerrormsg">{errors.columns}</div>
                )}
              </div>
              <Button
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    handleSubmit()
                  }
                }}
                className="run_rpt_btn rn_rpt_btn over_all_accessibility runreportnew"
                style={{ marginTop: 30 }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>

      <div id="webcrumbs">
        <div className="bg-white rounded-lg shadow p-4">
          <table className="border border-neutral-300 errorreport">
            <thead>
              <tr>
                <th className="border border-neutral-300 p-2 text-left">
                  Report Name
                </th>
                <th className="border border-neutral-300 p-2 text-left">
                  Error Category
                </th>
                <th className="border border-neutral-300 p-2 text-left" title="Current Completed Date">
                  {consterrreport?.headers?.[0] ? consterrreport?.headers?.[0] : "Current report error occurrence"}
                </th>
                <th className="border border-neutral-300 p-2 text-left" title="Previous Completed Date">
                  {consterrreport?.headers?.[1] ? consterrreport?.headers?.[1] : " Previous report error occurrence"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="2">
                  Ranking Summary
                </td>
                <td className="border border-neutral-300 p-2">
                  Not ranked keywords
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.not_ranked_keywords?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.not_ranked_keywords?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missed Opportunities
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.total_missed_value?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.total_missed_value?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="11">
                  Site analysis
                </td>
                <td className="border border-neutral-300 p-2">
                  Missing ALT Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_alt_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_alt_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate Titles
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_title_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_title_count?.previous_occurance}</td>
              </tr>

              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Canonical
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_canonical_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_canonical_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Descriptions
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_description_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_description_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate Descriptions
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_description_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_description_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Multiple H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.multiple_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.multiple_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Titles
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_title_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_title_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Hreflang</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.hreflang_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.hreflang_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Mismatch Canonical</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.mismatch_canonical_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.mismatch_canonical_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="2">
                  Schema analysis
                </td>
                <td className="border border-neutral-300 p-2">Warning count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Error Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Source code analysis
                </td>
                <td className="border border-neutral-300 p-2">
                  Fatal error count
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.fatal_error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.fatal_error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Error Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Warning count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Accessibility Report
                </td>
                <td className="border border-neutral-300 p-2">Error count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Warning Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Notice Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.notice_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.notice_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Link checker
                </td>
                <td className="border border-neutral-300 p-2">
                  Internal Links - 4xx links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fourxx_links_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fourxx_links_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Internal Links - 5xx links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fivexx_links_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fivexx_links_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  External Links - Invalid Links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.external_links_invalid_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.external_links_invalid_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="1">
                  Spell checker
                </td>
                <td className="border border-neutral-300 p-2">
                  Total misspelled words
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.spell_check?.misspelled_word_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.spell_check?.misspelled_word_count?.previous_occurance}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                lineHeight: "1px",
                width: "max-content",
              }}
            >
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url}>
                {domainNameUrl.domain_url}
              </a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button
            onClick={() => {
              navigate(first.path);
            }}
          >
            {first.name}
          </Button>
          <Button onClick={() => setcheckD(false)}>{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ErrorReport;
