import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Siteauditstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { GetCompetitorJobIds } from "../../../Store/CompetitiorJobId";
import { GetCompetitorJobIds as GetCompetitorDomainIds } from "../../../Store/CompetitiorDomainId";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import Loader from "../CommonLoader";
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TuneIcon from '@mui/icons-material/Tune';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { useNavigate } from "react-router-dom";
import { Commonfun, datezonechange } from './../Commonfun';
import { useSelector } from "react-redux";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function CompetitorTest(props) {
  const isMobile = useMediaQuery('(max-width:480px)');


  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [competitorjobDate, setcompetitorjobDate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [competitorcardData, setCompetitorcardData] = useState([]);
  const [competitorsitecardData, setCompetitorSitecardData] = useState([]);
  const [competitorinternalcardData, setCompetitorInternalcardData] = useState([]);
  const [value, setValue] = useState("1");
  const [domainval, Setdomainval] = useState('')
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [autoheightval, SetAutoheightval] = useState(true);
  const [ourDomainName, setOurDomainName] = useState('');
  const [compDomainName, setCompDomainName] = useState('');

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const arr = [];

  useEffect(() => {
    CloseModalTrigger()


    dispatch(GetCompetitorJobIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorDomainIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));

    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify === "true" ? true : false);

  }, []);
  const columns = [

    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description: 'On-page SEO elements are present on the website.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'Priority Status from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      headerName: ourDomainName,
      description: 'Our Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const column1 = [
    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description: 'Website metrics present on the website',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    {
      field: "our_occurences",
      headerName: ourDomainName,
      minWidth: 150,
      flex: 1,
      description: 'Our Occurrence count from competitor test',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const column2 = [

    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: 'It displays server error links (5xx) and broken links (4xx) on the website.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,


    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      headerName: ourDomainName,
      minWidth: 150,
      flex: 1,
      description: 'Our Occurrence count from competitor test',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const rows = cardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
  const cardrow = competitorcardData ?? [];
  const LoadAddFormFilterOptions = async () => {

    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
      if (completevalues?.data?.jobs.length > 0) {
        var jobSections = [];
        completevalues?.data?.jobs.forEach((element) => {
          jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
        });
        setcompletedate(jobSections);
        SetJobId(jobSections[0].value);
        SetdEngineoption(jobSections[0]);
        setprevdEngineoption(jobSections[0]);

      }


    }
  }
  const callUserdataApi = async (firstCall) => {
    setLoading(true);



    var datavalue;
    if (firstCall) {

      datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${props.compId1}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${props.compId2}&competitor_domain_id=${props.compId3}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });

    }
    else {




      datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${JobId}&user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${CompetitorDomainId.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });

    }


    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });
      var jobSections = [];
      completevalues?.data?.jobs.forEach((element) => {
        jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
      });
      setcompletedate(jobSections);
    }



    const dataval = datavalue?.data?.domain?.seo_metrics_list;
    const dataval1 = datavalue?.data?.domain?.site_metrics_list;
    const dataval2 = datavalue?.data?.domain?.internal_links_list;
    const compDataval = datavalue?.data?.competitor?.seo_metrics_list;
    const compDataval1 = datavalue?.data?.competitor?.site_metrics_list;
    const compDataval2 = datavalue?.data?.competitor?.internal_links_list;

    setjobDate(moment(datavalue?.data?.domain?.job_date).format("MMMM DD,YYYY HH:mm"));
    props.setJobdate(moment(datavalue?.data?.domain?.job_date).format("MMMM DD,YYYY HH:mm"));




    if (dataval && dataval.length > 0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }

    if (dataval1) {
      const newData = dataval1.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setSitecardData(newData);
    }


    if (dataval2) {
      const newData = dataval2.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setInternalcardData(newData);
    }





    setCompetitorcardData(compDataval)
    setCompetitorSitecardData(compDataval1)
    setCompetitorInternalcardData(compDataval2)

    var datavalueChart;

    if (firstCall) {

      datavalueChart = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&competitor_domain_id=${props.compId3}&job_id=${props.compId1}&comp_job_id=${props.compId2}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });

    } else {

      datavalueChart = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&competitor_domain_id=${CompetitorDomainId.value}&job_id=${JobId}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });

    }





    const chartval = datavalueChart?.data?.graph?.issue_graph;

    const seoindex = chartval?.map(function (val, index) {
      return val.seo_metrics;
    });
    const seooccurence = chartval?.map(function (val, index) {
      return val.comp_occurences;
    });
    const latestoccurence = chartval?.map(function (val, index) {
      return val.our_occurences;
    });

    setSeoindexData(seoindex);
    setSeooccurData(seooccurence);
    setLatestoccurData(latestoccurence);
    const datahearvals = ['High impact Issue Occurrence', 'Medium Impact Issue Occurrence', 'Low Impact Issue Occurrence']
    setArrheaderdata(datahearvals)

    const earlier_dataarrvals = [];
    const latest_dataarrvals = [];

    latest_dataarrvals[0] = datavalueChart?.data?.graph?.our_high_count;
    earlier_dataarrvals[0] = datavalueChart?.data?.graph?.comp_high_count;
    latest_dataarrvals[1] = datavalueChart?.data?.graph?.our_medium_count;
    earlier_dataarrvals[1] = datavalueChart?.data?.graph?.comp_medium_count;
    latest_dataarrvals[2] = datavalueChart?.data?.graph?.our_low_count;
    earlier_dataarrvals[2] = datavalueChart?.data?.graph?.comp_low_count;




    if (CompetitorDomainId) {
      let compDomains = esodata?.competitordomainids?.value;
      const selectedCompDomainName = compDomains?.find(d => d?.value == CompetitorDomainId);
      setCompDomainName(selectedCompDomainName?.label);
    }

    setLatestdataarr(latest_dataarrvals)
    setEarlierdataarr(earlier_dataarrvals)

    var severity_dataarrvals = [];

    severity_dataarrvals[0] = datavalueChart?.data?.graph?.our_high_serverity_graph;
    severity_dataarrvals[1] = datavalueChart?.data?.graph?.comp_high_serverity_graph;
    severity_dataarrvals[2] = datavalueChart?.data?.graph?.our_medium_severity_graph;
    severity_dataarrvals[3] = datavalueChart?.data?.graph?.comp_medium_severity_graph;
    severity_dataarrvals[4] = datavalueChart?.data?.graph?.our_low_severity_graph;
    severity_dataarrvals[5] = datavalueChart?.data?.graph?.comp_low_severity_graph;
    severity_dataarrvals[6] = datavalueChart?.data?.domain_name;
    severity_dataarrvals[7] = datavalueChart?.data?.comp_domain_name;
    severity_dataarrvals[8] = (moment(datavalueChart?.data?.job_date).format("MMM DD,YYYY "));
    severity_dataarrvals[9] = (moment(datavalueChart?.data?.comp_job_date).format("MMM DD,YYYY "));
    setServeritydata(severity_dataarrvals)

    setLoading(false)


  };
  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: 'Competitor Domain',
        data: seooccurData,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestoccurData,
        backgroundColor: ["#fe874e"],
      },

    ],
  };


  const dispatch = useDispatch();



  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value


  const [checkstatus, setcheckstatus] = useState(0)



  const [CompetitorJobId, SetCompetitorJobId] = useState('')
  const [CompetitorDomainId, SetCompetitorDomainId] = useState({
    label:
      '',
    value:
      '',
  })
  const [prevCompetitorDomainId, setprevCompetitorDomainId] = useState({
    label:
      '',
    value:
      '',
  })
  const [JobId, SetJobId] = useState('')

  const CddomainvaldataChange = (value) => {
    var errs = 1;
    let errors = {};
    SetCompetitorDomainId({
      label: value.label,
      value: value.value
    })
    if (value.value) {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }

  }

  const CloseModalTrigger = () => {

    setValidation({ ...validation, dateerror: '' })
    SetCompetitorDomainId(prevCompetitorDomainId);
    SetdEngineoption(prevdEngineoption);
    props.setIsOpen(false)
  }

  const FetchCompetitorList = () => {



    var errs = 1;
    let errors = {};


    if (CompetitorDomainId.value === '' || JobId === '') {
      errors.dateerror = "All fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }


    if (CompetitorDomainId.value !== '' && dEngineoption.value !== '') {
      callBeforeuserdataapi(true);
      setprevCompetitorDomainId(CompetitorDomainId);
      props.SetCompetitorDomainId(CompetitorDomainId.value);
      props.setJobIdvalue(dEngineoption.value);

      if (checkstatus === 4) {
        callUserdataApi()
        props.setIsOpen(false)
        setprevdEngineoption(dEngineoption);
        setValidation({ ...validation, dateerror: '' })
      }

    }
  }


  const [chartvalue, setChartvalue] = useState("4");
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };

  const barChartData1 = {
    labels: arrheaderdata,
    datasets: [
      {
        label: 'Competitor Domain',
        data: earlierdataarr,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestdataarr,
        backgroundColor: ["#fe874e"],
      },

    ],
  };


  const callBeforeuserdataapi = async (isFiltered) => {
    let aa = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis_status/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${CompetitorDomainId.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

    let changedStatus = aa?.data?.status;
    if (aa?.data?.status === 4 && !isFiltered) {
      if (props.compId1 && props.compId2 && props.compId3) {
        callUserdataApi(true);
      }
    }
    setcheckstatus(changedStatus);
  }


  useEffect(() => {
    callBeforeuserdataapi(false)


    if (checkstatus === 4) {

      if (esodata?.competitorinjobids?.value[0] && esodata?.competitorjobids?.value[0] && esodata?.competitordomainids?.value[0]) {
        var CompetitorjobDate = [];
        esodata?.competitorjobids?.value.forEach((element) => {
          CompetitorjobDate.push({ label: datezonechange(element.label), value: element.value });
        });
        setcompetitorjobDate(CompetitorjobDate);


      }

      setCompDomainName(esodata?.competitordomainids?.value[0]?.label);
      const selectedDomainId = esodata?.domaindata?.value?.header_selected;
      if (selectedDomainId) {
        let ourDomains = esodata?.domainlist?.value;
        const selectedDomainName = ourDomains?.find(d => d?.id == selectedDomainId);
        setOurDomainName(selectedDomainName?.domain__name);
      }



    }

    LoadAddFormFilterOptions()


  }, [esodata.competitorinjobids, esodata.competitorjobids, esodata.competitordomainids])




  return (
    <>

      {checkstatus === 4 ?
        (<><div className="tabs_graph_outer_wrap">
          <TabContext value={chartvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handlechartChange} aria-label="lab API tabs example" >
                <Tab className="tabbutton" label="By Issue" value="4" />
                <Tab className="tabbutton" label="By Severity" value="5" />
              </TabList>
            </Box>
            <TabPanel value="4">
              <div className="site_regress_chart_info">
                <div className="site_Chart_info_inner">
                  <Bar
                    type="bar"
                    width={150}
                    height={isMobile ? 180 : 80}
                    data={barChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                          display: true,
                          labels: {
                            color: '#0b2033',
                            font: {
                              size: 15,
                              family: 'Poppins',
                            }
                          }

                        },
                        title: {
                          display: true,
                          text: 'Competitor Analysis Summary By Issue',
                          color: '#0b2033',
                          font: {
                            size: 18,
                            family: 'Poppins',
                          }

                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            suggestedMin: 0,
                            stacked: true,
                            color: "#0b2033",
                            font: {
                              size: 14,
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                        x: {
                          
                          ticks: {
                            color: "#0b2033",
                            font: {
                              size: 12,
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                      },
                    }}
                  />
                </div>

              </div>
            </TabPanel>
            <TabPanel value="5">
              <div className="site_regress_chart_info">
                <div className="site_Chart_info_inner">
                  <Bar
                    type="bar"
                    width={130}
                    height={isMobile ? 150 : 80}
                    data={barChartData1}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                          display: true,
                          labels: {
                            color: '#103658',
                            font: {
                              size: 15,
                              family: 'Poppins',
                            }
                          }

                        },
                        title: {
                          display: true,
                          text: 'Competitor Analysis Summary By Severity',
                          color: '#0b2033',
                          font: {
                            size: 18,
                            family: 'Poppins',
                          }

                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            suggestedMin: 0,
                            stacked: true,
                            color: "#0b2033",
                            font: {
                              size: 14,
                              family: 'Poppins',
                            },
                            beginAtZero: true,

                          },
                        },
                        x: {
                          
                          ticks: {
                            color: "#0b2033",
                            font: {
                              size: 12,
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                      },
                    }}
                  />
                </div>

                <table>
                  <tr className="table_regression">
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[0]}%</td>
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[2]}%</td>
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]})- {serveritydata[4]}%</td>
                  </tr>
                  <tr className="table_regression">
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[1]}%</td>
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[3]}%</td>
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[5]}%</td>
                  </tr>
                </table>

              </div>
            </TabPanel>
          </TabContext>
        </div>

          <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="SEO Metrics" value="1" />
                  <Tab label="Site Metrics" value="2" />
                  <Tab label="Internal Links" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <DatagridComp
                  columns={columns}
                  rows={rows}
                  autoheightval={autoheightval}
                  datagridval={2}
                />
              </TabPanel>
              <TabPanel value="2">
                <DatagridComp
                  columns={column1}
                  rows={rows1}
                  autoheightval={autoheightval}
                  datagridval={2}
                />
              </TabPanel>
              <TabPanel value="3">
                <DatagridComp
                  columns={column2}
                  rows={rows2}
                  autoheightval={autoheightval}
                  datagridval={2}
                />
              </TabPanel>
            </TabContext>
          </div>

        </>) : checkstatus === 1 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                <div className="msg">
                  Note: Please ensure that competitor details are added accurately. For clarification, follow these instructions to add a competitor:
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to Settings.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Competitor Settings.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on the "Add Competitor" button.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Enter the competitor's name and URL, then click "Save".</p>
                    </div>
                  </li>
                </ul>
              </main>
            </div>
          </div>
        ) : checkstatus === 2 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                <div className="msg">
                  Note: Please ensure the following instructions are followed to check the Site Audit Reports.
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to the Technical</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Site Analysis &gt; Competitor Analysis.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on "Run Report."</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Choose the method</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor5">5</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><TuneIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Soft Run</h2>
                      <p className="heading_p">Generates a quick report for known competitors.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">6</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><WidgetsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Hard Run</h2>
                      <p className="heading_p">Runs a complete check for all competitors.</p>
                    </div>
                  </li>

                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">7</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><DisplaySettingsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 5</h2>
                      <p className="heading_p">Click Submit.</p>
                    </div>
                  </li>
                </ul>

              </main>


            </div>
          </div>
        ) : checkstatus === 3 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                <div className="msg">
                  Note: Please ensure the following instructions are followed to check the Competitor Analysis Reports.
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to the Technical</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Site Analysis &gt; Competitor Analysis.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on "Run Report."</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Choose the method</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor5">5</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><TuneIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Soft Run</h2>
                      <p className="heading_p">Generates a quick report for known competitors.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">6</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><WidgetsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Hard Run</h2>
                      <p className="heading_p">Runs a complete check for all competitors.</p>
                    </div>
                  </li>

                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">7</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><DisplaySettingsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 5</h2>
                      <p className="heading_p">Click Submit.</p>
                    </div>
                  </li>
                </ul>

              </main>


            </div>
          </div>
        ) : ""}

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      value={dEngineoption}
                      placeholder="Select Date"
                      options={completedate && completedate.length > 0 ? completedate : []}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        SetJobId(values?.value)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <span>Competitor Domain</span>
                    <Select
                      value={CompetitorDomainId}
                      placeholder="Select Domain"
                      options={esodata?.competitordomainids?.value.length > 0 ? esodata?.competitordomainids?.value : []}
                      onChange={CddomainvaldataChange}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>


              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts &&

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </>
  );
}
