import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import CommonAlert from "../../../Commonalert/commonalert";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import DomainAuthoritysvg from "../Asset/esocardicon/BaclinkResearch/DomainAuthority.svg"
import TotalBacklinkssvg from "../Asset/esocardicon/BaclinkResearch/TotalBacklinks.svg"
import TotalFollowlinkssvg from "../Asset/esocardicon/BaclinkResearch/TotalFollowlinks.svg"
import TotalReferringdomainssvg from "../Asset/esocardicon/BaclinkResearch/TotalReferringdomains.svg"
import DatagridComp from "../DatagridComp";
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function BacklinksResearch(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [AnalyzeUrl, SetAnalyzeUrl] = useState('')
  const [DomainAuthority, SetDomainAuthority] = useState(0)
  const [TotalBacklink, SetTotalBacklink] = useState(0)
  const [TotalReferringDomain, SetTotalReferringDomain] = useState(0)
  const [TotalFollowLink, SetTotalFollowLink] = useState(0)
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referring_page",
      headerName: "Referring Page/Title",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Referring page's URL/Referring page's title.",
      renderCell: (cardData) => {
        let baseUrl = cardData?.row?.referring_page.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData?.row?.referring_page.split("/").slice(3).join("/");

        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData?.row?.title}</span>
            <br />
            <Tooltip title={cardData.row.referring_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.referring_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "domain_authority",
      headerName: "DA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Domain InLink Rank of the target's root domain.",
    },
    {
      field: "page_authority",
      headerName: "PA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "InLink rank of the target URL.",
    },
    {
      field: "anchor",
      headerName: "Anchor/Target Page",
      minWidth: 350,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Link anchor text for text links/Destination page's URL..",
      renderCell: (cardData) => {
        let baseUrl = cardData?.row?.target_page.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData?.row?.target_page.split("/").slice(3).join("/");
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData?.row?.anchor}</span>
            <br />
            <Tooltip title={cardData.row.target_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.target_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "link_type",
      headerName: "Link Type",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Type of backlink.",
    },
    {
      field: "nofollow",
      headerName: "Follow/No Follow",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Number of backlinks marked as nofollow and number of backlinks not marked as nofollow.",
    },
    {
      field: "first_seen",
      headerName: "First Seen",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The date when the backlink was added to the index or the date when our crawlers last visited the referring page.",
    },
    {
      field: "last_visited",
      headerName: "Last Visited",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The date when the backlink was added to the index or the date when our crawlers last visited the referring page.",
    },
  ];

  const rows = cardData ?? [];
  const LoadData = async (tableid = '') => {
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });


      if (datavalue?.data) {
        if (datavalue?.data?.job_details) {

          props.setjobDate(moment(datavalue.data.job_details.job_date).format("MMMM DD,YYYY HH:mm"));
        }
      }

      if (datavalue.data) {

        if (datavalue.data.research_data) {
          SetDomainAuthority(datavalue.data.research_data.domain_authority)
          SetTotalBacklink(datavalue.data.research_data.total_backlinks_count)
          SetTotalFollowLink(datavalue.data.research_data.total_follow_links)
          SetTotalReferringDomain(datavalue.data.research_data.total_ref_domains)
          SetResearchType(datavalue.data.research_data.research_mode)
          SetResearchUrl(datavalue.data.research_data.research_url)
          SetRefilldate(datavalue.data.research_data.next_refill_date)
          SetTotalLimit(datavalue.data.research_data.total_research_limit)
          SetAvailableLimit(datavalue.data.research_data.available_research_count)
        }
        if (datavalue.data.research_table) {
          if (datavalue.data.research_table.data) {
            var TableArray = [];
            TableArray = datavalue.data.research_table.data;

            if (TableArray && TableArray.length > 0) {
              const newData = TableArray.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });
              setCardData(newData);
              SetAutoheightval(false)
            }
            else {
              setCardData("");
              SetAutoheightval(true)
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  const AnalyzeReport = async () => {
    setLoading(true)
    SetErrorMsg('')
    if (AnalyzeUrl === '') {
      SetErrorMsg('Please enter a URL')
      setLoading(false)
      return false
    }
    SetDomainAuthority('0')
    SetTotalBacklink('')
    SetTotalFollowLink('')
    SetTotalReferringDomain('')
    SetResearchType('')
    SetResearchUrl('')
    SetRefilldate('')
    SetTotalLimit('')
    SetAvailableLimit('')
    setCardData([])
    props.SetBacklinkurl(AnalyzeUrl)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&request_url=${AnalyzeUrl}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {

        if (datavalue?.data?.research_graph) {
          SetDomainAuthority(datavalue.data.research_graph.domain_authority)
          SetTotalBacklink(datavalue.data.research_graph.total_backlinks_count)
          SetTotalFollowLink(datavalue.data.research_graph.total_follow_links)
          SetTotalReferringDomain(datavalue.data.research_graph.total_ref_domains)
          SetResearchType(datavalue.data.research_graph.research_mode)
          SetResearchUrl(datavalue.data.research_graph.research_url)
          SetRefilldate(datavalue.data.research_graph.next_refill_date)
          SetTotalLimit(datavalue.data.research_graph.total_research_limit)
          SetAvailableLimit(datavalue.data.research_graph.available_research_count)
        }

        if (datavalue.data.research_table) {
          const FDataval = datavalue.data.research_table.table
          if (FDataval && FDataval.length > 0) {
            const newData = FDataval.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
            setCardData(newData);
            SetAutoheightval(false)
          }
          else {
            setCardData("");
            SetAutoheightval(true)

          }
        }
      }

      if (datavalue.data.error) {
        SetErrorMsg(datavalue.data.error)
      }
    } catch (err) {
      console.log('errr', err)
    }
    setLoading(false)
  }
  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])

  const cardDatas = [
    { title: "Domain Authority", value: DomainAuthority, iconSrc: DomainAuthoritysvg, altText: "Domain Authority" },
    { title: "Total Backlinks", value: TotalBacklink, iconSrc: TotalBacklinkssvg, altText: "Total Backlinks" },
    { title: "Total Referring domains", value: TotalReferringDomain, iconSrc: TotalReferringdomainssvg, altText: "Total Referring domains" },
    { title: "Total Follow links", value: TotalFollowLink, iconSrc: TotalFollowlinkssvg, altText: "Total Follow links" },
  ];

  const [ResearchUrl, SetResearchUrl] = useState('')
  const [Refilldate, SetRefilldate] = useState('')
  const [TotalLimit, SetTotalLimit] = useState('')
  const [AvailableLimit, SetAvailableLimit] = useState('')
  const [ResearchType, SetResearchType] = useState('')
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Research</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />


      <div className="cnt_hdr_top_wraps">
        <div className="cnt_hdr_top_lft_wrap">
          <p><span style={{ fontWeight: "600" }}>For {ResearchType}:</span> &nbsp; {ResearchUrl}</p>
          <p><span style={{ fontWeight: "600" }}>Next Refill date:</span> &nbsp;  {Refilldate}</p>
          <p><span style={{ fontWeight: "600" }}>Total Search Limit: </span>&nbsp;  {TotalLimit}</p>
          <p><span style={{ fontWeight: "600" }}>Available Research Count: </span>&nbsp; {AvailableLimit}</p>
        </div>

      </div>
      <div className="common-card-container">
        {cardDatas.map(({ title, value, iconSrc, altText }) => (
          <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
        ))}
      </div>


      <div className="plagarism_analyzer_row">
        <div className="input_col">
          <input type="text" className="plagarism_analyzer_row_input_field" onChange={(e) => { SetAnalyzeUrl(e.target.value) }} placeholder="Enter domain-related URL to check" />
        </div>
        <div className="button_col" style={{ marginLeft: "10px" }}><button class="runreportnew" onClick={AnalyzeReport}>Analyze</button></div>
      </div>
      <div className="error_plagarism_report lightorange">
        {
          ErrorMsg
        }

      </div>

      <DatagridComp
        columns={columns}
        rows={rows}
        autoheightval={autoheightval}
        datagridval={4}
      />

      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }

    </>
  );
}
