import React from 'react'
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./Logindetail.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isURL from 'validator/lib/isURL';
import { SetDomainHeader } from "../../../Store/DomainData";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Stepperformhead from "./Stepperformhead";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import Select from "react-select";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validator from 'validator'
import _ from 'lodash';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { useGoogleLogin } from "@react-oauth/google";
import { gapi } from 'gapi-script';
import { CommonErrorAlert } from '../../../Commonalert/commonErrorAlert';


function Impadddomain() {



    const [showPasswordlgn, setShowPasswordlgn] = useState(false);


    const [loading, setLoading] = useState(false);
    const [apiOpen, setApiOpen] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState("");
    const [toggle, setToggle] = useState(false);
    const [faqData, setfaqData] = useState("2")
    const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
    const [apiopen, setapiOpen] = React.useState(false);
    const [apierrorMsg, setapierrorMsg] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [nextBtStatus, setNextBtStatus] = useState(true);

    const sign = require('jwt-encode');

    const ApiToken = useSelector((state) => state.apiauthkey.value);
    const [isDisabled, setDisabled] = useState(false);

    const websiteoptions = [
        { value: "HTML", label: "HTML", name: "webtype" },
        { value: "JS", label: "JS", name: "webtype" }
    ];


    const webauthenticatestatus = [
        { value: "Yes", label: "Yes", name: "webauthenticate" },
        { value: "No", label: "No", name: "webauthenticate" }
    ]

    const [permissions, setPermissions] = useState([]);


    const [addedDomains, setAddedDomains] = useState([]);


    const [finalSelected, setfinalSelected] = useState({ url: "" })

    const [apiopenrloc, setApiopenrloc] = useState(false);
    const [regionalLevel, setRegionalLevel] = useState(null);
    const [SearchEngine, SetSearchEngine] = useState(null);
    const [Engineoptions, SetEngineoptions] = useState([]);
    const [Countryoptions, SetCountryoptions] = useState([]);
    const [Stateoptions, SetStateoptions] = useState([]);
    const [CityOptions, SetCityOptions] = useState([]);
    const [countryValue, setCountryValue] = useState(null);
    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);
    const [Country, SetCountry] = useState(null);
    const [State, SetState] = useState(null);
    const [City, SetCity] = useState(null);
    const [engineerr, setEngineerr] = useState(null);
    const [countryerr, setCountryerr] = useState(null);
    const [stateerr, setStateerr] = useState(null);
    const [cityerr, setCityerr] = useState(null);


    const handleClose = () => setApiOpen(false);

    const handleNext = () => {
        navigate("/Googleanalytics", { state: { referrer: window.location.pathname } });
    }


    const faqdatafun = async (menubutval = "") => {
        var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

        try {
            const doaminsectiondatavalue = await axios.get(
                datavar,
                {
                    headers: {
                        Authorization: `${ApiToken}`,
                    },
                }
            ).catch((error) => {
                if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
                    setapiOpen(true);
                    setapierrorMsg(error?.response?.data?.error)
                }
                else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
                    navigate('/errorpages')
                }
            });
            if (doaminsectiondatavalue?.data) {
                SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
            }
        } catch (err) { }
    }
    useEffect(() => {
        faqdatafun();
        LoadAddFormFilterOptions();
        getPreviousLocationbyUser();
    }, []);



    const getPreviousLocationbyUser = async () => {
        try {
            const datavalue = await axios.get(
                `${process.env.REACT_APP_API_URL}accounts/get_user_location/`,
                {
                    headers: {
                        Authorization: `${ApiToken}`,
                    },
                }
            ).catch((error) => {

                if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
                    navigate('/errorpages');
                }
                else if (error?.code == "ERR_NETWORK") {
                    navigate('/errorpages');
                }
            });

            if (datavalue?.data) {

                const countrydatavalue = await axios.get(
                    `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
                    {
                        headers: {
                            Authorization: `${ApiToken}`,
                        },
                    }
                )

                if (countrydatavalue?.data) {
                    var Sections = [];
                    countrydatavalue.data.forEach((element) => {
                        Sections.push({ label: element.name, value: element.id });
                    });

                    var countryData = Sections.find((option) => option.value === datavalue?.data.country_id);
                    setCountryValue(countryData);
                    const statedatavalue = await axios.get(
                        `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${datavalue?.data.country_id}`,
                        {
                            headers: {
                                Authorization: `${ApiToken}`,
                            },
                        }
                    )


                    if (statedatavalue?.data?.states) {
                        var Sections = [];
                        statedatavalue.data.states.forEach((element) => {
                            Sections.push({ label: element.state_name, value: element.id });
                        });

                        var stateData = Sections.find((option) => option.value === datavalue?.data.state_id);

                        setStateValue(stateData);

                        const citydatavalue = await axios.get(
                            `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${datavalue?.data.state_id}`,
                            {
                                headers: {
                                    Authorization: `${ApiToken}`,
                                },
                            }
                        )


                        if (citydatavalue?.data?.cities) {
                            var Sections = [];
                            citydatavalue.data.cities.forEach((element) => {
                                Sections.push({ label: element.city_name, value: element.id });
                            });
                            SetCityOptions(Sections);
                            var cityData = Sections.find((option) => option.value === datavalue?.data.city_id);
                            setCityValue(cityData);


                            const searchenginedatavalue = await axios.get(
                                `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
                                {
                                    headers: {
                                        Authorization: `${ApiToken}`,
                                    },
                                }
                            )

                            if (searchenginedatavalue?.data) {
                                SetSearchEngine({ label: searchenginedatavalue.data[0].name, value: searchenginedatavalue.data[0].id });
                            }
                        }
                    }
                }

            }

        } catch (error) {

        }
    }



    const handleToggle = () => {
        if (faqData != 1) {
            setfaqData(1)
        }
        else {
            setfaqData(2)
        }
        setToggle((pre) => !pre);
    };

    const TriggerLogout = async () => {
        try {
            const datavalue = await axios.get(
                `${process.env.REACT_APP_API_URL}accounts/logout/`,
                {
                    headers: {
                        Authorization: `Token ${ApiToken}`,
                    },
                }
            );

            if (datavalue.data) {

                localStorage.setItem("eso_auth_token", "");
                localStorage.setItem("eso_user_token", "");
                dispatch(SetUserToken({ eso_user_token: "" }));
                dispatch(SetAuthToken({ eso_auth_token: "" }));
                navigate("/login");
            }
        } catch (err) {
            localStorage.setItem("eso_auth_token", "");
            localStorage.setItem("eso_user_token", "");
            dispatch(SetUserToken({ eso_user_token: "" }));
            dispatch(SetAuthToken({ eso_auth_token: "" }))
            navigate("/login");
        }
    };


    const triggerPopup = () => {
        setApiOpen(true);
    }


    const glogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            axios
                .get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    fetchSearchConsoleData(codeResponse.access_token);
                })
                .catch((error) => {
                    setLoading(false);
                    handleApiError(error);
                });
        },
        onError: (error) => console.log("Login Failed:", error),
        scope: "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly", // Ensure correct scope for both Analytics and Search Console
    });

    const handleApiError = (error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setApiOpen(true);
            setApiErrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK") {
            navigate("/login-errorpages");
        } else {
            console.error('Unknown error:', error);
        }
    };

    const fetchSearchConsoleData = (access_token) => {
        axios
            .get("https://www.googleapis.com/webmasters/v3/sites", {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then((response) => {

                if (response.data.siteEntry) {
                    response.data.siteEntry.forEach(item => {
                        if (item.siteUrl.startsWith('sc-domain:')) {
                            item.siteUrl = item.siteUrl.replace(/^sc-domain:/, '');
                        }
                    });
                    setPermissions(response.data.siteEntry)
                    triggerPopup();
                } else {
                    setPermissions([]);
                    triggerPopup();
                }
            })
            .catch((error) => {
                console.error('Error fetching Search Console data:', error);
                if (error?.response?.status === 401) {
                    console.error('Unauthorized: Invalid access token or missing permissions.');
                }
            });
    };


    const handleAddDomain = async (data, i) => {

        setApiopenrloc(true);
        setfinalSelected({ ...finalSelected, url: data });
    }


    const apihandleClose = () => {
        setapiOpen(false);
        apihandleCloserloc();
    }


    const LoadAddFormFilterOptions = async (countryId, searchEngineId) => {
        SetCountryoptions([]);
        SetEngineoptions([]);

        try {
            const countrydatavalue = await axios.get(
                `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
                {
                    headers: {
                        Authorization: `${ApiToken}`,
                    },
                }
            ).catch((error) => {
                if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
                    setapiOpen(true);
                    setapierrorMsg(error?.response?.data?.error)
                    return false;
                }
                else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
                    navigate('/errorpages')
                }
            });

            if (countrydatavalue?.data) {
                if (countrydatavalue?.data) {
                    var Sections = [];
                    countrydatavalue.data.forEach((element) => {
                        Sections.push({ label: element.name, value: element.id });
                    });
                    SetCountryoptions(Sections);
                }
            }
        } catch (err) { }

        try {
            const enginedatavalue = await axios.get(
                `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
                {
                    headers: {
                        Authorization: `${ApiToken}`,
                    },
                }
            ).catch((error) => {
                if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
                    setapiOpen(true);
                    setapierrorMsg(error?.response?.data?.error)
                    return false;
                }
                else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
                    navigate('/errorpages')
                }
            });

            if (enginedatavalue?.data) {
                if (enginedatavalue?.data) {
                    var Sections = [];
                    enginedatavalue.data.forEach((element) => {
                        if (element.id === searchEngineId) {
                            SetSearchEngine({ label: element.name, value: element.id })
                        }
                        Sections.push({ label: element.name, value: element.id });
                    });
                    SetEngineoptions(Sections);
                }
            }
        } catch (err) { }
    };

    const handlestatedataoptions = (e) => {

        setCountryValue(e);

        setStateValue("");
        setCityValue("");
        SetState("");
        SetCity("");


        var Countrys = e.value;
        handlestateoptions(Countrys)
    }

    const handlestateoptions = async (Countrys = "", stateId) => {

        if (Countrys == "" || Countrys == null || !Countrys)
            return false;


        SetCountry(Countrys);

        const statedatavalue = await axios.get(
            `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
            {
                headers: {
                    Authorization: `${ApiToken}`,
                },
            }
        ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
                setapiOpen(true);
                setapierrorMsg(error?.response?.data?.error)
                return false;
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
                navigate('/errorpages')
            }
        });

        if (statedatavalue?.data) {
            if (statedatavalue?.data?.states) {
                var Sections = [];
                statedatavalue.data.states.forEach((element) => {
                    Sections.push({ label: element.state_name, value: element.id });
                });
                SetStateoptions(Sections);
                var stateData = Sections.find((option) => option.value === stateId);
                setStateValue(stateData);
            }
        }
    };
    const handleCityoptionsdata = (e) => {
        setStateValue(e);
        SetState("");
        SetCity("");
        var States = e.value;
        handleCityoptions(States)
    }
    const handleCityoptions = async (States = "", cityId) => {
        if (States == "" || States == null || !States) {
            return false;
        }

        SetState(States);

        const citydatavalue = await axios.get(
            `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
            {
                headers: {
                    Authorization: `${ApiToken}`,
                },
            }
        ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
                setapiOpen(true);
                setapierrorMsg(error?.response?.data?.error)
                return false;
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
                navigate('/errorpages')
            }
        });

        if (citydatavalue?.data) {
            if (citydatavalue?.data?.cities) {
                var Sections = [];
                citydatavalue.data.cities.forEach((element) => {
                    Sections.push({ label: element.city_name, value: element.id });
                });
                SetCityOptions(Sections);
                var cityData = Sections.find((option) => option.value === cityId);
                setCityValue(cityData);
            }
        }
    };

    const setCurrentCityValue = (e) => {
        setCityValue(e);
        SetCity(e.value);
    }


    const apihandleCloserloc = () => {
        setApiopenrloc(false);
    }



    const finalreportNow = async () => {

        setLoading(true);
        var data_val = 1;

        if (!SearchEngine) {
            setEngineerr("Please select search engine");
            data_val = 2;
        }
        if (!countryValue) {
            setCountryerr("Please select country");
            data_val = 2;
        }
        if (data_val === 2) {
            setLoading(false);

            return false;
        }
        else {

            try {
                if (finalSelected.url) {

                    let fData = {
                        url: finalSelected.url,
                        campaign_location: true,
                        search_engine_id: SearchEngine.value,
                        country_id: countryValue.value,
                        state_id: stateValue?.value,
                        city_id: cityValue?.value
                    }

                    const jwt_datas = sign(fData, "VdM1t3n0NiD_Qsawq7ZX");
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}domain/add_auto_domain/`, { data: jwt_datas }
                        ,
                        {
                            headers: {
                                Authorization: `${ApiToken}`,
                            },
                        }
                    ).catch((error) => {
                        setLoading(false)
                        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
                            setapiOpen(true);
                            setapierrorMsg(error?.response?.data?.error)
                        }
                        else if (error?.code == "ERR_NETWORK") {
                            navigate('/login-errorpages')
                        }
                    });

                    if (response?.status == 200) {
                        apihandleCloserloc();
                        setapiOpen(true);
                        setapierrorMsg(response.data.message);
                        setAddedDomains(prevState => [...prevState, finalSelected.url]);
                        setNextBtStatus(false);
                    }
                }
            } catch (error) {

            }




        }

    }




    return (
        <>
            <div className="userdata">
                <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px", background: "#fff", position: "fixed", top: 0, zIndex: 999 }}>
                    <img
                        className="Eso_logo"
                        src={logoimage}
                        alt="logo"
                        style={{ width: "14%", background: "#fff" }}
                    />

                    <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingRight: "20px" }}>
                        <button
                            onClick={handleToggle}
                            className="faq_class"
                            title="Frequently Asked Question"
                            style={{ padding: "8px 13px 8px 7px" }}
                        >
                            <QuestionMarkIcon />
                        </button>

                        {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

                        <button onClick={TriggerLogout}>
                            <span
                                className="material-icons"
                                title="Logout"
                                style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
                            >
                                <LogoutIcon />
                            </span>
                        </button>
                    </div>
                </div>

                <Stepperformhead setActiveStep={1} />

                <div className="outer-container"
                    style={{
                        width: "calc(100% - 60px)",
                        marginRight: "60px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    <div className="userdata_inner">
                        <h1>{"Add your Domain(s)"}</h1>

                        <form className="frm_container">
                            <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    <h3 style={{ textAlign: 'center' }}>Import from <br /> Google Analytics Console</h3>
                                    <ul>
                                        <li>Automatically Verify Domain Ownership</li>
                                        <li>Can import multiple domains at once</li>
                                    </ul>
                                </div>

                                <div className="vertical-line">
                                    <span className="or-text">OR</span>
                                </div>


                                <div>
                                    <h3 style={{ textAlign: 'center' }}>Add Domain Details <br /> Manually</h3>
                                    <ul>
                                        <li>Verify domain ownership by <br /> adding  a TXT record to the DNS settings</li>
                                        <li>Register one domain at a time</li>
                                    </ul>
                                </div>

                            </div>


                            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => glogin()}
                                    sx={{
                                        padding: "10px 10px",
                                        borderRadius: "10px",
                                        fontSize: "16px",
                                        backgroundColor: "#ff601f",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#ff7d45" },
                                    }}
                                >
                                    Import
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/Domaindata")}
                                    sx={{
                                        padding: "10px 10px",
                                        borderRadius: "10px",
                                        
                                        fontSize: "16px",
                                        backgroundColor: "#ff601f",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#ff7d45" },
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate("/Userdata")}
                                    sx={{
                                        padding: "10px 10px",
                                        borderRadius: "10px",
                                        
                                        fontSize: "16px",
                                        backgroundColor: "#ff601f",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#ff7d45" },
                                    }}
                                >
                                    <FastRewindIcon />Back
                                </Button>
                            </Box>
                        </form>




                    </div>
                </div>
                <Dialog open={apiOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle className="keyword-dialog-header">{"Your Domains"}</DialogTitle>
                    <DialogContent sx={{ width: "500px" }}>
                        <div className="user-role-inner form-container">
                            <form className="form-content">
                                <div className="form-group">
                                    <div className="permissions-container" style={{ border: '1px solid #ff601f', padding: '10px 30px' }}>
                                        <div className="permissions-list">
                                            {permissions.every(item => item.permissionLevel === "siteUnverifiedUser") ? (
                                                <p style={{ textAlign: "center", color: "#ff601f" }}>
                                                    You Have No Registered Domains
                                                </p>
                                            ) : permissions.length > 0 ? (
                                                permissions.map((item, index) =>
                                                    item.permissionLevel !== "siteUnverifiedUser" && (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "space-evenly",
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <div style={{ flex: 1 }}>
                                                                <p>{item.siteUrl}</p>
                                                            </div>
                                                            <div>
                                                                {addedDomains.includes(item.siteUrl) ? (
                                                                    <span
                                                                        style={{
                                                                            color: "#28a745",
                                                                            fontSize: "20px",
                                                                            fontWeight: "bold",
                                                                            textAlign: "center",
                                                                            padding: "10px 20px"
                                                                        }}
                                                                    >
                                                                        ✔
                                                                    </span>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        style={{
                                                                            background: "#ff601f",
                                                                            color: "white",
                                                                            borderRadius: "10px",
                                                                            padding: "10px 20px"
                                                                        }}
                                                                        onClick={() => handleAddDomain(item.siteUrl, index)}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <p style={{ textAlign: "center", color: "#ff601f" }}>
                                                    You Have No Registered Domains
                                                </p>
                                            )}
                                        </div>

                                    </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: "30px" }}>
                                    <button type="button" className="keyword-cancel" onClick={handleClose} >
                                        Cancel
                                    </button>
                                    <button type="button" disabled={nextBtStatus} style={{ background: nextBtStatus ? "#EAEAEA" : "#ff601f", padding: "12px 25px", borderRadius: "10px", color: "white", border: "1px solid #ff601f" }} onClick={handleNext} >
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="dialog-action-button">
                    </DialogActions>
                </Dialog>
                <CommonErrorAlert
                    data={{
                        apiopen: apiopen,
                        apiErrorMsg: apierrorMsg,
                        isIconStyled: true
                    }}
                    closeAlert={apihandleClose} />

                <Dialog
                    open={apiopenrloc}
                    onClose={apihandleCloserloc}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="keyword-dialog-header">{"Add Location Details"}</DialogTitle>
                    <DialogContent>
                        <div >
                            <form className="frm_container">
                                <div className="campaigns-addedit-row">
                                    <div className="keyword-col1-fields">
                                        <div>
                                            <label>
                                                Search Engine :
                                            </label>
                                            <Select
                                                name="engine"
                                                value={SearchEngine}
                                                options={Engineoptions}
                                                onChange={(e) => { SetSearchEngine(e) }}
                                                placeholder="Select Engine"
                                                styles={customDropdownStyles()}
                                                menuPortalTarget={document.body}
                                                className=""
                                                classNamePrefix="addkeyword"
                                            />
                                        </div>
                                        <div>
                                            <span className="error-value">{engineerr}</span>
                                        </div>
                                    </div>
                                    <div className="keyword-col2-fields">
                                        <div>
                                            <label>
                                                Country :
                                            </label>
                                            <Select
                                                id="yourElementId"
                                                name="country"
                                                value={countryValue}
                                                options={Countryoptions}
                                                onChange={(e) => { handlestatedataoptions(e) }}
                                                placeholder="Select Country"
                                                isSearchable
                                                styles={customDropdownStyles()}
                                                menuPortalTarget={document.body}
                                                className=""
                                                classNamePrefix="addkeyword"
                                            />
                                        </div>
                                        <div>
                                            {" "}
                                            <span className="error-value">{countryerr}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="campaigns-addedit-row">

                                    <div className="keyword-col1-fields">
                                        <div>
                                            <label>
                                                State :
                                            </label>
                                            <Select
                                                name="state"
                                                value={stateValue}
                                                options={Stateoptions}
                                                onChange={handleCityoptionsdata}
                                                placeholder="Select State (Optional)"
                                                isSearchable
                                                styles={customDropdownStyles()}
                                                menuPortalTarget={document.body}
                                                className=""
                                                classNamePrefix="addkeyword"
                                            />
                                        </div>
                                        <div>
                                            <span className="error-value">{stateerr}</span>
                                        </div>
                                    </div>


                                    <div className="keyword-col2-fields">
                                        <div>
                                            <label>
                                                City :
                                            </label>
                                            <Select
                                                name="city"
                                                value={cityValue}
                                                options={CityOptions}
                                                onChange={setCurrentCityValue}
                                                placeholder="Select City (Optional)"
                                                isSearchable
                                                styles={customDropdownStyles()}
                                                menuPortalTarget={document.body}
                                                className=""
                                                classNamePrefix="addkeyword"
                                            />
                                        </div>
                                        <div>
                                            {" "}
                                            <span className="error-value">{cityerr}</span>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <button
                                        className="keyword-cancel"
                                        type="button"
                                        onClick={apihandleCloserloc}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="keyword-submit"
                                        type="button"
                                        onClick={finalreportNow}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </DialogContent>
                </Dialog>
            </div >
        </>
    )
}

export default Impadddomain