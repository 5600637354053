import React, { useState, useEffect, useMemo } from "react";
import ChainIcon from "../../../Icons/chain.png";
import BrokenIcon from "../../../Icons/broken-link.png";
import BrowseImageIcon from "../../../Icons/browse-images.png";
import ExternalLinkIcon from "../../../Icons/external-link.png";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from 'react-select';
import Loader from "../CommonLoader";
import { datezonechange } from "../Commonfun"
import searchEngine from "../Asset/ESO/search-engine.svg";
import Invalid_Linkssvg from "../Asset/esocardicon/ExternalLinks/Invalid_Links.svg"
import VideoLinkssvg from "../Asset/esocardicon/ExternalLinks/VideoLinks.svg"
import TotalExternalLinkssvg from "../Asset/esocardicon/ExternalLinks/TotalExternalLinks.svg"
import RootDomainsLinkedsvg from "../Asset/esocardicon/ExternalLinks/RootDomainsLinked.svg"


import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from "react-router-dom";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
const ExternalLinkListData = (props) => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const [TotalExternalLinks, SetTotalExternalLinks] = useState(0)
  const [InvalidLinks, SetInvalidLinks] = useState(0)
  const [ImageVideoLinks, SetImageVideoLinks] = useState(0)
  const [RootDomainsLinks, SetRootDomainsLinks] = useState(0)
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();

  const [BrowseImageIcons, setBrowseImageIcons] = useState(BrowseImageIcon);
  const [ChainIcons, setChainIcons] = useState(ChainIcon);
  const [BrokenIcons, setBrokenIcons] = useState(BrokenIcon);
  const [ExternalLinkIcons, setExternalLinkIcons] = useState(ExternalLinkIcon);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const columns =  [
    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "url",
      headerName: "URL",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      description: "External web page URL of the domain",
      renderCell: (cardData) => {

        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },
    },
    {
      field: "external_url",
      headerName: "External URL",
      description: 'External links are present on the web page.',
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        let baseUrl = cardData.row.external_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.external_url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.external_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.external_url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },
    },
    {
      field: "content_type",
      headerName: "Content Type",
      description: 'It displays the content type of the external link',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "anchor_text",
      headerName: "Anchor Text",
      description: 'Anchor text used in the web page for linking the external link',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "status_code",
      headerName: "Status Code",
      description: "Codes are generated by the server in response to the client's request.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  const FetchExternalLinkList = async (jobid = '') => {
    setLoading(true)
    setCardData([]);
    SetTotalExternalLinks(0)
    SetInvalidLinks(0)
    SetImageVideoLinks(0)
    SetRootDomainsLinks(0)

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/external_links/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {
        if (datavalue?.data?.links_data) {
          SetAutoheightval(false)
          const newData = datavalue?.data?.links_data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setCardData(newData);
        }
        setLoading(false);
      }
      else {
        setCardData("");
        SetAutoheightval(true)
        setLoading(false);
      }

      props.SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

    } catch (err) {
      console.log('err', err)
    }





    try {

      const datavaluecard = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/external_links_count/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (datavaluecard?.data) {
        SetTotalExternalLinks(datavaluecard?.data?.total_external_url_count)
        SetInvalidLinks(datavaluecard?.data?.total_invalid_links)
        SetImageVideoLinks(datavaluecard?.data?.total_image_and_video_links)
        SetRootDomainsLinks(datavaluecard?.data?.total_root_domains_linked)
      }

    } catch (err) {
      console.log('err', err)
    }


    props.setIsOpen(false);
    setLoading(false)
  };


  const CloseModalTrigger = () => {
    var errors = {}
    setValidation(errors)
    props.setIsOpen(false)
    SetdEngineoption(prevdEngineoption);
  }

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setprevdEngineoption(dEngineoption);
    FetchExternalLinkList(selectval)
  }

  const FetchJobDates = async () => {


    try {

      const completevalues = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=link_checker&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var Selectedmets = [];
      if (completevalues?.data?.jobs.length > 0) {
        completevalues?.data?.jobs.forEach((element) => {
          Selectedmets.push({ value: element?.id, label: datezonechange(element?.completed_at) });
        });
        setcompletedate(Selectedmets)
        setSelectval(Selectedmets[0]?.value)
        SetdEngineoption(Selectedmets[0])
        setprevdEngineoption(Selectedmets[0]);
      }

    } catch (err) {
      console.log('err', err)
    }






  }
  const apihandleClose = () => {

    setapiOpen(false);
  };


  useEffect(() => {
    FetchExternalLinkList();
    FetchJobDates()
  }, []);


  const cardDatas = [
    { title: "Total External Links", value: TotalExternalLinks, iconSrc: TotalExternalLinkssvg, altText: "Total External Links" },
    { title: "Invalid Links", value: InvalidLinks, iconSrc: Invalid_Linkssvg, altText: "Invalid Links" },
    { title: "Image/Video Links", value: ImageVideoLinks, iconSrc: VideoLinkssvg, altText: "Image/Video Links" },
    { title: "Root Domains Linked", value: RootDomainsLinks, iconSrc: RootDomainsLinkedsvg, altText: "Root Domains Linked" },
  ];


  return (
    <>
      {
        loading === true ? <Loader /> : null
      }
      <div className="common-card-container" style={{ justifyContent: "center" }}>
        {cardDatas.map(({ title, value, iconSrc, altText }) => (
          <Commoncard key={title} title={title} value={value} iconSrc={iconSrc} altText={altText} />
        ))}
      </div>

      <>
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />

      </>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                      value={dEngineoption}
                      placeholder="Select Date"
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values.value)
                        props.setJobIdvalue(values.value)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: true
        }}
        closeAlert={apihandleClose} />
    </>
  );
};

export default ExternalLinkListData;
