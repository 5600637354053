import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


const UserExperienceSettings = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);

  const [TotalPageLoadTime, SetTotalPageLoadTime] = useState(0);
  const [PageLoadTime, SetPageLoadTime] = useState(0);
  const [SampleSpeed, SetSampleSpeed] = useState(0);
  const [Url, Seturl] = useState("");
  const [UserExperienceSettingsErr, SetUserExperienceSettingsErr] =
    useState("");
  const [UpdateId, SetUpdateId] = useState("");
  const [Apdex, SetApdex] = useState("");
  const [loading, Setloading] = useState(false);

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [totalpageerror, SetTotalpageerror] = useState()
  const [pageloaderror, SetPageloaderror] = useState()
  const [seederror, SetSeederror] = useState()
  const [uxscoreerror, SetUxscoreerror] = useState()
  const [userExpSettingPlan, setuserExpSettingPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });

  const GetUserExperienceSettings = async () => {
    Setloading(true);
    SetTotalPageLoadTime(1);
    SetPageLoadTime(1);
    SetSampleSpeed(1);
    Seturl("");
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}settings/UxScoreSettings/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      Setloading(false);

      if (datavalue?.data) {
        SetTotalPageLoadTime(datavalue.data[0].tol_page_load_time);
        SetPageLoadTime(datavalue.data[0].page_load_time);
        SetSampleSpeed(datavalue.data[0].sample_seed);
        Seturl(datavalue.data[0].default_url);
        SetApdex(datavalue.data[0].apdex_score_metrics);
        SetUpdateId(datavalue.data[0].id);
      }
    } catch (err) {
      Setloading(false);
      console.log(err);
    }
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SaveSettings = async () => {
    SetUserExperienceSettingsErr("");
    SetTotalpageerror("")
    SetPageloaderror("")
    SetSeederror("")
    SetUxscoreerror("")
    var datavals = 2;

    if (TotalPageLoadTime === 0 || TotalPageLoadTime === "") {
      SetTotalpageerror("Please fill the value between 1 to 12")

      datavals = 1
    }
    if (PageLoadTime === 0 || PageLoadTime === "") {
      SetPageloaderror("Please fill the value between 1 to 12")
      datavals = 1
    }
    if (SampleSpeed === 0 || SampleSpeed === "") {
      SetSeederror("Please fill the value between 5 to 50")
      datavals = 1
    }
    if (Url === "") {
      SetUxscoreerror("Please enter default UX Score URL")
      datavals = 1
    }
    else if (Url !== "") {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9$._%+-]+)@)?' + // username:password@
        '(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}))' + // domain name
        '(\\:[0-9]{1,5})?' + // port (optional)
        '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' + // path
        '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-zA-Z0-9_]*)?$' // fragment identifier (optional)
      );
      const isValidUrl = urlPattern.test(Url);
      if (!isValidUrl) {
        SetUxscoreerror("Please enter valid UX Score URL")
        datavals = 1
      }


    }

    if (SampleSpeed !== 0 || SampleSpeed !== "") {
      if (!isNaN(SampleSpeed) && SampleSpeed < 5 || SampleSpeed > 50) {
        SetSeederror("Please fill the value between 5 to 50");
        datavals = 1
      }
      else if (isNaN(SampleSpeed)) {
        SetSeederror("Please fill the value between 5 to 50");
        datavals = 1
      }
    }
    if (datavals != 1) {
      Setloading(true);
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}settings/Update_UXscore_setting/${UpdateId}/`,
            {
              user_domain_id: esodata.domaindata.value.header_selected,
              default_url: Url,
              sample_seed: SampleSpeed,
              page_load_time: PageLoadTime,
              tol_page_load_time: TotalPageLoadTime,
              apdex_score_metrics: Apdex,
            },

            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/errorpages");
            }
          });
        setapiOpen(true);
        setapierrorMsg("You have successfully updated your settings.");
        Setloading(false);
      } catch (err) {
        Setloading(false);
      }
    }
  };

  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if (userExpSettingP.status) {
        GetUserExperienceSettings();
        faqdatafun();
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
    GetuserDatavalue();
  }, []);



  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if (userExpSettingP.status) {
        var DomainList = esodata.domainlist.value;
        DomainList = DomainList.find(
          (obj) => obj.id == esodata.domaindata.value.header_selected
        );
        if (DomainList) {
          Seturl(DomainList.domain__url);
        }
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
  }, [esodata]);

  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input" ||
      event.target.classList[0] === "run_rpt_btn" ||
      event.target.classList[0] === "input_col" ||
      event.target.classList[0] === "plagarism_analyzer_row" ||
      event.target.classList[0] === "MuiDataGrid-cell--withRenderer" || event.target.classList[0] !== "MuiSvgIcon-root"
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=46`;

    try {
      const doaminsectiondatavalue = await axios
        .get(datavar, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Experience Score Settings</title>
      </Helmet>
      <div className={`over_all_container user_experieceover_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {loading === true ? <Loader /> : null}
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Settings</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>User Experience Score Settings</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>

            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font"> User Experience Settings</h2>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <Link to={first.statusDomain ? "#" : "/user-experience-setting"} onClick={first.statusDomain ? () => setcheckD(first.statusDomain) : null}><button type="button" className="site_rn_rpt_btn active runreportnew"   >User Experience Settings</button></Link>
              <Link to={first.statusDomain ? "#" : "/page-level-setting"} onClick={first.statusDomain ? () => setcheckD(first.statusDomain) : null}><button type="button" className={`site_rn_rpt_btn`} style={{ color: "#ff601f !important", width: 150, height: 37, padding: 8 }} >Page Level Settings</button></Link>

            </div>

          </div>
          {userExpSettingPlan.status ?
            <div className="user_experince_form_container_wrap">
              <div className="user_experince_form_container">
                <div className="form_row">
                  <div className="form_column">
                    <div className="label">
                      <label>
                        Target Tolerable Time To Load Page<span>(s)</span>
                      </label>
                    </div>
                    <div className="">
                      {" "}
                      <input
                        type="number"
                        defaultValue={TotalPageLoadTime}
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          if (value >= 1 && value <= 12) {
                            SetTotalPageLoadTime(value);
                          } else if (e.target.value === '') {
                            SetTotalPageLoadTime('');
                          } else {
                            SetTotalPageLoadTime(TotalPageLoadTime);
                          }
                        }}
                        value={TotalPageLoadTime}
                        className="userexp_input"
                      />
                      <div className="err user_experince_form_err">
                        {totalpageerror}
                      </div>

                    </div>

                  </div>
                  <div className="form_column">
                    <div className="label">
                      <label>
                        Target Satisfactory Time To Load Page<span>(s)</span>
                      </label>
                    </div>
                    <div className="">
                      <input
                        type="number"
                        defaultValue={PageLoadTime}
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);
                          if (value >= 1 && value <= 12) {
                            SetPageLoadTime(value);
                          } else if (e.target.value === '') {
                            SetPageLoadTime('');
                          }
                        }}
                        value={PageLoadTime}
                        className="userexp_input"
                      />
                      <div className="err user_experince_form_err">
                        {pageloaderror}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form_row">
                  <div className="form_column">
                    <div className="label">
                      <label>
                        Random Sample Seed<span></span>
                      </label>
                    </div>
                    <div className="">
                      {" "}
                      <input
                        type="text"
                        defaultValue={SampleSpeed}
                        onChange={(e) => {
                          SetSampleSpeed(e.target.value);
                        }}
                        value={SampleSpeed}
                        style={{ border: "1px solid #ff601f" }}
                      />
                      <div className="err user_experince_form_err">
                        {seederror}
                      </div>

                    </div>
                  </div>
                  <div className="form_column">
                    <div className="label">
                      <label>Default UX Score URL</label>
                    </div>
                    <div className="">
                      {" "}
                      <input
                        type="text"
                        defaultValue={Url}
                        onChange={(e) => {
                          Seturl(e.target.value);
                        }}
                        value={Url}
                        style={{ border: "1px solid #ff601f" }}
                      />
                      <div className="err user_experince_form_err">
                        {uxscoreerror}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="err user_experince_form_err">
                  {UserExperienceSettingsErr}
                </div>
                <div className="user_experince_form_button">
                  <button
                    className="run_rpt_btn rn_rpt_btn"
                    onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : SaveSettings}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div> : <Freeaudit planStatus={userExpSettingPlan} />
          }
        </div>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&

          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
      </div>
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserExperienceSettings;
