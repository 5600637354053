import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./DomainLogindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import CommonAlert from "../../../Commonalert/commonalert";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
function EditDomaindata() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [onUsername, SetonUsername] = useState("")
  const [onPassword, SetonPassword] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [onUserError, SetonUserError] = useState("")
  const [onPasswordError, SetonPasswordError] = useState("")
  const [onPageError, SetonPageError] = useState("")
  const [userdomaindata, SetUserdomaindata] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  let querystring = useParams();
  let domain_id = querystring.id;
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value === "Yes" ? setShowResults(true) : setShowResults(false)
    e.target.value === "No" && SetonUsername(""); SetonPassword("");
  }
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e => {
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e => {
    SetonPassword(e.target.value)
  }
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}competitor-settings`

  }
  useEffect(() => {
    callUserdataApi();
  }, []);
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_competitor/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_id=${domain_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {
        SetWebsiteName(datavalue.data.name)
        SetwebLink(datavalue.data.url)
        SetUserdomaindata(datavalue.data.id)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetonUserError("")
    SetonPasswordError("")
    setLoading(true);
    var data_val = 1;
    if (websiteName === "") {
      SetWebnameError("Enter Competitor Name")
      data_val = 2;
    }
    if (webLink === "") {
      SetweblinkError("Please enter the competitor URL address")
      data_val = 2;
    }
    if (webLink !== "") {
      if (!isURL(webLink)) {
        SetweblinkError("Enter Valid Link Address")
        data_val = 2;
      }
    }

    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}domain/edit_competitor/`,
          {
            competitor_name: websiteName,
            competitor_url: webLink,
            competitor_id: userdomaindata,
            user_domain_id: esodata.domaindata.value.header_selected,
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
          .catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK") {
              navigate('/errorpages')
            }
          });
        if (response?.data?.status == "Domain Competitor updated successfully") {
          window.location.href = `${process.env.REACT_APP_DOMAIN}competitor-settings`
        }
      } catch (err) {
        setLoading(false);
        SetonPageError(err.response.data.error)
      }
    }

  }
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
        <div className="userdatas_inner">
          <p>Edit Competitor Details</p>
          <span className="error-value">{onPageError}</span>

          <form className='frm_container'>
            <div className="form-group user_country websitename">
              <label>Competitor Name:</label>
              <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={onwebName} value={websiteName} />

              <span className="error-value">{webnameError}</span>

            </div>
            <div className="form-group user_country websitename">
              <label>Competitor URL:</label>
              <input type="text" className="comp-url" title="URL of your website (eg: www.example.com)" name="websitelink" onChange={onwebLink} value={webLink} />
              <span className="error-value">{weblinkError}</span>
            </div>
            <div className="form_next">
              <button className="nextbutton" type="button" onClick={finalreportCancel}>Close</button>
              <button className="nextbutton" type="button" onClick={finalreportNow}>Update</button>
            </div>
          </form>
        </div>
      </div>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>

  );
}

export default EditDomaindata;


