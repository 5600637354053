import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Switch, FormControlLabel, Radio } from '@mui/material';
import DatagridComp from "../DatagridComp";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function UserRole() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [pagename, setPagename] = useState("User Management")
  const [selectedRows, setSelectedRows] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [userListPlan, setuserListPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  const [isAddEditModel, setIsAddEditModel] = useState(false);
  const [submitUser, setSubmitUser] = useState(false);
  const [addEditDialogTitle, setAddEditDialogTitle] = useState('addUser');
  const [editUserId, setEditUserId] = useState(null);

  const [userRole, setUserRole] = useState("");


  const navigate = useNavigate();
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";

  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };


  const handleClickOpen = async (e) => {
    SetDeleteId(e.target.id)
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;


  const checkUserPlan = async () => {
    const userListP = await Utils("user_management", ApiToken);
    if (userListP.status) {
      callUserdataApi();
      faqdatafun();
      getCurrentUserPermission();
    }
    setuserListPlan(userListP);
  };

  const getCurrentUserPermission = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/user_management_get_current_user/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
      if (datavalue?.data?.result?.role) {
        setUserRole(datavalue.data.result.role);
      }

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }


  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, [submitUser]);


  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/user_management_user_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.users) {
        if (datavalue?.data?.users) {
          const newData = datavalue.data.users.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });


          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    if (first.statusDomain) {
      setcheckD(first.statusDomain);
    } else {
      setAddEditDialogTitle('addUser');
      setSubmitUser(false);
      setIsAddEditModel(true);
    }
  }


  const reportNow = async () => {
    var err = 1;
    if (websiteName === "") {
      SetWebnameError("Website Name is Required")
      err = 2;
    }
    if (webLink === "") {
      SetweblinkError("Website Link is Required")
      err = 2;
    }
    if (err == 2) {
      return false;
    }
    else {
      setLoading(true)
      await axios.post("", {
        email: websiteName,
        url: webLink,

      })
        .then((response) => {
          if (response.data.result == 200) {
            closeModal()
          }
        }).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
      setLoading(false)
    }

  };
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const [rowsdata, setRowsdata] = useState(rows);

  const handleSwitchChange = (id) => (event) => {
    statusUpdate(id, event.target.checked)
    const updatedRows = rows.map((row) =>

      row.id === id ? { ...row, is_active: event.target.checked } : row
    );
    setCardData(updatedRows);
  };
  const statusUpdate = async (id, statusval) => {

    if (statusval == true)
      var urldata = `${process.env.REACT_APP_API_URL}settings/user_management_enable_user/?user_id=${id}`;
    else
      var urldata = `${process.env.REACT_APP_API_URL}settings/user_management_disable_user/?user_id=${id}`;
    try {
      const response = await axios.get(
        urldata,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      const datavalue = response.data;
    } catch (error) {
      if (error?.response?.status === 599 || error?.response?.status === 400 || error?.response?.status === 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error);
      } else if (error?.code === "ERR_NETWORK" || error?.response?.status === 404) {
        navigate('/errorpages');
      }
    }

  };

  const primaryUserAddRemove = async (data) => {
    try {

      if (!data.is_primary_user && data.role === "Admin") {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}settings/user_management_make_primary_user/?user_id=${data.id}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }).catch((error) => {
            setLoading(false)
            console.log(error)
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK") {
              navigate('/errorpages')
            }
          });
        if (datavalue.status === 200) {
          callUserdataApi();
        }

      }

    } catch (error) {
      console.log(error);
    }
  }



  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'User Email ID',
      renderCell: (cardData) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          {cardData.row.is_primary_user && (
            <span
              style={{
                position: 'absolute',
                top: 0,
                right: -90,
                backgroundColor: '#ffcc00',
                padding: '2px 8px',
                fontSize: '12px',
                borderRadius: '3px',
                color: 'black'
              }}
            >
              Primary User
            </span>
          )}
          <p>{cardData.row.email}</p>
        </div>
      )
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      description: 'Role that have been added for the domain',
      disableClickEventBubbling: true,
    },
    {
      field: "is_active",
      headerName: "Status",
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Indication of account status of user',
      renderCell: (cardData) => (
        <Switch
          checked={cardData.row.is_active}
          disabled={cardData.row.role === "Admin" && cardData.row.is_primary_user ? true : false}
          onChange={handleSwitchChange(cardData.row.id)}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        return (
          <Tooltip  title={'Click here to edit the user'}>
          <div className="EditBrandedTermClass">  <button
              className="buttonView edit_icon keyword-edit-icon"
              onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => {
                setAddEditDialogTitle('editUser');
                setIsAddEditModel(true);
                setSubmitUser(false);
                setEditUserId(cardData.row.id);
              }}
            >
              <EditIcon className="edit-icon-svg"/>
            </button></div>
          </Tooltip>

        );
      }
    }

  ].concat(
    userRole === "Admin"
      ? [
        {
          field: "Make Primary User",
          headerName: "Make Primary User",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          align: "center",
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (cardData) => {
            return cardData.row.role === "Admin" && !cardData.row.is_primary_user /* && cardData.row.id != userId && primaryUser*/ ?
              (<button className="buttonView" onClick={() => { primaryUserAddRemove(cardData.row) }}>
                Make primary User
              </button>) : null
          }
        },
      ]
      : []
  );
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };


  const [CallCampaignTable, SetCallCampaignTable] = useState(false)

  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=57`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };




  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Role Management</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>User Management</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> {pagename}</h2>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">

            {userListPlan.status &&
              <button
                title="Add User"
                className="runreportnew"
                onClick={() => { openModal() }}


              >
                Add User
              </button>
            }
          </div>

        </div>

        {userListPlan.status ?
          <>
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </> : <Freeaudit planStatus={userListPlan} />
        }

      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts &&


        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />

      }


      <Dialog
        open={isAddEditModel}
        onClose={() => setIsAddEditModel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">{addEditDialogTitle == 'addUser' ? 'Add User' : 'Edit User'}</DialogTitle>
        <DialogContent className="update-user-dialog">
          {addEditDialogTitle == 'addUser' ?
            <AddForm setIsAddEditModel={setIsAddEditModel} setSubmitUser={setSubmitUser} /> :
            <EditForm
              setIsAddEditModel={setIsAddEditModel}
              setSubmitUser={setSubmitUser}
              editUserId={editUserId}
            />
          }
        </DialogContent>
      </Dialog>
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>


  );
}
