import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import onpageerroricon from "../Asset/ESO/onpageerror.png";
import onpageerroricon1 from "../Asset/ESO/onpageerror1.png";
import totalerrorlinkicon from "../Asset/ESO/totalerrorlink.png";
import totalerrorlinkicon1 from "../Asset/ESO/totalerrorlink1.png";
import visibilityicon from "../Asset/ESO/visibility.png";
import visibilityicon1 from "../Asset/ESO/visibility1.png";

const DashboardCards = (props) => {
  const [icons, setIcons] = useState({
    rankedkeywords: rankedkeywordsicon,
    onpageerror: onpageerroricon,
    totalerrorlink: totalerrorlinkicon,
    visibility: visibilityicon,
  });
  
  const navigate = useNavigate();

  const cardData = [
    {
      icon: rankedkeywordsicon,
      iconHover: rankedkeywordsicon1,
      title: "Ranked Keywords",
      value: props?.RankedKeywords || 0,
      route: "/ranking-summary/2",
      iconName: "rankedkeywords",
    },
    {
      icon: onpageerroricon,
      iconHover: onpageerroricon1,
      title: "On Page Errors",
      value: props?.OnPageErrors || 0,
      route: "/onpage-crawl-data/2/1",
      iconName: "onpageerror",
    },
    {
      icon: totalerrorlinkicon,
      iconHover: totalerrorlinkicon1,
      title: "Total Internal Links",
      value: props?.TotalInternalLinks || 0,
      route: "/internal-link-checker",
      iconName: "totalerrorlink",
    },
    {
      icon: visibilityicon,
      iconHover: visibilityicon1,
      title: "Visibility Score",
      value: props?.VisibilityScore || 0,
      route: "/visibility-score-detail",
      iconName: "visibility",
    },
  ];

  const handleCardClick = (route) => {
    navigate(route);
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: "11px",
        padding: "20px",
      }}
    >
      {cardData.map((card, index) => (
        <div
          key={index}
          style={{
            position: "relative",
            width: "calc(50% - 11px)", 
            maxWidth: "290px", 
            textAlign: "center",
            boxSizing: "border-box",
            marginTop: 30,
            cursor: "pointer",
          }}
          onMouseEnter={() => setIcons((prevIcons) => ({ ...prevIcons, [card.iconName]: card.iconHover }))}
          onMouseLeave={() => setIcons((prevIcons) => ({ ...prevIcons, [card.iconName]: card.icon }))}
          className="cardactivecontainer"
          onClick={() => handleCardClick(card.route)}
        >
          <div
            style={{
              position: "absolute",
              top: "-25px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "65px",
              height: "65px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              color: "white",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img src={icons[card.iconName]} style={{ pointerEvents: "none" }} alt={card.title} />
          </div>
          <div
            style={{
              padding: "50px 20px 20px",
              borderRadius: "8px",
              border: "2px solid #ff6600",
              backgroundColor: "white",
              transition: "0.3s",
              height: "80px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="cardcontentactivecontainer ranking_summary_col_new"
            data-link={card.route}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "5px",
                color: "#0B2033",
              }}
            >
              {card.title}
            </span>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#0B2033",
              }}
            >
              {card.value}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;
