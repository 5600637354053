import React, { useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./login.css";
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GoogleLogo from "./Asset/google.png";
import AppleLogo from "./Asset/apple-logo.png";
import LoginImage from "./Asset/On_Board/login.gif";
import RegisterImage from "./Asset/On_Board/signup.gif";
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../Store/UserTokenSlice";
import { SetUserSubscription } from "../../Store/SubscriptionSlice";
import { SetUserFirstTimeLogin } from "../../Store/FirstTimeLoginSlice";
import { SetAuthToken } from "../../Store/ApiAuthKey";
import { useGoogleLogin } from "@react-oauth/google";

import AppleLogin from "react-apple-login";
import AppleIcon from "@mui/icons-material/Apple";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { RiHome2Fill } from "react-icons/ri";
import { CommonErrorAlert } from "../../Commonalert/commonErrorAlert";

function Newlogin() {
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  function testPasswordStrength(password) {
    return strongPasswordRegex.test(password);
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    emailPhone: "",
    password: "",
    emailPhonelgn: "",
    passwordlgn: "",
  });
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [loading, setLoading] = useState(false);

  const [showPasswordreg, setShowPasswordreg] = useState(false);
  const [showCPasswordreg, setShowCPasswordreg] = useState(false);
  const [showPasswordlgn, setShowPasswordlgn] = useState(false);
  const [RememberMe, SetRememberMe] = useState(false);
  const [AcceptMe, SetAcceptMe] = useState(false);
  const [appledatavalue, Setappledatavalue] = useState(1);

  const [errors, setErrors] = useState({
    emailPhone: "",
    password: "",
    emailPhonelgn: "",
    passwordlgn: "",
  });
  const fullnameRef = useRef(null);
  const emailPhoneRef = useRef(null);
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);
  const confirmpasswordRef = useRef(null);
  const sign = require("jwt-encode");
  const emailPhonelgnRef = useRef(null);
  const passwordlgnRef = useRef(null);
  const SmthandleChangelgn = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const SmthandleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const [AppleId, SetAppleId] = useState("kavitha@egrovesys.com");
  const appleResponse = async (e) => {
    if (appledatavalue == 1) {
      Setappledatavalue(2);
      if (!e.user) {
        const idToken = jwtDecode(e.authorization?.id_token);
        setLoading(true);
        try {
          var datas = {
            email: idToken.email,
            type: "apple",
          };
          const jwt_datas = sign(datas, "VdM1t3n0NiD_Qsawq7ZX");
          const response = await axios
            .post(
              `${process.env.REACT_APP_API_URL}accounts/save_social_login_data/`,
              { data: jwt_datas }
            )
            .catch((error) => {
              setLoading(false);
              if (
                error?.response?.status == 599 ||
                error?.response?.status == 500 ||
                error?.response?.status == 400 ||
                error?.response?.status == 404
              ) {
                setapiOpen(true);
                setapierrorMsg(error?.response?.data?.error);
              } else if (error?.code == "ERR_NETWORK") {
                navigate("/login-errorpages");
              }
            });
          if (response?.data?.token) {
            dispatch(SetUserToken({ eso_user_token: response.data.token }));
            dispatch(
              SetAuthToken({ eso_auth_token: "Token " + response.data.token })
            );
            if (response.data.is_onboarding_completed === true)
              window.location.href = `${process.env.REACT_APP_DOMAIN}`;
            else
              window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          }
        } catch (err) {
          setLoading(false);
          toast.error(err.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setLoading(false);
      } else {
        setLoading(true);
        try {
          var datas = {
            name: e.user.name.firstName + e.user.name.lastName,
            email: e.user.email,
            type: "apple",
          };
          const jwt_datas = sign(datas, "VdM1t3n0NiD_Qsawq7ZX");
          const response = await axios
            .post(
              `${process.env.REACT_APP_API_URL}accounts/save_social_login_data/`,
              { data: jwt_datas }
            )
            .catch((error) => {
              setLoading(false);
              if (
                error?.response?.status == 599 ||
                error?.response?.status == 500 ||
                error?.response?.status == 400 ||
                error?.response?.status == 404
              ) {
                setapiOpen(true);
                setapierrorMsg(error?.response?.data?.error);
              } else if (error?.code == "ERR_NETWORK") {
                navigate("/login-errorpages");
              }
            });
          if (response?.data?.token) {
            dispatch(SetUserToken({ eso_user_token: response.data.token }));
            dispatch(
              SetAuthToken({ eso_auth_token: "Token " + response.data.token })
            );
            if (response.data.is_onboarding_completed === true)
              window.location.href = `${process.env.REACT_APP_DOMAIN}`;
            else
              window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          }
        } catch (err) {
          setLoading(false);
          toast.error(err.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setLoading(false);
      }
    }
  };
  const handleTogglePasswordreg = () => {
    setShowPasswordreg(!showPasswordreg);
  };
  const handlecTogglePasswordreg = () => {
    setShowCPasswordreg(!showCPasswordreg);
  };
  const handleTogglePasswordlgn = () => {
    setShowPasswordlgn(!showPasswordlgn);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { emailaddress, password, confirmpassword, username, fullname } =
      formData;
    const phoneNumberRegex = /^[0-9]{10}$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;
    const newErrors = {};
    if (!emailaddress || emailaddress.length === 0) {
      newErrors.emailaddress = "Please enter your Email address";
      emailPhoneRef.current.focus();
    } else if (!emailRegex.test(emailaddress)) {
      newErrors.emailaddress = "Invalid Email Address";
      emailPhoneRef.current.focus();
    }
    if (!password) {
      newErrors.password = "Please enter your Password";
      passwordRef.current.focus();
    }
    if (password) {
      if (testPasswordStrength(password) === false) {
        newErrors.password =
          "Password does not meet the criteria. It should contain at least one uppercase letter, one digit, and one special character (@, $, !, %, *, ?, &), and be at least 8 characters long";
        passwordRef.current.focus();
      }
    }
    if (!confirmpassword) {
      newErrors.Confirmpassword = "Please enter Confirm Password";
      confirmpasswordRef.current.focus();
    }
    if (
      confirmpassword &&
      password &&
      testPasswordStrength(password) !== false
    ) {
      if (confirmpassword != password)
        newErrors.Confirmpassword =
          "Password and Confirm password should be same";
      confirmpasswordRef.current.focus();
    }
    if (!fullname) {
      newErrors.fullname = "Please enter your Full Name";
      fullnameRef.current.focus();
    }
    if (AcceptMe === false) {
      newErrors.acceptme = "Please accept Terms and Conditions";
    }
    setErrors(newErrors);
    if (newErrors.emailPhone) {
      emailPhoneRef.current.focus();
    } else if (newErrors.password) {
      passwordRef.current.focus();
    } else if (newErrors.username) {
      usernameRef.current.focus();
    } else if (newErrors.fullname) {
      fullnameRef.current.focus();
    }
    if (isObjectEmpty(newErrors)) {
      setLoading(true);
      try {
        const Response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}accounts/register/`,
            {
              username: emailaddress,
              password: password,
              email: emailaddress,
              name: fullname,
            },
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            setLoading(false);
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 500 ||
              error?.response?.status == 400 ||
              error?.response?.status == 404
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/login-errorpages");
            }
          });
        if (
          Response?.data?.status === "sucess" &&
          Response?.data?.is_email_verified === true
        ) {
          var inputElement = document.querySelector('[name="fullname"]');
          if (inputElement !== null) {
            inputElement.value = "";
          }

          var inputElement = document.querySelector('[name="emailaddress"]');
          if (inputElement !== null) {
            inputElement.value = "";
          }

          var inputElement = document.querySelector('[name="password"]');
          if (inputElement !== null) {
            inputElement.value = "";
          }
          tab1ref.current.click();
          setapiOpen(true);
          setapierrorMsg("You have successfully registered!. Please Login");
          setLoading(false);
          setFormData({});
        } else {
          await TriggerEmailOtp(Response.data.token);
          localStorage.setItem("verify_register_token", Response.data.token);
          navigate("/email-verification?verification=register");
        }
      } catch (err) {
        setLoading(false);
        if (err) {
          if (err.response) {
            if (err.response.data) {
              toast.error(err.response.data.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          }
        }
        console.log(err);
      }
    }
  };

  function isObjectEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  const loginSubmit = async (e) => {
    e.preventDefault();
    const { emailPhonelgn, passwordlgn } = formData;
    const phoneNumberRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};
    if (!emailPhonelgn || emailPhonelgn.length === 0) {
      newErrors.emailPhonelgn = "Please enter your Email address";
      emailPhonelgnRef.current.focus();
    }
    if (!passwordlgn) {
      newErrors.passwordlgn = "Please enter your Password";
      passwordlgnRef.current.focus();
    }
    setErrors(newErrors);
    if (newErrors.emailPhonelgn) {
      emailPhonelgnRef.current.focus();
    } else if (newErrors.passwordlgn) {
      passwordlgnRef.current.focus();
    }
    if (isObjectEmpty(newErrors)) {
      setLoading(true);
      try {
        if (RememberMe === true) {
          localStorage.setItem("logincookieemail", emailPhonelgn);
          localStorage.setItem("logincookiepass", passwordlgn);
          localStorage.setItem("logincookiestatus", RememberMe);
        } else {
          localStorage.setItem("logincookieemail", "");
          localStorage.setItem("logincookiepass", "");
          localStorage.setItem("logincookiestatus", "");
        }
        var Response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}accounts/login/`,
            {
              username: emailPhonelgn,
              password: passwordlgn,
              remember_me: RememberMe,
            },
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            setLoading(false);
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 500 ||
              error?.response?.status == 400 ||
              error?.response?.status == 404
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/login-errorpages");
            }
          });
        if (Response?.data) {
          if (
            Response?.data?.success &&
            Response?.data?.is_email_verified === true &&
            Response?.data?.subscription_expired === false
          ) {
            if (Response?.data?.success === "Successfully logged in") {
              dispatch(SetUserToken({ eso_user_token: Response.data.token }));
              dispatch(
                SetAuthToken({ eso_auth_token: "Token " + Response.data.token })
              );
              dispatch(
                SetUserSubscription({
                  eso_user_subscription: Response.data.subscription_expired,
                })
              );
              dispatch(
                SetUserFirstTimeLogin({
                  eso_user_first_time_login: Response.data.first_time_login,
                })
              );
              setLoading(false);
              setFormData({});

              window.location.href = `${process.env.REACT_APP_DOMAIN}`;
            }
          } else if (Response?.data?.is_email_verified === false) {
            await TriggerEmailOtp(Response.data.token);

            localStorage.setItem("verify_register_token", Response.data.token);
            navigate("/email-verification?verification=login");
          } else if (Response?.data?.subscription_expired === true) {
            dispatch(SetUserToken({ eso_user_token: Response.data.token }));
            dispatch(
              SetAuthToken({ eso_auth_token: "Token " + Response.data.token })
            );
            dispatch(
              SetUserSubscription({
                eso_user_subscription: Response.data.subscription_expired,
              })
            );
            navigate("/subscription/auth-check");
          }
        }
      } catch (err) {
        setLoading(false);

        if (err?.response?.data?.error) {
          setapiOpen(true);
          setapierrorMsg(err?.response?.data?.error);
        }
        console.log(err);
      }
    }
  };

  const CheckUserTokenExists = () => {
    if (
      esodata.usertoken.value.eso_user_token !== undefined &&
      esodata.usertoken.value.eso_user_token !== null &&
      esodata.usertoken.value.eso_user_token !== ""
    ) {
      navigate("/");
    }
  };

  const LoadCookieData = () => {
    var CookieEmail = localStorage.getItem("logincookieemail");
    var CookiePass = localStorage.getItem("logincookiepass");
    var CookieStatus = localStorage.getItem("logincookiestatus");
    var Obj = {};
    if (CookieEmail !== null && CookieEmail !== "") {
      Obj["emailPhonelgn"] = CookieEmail;
    }
    if (CookiePass !== null && CookiePass !== "") {
      Obj["passwordlgn"] = CookiePass;
    }
    setFormData(Obj);
    if (CookieStatus !== null && CookieStatus !== "") {
      if (CookieStatus == "true") {
        const checkbox = document.getElementById("remember_me_id");
        if (checkbox) {
          checkbox.checked = true;
        }
        SetRememberMe(true);
      }
    }
  };

  useEffect(() => {
    const clientId = process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID;
    CheckUserTokenExists();
    LoadCookieData();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    if (newValue == "1") {
      var IntialAobj = formData;
      var emailPhonelgnIn = "";
      var passwordlgnIn = "";

      if (IntialAobj.emailPhonelgn) {
        emailPhonelgnIn = IntialAobj.emailPhonelgn;
      }
      if (IntialAobj.passwordlgn) {
        passwordlgnIn = IntialAobj.passwordlgn;
      }
      setFormData({
        emailPhonelgn: emailPhonelgnIn,
        passwordlgn: passwordlgnIn,
        fullname: "",
        emailaddress: "",
        password: "",
      });
    }
    setValue(newValue);
  };

  const [guser, setgUser] = useState([]);

  const glogin = useGoogleLogin({

    onSuccess: (codeResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {

          SingUpOrLogInwithgoogle("login", res);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/login-errorpages");
          }
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    redirect_uri: process.env.REACT_APP_GOOGLE_LOGIN_URL,
  });

  const glogins = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          SingUpOrLogInwithgoogle("sign_up", res);
        })
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/login-errorpages");
          }
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    redirect_uri: process.env.REACT_APP_GOOGLE_LOGIN_URL,

  });

  const SingUpOrLogInwithgoogle = async (action = "", res = "") => {
    setLoading(true);
    var datas = {
      name: res.data.name,
      email: res.data.email,
      type: "google",
    };

    const jwt_datas = sign(datas, "VdM1t3n0NiD_Qsawq7ZX");
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}accounts/save_social_login_data/`,
          { data: jwt_datas }
        )
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/login-errorpages");
          }
        });
      if (response?.data?.token) {
        dispatch(SetUserToken({ eso_user_token: response.data.token }));
        dispatch(
          SetAuthToken({ eso_auth_token: "Token " + response.data.token })
        );
        if (response?.data?.is_onboarding_completed === true)
          window.location.href = `${process.env.REACT_APP_DOMAIN}`;
        else window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      if (code) {
        glogin({ code });
      }
    }
  }, [glogin]);

  const TriggerEmailOtp = async (token) => {
    try {
      const Response = await axios
        .get(
          `${process.env.REACT_APP_API_URL}accounts/verify_user_email_send_otp/`,

          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .catch((error) => {
          setLoading(false);
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/login-errorpages");
          }
        });
    } catch (error) {
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error);
    }
  };

  const tabData = [
    {
      title: <div className="tab_head_container">Log in</div>,
      content: (
        <div className="tab_head_container_outr">
          <div className="frm_otr_wrap">
            <div className="frm_inner_wrap">
              <form
                onSubmit={loginSubmit}
                className="frm_container"
                method="post"
              >
                <div class="form-group email_grp">
                  <input
                    type="text"
                    id="email_Phone"
                    className="newform_val"
                    name="emailPhonelgn"
                    placeholder="Email address"
                    value={formData.emailPhonelgn}
                    onChange={SmthandleChangelgn}
                    ref={emailPhonelgnRef}
                  />
                  <div className="frm_error">{errors.emailPhonelgn}</div>
                </div>
                <div class="form-group">
                  <input
                    type={showPasswordlgn ? "text" : "password"}
                    id="password"
                    name="passwordlgn"
                    className="newform_val"
                    placeholder="Password"
                    value={formData.passwordlgn}
                    onChange={SmthandleChangelgn}
                    ref={passwordlgnRef}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordlgn}
                  >
                    {showPasswordlgn ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                </div>
                <div className="frm_error">{errors.passwordlgn}</div>

                <div className="forgot_remember_row">
                  <span class="forgot-password">

                  </span>
                  <a href="/password-reset" class="forgot-password">
                    Forgot Password?
                  </a>
                </div>

                <button type="submit" className="sbt_btn">
                  Login
                </button>
              </form>
            </div>
          </div>
          <div className="tab_link_outr_wrap">
            <a href="#0" onClick={() => glogin()}>
              Login in with
              <span>
                {" "}
                <img src={GoogleLogo} alt="google Logo" />
              </span>
            </a>
            <a href="#0" className="apple_login_outer">
              Login in with
              <span style={{ marginLeft: "5px" }}>
                {" "}
                <AppleLogin
                  clientId={process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID}
                  redirectURI={process.env.REACT_APP_APPLE_LOGIN_REDIRECT_PATH}
                  usePopup={true}
                  callback={appleResponse}
                  responseType={"code"}
                  scope="name email"
                  responseMode="query"
                  render={(
                    renderProps
                  ) => (
                    <button
                      class="apple-sign-in"
                      onClick={renderProps.onClick}
                    ></button>
                  )}
                />
                <AppleIcon />
              </span>
            </a>
          </div>
        </div>
      ),
      image: (
        <img src={LoginImage} alt="left image" className="login_lft_img" />
      ),
      tabId: "lgn-tab-container",
    },
    {
      title: <div className="tab_head_container">Sign up</div>,
      content: (
        <div className="tab_head_container_outr">

          <div className="frm_otr_wrap">
            <div className="frm_inner_wrap">
              <form
                onSubmit={handleSubmit}
                className="frm_container"
                method="post"
              >
                <div class="form-group email_grp">
                  <input
                    type="text"
                    id="email_Phone"
                    className="newform_val"
                    name="fullname"
                    placeholder="Full name"
                    value={formData.fullname}
                    defaultValue={formData.fullname}
                    onChange={SmthandleChange}
                    ref={fullnameRef}
                  />
                  <div className="frm_error">{errors.fullname}</div>
                </div>
                <div class="form-group email_grp">
                  <input
                    type="text"
                    id="email_Phone"
                    className="newform_val"
                    name="emailaddress"
                    placeholder="Email address"
                    value={formData.emailaddress}
                    defaultValue={formData.emailaddress}
                    onChange={SmthandleChange}
                    ref={emailPhoneRef}
                  />
                  <div className="frm_error">{errors.emailaddress}</div>
                </div>

                <div class="form-group pass_field_grp">
                  <input
                    type={showPasswordreg ? "text" : "password"}
                    id="password"
                    name="password"
                    className="newform_val sgn_pswd"
                    autoComplete="off"
                    placeholder="Password"
                    onChange={SmthandleChange}
                    ref={passwordRef}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordreg}
                  >
                    {showPasswordreg ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                  <div className="frm_error">{errors.password}</div>
                </div>
                <div class="form-group pass_field_grp">
                  <input
                    type={showCPasswordreg ? "text" : "password"}
                    id="password"
                    name="confirmpassword"
                    className="newform_val sgn_pswd"
                    autoComplete="off"
                    placeholder="Confirm Password"
                    onChange={SmthandleChange}
                    ref={confirmpasswordRef}
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={handlecTogglePasswordreg}
                  >
                    {showCPasswordreg ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                  <div className="frm_error">{errors.Confirmpassword}</div>
                </div>
                <div className="form-group register_term_accept">
                  <input
                    type="checkbox"
                    value={AcceptMe}
                    onChange={(e) => {
                      SetAcceptMe(e.target.checked);
                    }}
                  />
                  <span>
                    Click here to agree to the Terms & Conditions. By creating your account, you agree to the{" "}
                    <a
                      href="https://www.elitesiteoptimizer.com/terms-and-conditions/"
                      target="_blank"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.elitesiteoptimizer.com/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy.
                    </a>
                  </span>
                  <div className="frm_error">{errors.acceptme}</div>
                </div>

                <button type="submit" className="sbt_btn crt_act">
                  Create account
                </button>
              </form>
            </div>
          </div>
          <div className="tab_link_outr_wrap">
            <a href="#0" onClick={() => glogins()}>
              Sign up with
              <span>
                {" "}
                <img src={GoogleLogo} alt="google Logo" />
              </span>
            </a>
            <a href="#0" className="apple_login_outer">
              Sign up with
              <span>
                {" "}
                <AppleLogin
                  clientId={process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID}
                  redirectURI={process.env.REACT_APP_APPLE_LOGIN_REDIRECT_PATH}
                  usePopup={true}
                  callback={appleResponse}
                  responseType={"code"}
                  scope="name email"
                  responseMode="query"
                  render={(
                    renderProps
                  ) => (
                    <button
                      class="apple-sign-in"
                      onClick={renderProps.onClick}
                    ></button>
                  )}
                />
                <AppleIcon />
              </span>
            </a>
          </div>
        </div>
      ),
      image: (
        <img src={RegisterImage} className="RegisterImage" alt="left image" />
      ),
      tabId: "sgn-tab-container",
    },
  ];

  const tab1ref = useRef(null);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        style={{
          width: "calc(100% - 60px)",
          marginRight: "60px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="tab_otr_outer lgn_container new_tab_otr_outer">
          <div className="tab_head_container_lft newlogin">
            <div className="login_tab_img_outer">
              <img
                src={Logo}
                className="login_tab_img dataimg"
                alt="ESO logo"
              />
            </div>
            <Box
              sx={{
                maxWidth: { xs: 768, sm: 475 },
                bgcolor: "background.paper",
              }}
            >
              <div className="tab-header">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  className="signallbutton"
                >
                  <Tab
                    ref={tab1ref}
                    key={1}
                    label={"Login"}
                    className="signbutton"
                  />
                  <Tab key={2} label={"SignUp"} className="signbutton" />
                </Tabs>
              </div>
              <div className="das_tab_container">{tabData[value].content}</div>
            </Box>
          </div>
        </div>
      </div>
      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: false
        }}
        closeAlert={apihandleClose} />
    </>
  );
}
export default Newlogin;
