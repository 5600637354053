import React, { useEffect, useState, useContext, useMemo } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import LinkIcon from "@mui/icons-material/Link";
import moment from "moment";
import calendarpng from "../Asset/New_Assert/ESO_Icon/Filter.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

const SiteMapGenerator = () => {
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [XmlYes, SetXmlYes] = useState(true);
  const [HtmlYes, SetHtmlYes] = useState(true);
  const [ImageYes, SetImageYes] = useState(true);
  const [VideoYes, SetVideoYes] = useState(true);
  const [ShowSetttings, SetShowSetttings] = useState(false);
  const [ChangeFrequency, SetChangeFrequency] = useState("");
  const [SiteProtected, SetSiteProtected] = useState("no");
  const [SiteUserName, SetSiteUserName] = useState("");
  const [SitePassword, SetSitePassword] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const [jobDate, SetjobDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [initialDateval, SetInitialDateval] = useState([]);
  const [sitemapGeneratorPlan, setsitemapGeneratorPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [checkD, setcheckD] = useState(false);
  const navigate = useNavigate();
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const checkUserPlan = async () => {
      const sitemapGeneratorP = await Utils("sitemap", ApiToken);
      if (sitemapGeneratorP.status) {
        var DomainList = esodata.domainlist.value;
        DomainList = DomainList.find(
          (obj) => obj.id == esodata.domaindata.value.header_selected
        );
        if (DomainList) {
          SetAnalyzeUrl(DomainList.domain__url);
        }
        faqdatafun();
      }
      setsitemapGeneratorPlan(sitemapGeneratorP);
    };
    checkUserPlan();
  }, [esodata]);

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "url",
      headerName: "URL",
      description: "List Of The Website's URLs",
      minWidth: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon />
                    {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "type_of_sitemap",
      headerName: "Type of sitemap",
      description: "The Type of Sitemap Generated for the Domain",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.type_of_sitemap !== null) {
          return cardData.row.type_of_sitemap;
        } else {
          return "-";
        }
      },
    },
    {
      field: "date_of_request",
      headerName: "Date of request",
      description: "The Date On Which the Sitemap Was Generated",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.date_of_request !== null) {
          return cardData.row.date_of_request;
        } else {
          return "-";
        }
      },
    },
    {
      field: "total_urls",
      headerName: "Total URLs",
      description: "The Total No. Of URLs Crawled For The Domain",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.total_urls !== null) {
          return cardData.row.total_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "valid_urls",
      headerName: "Valid URLs",
      description: "The Total No. Of Valid URLs Crawled For The Domain",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.valid_urls !== null) {
          return cardData.row.valid_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "invalid_urls",
      headerName: "Invalid URLs",
      description: "The Total No. Of Invalid URLs Crawled For The Domain",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.invalid_urls !== null) {
          return cardData.row.invalid_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "change_in_urls",
      headerName: "Change In URLs",
      description: "The change of URL count comparing the previously generated Sitemap and recently generated Sitemap.",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.change_in_urls !== null) {
          return cardData.row.change_in_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "total_time_to_generate",
      headerName: "Total time to generate",
      description: "The Totaltime Taken To Generate The Specific Type Of Sitemap For The Domain",
      minWidth: 120,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let duration = moment.duration(cardData.row.total_time_to_generate);

        if (cardData.row.total_time_to_generate !== null) {
          let minutes = duration.minutes();
          let seconds = duration.seconds();
          let humanReadableTime = `${minutes} minutes and ${seconds} seconds`;

          return humanReadableTime;
        } else {
          return "-";
        }
      },
    },
    {
      field: "status",
      headerName: "Action",
      description: "Clicking On The Download Button Will Allow You To Download The Specific Type Of Sitemap For The Domain.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.status == true) {
          return (
            <div>
              <button
                id={cardData.row.id}
                onClick={DownloadSitemap}
                className="view-report-button"
                style={{ padding: '12px 23px' }}
              >
                Download
              </button>
            </div>
          );
        } else if (cardData.row.status == false) {
          return (
            <div className="in_table_progress_icon_wrap">
              <div className="view-report-button">In Progress</div>
              <div>
              </div>
            </div>
          );
        }
      },
    },
  ];

  const DownloadSitemap = async (e) => {
    const Id = e.target.closest(".view-report-button").getAttribute("id");
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}sitemap_generator/download_sitemap_generator_file/?user_domain_id=${esodata.domaindata.value.header_selected}&pk_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (datavalue?.headers['content-type']) {
        var String = datavalue.headers['content-type'];
        String = String.split('/');
        String = String[1]
        var pseudoelement = document.createElement("a");
        var theMoment = moment();
        var filename = "report-" + theMoment + "." + String;
        var pseudoelement = document.createElement("a");
        var blob = new Blob([datavalue.data], { type: datavalue.headers['content-type'] });
        pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
        pseudoelement.setAttribute("download", filename);
        pseudoelement.dataset.downloadurl = [datavalue.headers['content-type'], pseudoelement.download, pseudoelement.href].join(":");
        pseudoelement.draggable = true;
        pseudoelement.classList.add("dragout");
        pseudoelement.click();
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error('Download has been failed!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const TriggerSettings = () => {
    SetShowSetttings(!ShowSetttings);
  };

  const AnalyzeReport = async () => {
    setLoading(true);
    try {
      const Response = await axios.post(
        `${process.env.REACT_APP_API_URL}sitemap_generator/generate_sitemap/`,
        {
          xml_sitemap: XmlYes,
          html_sitemap: HtmlYes,
          images: ImageYes,
          videos: VideoYes,
          request_url: AnalyzeUrl,
          changefrq: ChangeFrequency,
          Is_site_protected: SiteProtected,
          username: SiteUserName,
          password: SitePassword,
          user_domain_id: `${esodata.domaindata.value.header_selected}`,
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (Response?.data?.status) {
        setLoading(false);
        toast.error(Response.data.status, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setTimeout(() => {
          setLoading(false);
          GetTableReport();
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const GetTableReport = async (JobId = "") => {
    setLoading(true);
    setCardData([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}sitemap_generator/sitemap_generator_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&timezone=${timezoneval()}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {
        if (datavalue?.data?.table_data) {
          SetAutoheightval(false)
          if (datavalue?.data?.job_date) {

            SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
          }
          else if (datavalue?.data?.table_data) {
            SetjobDate(moment(datavalue.data.table_data[0].date_of_request).format("MMMM DD,YYYY HH:mm"));
          }



          const newData = datavalue.data.table_data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });

          setCardData(newData);
        }
      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const reportNow = () => {
    if (selectval !== "") {
      if (selectval.length > 0) {
        var JobId = "";
        JobId = selectval[0].id;
        if (JobId !== "") {
          GetTableReport(JobId);
          closeModal();
        }
      }
    }
  };

  const GetJobDates = async () => {
    setcompletedate([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=sitemap_generator`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var Selectedmets = [];
      if (datavalue?.data) {
        if (datavalue?.data?.jobs) {
          datavalue?.data?.jobs.forEach((element) => {
            Selectedmets.push({
              id: element.id,
              completed_at: element.completed_at,
            });
          });
          SetInitialDateval(Selectedmets[0]);
          setSelectval(Selectedmets[0].value);
          SetdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets);
        }
      }

    } catch (err) {
      console.log(err)
    }


  };

  useEffect(() => {
    GetuserDatavalue();
    const checkUserPlan = async () => {
      const sitemapGeneratorP = await Utils("sitemap", ApiToken);
      if (sitemapGeneratorP.status) {
        GetTableReport();
        GetJobDates();
      }
      setsitemapGeneratorPlan(sitemapGeneratorP);
    };
    checkUserPlan();
  }, []);

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=42`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Site Map Generator</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <div className={`over_all_container sitemap_generator ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        {loading === true ? <Loader /> : null}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Sitemap Generator
              </Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />
              </button>
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Sitemap Generator</h2>
              <Tooltip title={AnalyzeUrl}>
                <div className="prefered_url_external02">
                  <a target="_blank" href={AnalyzeUrl} style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="spanlink">
                      {AnalyzeUrl ? <LinkIcon /> : ""} {AnalyzeUrl}
                    </span>

                  </a>
                </div>
              </Tooltip>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className="button_col" style={{ display: "flex", gap: "20px" }}>
                <button className="runreportnew over_all_accessibility runreportnew" onClick={() => {
                  if (first.statusDomain) {

                    setcheckD(first.statusDomain);
                  } else {
                    AnalyzeReport()
                  }
                }}>
                  Generate
                </button>
                <button className="runreportnew over_all_accessibility runreportnew" onClick={() => {
                  if (first.statusDomain) {

                    setcheckD(first.statusDomain);
                  } else {
                    TriggerSettings()
                  }
                }}
                >
                  Settings
                </button>
              </div>
            </div>
          </div>
          {sitemapGeneratorPlan.status ?
            <div>

              <div
                className="plagarism_analyzer_row no_pad"
                style={{ color: "red" }}
              >
              </div>
              {ShowSetttings == true ? (
                <div className="row settings_form_row">
                  <div className="type_of_settings">
                    <div className="typecol">
                      <input
                        type="checkbox"
                        defaultChecked={XmlYes}
                        onChange={(e) => {
                          SetXmlYes(e.target.checked);
                        }}
                      />
                      XML Sitemap{" "}
                      <Tooltip title={"list of the website's URLs as an XML file"}>
                        <QuestionMarkIcon />
                      </Tooltip>
                    </div>

                    <div className="typecol">
                      <input
                        type="checkbox"
                        defaultChecked={HtmlYes}
                        onChange={(e) => {
                          SetHtmlYes(e.target.checked);
                        }}
                      />
                      HTML Sitemap
                      <Tooltip
                        title={
                          "An HTML sitemap allows site visitors to easily navigate a website"
                        }
                      >
                        <QuestionMarkIcon />
                      </Tooltip>
                    </div>

                    <div className="typecol">
                      <input
                        type="checkbox"
                        defaultChecked={ImageYes}
                        onChange={(e) => {
                          SetImageYes(e.target.checked);
                        }}
                      />
                      Image Sitemap
                      <Tooltip
                        title={
                          "Image siteamp includies imaeg URLs in the sitemap to avoid repeating  images acorss your pages"
                        }
                      >
                        <QuestionMarkIcon />
                      </Tooltip>
                    </div>

                    <div className="typecol">
                      <input
                        type="checkbox"
                        defaultChecked={VideoYes}
                        onChange={(e) => {
                          SetVideoYes(e.target.checked);
                        }}
                      />
                      Video Sitemap
                      <Tooltip
                        title={
                          "A video sitemap provides additional information about any video content hosted on the webpage"
                        }
                      >
                        <QuestionMarkIcon />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="settings_form">
                    <div className="form_field_row">
                      <div className="label_col">
                        Change Frequency{" "}
                        <Tooltip
                          title={"how frequently content is likely to change"}
                        >
                          <QuestionMarkIcon />
                        </Tooltip>
                      </div>
                      <div className="field_col">
                        <select
                          defaultValue={ChangeFrequency}
                          onChange={(e) => {
                            SetChangeFrequency(e.target.value);
                          }}
                        >
                          <option value={""}>Select Frequency</option>
                          <option value={"Monthly"}>Monthly</option>
                          <option value={"Always"}>Always</option>
                          <option value={"Hourly"}>Hourly</option>
                          <option value={"Daily"}>Daily</option>
                          <option value={"Weekly"}>Weekly</option>
                          <option value={"Yearly"}>Yearly</option>
                          <option value={"Never"}>Never</option>
                        </select>
                      </div>
                    </div>
                    <div className="form_field_row">
                      <div className="label_col">
                        Is site protected{" "}
                        <Tooltip
                          title={
                            "Site is protected with username and password authentication"
                          }
                        >
                          <QuestionMarkIcon />
                        </Tooltip>
                      </div>
                      <div className="field_col">
                        <input
                          type="radio"
                          name="site_protected"
                          value={"yes"}
                          defaultChecked={SiteProtected == "yes" ? true : false}
                          onChange={(e) => {
                            SetSiteProtected(e.target.value);
                          }}
                        />
                        Yes &nbsp;
                        <input
                          type="radio"
                          name="site_protected"
                          value={"no"}
                          defaultChecked={SiteProtected == "no" ? true : false}
                          onChange={(e) => {
                            SetSiteProtected(e.target.value);
                          }}
                        />
                        No
                      </div>
                    </div>

                    {SiteProtected === "yes" ? (
                      <div className="form_field_row">
                        <div className="label_col">
                          Credentials{" "}
                          <Tooltip
                            title={"Username and password to access the website"}
                          >
                            <QuestionMarkIcon />
                          </Tooltip>
                        </div>
                        <div className="field_col">
                          <div className="two_inputs">
                            <input
                              type="text"
                              defaultValue={SiteUserName}
                              placeholder="Username"
                              onChange={(e) => SetSiteUserName(e.target.value)}
                            />
                            <input
                              type="text"
                              defaultValue={SitePassword}
                              placeholder="Password"
                              onChange={(e) => {
                                SetSitePassword(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />

            </div> : <Freeaudit planStatus={sitemapGeneratorPlan} />
          }
        </div>

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div>
                  <div className="">
                    <div>
                      <span>Completed Date</span>
                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          setSelectval(values)

                          SetdEngineoption({
                            completed_at:
                              values[0].completed_at,
                            id:
                              values[0].id,
                          });

                        }}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <span className="text-error">{""}</span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button
              className="form_submit_btn"
              onClick={reportNow}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>
        {!openCommonAlerts &&



          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  lineHeight: "1px",
                  width: "max-content",
                }}
              >
                <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                <a href={domainNameUrl.domain_url}>
                  {domainNameUrl.domain_url}
                </a>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button
              onClick={() => {
                navigate(first.path);
              }}
            >
              {first.name}
            </Button>
            <Button onClick={() => setcheckD(false)}>{first.closeName}</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default SiteMapGenerator;
