import React, { useState, useEffect, useContext, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import KeywordAddForm from "./KeywordAddForm";
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedIcon from '@mui/icons-material/Verified';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Domaindata from "./Domaindata";
import EditDomaindata from "./EditDomaindata";
import Domainownership from "./Domainownership";
import GoogleAnalytics from "./Googleanalytics";
import { useGoogleLogin } from "@react-oauth/google";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

const sign = require('jwt-encode');


export default function UserDomainList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [domainListPlan, setdomainListPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [addEditDomainPopup, setAddEditDomainPopup] = useState(false);
  const [addEditPopupTitle, setAddEditPopupTitle] = useState('edit');
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  const [pagename, setPagename] = useState("Domain Management")
  const [selectedRows, setSelectedRows] = useState(null);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();


  const [apiOpen, setApiOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [addedDomains, setAddedDomains] = useState([]);
  const [finalSelected, setfinalSelected] = useState({ url: "" })
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [nextBtStatus, setNextBtStatus] = useState(true);

  const [apiopenr, setApiopenr] = useState(false);

  const [apiopenrloc, setApiopenrloc] = useState(false);
  const [regionalLevel, setRegionalLevel] = useState(null);
  const [SearchEngine, SetSearchEngine] = useState(null);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [Country, SetCountry] = useState(null);
  const [State, SetState] = useState(null);
  const [City, SetCity] = useState(null);
  const [engineerr, setEngineerr] = useState(null);
  const [countryerr, setCountryerr] = useState(null);
  const [stateerr, setStateerr] = useState(null);
  const [cityerr, setCityerr] = useState(null);

  const navigate = useNavigate();
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const apihandleClose = () => {
    setapiOpen(false);
    apihandleCloserloc();
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = async (e) => {
    SetDeleteId(e)
    setIsOpen(true);
  };


  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domain-list/`;

  const checkUserPlan = async () => {
    const domainListP = await Utils("domain_management", ApiToken);
    if (domainListP.status) {
      callUserdataApi();
      faqdatafun()
    }
    setdomainListPlan(domainListP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
    getRegionalLevelSetting();
    LoadAddFormFilterOptions();
    getPreviousLocationbyUser();
  }, [addEditDomainPopup]);


  const getPreviousLocationbyUser = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_location/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });


      if (datavalue?.data) {

        const countrydatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )

        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });

          var countryData = Sections.find((option) => option.value === datavalue?.data.country_id);
          setCountryValue(countryData);
          const statedatavalue = await axios.get(
            `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${datavalue?.data.country_id}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )


          if (statedatavalue?.data?.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({ label: element.state_name, value: element.id });
            });

            var stateData = Sections.find((option) => option.value === datavalue?.data.state_id);

            setStateValue(stateData);

            const citydatavalue = await axios.get(
              `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${datavalue?.data.state_id}`,
              {
                headers: {
                  Authorization: `${ApiToken}`,
                },
              }
            )


            if (citydatavalue?.data?.cities) {
              var Sections = [];
              citydatavalue.data.cities.forEach((element) => {
                Sections.push({ label: element.city_name, value: element.id });
              });
              SetCityOptions(Sections);
              var cityData = Sections.find((option) => option.value === datavalue?.data.city_id);
              setCityValue(cityData);


              const searchenginedatavalue = await axios.get(
                `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
                {
                  headers: {
                    Authorization: `${ApiToken}`,
                  },
                }
              )

              if (searchenginedatavalue?.data) {
                SetSearchEngine({ label: searchenginedatavalue.data[0].name, value: searchenginedatavalue.data[0].id });
              }
            }
          }
        }

      }

    } catch (error) {

    }
  }


  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };



  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_all_user_domains/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      if (datavalue?.data) {
        if (datavalue?.data) {
          const newData = datavalue.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });

          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    if (first.statusDomain) {
      setcheckD(first.statusDomain);
    } else {
      setApiopenr(false);
      setAddEditPopupTitle('add');
      setAddEditDomainPopup(true);
    }
  }

  const DeleteCampaignSettings = async (e) => {
    setLoading(true);
    setCardData([])
    setIsOpen(false);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/delete_domain/?user_domain_id=${deleteId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
    callUserdataApi();
  };
  const reportNow = async () => {
    var err = 1;
    if (websiteName === "") {
      SetWebnameError("Website Name is Required")
      err = 2;
    }
    if (webLink === "") {
      SetweblinkError("Website Link is Required")
      err = 2;
    }
    if (err == 2) {
      return false;
    }
    else {
      setLoading(true)
      await axios.post("", {
        email: websiteName,
        url: webLink,

      })
        .then((response) => {
          if (response?.data?.result == 200) {
            closeModal()
          }
        })
        .catch(error => {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          console.log(error)
        })
      setLoading(false)
    }

  };
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "domain__name",
      headerName: "Website Name",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "domain__url",
      headerName: "Website URL",
      minWidth: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      description: 'Website Url that have been added for the domain',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        let firstEightLetters = cardData.row.domain__url;
        let whoedata = firstEightLetters;
        let baseUrl = cardData.row.domain__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.domain__url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.domain__url}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.domain__url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>

          </>
        );

      },

    },
    {
      field: "Google Analytics",
      headerName: "Google Analytics",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
        <div className="EditBrandedTermClass">
            <button
            className="buttonView edit_icon keyword-edit-icon"
            onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => {
              setSelectedRows(cardData.row.id);
              setAddEditPopupTitle('google_analytics');
              setAddEditDomainPopup(true);
            }
            }
          >
            <InsightsIcon className="edit-icon-svg"/>
          </button >

        </div>
        );
      },

    },
    {
      field: "Domain Ownership",
      headerName: "Domain Ownership",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.domain__is_verified == true) {
          return (
         <div className="EditBrandedTermClass">
             <button
              className="buttonView edit_icon keyword-edit-icon"
            >
              <VerifiedIcon className="edit-icon-svg"/>
            </button>
         </div>
          );
        }
        else {
          return (
          <div className="EditBrandedTermClass">
              <button
              className="buttonView edit_icon keyword-edit-icon"
              onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => {
                setSelectedRows(cardData.row.id);
                setAddEditPopupTitle('domain_ownership');
                setAddEditDomainPopup(true);
              }}
            >
              <RemoveDoneIcon className="edit-icon-svg"/>
            </button>
          </div>

          );
        }
      },

    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        return (
        <div className="EditBrandedTermClass">
            <button
            className="buttonView edit_icon keyword-edit-icon"
            onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => {
              setSelectedRows(cardData.row.id);
              setAddEditPopupTitle('edit');
              setAddEditDomainPopup(true);
            }}
          >
            <EditIcon className="edit-icon-svg"/>
          </button>
        </div>

        );
      },

    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
        <div className="DeleteBrandedtermClass">
            <button
            className="buttonView trash_icon keyword-delete-icon"
            onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => { handleClickOpen(cardData.row.id) }}
            id={cardData.row.id}
            data-value={cardData.row.domain__name}
          >
            <DeleteIcon className="delete-icon-svg"/>
          </button>
        </div>
        );
      },

    },
  ];
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  const [CallCampaignTable, SetCallCampaignTable] = useState(false)

  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=44`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const triggerPopup = () => {
    setApiOpen(true);
  }


  const glogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          fetchSearchConsoleData(codeResponse.access_token);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
    onError: (error) => console.log("Login Failed:", error),
    scope: "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/webmasters.readonly", // Ensure correct scope for both Analytics and Search Console
  });

  const handleApiError = (error) => {
    if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
      setApiOpen(true);
      setApiErrorMsg(error?.response?.data?.error);
    } else if (error?.code == "ERR_NETWORK") {
      navigate("/login-errorpages");
    } else {
      console.error('Unknown error:', error); // Log other errors
    }
  };

  const fetchSearchConsoleData = (access_token) => {
    axios
      .get("https://www.googleapis.com/webmasters/v3/sites", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {

        if (response.data.siteEntry) {
          response.data.siteEntry.forEach(item => {
            if (item.siteUrl.startsWith('sc-domain:')) {
              item.siteUrl = item.siteUrl.replace(/^sc-domain:/, '');
            }
          });
          setPermissions(response.data.siteEntry)
          apihandleCloser();
          triggerPopup();
        } else {
          setPermissions([]);
          apihandleCloser();
          triggerPopup();
        }
      })
      .catch((error) => {
        console.error('Error fetching Search Console data:', error);
        if (error?.response?.status === 401) {
          console.error('Unauthorized: Invalid access token or missing permissions.');
        }
      });
  };


  const handleAddDomain = async (data, i) => {

    setApiopenrloc(true);
    setfinalSelected({ ...finalSelected, url: data });
  }

  const handleClose = () => {
    setApiOpen(false);
    setApiopenr(true);
  }

  const handleClose1 = () => {
    callUserdataApi();
    setApiOpen(false);
  }

  const apihandleCloser = () => setApiopenr(false);

  const openImportpopup = () => {
    if (first.statusDomain) {
      setcheckD(first.statusDomain);
    } else {
      setApiopenr(true);
    }
  }

  const getRegionalLevelSetting = async () => {
    setLoading(true);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        setRegionalLevel(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };


  const LoadAddFormFilterOptions = async (countryId, searchEngineId) => {
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            if (element.id === searchEngineId) {
              SetSearchEngine({ label: element.name, value: element.id })
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }
  };

  const handlestatedataoptions = (e) => {

    setCountryValue(e);
    SetState("");
    SetCity("");


    var Countrys = e.value;
    handlestateoptions(Countrys)
  }

  const handlestateoptions = async (Countrys = "", stateId) => {

    if (Countrys == "" || Countrys == null || !Countrys)
      return false;


    SetCountry(Countrys);

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        var stateData = Sections.find((option) => option.value === stateId);
        setStateValue(stateData);
      }
    }
  };
  const handleCityoptionsdata = (e) => {
    setStateValue(e);
    SetState("");
    SetCity("");
    var States = e.value;
    handleCityoptions(States)
  }
  const handleCityoptions = async (States = "", cityId) => {
    if (States == "" || States == null || !States) {
      return false;
    }

    SetState(States);

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        var cityData = Sections.find((option) => option.value === cityId);
        setCityValue(cityData);
      }
    }
  };

  const setCurrentCityValue = (e) => {
    setCityValue(e);
    SetCity(e.value);
  }


  const apihandleCloserloc = () => {
    setApiopenrloc(false);
  }



  const finalreportNow = async () => {

    setLoading(true);
    var data_val = 1;

    if (!SearchEngine) {
      setEngineerr("Please select search engine");
      data_val = 2;
    }
    if (!countryValue) {
      setCountryerr("Please select country");
      data_val = 2;
    }
    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {
        if (finalSelected.url) {

          let fData = {
            url: finalSelected.url,
            campaign_location: true,
            search_engine_id: SearchEngine.value,
            country_id: countryValue.value,
            state_id: stateValue?.value,
            city_id: cityValue?.value
          }

          const jwt_datas = sign(fData, "VdM1t3n0NiD_Qsawq7ZX");
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}domain/add_auto_domain/`, { data: jwt_datas }
            ,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          ).catch((error) => {
            setLoading(false)
            if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK") {
              navigate('/login-errorpages')
            }
          });

          if (response?.status == 200) {
            apihandleCloserloc();
            setapiOpen(true);
            setapierrorMsg(response.data.message);
            setAddedDomains(prevState => [...prevState, finalSelected.url]);
            setNextBtStatus(false);
          }
        }
      } catch (error) {

      }

    }

  }


  return (


    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Domain List</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Domain Management</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> {pagename}</h2>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">

            {domainListPlan.status &&
              <button
                title="Add Domain"
                className="runreportnew"
                onClick={openImportpopup}
              >
                Add Domain
              </button>
            }
          </div>

        </div>

        {domainListPlan.status ?
          <>
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </> : <Freeaudit planStatus={domainListPlan} />
        }
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              Are you sure you want to delete this domain name?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="">
          <button className="keyword-cancel" onClick={closeModal}>close</button>
          <button className="keyword-submit" onClick={DeleteCampaignSettings} style={{ cursor: 'pointer' }} >
            OK
          </button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts &&




        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />

      }

      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "white !important",
            top: "10px !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={addEditDomainPopup}
        onClose={() => setAddEditDomainPopup(false)}
      >
        <DialogTitle className="keyword-dialog-header">
          {addEditPopupTitle == 'edit' ?
            'Edit Domain' : addEditPopupTitle === 'add' ?
              'Add Domain' : addEditPopupTitle === 'domain_ownership' ?
                'Domain Ownership' : 'Google Analytics'}
        </DialogTitle>
        <div style={{ overflowY: "auto" }}>
          {addEditPopupTitle === 'edit' ?
            <EditDomaindata
              setAddEditDomainPopup={setAddEditDomainPopup}
              selectedRows={selectedRows}
            /> :
            addEditPopupTitle === 'add' ?
              <Domaindata
                setAddEditDomainPopup={setAddEditDomainPopup}
                setAddEditPopupTitle={setAddEditPopupTitle}
                callUserdataApi={callUserdataApi}
              /> :
              addEditPopupTitle === 'domain_ownership' ?
                <Domainownership
                  setAddEditDomainPopup={setAddEditDomainPopup}
                  selectedRows={selectedRows}
                /> :
                <GoogleAnalytics setAddEditDomainPopup={setAddEditDomainPopup} />
          }
        </div>
      </Dialog>

      <Dialog open={apiOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle className="keyword-dialog-header">{"Your Domains"}</DialogTitle>
        <DialogContent sx={{ width: "500px" }}>
          <div className="user-role-inner form-container">
            <form className="form-content">
              <div className="form-group">
                <div className="permissions-container" style={{ border: '1px solid #ff601f', padding: '10px 30px' }}>

                  <div className="permissions-list">
                    {permissions.every(item => item.permissionLevel === "siteUnverifiedUser") ? (
                      <p style={{ textAlign: "center", color: "#ff601f" }}>
                        You Have No Registered Domains
                      </p>
                    ) : permissions.length > 0 ? (
                      permissions.map((item, index) =>
                        item.permissionLevel !== "siteUnverifiedUser" && (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-evenly",
                              alignItems: "center"
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <p>{item.siteUrl}</p>
                            </div>
                            <div>
                              {addedDomains.includes(item.siteUrl) ? (
                                <span
                                  style={{
                                    color: "#28a745",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    padding: "10px 20px"
                                  }}
                                >
                                  ✔
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  style={{
                                    background: "#ff601f",
                                    color: "white",
                                    borderRadius: "10px",
                                    padding: "10px 20px"
                                  }}
                                  onClick={() => handleAddDomain(item.siteUrl, index)}
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <p style={{ textAlign: "center", color: "#ff601f" }}>
                        You Have No Registered Domains
                      </p>
                    )}
                  </div>

                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: "30px" }}>
                <button type="button" onClick={handleClose1} style={{ background: "#ff601f", padding: "12px 25px", borderRadius: "10px", color: "white", border: "1px solid #ff601f" }}  >
                  Close
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions className="dialog-action-button">
        </DialogActions>
      </Dialog>

      <Dialog
        open={apiopenr}
        onClose={apihandleCloser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Add your Domain(s)"}</DialogTitle>
        <DialogContent>
          <div >
            <form className="frm_container">
              <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h3 style={{ textAlign: 'center' }}>Import from <br /> Google Analytics Console</h3>
                  <ul>
                    <li>Automatically Verify Domain Ownership</li>
                    <li>Can import multiple domains at once</li>
                  </ul>
                </div>

                <div className="vertical-line">
                  <span className="or-text">OR</span>
                </div>

                <div>
                  <h3 style={{ textAlign: 'center' }}>Add Domain Details <br /> Manually</h3>
                  <ul>
                    <li>Verify domain ownership by <br /> adding  a TXT record to the DNS settings</li>
                    <li>Register one domain at a time</li>
                  </ul>
                </div>
              </div>


              <Box sx={{ mt: 3, display: "flex", justifyContent: "space-around", alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => glogin()}
                  sx={{
                    padding: "10px 10px",
                    borderRadius: "10px",
                    
                    fontSize: "16px",
                    backgroundColor: "#ff601f",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7d45" },
                  }}
                >
                  Import
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openModal()}
                  sx={{
                    padding: "10px 10px",
                    borderRadius: "10px",
                    
                    fontSize: "16px",
                    backgroundColor: "#ff601f",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7d45" },
                  }}
                >
                  Add
                </Button>
              </Box>
            </form>
          </div>

        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button sx={{
            padding: "10px 10px",
            borderRadius: "10px",
            
            fontSize: "16px",
            backgroundColor: "#ff601f",
            color: "white",
            "&:hover": { backgroundColor: "#ff7d45" },
          }} onClick={apihandleCloser}>Close</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={apiopenrloc}
        onClose={apihandleCloserloc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Add Location Details"}</DialogTitle>
        <DialogContent>
          <div >
            <form className="frm_container">
              <div className="campaigns-addedit-row">
                <div className="keyword-col1-fields">
                  <div>
                    <label>
                      Search Engine :
                    </label>
                    <Select
                      name="engine"
                      value={SearchEngine}
                      options={Engineoptions}
                      onChange={(e) => { SetSearchEngine(e) }}
                      placeholder="Select Engine"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className=""
                      classNamePrefix="addkeyword"
                    />
                  </div>
                  <div>
                    <span className="error-value">{engineerr}</span>
                  </div>
                </div>
                <div className="keyword-col2-fields">
                  <div>
                    <label>
                      Country :
                    </label>
                    <Select
                      id="yourElementId"
                      name="country"
                      value={countryValue}
                      options={Countryoptions}
                      onChange={(e) => { handlestatedataoptions(e) }}
                      placeholder="Select Country"
                      isSearchable
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className=""
                      classNamePrefix="addkeyword"
                    />
                  </div>
                  <div>
                    {" "}
                    <span className="error-value">{countryerr}</span>
                  </div>
                </div>
              </div>
              <div className="campaigns-addedit-row">
                {(regionalLevel == 'State' || regionalLevel == 'City') &&
                  <div className="keyword-col1-fields">
                    <div>
                      <label>
                        State :
                      </label>
                      <Select
                        name="state"
                        value={stateValue}
                        options={Stateoptions}
                        onChange={handleCityoptionsdata}
                        placeholder="Select State (Optional)"
                        isSearchable
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    </div>
                    <div>
                      <span className="error-value">{stateerr}</span>
                    </div>
                  </div>
                }
                {regionalLevel == 'City' &&
                  <div className="keyword-col2-fields">
                    <div>
                      <label>
                        City :
                      </label>
                      <Select
                        name="city"
                        value={cityValue}
                        options={CityOptions}
                        onChange={setCurrentCityValue}
                        placeholder="Select City (Optional)"
                        isSearchable
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{cityerr}</span>
                    </div>
                  </div>
                }
              </div>
              <div style={{ textAlign: 'center' }}>
                <button
                  className="keyword-cancel"
                  type="button"
                  onClick={apihandleCloserloc}
                >
                  Close
                </button>
                <button
                  className="keyword-submit"
                  type="button"
                  onClick={finalreportNow}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

        </DialogContent>
      </Dialog>



    </div>


  );
}
