import React from 'react'

export default function Commoncard({ title, value, iconSrc, altText }) {
    return (
        <div className="common-card-wrapper-style cardactivecontainer">
            <div className="common-card-icon-container">
                <div className="common-card-icons">
                    <img src={iconSrc} className="common-card-icon-img" alt={altText} />
                </div>
            </div>
            <div className="common-card-subtitle cardcontentactivecontainer">
                <span className="common-card-title">{title}</span>
                <span className="common-card-value">{value}</span>
            </div>
        </div>
    )
}
