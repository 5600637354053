import React, { useState, useRef, useEffect, useContext } from 'react';
import "./login.css";
import Logo from '../NavBar/Asset/New_Assert/ESO_Logo.png';
import Eso_cnfrm_mail_img from './Asset/On_Board/confirmMail.gif';
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button"
import { AppContext } from "../../Context/Context";
import Userpermission from '../../Commonalert/Userpermission';
import { CommonErrorAlert } from '../../Commonalert/commonErrorAlert';
const Myaccount = () => {

  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  function testPasswordStrength(password) {
    return strongPasswordRegex.test(password);
  }


  let querystring = useParams();
  let uid = querystring.uid;
  let utoken = querystring.utoken;
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();


  const [userId, setUserId] = useState("");


  const apihandleClose = () => {
    setapiOpen(false);
  };
  const newpassref = useRef(null);
  const cnewpassref = useRef(null);
  const oldpassref = useRef(null);

  const [profileName, SetprofileName] = useState("");
  const [profileEmail, SetprofileEmail] = useState("");
  const [VerifyOtpEnable, SetVerifyOtpEnable] = useState(false)
  const [Otp, SetOtp] = useState('')
  const [OtpError, SetOtpError] = useState('')
  const [EmailError, SetEmailError] = useState('')


  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;




  const onEmailupdate = e => {
    SetprofileEmail(e.target.value)
  }
  const onNameupdate = e => {
    SetprofileName(e.target.value)
  }
  const onNamefunupdate = async () => {



    if (profileName !== '') {
      setLoading(true)

      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}accounts/update_user_names/`,
          {
            name: profileName
          },
          {
            headers: {
              Authorization: ApiToken,
            },
          }
        ).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            return false;
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        setLoading(false)
        if (Response?.status == 200) {
          setapiOpen(true);
          setapierrorMsg(Response?.data?.status)
        }

      } catch (err) {
        setLoading(false)
        console.log(err)
      }

    }


  }
  const onEmailfunupdate = async () => {
    SetEmailError('')
    setLoading(true)
    try {
      const Response = await axios.post(
        `${process.env.REACT_APP_API_URL}accounts/update_user_email_send_otp/`,
        {
          new_email: profileEmail
        },
        {
          headers: {
            Authorization: ApiToken,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (Response?.data) {


        if (Response?.data?.status_code === 200) {
          setLoading(false)
          SetVerifyOtpEnable(true)
        }
      }

    } catch (err) {
      setLoading(false)
      SetEmailError(err.response.data.error)
      console.log(err)
    }
  }

  const GetProfiledetails = async () => {

    try {

      const Response = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,

        {
          headers: {
            Authorization: ApiToken,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      if (Response?.data) {
        if (Response?.data?.name) {

          SetprofileName(Response.data.name)
        }
        if (Response.data.email) {
          SetprofileEmail(Response.data.email)
        }

        if (Response.data.user_id) {
          setUserId(Response.data.user_id);
        }
      }

    } catch (err) {
      console.log(err)
    }







  }


  useEffect(() => {
    GetProfiledetails()
  }, []);

  const onOtpupdate = (e) => {
    SetOtp(e.target.value)

  }

  const VerifyOtp = async () => {

    SetOtpError('')
    setLoading(true)
    try {

      const Response = await axios.post(
        `${process.env.REACT_APP_API_URL}accounts/update_user_email_verify_otp/`,
        {
          otp: Otp
        },
        {
          headers: {
            Authorization: ApiToken,
          },
        }
      ).catch((error) => {
        setLoading(false)

        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      if (Response?.data) {

        if (Response.data.status_code === 200) {
          setLoading(false)
          SetVerifyOtpEnable(false)
        }

      }

    } catch (err) {
      setLoading(false)
      SetOtpError(err.response.data.error)
      console.log(err)
    }



  }


  return (
    <div>
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Account</title>
        </Helmet>
        {loading === true ? <Loader /> : null}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="outer-container"
          style={{
            width: "calc(100% - 60px)",
            marginRight: "60px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="rest_scs_outer_container confirm_sec_outer_container ">
            <div className="rest_scs_ins_container">
              <div className="Eso_cnfrm_mail_outer_wrap">
                <img className="Eso_logo" src={Logo} alt="logo" />
                <img className="Eso_rst_img" src={Eso_cnfrm_mail_img} alt="animation gif" />
              </div>
              <h1 className="scs_tt">My Profile</h1>

              {
                VerifyOtpEnable === false ? <div className="rest_inst_wrap">
                  <p>Profile Details</p>

                  <div class="form-group email_grp change_password_eye" style={{ marginBottom: '30px' }}>
                    <label className='profile_update_label'>Name:</label>
                    <input type="text" id="otppas" name="oldpass" defaultValue={profileName} value={profileName} onChange={onNameupdate} />
                    <button className='profile_update_button' onClick={onNamefunupdate}>Update Name</button>
                  </div>
                  <div class="form-group email_grp change_password_eye" style={{ marginBottom: '30px', paddingBottom: "40px" }}>
                    <label className='profile_update_label'>Email:</label>
                    <input type="text" id="otppas" name="newpass" defaultValue={profileEmail} onChange={onEmailupdate} value={profileEmail} />

                    <button className='profile_update_button' onClick={onEmailfunupdate}>Update Email</button>
                    <div className="frm_error">{EmailError}</div>
                  </div>

                </div> : null
              }



              {
                VerifyOtpEnable === true ? <div className="rest_inst_wrap profile_email_verify_outer_wrap">
                  <p>Please verify OTP sent to your email address to update</p>

                  <div class="form-group email_grp change_password_eye" style={{ marginBottom: '30px' }}>

                    <input type="text" id="otppas" name="oldpass" onChange={onOtpupdate} />

                    <div className="frm_error">{OtpError}</div>
                  </div>

                  <button className='profile_update_button' onClick={VerifyOtp}>Verify OTP</button>
                  <button className='profile_update_button' onClick={() => { SetVerifyOtpEnable(false) }}>Cancel</button>


                </div> : null
              }


              {
                userId && <Userpermission userId={userId} />
              }


              <div className='cnfrm'>

              </div>
            </div>

          </div>
        </div>
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />

      </div>

    </div>

  );
}
export default Myaccount;