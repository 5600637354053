import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Loader from "./../../CommonLoader";
import DatagridComp from "../../DatagridComp";
import { CommonErrorAlert } from "../../../../Commonalert/commonErrorAlert";

export default function Allpagecrawl(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = React.useState(false)
  const [autoheightval, SetAutoheightval] = useState(true)
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value

  useEffect(() => {
    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify)
    callUserdataApi();
  }, []);

  useEffect(() => {


    if (props.SubmitTriggered === true) {
      callUserdataApi()
    }

  }, [props.SubmitTriggered])
  useEffect(() => {
    callUserdataApi()

  }, [props.domainsection])
  const columns =  [


    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "page_url",
      headerName: "Page URL",
      description: 'URL of the web page',
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        let baseUrl = cardData.row.page_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.page_url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.page_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.page_url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>

            </Tooltip>
          </>
        );

      },

    },
    {
      field: "page_title",
      headerName: "Page Title",
      description: 'Page title for the web page.',
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    {
      field: "canonical_url",
      headerName: "Canonical URL",
      description: 'A mismatch in the Canonical URL for the web page.',
      minWidth: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        let baseUrl = cardData.row.canonical_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.canonical_url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.canonical_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.canonical_url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>

            </Tooltip>
          </>
        );

      },


    },
    {
      field: "meta_description",
      headerName: "Meta Description",
      description: 'A meta description is a short description of two sentences that describe the page appearing below the URL in a search engine. It displays the meta description for the web page.',
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      whiteSpace: "wrap",
    },
  ];
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {

    setLoading(true)
    setCardData([])
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${props.domainsection}&job_id=${props.JobId}&field_type=all`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });


    if (datavalue?.data && datavalue?.data?.table?.all_data.length > 0) {
      const dataval = datavalue?.data?.table?.all_data
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }
    setLoading(false)
    props.SetSubmitTriggered(false)

  };

  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        marginRight: "40px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
        {loading === true ? <Loader /> : null}
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />
        {!openCommonAlerts &&



          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: false
            }}
            closeAlert={apihandleClose} />
        }
      </div>
    </div>
  );
}
