import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import "./searchkeyvolumestyle.css";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from 'react-select';
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import Freeaudit from "../../../Utils/Freeaudit";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { timezonechange } from '../Commonfun';
import DatagridComp from "../DatagridComp";
import NewLoader from "../NewLoader";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Keywordsuggestion(props) {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [locationval, setlocationval] = useState("");
  const [campaignval, setcampaignval] = useState([]);
  const [sectionval, setsectionval] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [successapiopen, setsuccessapiopen] = useState(false);

  const [apierrorMsg, setapierrorMsg] = useState();
  const [apisuccessMsg, setapisuccessMsg] = useState();

  const [selectsearchval, setSelectsearchval] = useState([]);
  const [countryval, setCountryval] = useState("");
  const [selectcountryval, setSelectcountryval] = useState([]);
  const [stateval, setStateval] = useState([]);
  const [selectstateval, setSelectstateval] = useState([]);
  const [cityval, setCityval] = useState([]);
  const [selectcityval, setSelectcityval] = useState([]);
  const [searchcarddata, setSearchcarddata] = useState([]);
  const [domaindata, setDomaindata] = useState("");
  const [locationvaldata, setlocationvaldata] = useState("");
  const [seedvaldata, setseedvaldata] = useState("");
  const [keywordsuggest, setkeywordsuggest] = useState("");
  const [seedErr, SetseedErr] = useState("");
  const [locationErr, SetlocationErr] = useState("");
  const [countrieName, setCountrieName] = useState("");
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [autoheightval, SetAutoheightval] = useState(true)
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );


  const handleClose = () => {
    setsearchopen(false)
    setOpen(false);
  };
  function openModal() {
    setfaqData(2)
    setToggle(false)
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
    props.setCheckFilter(false);
  }

  const AnalyzeReport = async () => {

    setLoading(true);
    SetErrorMsg("");
    SetseedErr("")
    SetlocationErr("")

    const domainRegex = /^[a-zA-Z0-9._%+-]+@example\.com$/;
    var setmsg = 1;

    if (domaindata == "") {
      SetErrorMsg("Please Enter Business Specialize");
      setLoading(false);
      setmsg = 2;
    }
    if (seedvaldata === "") {
      SetseedErr("Please Enter Target Keyword");
      setLoading(false);
      setmsg = 2;
    }
    if (locationvaldata === "") {
      SetlocationErr("Please Enter The Campaign");
      setLoading(false);
      setmsg = 2;
    }
    if (setmsg == 1) {
      SetErrorMsg("");
      SetErrorMsg("");
      SetseedErr("")
      SetlocationErr("")
      setLoading(false);
      calltabledataApi(domaindata, seedvaldata, locationvaldata);
    }
  };
  const calltabledataApi = async (domaindata = "", seedvaldata = "", locationvaldata = "") => {
    setLoading(true);
    try {

      const datavalue = await axios.post(
        `${process.env.REACT_APP_API_URL}keywords/keyword_suggestions_summary/`,
        {
          user_domain_id: esodata.domaindata.value.header_selected,
          campaign_id: locationvaldata,
          buisness_specialization: domaindata,
          target_audience: seedvaldata
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }

      });
      if (datavalue?.status == 200) {
        SetErrorMsg("");
        SetseedErr("")
        SetlocationErr("")
        setDomaindata("")
        setseedvaldata("")
        setlocationvaldata("")
        setIsOpen(false)
        props.setCheckFilter(false);
        setsuccessapiopen(true);
        setapisuccessMsg(datavalue?.data?.error)

      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false);

  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
    keywordfilterdata();
    getCountry();
  }, []);
  let detailurl = `${process.env.REACT_APP_DOMAIN}keyword-suggestion-detail/`;

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      description: 'S.No',
      minWidth: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: true,
    },
    {
      field: "date_of_request",
      headerName: "Date of Request",
      description: "The day on which a request is initiated",
      minWidth: 280,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (date_of_request) => {
        return timezonechange(date_of_request.row.date_of_request)
      },
    },
    {
      field: "buisness_specialization",
      headerName: "Business Specialization",
      description: 'The niche or focus of your business, such as products or services.',
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "target_audience",
      headerName: "Target Audience",
      description: 'The group of people your business aims to reach, based on demographics and interests.',
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "view",
      headerName: "View",
      description: "This leads to the detailed report",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (keywordsuggest) => {
        return (
          <a href={detailurl + `${keywordsuggest?.row?.id}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        )
      },
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const successapihandleClose = () => {
    setsuccessapiopen(false);
    callUserdataApi();
  };
  const rows = keywordsuggest ?? [];
  const callUserdataApi = async (domainterm = "", locationval = "") => {
    setLoading(true);
    try {
      const locationdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/keyword_suggestions_summary/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }

      });

      if (locationdatavalue?.data && locationdatavalue?.data.length > 0) {
        const newData = locationdatavalue?.data.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setkeywordsuggest(newData)
        SetAutoheightval(false)
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  const keywordfilterdata = async () => {
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const campaigndataval = campaigndatavalue?.data?.data.map(element => ({
        label: element.campaign_id,
        value: element.campaign_name
      }));
      setsectionval(campaigndataval);

    } catch (err) {
      console.log(err)
    }
  }
  const campaignreportNow = async () => {
    setsearchselectedRows(selectedRows)
    setfaqData(2)
    setToggle(false)
    if (selectedRows.length > 0) setIsOpen(true);
    else setsearchopen(true);
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setSearchcarddata([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        setSearchcarddata(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  function closeModal() {
    setSelectcamptval("")
    setSelectsectionval("")
    setSelectsearchval("")
    setCountryval([])
    setStateval([])
    setCityval([])
    setValidation("")
    setIsOpen(false);
    props.setCheckFilter(false);
  }


  const getCountry = async () => {
    try {

      const locationdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }

      });
      const countrydataval = locationdatavalue?.data.map(element => ({
        label: element.campaign_name,
        value: element.campaign_id
      }));
      setCountrieName(countrydataval)


    } catch (error) {

    }
  }


  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=61`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  


  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (props.checkfilter) {
      openModal();
    }
  }, [props])

  return (
    <div className={`over_all_container search_prnt ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyword Suggestion List</title>
      </Helmet>
      {
        loading === true ? <NewLoader /> : null
      }

      <div className="cnt_audit_outer_wrap">
        <div className=" search_key_wrap searchvolume_key_wrap">
          {props.Auditstatus.status &&
            <div className="search_volumn_data">

              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
            </div>
          }

          {!props.Auditstatus.status &&
            <Freeaudit planStatus={props.Auditstatus} />
          }
          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ alignItems: 'flex-start' }}
          >
            <DialogTitle className="keyword-dialog-header">Generate SEO Keywords </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="">
                  <div className="row">
                    <div className="col">
                      <span>Business Specialization:</span>
                      <Tooltip
                        className="moda_pop_search_volume_svg"
                        title="The niche or focus of your business (example: products or services offered)"
                        arrow
                      >
                        <HelpIcon style={{ width: "0.5em" }} />
                      </Tooltip>
                      <textarea
                        style={{ height: "100px", borderRadius: "5px", width: "260px", border: "1px solid #ff601f" }}
                        type="text"
                        className="input_domain_field"
                        placeholder="Business Info: What does your business specialize in?"
                        onChange={(e) => {
                          setDomaindata(e.target.value);
                        }}
                        title="Business Info: What does your business specialize in?"
                      />
                      {ErrorMsg && <span class="error-value">{ErrorMsg}</span>}
                    </div>
                    <div className="col">
                      <span> Target Audience:</span>
                      <Tooltip
                        className="moda_pop_search_volume_svg"
                        title="The group of people your business aims to reach, based on demographics and interests."
                        arrow
                      >
                        <HelpIcon style={{ width: "0.5em" }} />
                      </Tooltip>
                      <textarea
                        style={{ height: "100px", borderRadius: "5px", width: "260px", border: "1px solid #ff601f" }}
                        type="text"
                        className="input_domain_field"
                        placeholder="Who is your Target Audience?"
                        onChange={(e) => {
                          setseedvaldata(e.target.value);
                        }}
                        title="Who is your Target Audience?"
                      />
                      {seedErr && <span class="error-value">{seedErr}</span>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span>Campaign </span>
                      <Tooltip
                        className="moda_pop_search_volume_svg"
                        title="Campaigns that have been added to your domain"
                        arrow
                      >
                        <HelpIcon style={{ width: "0.5em" }} />
                      </Tooltip>
                      <Select
                        options={countrieName}
                        labelField="name"
                        valueField="id"
                        onChange={(e) => setlocationvaldata(e.value)}
                        placeholder="Campaign"
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                      <span>Note:Location details of your business will be pulled from the selected Campaign</span>
                      {locationErr && <span class="error-value">{locationErr}</span>}
                    </div>
                    <div className="col">

                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <div className="submitbutton">
                <button className="keyword-cancel" style={{ marginLeft: '20px' }} onClick={closeModal}>
                  Close
                </button>
                <button className="keyword-submit" onClick={AnalyzeReport}>
                  Submit
                </button>

              </div>
            </DialogActions>
          </Dialog>

          <CommonErrorAlert
            data={{
              apiopen: open,
              apiErrorMsg: 'A new keyword has been added to the Targeted Keywords',
              isIconStyled: false
            }}
            closeAlert={handleClose} />
          <CommonErrorAlert
            data={{
              apiopen: searchopen,
              apiErrorMsg: 'Please Select Data !',
              isIconStyled: true
            }}
            closeAlert={handleClose} />
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
          <CommonErrorAlert
            data={{
              apiopen: successapiopen,
              apiErrorMsg: apisuccessMsg,
              isIconStyled: false
            }}
            closeAlert={successapihandleClose} />
        </div>
      </div>

    </div>
  );
}