import React, { useEffect, useState ,createContext,useContext} from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ESOLOGONEW from '../Asset/New_Assert/ESO_Logo.png'
import { useNavigate } from "react-router-dom";
import { SetUserFirstTimeLogin } from "../../../Store/FirstTimeLoginSlice";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import DetailPlan from "./DetailPlan";
export default function Plans() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const UserSubscriptionIsExpired =
    esodata.usersubscription.value.eso_user_subscription;
  const UserFirstTimeLogin=   esodata.firsttimelogin.value.eso_user_first_time_login;
  const [PaypalClient, SetPaypalClient] = useState("");

  const [buttnval, Setbuttnval] = useState("1");
  const menuChange = (e) => {
    e.preventDefault();
    Setbuttnval(e.target.id);
  };

  const [MonthlyPlanList, SetMonthlyPlanList] = useState([]);
  const [YearlyPlanList, SetYearlyPlanList] = useState([]);


  const PlanContext = createContext();

  const GetPlanListing = async () => {
    Setloading(true)
    SetMonthlyPlanList([]);
    SetYearlyPlanList([]);
    try {
      const Response = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_subscription_plan_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      Setloading(false)
      if (Response.data) {
        if (Response.data.client_id) {
          SetPaypalClient(Response.data.client_id);
        }

        if (Response.data.plans) {
          if (Response.data.plans["Monthly Renewal"]) {
            SetMonthlyPlanList(Response.data.plans["Monthly Renewal"]);
          }
          if (Response.data.plans["Yearly Renewal"]) {
            SetYearlyPlanList(Response.data.plans["Yearly Renewal"]);
          }
        }
      }
    } catch (err) {
      Setloading(false)
      console.log(err);
    }
  };

  const [DetailPageData, SetDetailPageData] = useState([]);
  const [DetailTargetSes, SetDetailTargetSes] = useState("");

  const ChoosePlan = (e) => {
    const PaypalProductId = e.target.getAttribute(
      "data-paypal_product__product_id"
    );

    const SubscriptionPlanId = e.target.getAttribute(
      "data-subscription_plan_id"
    );
    const TargetSes = e.target.getAttribute("data-ses");
    SetDetailTargetSes(TargetSes);
    var FoundObj = [];
    if (TargetSes === "monthly") {
      FoundObj[0] = MonthlyPlanList.find(
        (obj) => obj["subscription_plan_id"] === SubscriptionPlanId
      );
    } else if (TargetSes === "yearly") {
      FoundObj[0] = YearlyPlanList.find(
        (obj) => obj["subscription_plan_id"] === SubscriptionPlanId
      );
    }

    SetDetailPageData(FoundObj);
    SetShowDetailTab(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    GetPlanListing();
    GetCurrentPlan();
  }, []);

  const GetCurrentPlan = async () => {
    Setloading(true)
    try {
      const Response = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_subscription/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      Setloading(false)

      if (Response.data) {
        SetCurrentPlanDetails(
          Response.data.user_subscription_data.subscription_plan_id
        );
    
        if (
          Response.data.user_subscription_data.subscription_plan_id === "free"
        ) {
          SetShowFreeCard(true);
          SetPlanCard(false)
        } else {

          SetShowFreeCard(false);
          SetCurrentPlanName(Response.data.user_subscription_data.subscription__name+'/'+Response.data.user_subscription_data.subscription__type)
          SetCurrentPlanDetailsType(Response.data.user_subscription_data.subscription__type)
          SetPlanCard(true)

         
        }
      }

    } catch (err) {
      Setloading(false)
      console.log(err);
    }
  };

  const [ShowDetailTab, SetShowDetailTab] = useState(false);
  const [CurrentPlanDetails, SetCurrentPlanDetails] = useState("");
  const [CurrentPlanDetailsType,SetCurrentPlanDetailsType]=useState("");
  const [loading, Setloading] = useState(false);
  const CheckRenderButton = (item, plantype) => {
    if (CurrentPlanDetails !== item.subscription_plan_id) {
      return (
        <Link to={`/plan-details/${item.subscription_plan_id}`}>
        <button type="button" className="site_rn_rpt_btn" style={{color:"#000000"}}>View Details</button>
      </Link>
      );
    } else {
      if (
        UserSubscriptionIsExpired == "false" ||
        UserSubscriptionIsExpired == false   || !UserSubscriptionIsExpired
      ) {

        return (
          <Link to={`/plan-details/${item.subscription_plan_id}`}>
          <button type="button" className="site_rn_rpt_btn" style={{color:"#000000"}}>Your Active Plan</button>
        </Link>
        );
      } else if (
        UserSubscriptionIsExpired == "true" ||
        UserSubscriptionIsExpired == true  
      ) {
        return (
          <button
            className="choose_button"
            data-paypal_product__product_id={item.paypal_product__product_id}
            data-subscription_plan_id={item.subscription_plan_id}
            onClick={(e) => {
              ChoosePlan(e);
            }}
            data-ses={plantype}
          >
            Choose
          </button>
        );
      }
    }
  };

  const [ShowFreeCard, SetShowFreeCard] = useState(false);
  const [ShowPlanCard, SetPlanCard] = useState(false);
  const [ShowInactiveCard,SetShowInactiveCard]=useState(UserSubscriptionIsExpired);
  const [UserFirstTimeLog,SetUserFirstTimeLog]=useState(UserFirstTimeLogin)
  const [CurrentPlanName,SetCurrentPlanName]=useState('')
  const ViewFreePlanDetails = async () => {
    SetDetailPageData("free");

    SetShowDetailTab(true);
    window.scrollTo(0, 0);
  };

  const SkipThepayment=async()=>{

    try{

      const Response = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/remove_first_time_login/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if(Response.data)
      {
        dispatch(SetUserFirstTimeLogin({eso_user_first_time_login:false}))
        window.location.href= `${process.env.REACT_APP_DOMAIN }`
      }



   

    }catch(err)
    {
      console.log(err)
    }

 

  }
  const ReturnClassList=(item)=>{



    if(item.subscription_plan_id==CurrentPlanDetails)
    {
      return 'lists active'
    }
    else{
      return 'lists'
    }

 

  }

  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription</title>
            </Helmet>
      {loading === true ? <Loader /> : null}
      {ShowDetailTab === false ? (
        <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

          {
           ((ShowInactiveCard==true || ShowInactiveCard=='true') || (UserFirstTimeLog==true || UserFirstTimeLog=='true')) ?    <div className="plan_eso_logo_wrap">
           <div className="plan_eso_logo_row">
           <img src={ESOLOGONEW}/>
           </div>
  
         </div> : null
          }
        
       
          <div className="subscription_wrap sup-plan">
            <div className="pricing_heading">
              <span>Pricing</span>
            </div>

            {ShowFreeCard === true ? (
              <div className="free_plan_wrap">
                <div className="free_plan_info">
                  <div>
                    <div className="info">You are currently on a free plan</div>
                    <div className="view_button">
                    </div>
                  </div>
                </div>
                <div
                  className="close_free"
                  onClick={() => {
                    SetShowFreeCard(false);
                  }}
                >
                  <HighlightOffIcon />
                </div>
              </div>
            ) : null}

               {ShowPlanCard === true ? (
              <div className="free_plan_wrap">
                <div className="free_plan_info">
                  <div>
                    <div className="info">Your current plan - {CurrentPlanName}</div>
                    <div className="view_button">
                    </div>
                  </div>
                </div>
                <div
                  className="close_free"
                  onClick={() => {
                    SetPlanCard(false);
                  }}
                >
                  <HighlightOffIcon />
                </div>
              </div>
            ) : null}

            {
              (ShowInactiveCard==true || ShowInactiveCard=='true' ) ?   <div className="free_plan_wrap">
              <div className="free_plan_info inactive_card_info">
                <div>
                <div className="view_button">
                  
                  <ErrorOutlineIcon/>
                  </div>
                  <div className="info">You dont have any active plan. Please subscribe to access the features</div>
                 
                </div>
              </div>
              <div
                className="close_free"
                onClick={() => {
                  SetShowInactiveCard(false);
                }}
              >
                <HighlightOffIcon />
              </div>
            </div> : null
            }

            <div className="plan_description">
              <p>Get Started Now, Pick a Plan Later</p>
            </div>
            <div className="plan_switch_button">
              <div className="row">
                <div
                  id={1}
                  className={`columns ${buttnval === "1" ? "active" : ""}`}
                  onClick={(e) => menuChange(e)}
                >
                  Monthly
                </div>
                <div
                  id={2}
                  className={`columns ${buttnval === "2" ? "active" : ""}`}
                  onClick={(e) => menuChange(e)}
                >
                  Yearly
                </div>
              </div>
            </div>

            {buttnval === "1" ? (
              <div className="plans_list">
                <div class="container">
                  {MonthlyPlanList.map((item) => (
                   
                    <div className={ReturnClassList(item)}>
                      <div className="first_row">
                        <div className="plan_head">
                          <p class="type">{item.subscription_name}</p>
                          <p>{item.name}</p>
                        </div>

                        {item.price_unit == "USD" ? (
                          <p class="price">
                            {"$" + item.price}
                            <span className="period">/month</span>
                          </p>
                        ) : null}
                        <div className="plan_ul_list_wrap">
                        <p>{item.description}</p>
                        </div>
                      </div>

                      <div className="choose_button_wrap">
                        {CheckRenderButton(item, "monthly")}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {buttnval === "2" ? (
              <div className="plans_list">
                <div class="container">
                  {YearlyPlanList.map((item) => (
                    <div className={ReturnClassList(item)}>
                      <div className="first_row">
                        <div className="plan_head">
                          <p class="type">{item.subscription_name}</p>
                          <p>{item.name}</p>
                        </div>

                        {item.price_unit == "USD" ? (
                          <p class="price">
                            {"$" + item.price}
                            <span className="period">/year</span>
                          </p>
                        ) : null}
                      <div className="plan_ul_list_wrap">
                      <p>{item.description}</p>
                        </div>
                      </div>

                      <div className="choose_button_wrap">
                        {CheckRenderButton(item, "yearly")}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          {
            (UserFirstTimeLog==true || UserFirstTimeLog=='true') ? <div className="skip_button_payment_wrap">
            <button onClick={SkipThepayment}>Skip</button>
          </div> : null
          }

         
        </div>
      ) : null}

   

      {
        (ShowDetailTab===true && ((ShowInactiveCard==true || ShowInactiveCard=='true') || (UserFirstTimeLog==true || UserFirstTimeLog=='true')) ) ?  
        <div className="plan_eso_logo_wrap">
           <div className="plan_eso_logo_row">
           <img src={ESOLOGONEW}/>
           </div>
  
         </div> : null
      }

        
      {ShowDetailTab === true ? (



        <DetailPlan
          DetailPageData={DetailPageData}
          SetDetailPageData={SetDetailPageData}
          SetShowDetailTab={SetShowDetailTab}
          Setloading={Setloading}
          GetCurrentPlan={GetCurrentPlan}
          GetPlanListing={GetPlanListing}
          DetailTargetSes={DetailTargetSes}
          PaypalClient={PaypalClient}
          CurrentPlanDetails={CurrentPlanDetails}
        />
      ) : null}
    </>
  );
}
