import { IoMdCloseCircle } from 'react-icons/io'
import Faq from 'react-faq-component';
import { width } from '@mui/system';
import React, { useState, useEffect, useRef } from "react";


const Rightsidebar = (props) => {
  const [keywordtest, setKeywordtest] = useState([])
  const [showFaq, setShowFaq] = useState(true);

  const arrayData = props.initialfaqDateval ? props.initialfaqDateval : [];


  useEffect(() => {
    setKeywordtest(arrayData)

  }, []);

  const escapeHtml = (str) => {
    return str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const data = {
    title: "FAQ",
    rows: arrayData.map((item) => ({
      ...item,
      content: escapeHtml(item.content),
    }))
  };


  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {

    console.log(event.target.classList[0])

    if (event.target.classList[0] !== 'MuiSvgIcon-root' && event.target.classList[0] !== 'faq_class' && event.target.classList[0] !== 'arrow-image' && event.target.classList[0] !== 'row-title-text' && event.target.classList[0] !== 'styles_row-content-text__2sgAB' && event.target.classList[0] !== 'row-title' && event.target.classList[0] !== 'faq-title' && event.target.classList[0] !== 'sidebar' && event.target.classList[0] !== undefined && event.target.classList[0] !== 'faq-row-wrapper') {
      setShowFaq(false)
      props.setToggle(false)
    }
  });


  if (!showFaq) {
    return null;
  }
  const styles = {
    titleTextColor: "#103658",
    rowTitleColor: "#103658",
    arrowColor: "red",
    width: "30px",
  };
  return <aside className='sidebar' >
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '30px' }}>
      <IoMdCloseCircle
        style={{ cursor: 'pointer', color: 'black', position: 'absolute', fontSize: '24px', left: "60px" }}
        onClick={() => setShowFaq(false)}
      />
    </div>

    <div >
      <Faq data={data}
        styles={styles} />
      {data.rows.length === 0 && <p style={{ textAlign: 'center', color: '#ff601f' }} >No FAQs available</p>}
    </div>

  </aside>
}

export default Rightsidebar;