import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./contentStyle.css";
import pdficon from "./../Asset/PDF.png";
import passschema from "./../Asset/passschema.png";
import acceptschema from "./../Asset/acceptschema.png";
import failedschema from "./../Asset/failedschema.png";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import CommonAlert from "../../../Commonalert/commonalert";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function ContentauditDetail() {
  const [cardData, setCardData] = useState([]);
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value

  let querystring = useParams();
  let cont_audit_id = querystring.id;
  let job_audit_id = querystring.jobid;


  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/analysis_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${cont_audit_id}&job_id=${job_audit_id}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    const dataval = datavalue?.data;
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

    setCardData(dataval);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const pdfdown = async () => {
    setIsDownloading(true);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/analysis_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${cont_audit_id}&job_id=${job_audit_id}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AnalysisSummaryDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setIsDownloading(false);
    setLoading(false);

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=11`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Analysis Summary Detail</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap ContentAuditWrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Content</Typography>
            <Link to="/analysis-summary">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Analysis Summary</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Analysis Summary Detail</Typography>

          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

            <>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>


        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div >

              <h2 className="heading_font">Analysis Summary Detail Report </h2>
              <a target="_blank" href={cardData?.url} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="spanlink" style={{ flexDirection: "column" }}>
                  {cardData?.url}
                </span>
              </a>

              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>

            </div>
          </div>
        </div>
        <div className="cnt_hdr_content_table">
          <div className="box_wrap">
            <div className="hor_line">
              <h3>Passed</h3>
            </div>
            <table className="table">
              <col style={{ width: "5%" }} />
              <col style={{ width: "35%" }} />
              <col style={{ width: "50%" }} />
              <col style={{ width: "20%" }} />
              <tbody>
                {cardData?.passed_audit?.map((row, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row?.description}</td>
                    <td dangerouslySetInnerHTML={{ __html: row?.result }}></td>
                    <td>
                      <img src={passschema} alt="pass" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cnt_hdr_content_table">
          <div className="box_wrap">
            <div className="hor_line">
              <h3>Acceptable</h3>
            </div>
            <table className="table">
              <col style={{ width: "5%" }} />
              <col style={{ width: "35%" }} />
              <col style={{ width: "50%" }} />
              <col style={{ width: "20%" }} />
              <tbody>
                {cardData?.okay_audit?.map((row, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row.description}</td>
                    <td dangerouslySetInnerHTML={{ __html: row.result }}></td>
                    <td>
                      <img src={acceptschema} alt="accept" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cnt_hdr_content_table">
          <div className="box_wrap">
            <div className="hor_line">
              <h3>Failed</h3>
            </div>
            <table className="table">
              <col style={{ width: "5%" }} />
              <col style={{ width: "35%" }} />
              <col style={{ width: "50%" }} />
              <col style={{ width: "20%" }} />
              <tbody>
                {cardData?.failed_audit?.map((row, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{row.description}</td>
                    <td dangerouslySetInnerHTML={{ __html: row?.result }}></td>
                    <td>
                      <img src={failedschema} alt="failed" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </div>
  );
}