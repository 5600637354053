import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-dropdown-select";
import { AppContext } from "../../../Context/Context";


function Addcampaigndata (){
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)  
  const [websiteName,SetWebsiteName] =useState("")
  const [webLink,SetwebLink] =useState("")
  const [onUsername,SetonUsername] =useState("")
  const [onPassword,SetonPassword] =useState("")
  const [webnameError,SetWebnameError]=useState("")
  const [weblinkError,SetweblinkError]=useState("")
  const [onUserError,SetonUserError]=useState("")
  const [onPasswordError,SetonPasswordError]=useState("")
  const [onPageError,SetonPageError]=useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);



  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const navigate = useNavigate();

  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value==="Yes"?setShowResults(true):setShowResults(false)
    e.target.value==="No"&&SetonUsername("");  SetonPassword("");
  }
  const onwebName = e =>{
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e =>{
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e =>{
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e =>{
    SetonPassword(e.target.value)
  }
  document.body.addEventListener("click", function (event) {
    if (event.target.classList.contains("brandedtermremove")) {
      var brandedTerm = event.target.closest(".branded_term");
      if (brandedTerm) {
        brandedTerm.remove();
      }
    }
  });
  const finalreportNow = async () =>{
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetonUserError("")
    SetonPasswordError("")
    setLoading(true);
    var data_val=1;
    if(websiteName===""){
      SetWebnameError("Enter Campaign Name")
      data_val=2;
    }
    if(data_val===2){
    setLoading(false);
      return false;
    }
    else{
      try{
    setLoading(true);
        const response=await axios.post(
       `${process.env.REACT_APP_API_URL}domain/add_domain/`,
         {
          domain_name:   websiteName,
         },
          {
                headers: {
                  Authorization: `${ApiToken}`,
                },
              }       
     )
     if(response.data.status_code==200)
     {
      dispatch(SetDomainHeader({header_selected:response.data.user_domain_id}))
       window.location.href=`${process.env.REACT_APP_DOMAIN}domain-list`
     }
   }catch(err){
    setLoading(false);
    SetonPageError(err.response.data.error)
   }
    }

  }

  const IncreseBrandIndex = () => {
    const node = document.getElementsByClassName("branded_term")[0];
    var clone = node.cloneNode(true);

    var clonedInputs = clone.querySelectorAll("input");

    var clonedXButton = clone.querySelectorAll(".IncreseBrandIndexClone");

    clonedXButton.forEach(function (button) {
      button.innerHTML = "";
      var xIcon = document.createElement("i");
      xIcon.className = "fa fa-times brandedtermremove";
      button.appendChild(xIcon);
    });

    var parentDiv = document.getElementById("branded_term_clone");
    var elements = parentDiv.querySelectorAll("*");
    var numElements = elements.length;

    clonedInputs.forEach(function (input, index) {
      if (input.type === "radio") {
        input.setAttribute("name", "brand_match[" + (numElements + 1) + "]");
      }
      if (input.type === "text") {
        input.setAttribute(
          "name",
          "brand_match_text_" + (numElements + 1) + ""
        );
      }
    });

    document.getElementById("branded_term_clone").appendChild(clone);
  };

  
  const [campaigndata,Setcampaigndata]=useState([])
  const [SelectCampaign,setSelectCampaign]=useState([])
  const GetCampaignData=async()=>{

    Setcampaigndata([])

    try{
      const response=await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        
           {
                 headers: {
                   Authorization: `${ApiToken}`,
                 },
               }       
      )
      if(response.data)
      {
        Setcampaigndata (response.data)
      }


    }catch(err)
    {
      console.log(err)
    }

 

  }

  const CancelBrand=()=>{
    navigate('/keywords')
  }

  const SaveBrand=async()=>{


     var BrandingTermNameArray = [];
     var BrandingTermMatchArray = [];
     var BrandingMergeArray = [];
 
     var examples = document.querySelectorAll(".branded_term");
 
     examples.forEach(function (example) {
       var textInputs = example.querySelectorAll('input[type="text"]');
 
       for (var i = 0; i < textInputs.length; i++) {
         BrandingTermNameArray.push(textInputs[i].value);
 
       }
     });
 
     var examples = document.querySelectorAll(".branded_term");
 
     examples.forEach(function (example) {
       var radioInputs = example.querySelectorAll('input[type="radio"]');
       radioInputs.forEach(function (input) {
         if (input.checked) {
           BrandingTermMatchArray.push(input.value);
 
         }
       });
     });
 
     if (BrandingTermNameArray.length === BrandingTermMatchArray.length) {
       BrandingTermNameArray.forEach((element, index) => {
         BrandingMergeArray.push({
           term: BrandingTermNameArray[index],
           match: BrandingTermMatchArray[index],
         });
       });
     }

  
    try{

      const response=await axios.post(
        `${process.env.REACT_APP_API_URL}keywords/add_brandterm/`,
        {
          user_domain_id:`${esodata.domaindata.value.header_selected}`,
          campaign_id:SelectCampaign[0].campaign_id,
          brand_terms:BrandingMergeArray
      },
          {
                headers: {
                  Authorization: `${ApiToken}`,
                },
          }       
      )

    }catch(err)
    {
      console.log(err)
    }



  }

  useEffect(()=>{
    GetCampaignData()
  },[])
    return (

      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
     
        <div className="userdatas_inner">
        <p>Brands  </p>  

        <div>
        <div> 
          <label className="form-label">Campaign:</label>
          <Select
          options={campaigndata}
                    labelField="campaign_name"
                    valueField="campaign_id"
                    onChange={(values) => setSelectCampaign(values)}
           />
        </div>
        </div>  
            
        <div className="form-inner_brands">
    
        <div className="branded_term">
        <div className="row addbranded_terms">
          <div className="colhead brandhead">
            <span className="col12">Branded Term :</span>
            <div className="col21 col12">
              <input
                name="brand_match_text_0"
                className="form-control"
                type="text"
              />
            </div>
          </div>

          <div className="colhead inputhead">
            <input type="radio" name="brand_match[]" value={"phrase"} />
            Phrase Match
            <input type="radio" name="brand_match[]" value={"exact"} />
            Exact Match
            <span
              className="IncreseBrandIndexClone"
              onClick={IncreseBrandIndex}
            >
              <AddIcon />
            </span>
          </div>
        </div>
      </div>
      <div id="branded_term_clone"></div>

      <div>Error Message will be shown here</div>
      <div className="form_button">
        <button className="greenbutton" onClick={SaveBrand} >
          Save
        </button>
        <button className="redbutton" onClick={CancelBrand} >Cancel</button>
      </div>

      
      </div>


      
        </div> 
      </div>

    );
}

export default Addcampaigndata;


