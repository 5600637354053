import React, { useEffect, useState, useContext, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from 'react-select';
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Loader from "../CommonLoader";
import ReactApexChart from "react-apexcharts";
import InprogressIcon from "../../../Icons/work-in-progress-unscreen.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import DoneIcon from "@mui/icons-material/Done";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Commonfun, datezonechange } from './../Commonfun';
import moment from "moment";
import { AppContext } from "../../../Context/Context";
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from "@mui/material";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import ApexCharts from "react-apexcharts";
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
import NewLoader from "../NewLoader"
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

const BrandMentions = () => {

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:900px)');
  const isDesktop = useMediaQuery('(min-width:900px)');
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const [newloading, setnewLoading] = useState(false);
  const [modalIsOpen, SetmodalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [BrandTerm, SetBrandTerm] = useState("");
  const [AdditionalTerm, SetAdditionalTerm] = useState("");
  const [Sentiment, SetSentiment] = useState({ value: 'all', label: 'All' });
  const [prevSentiment, setPrevSentiment] = useState("");
  const [ErrPop, SetErrPop] = useState("");
  const [erraddterm, SetErraddterm] = useState("");

  const [sentimentErr, SetSentimentErr] = useState("");

  const [ShowAnalyzedTable, SetShowAnalyzedTable] = useState(false);
  const [ShowInProgress, SetShowInProgress] = useState(false);
  const [InProgressMsg, SetInProgressMsg] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [jobDate, setjobDate] = useState("");
  const [brandMentionsPlan, setbrandMentionsPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const navigate = useNavigate();
  const closeModal = () => {
    SetmodalIsOpen(false);
    SetErrPop("");
  };
  const OpenModal = () => {
    SetmodalIsOpen(true);
  };
  const apihandleClose = () => {
    SetShowAnalyzedTable(true);
    SetShowInProgress(false);
    SetInProgressMsg('');
    setapiOpen(false);
  };
  const columns = useMemo(()=> [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Index of the row",
    },

    {
      field: "brand_name",
      headerName: "Brand Name",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (
          cardData.row.brand_name !== null &&
          cardData.row.brand_name !== ""
        ) {
          return cardData.row.brand_name;
        } else {
          return "-";
        }
      },
    },
    {
      field: "additional_info",
      headerName: "Additional Info",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (cardData) => {
        if (
          cardData.row.additional_info !== null &&
          cardData.row.additional_info !== ""
        ) {
          return cardData.row.additional_info;
        } else {
          return "-";
        }
      },
    },

    {
      field: "url",
      headerName: "URL",
      minWidth: 380,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: "URL of the web page",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        const url = cardData.row.url;
        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");

        if (url !== null && url !== "") {
          return (
            <Tooltip title={url}>
              <div className="prefered_url_external02">
                <a className="table_links" href={url} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
    },

    {
      field: "meta_description",
      headerName: "Meta Description",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
    },
    {
      field: "sentiment",
      headerName: "Sentiment",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
    },

    {
      field: "date_of_request",
      headerName: "Date of request",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (carddata) => {
        if (carddata.row.status == true || carddata.row.status == "true") {
          return (
            <>
              <DoneIcon />
            </>
          );
        }
      },
    },
  ],[]);

  const [PositivePie, SetPositivePie] = useState(0);
  const [NegativePie, SetNegativePie] = useState(0);
  const [NeutralPie, SetNeutralPie] = useState(0);
  const [pieseriesData, SetpieseriesData] = useState([]);

  const pieseries = pieseriesData;

  const pieoptions = {
    chart: {
      type: "donut",
      height: 350,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div class='custom_pie_tool_tip'>" +
          w.config.labels[seriesIndex] +
          "</div>"
        );
      },
    },
    labels: [
      "Positive - " + PositivePie,
      "Negative - " + NegativePie,
      "Neutral - " + NeutralPie,
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: isMobile || isTablet ? "bottom" : "right",
      horizontalAlign: "center",
      verticalAlign: "center"
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    }
  };


  const Submit = async () => {
    setnewLoading(true);
    SetErrPop("");
    SetErraddterm("")
    var termsval = 1
    if (BrandTerm === "") {
      SetErrPop("Please enter brand name");
      termsval = 2;
    }
    if (AdditionalTerm == "") {
      SetErraddterm("Please enter additional terms")
      termsval = 2;
    }
    if (termsval == 1) {
      setCardData([]);
      SetShowAnalyzedTable(false);
      SetShowInProgress(false);
      SetInProgressMsg("");
      setLoading(true);
      SetmodalIsOpen(false)
      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&brand_name=${BrandTerm}&additional_terms=${AdditionalTerm}&new_scrap=True`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setCardData("");
            SetAutoheightval(true)
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        setLoading(false);
        if (datavalue?.data?.job_date) {
          setjobDate(datavalue?.data?.job_date)
        }
        else {
          setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
        }

        if (datavalue.data) {
          if (datavalue.data.status === "completed") {
            SetBrandTerm("");
            SetAdditionalTerm("");
            SetSentiment("");

            if (datavalue.data.data && datavalue?.data?.data?.length > 0) {
              const newData = datavalue.data.data.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });
              SetAutoheightval(false)
              setCardData(newData);
              closeModal();
              SetShowAnalyzedTable(true);
            }
          } else if (datavalue.data.status === "in_progress") {
            SetShowAnalyzedTable(false);
            SetShowInProgress(true);
            SetInProgressMsg(datavalue.data.message);
            setCardData("");
            SetAutoheightval(true)
            setapiOpen(true);
            setapierrorMsg('After analyze completion we will send a mail. Thank you');
            closeModal();
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          if (err.response.data.error) {
            setCardData("");
            SetAutoheightval(false)
            SetErrPop(err.response.data.error);
          }
          if (err.response.data.status) {
            if (err.response.data.status === "in_progress") {
              SetShowInProgress(true);
              SetInProgressMsg(err.response.data.message);
              setCardData("");
              SetAutoheightval(false)
              closeModal();
              return false;
            }
          }
        } else {
          setCardData("");
          SetErrPop("");
        }
      }
    }
    setnewLoading(false);
  };

  const GetHistoryBrandMentions = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&new_scrap=False&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        if (datavalue?.data?.data) {
          const newData = datavalue.data.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          if (datavalue?.data?.job_date) {
            setjobDate(datavalue?.data?.job_date)
          }
          else {
            setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
          }
          if (newData && newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {

            SetAutoheightval(true)
            setCardData("");
          }
          SetShowAnalyzedTable(true);

        }
        if (datavalue.data.error) {
          toast.error(datavalue.data.error, {

            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (err) {
      setLoading(false);

      if (err.response.data.error) {
        SetShowAnalyzedTable(true);
        toast.error(err.response.data.error, {

          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (err.response.data.message) {
        SetShowInProgress(true);
        SetInProgressMsg(err.response.data.message);
      } else {
        SetShowAnalyzedTable(true);
        toast.error(err.response.data.message, {

          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const GetHistoryBrandGraph = async (jobid = "") => {
    setLoading(true);
    try {
      const datavalueGraph = await axios.get(
        `${process.env.REACT_APP_API_URL}brandmentions/sentiment_count/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      var PositiveGraph = 0;
      var NegativeGraph = 0;
      var NuetralGraph = 0;

      if (datavalueGraph?.data) {
        PositiveGraph = parseFloat(datavalueGraph?.data?.positive_percentage);
        NegativeGraph = parseFloat(datavalueGraph?.data?.negative_percentage);
        NuetralGraph = parseFloat(datavalueGraph?.data?.neutral_percentage);
      }

      SetPositivePie(PositiveGraph);
      SetNegativePie(NegativeGraph);
      SetNeutralPie(NuetralGraph);
      SetpieseriesData([PositiveGraph, NegativeGraph, NuetralGraph]);
    } catch (err) {
      setLoading(false);
    }
  };
  const checkUserPlan = async () => {
    const brandMentionsP = await Utils("brand_mentions", ApiToken);
    if (brandMentionsP.status) {
      GetHistoryBrandMentions();
      GetHistoryBrandGraph();
      GetReportJobDates();
      faqdatafun();
    }
    setbrandMentionsPlan(brandMentionsP);
  };


  useEffect(() => {
    checkUserPlan();
  }, []);

  const [OpenModalJob, SetOpenModalJob] = useState(false);
  const CloseModalJobTrigger = () => {
    if (prevdEngineoption) {
      setSelectval(prevdEngineoption);
      SetdEngineoption(prevdEngineoption);
    }
    SetSentiment(prevSentiment);
    SetOpenModalJob(false);
    SetSentimentErr("");
    SetvalidationFiter("");
  };
  const OpenModalJobTrigger = () => {
    SetOpenModalJob(true);
  };
  const [completedate, Setcompletedate] = useState([]);
  const [Selectval, setSelectval] = useState('');
  const [validationFiter, SetvalidationFiter] = useState("");
  const [jobvalsel, SetJobvalsel] = useState("")

  const TriggerFilter = async () => {
    setPrevdEngineoption(dEngineoption);
    setPrevSentiment(Sentiment);
    let hasError = 1;
    if (Selectval === '') {
      SetvalidationFiter("Please select the completed date");
      hasError = 2;
    } else {
      SetvalidationFiter("");
    }
    if (Sentiment === '') {
      SetSentimentErr("Please select the sentiment for filter purpose");
      hasError = 2;
    } else {
      SetSentimentErr("");
    }
    if (hasError == 2) {
      return false;
    }
    else if (Sentiment.label === 'all') {
      let JobId;
      if (Selectval) {
        JobId = Selectval.value;
      } else {
        JobId = jobvalsel;
      }
      GetHistoryBrandMentions(JobId);
      SetJobvalsel(JobId);
      GetHistoryBrandGraph(JobId);
      SetOpenModalJob(false);
      SetSentimentErr("");
      SetvalidationFiter("");
    }
    else {
      let JobId;
      if (Selectval) {
        JobId = Selectval.value;
      } else {
        JobId = jobvalsel;
      }
      SetJobvalsel(JobId)
      GetHistoryBrandGraph(JobId);
      SetOpenModalJob(false);
      SetSentimentErr("");
      SetvalidationFiter("");

      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&sentiment=${Sentiment.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        setLoading(false);
        if (datavalue?.data?.job_date) {
          setjobDate(datavalue?.data?.job_date)
        }
        else {
          setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
        }
        if (datavalue?.data?.data.length > 0) {
          const newData = datavalue.data.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
          SetShowAnalyzedTable(true);
          SetOpenModalJob(false);
          SetSentimentErr("");
          SetvalidationFiter("");
        }
        else {
          SetAutoheightval(true)
          setCardData("");
        }

      } catch (err) {
        setLoading(false);
      }

    }
  };
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobvalsel}&sentiment=${Sentiment.value}&new_scrap=&formats=pdf&timezone=${timezoneval()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `${ApiToken}`,
          },
        });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("BrandMention", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobvalsel}&sentiment=${Sentiment.value}&new_scrap=&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("BrandMention", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();


        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }

  const GetReportJobDates = async () => {
    setLoading(true);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=brandmentions`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data?.jobs.length > 0) {
        var brandMention = [];
        datavalue?.data?.jobs.forEach((element) => {
          brandMention.push({ label: datezonechange(element.completed_at), value: element.id });
        });
        SetdEngineoption(brandMention[0]);
        setPrevdEngineoption(brandMention[0]);
        Setcompletedate(brandMention);
        setSelectval(brandMention[0])
      }

    } catch (err) {
      setLoading(false);
    }
  };

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at: "Select Date",
    id: "Select Date",
  });
  const [prevdEngineoption, setPrevdEngineoption] = useState(null);


  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=32`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <>
      {loading === true ? <Loader /> : null}
      {
        newloading === true ? <NewLoader /> : null
      }
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Brand Mentions</title>
        </Helmet>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Brand Mentions</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {brandMentionsPlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                    csvdown={csvdown} pdfdown={pdfdown} />
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }} > {"Brand Mentions"}</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={brandMentionsPlan.status ? OpenModalJobTrigger : null} />
                </span>
              </div>
              <p className="subheader-timedate">as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

            </div>
            {ShowInProgress === false && <button
              className="runreportnew"
              onClick={() => {
                OpenModal();
              }}
              disabled={!brandMentionsPlan.status}
            >
              Analyze
            </button>}
          </div>
          {!brandMentionsPlan.status &&
            <Freeaudit planStatus={brandMentionsPlan} />
          }
        </div>
        {ShowAnalyzedTable === true && brandMentionsPlan.status &&
          (PositivePie == 0 && NegativePie == 0 && NeutralPie == 0) === false ? (
          <div className="pie-chart-outer">
            <div className="pie-chart-inner">
              <ApexCharts options={pieoptions} series={pieseries} type="donut" height={350} />
            </div>
          </div>
        ) : null}
        <div className="cnt_audit_outer_wrap">
          {(ShowAnalyzedTable === true && brandMentionsPlan.status) ? (
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          ) : null}
        </div>

        {(ShowInProgress == true && brandMentionsPlan.status) ? (
          <div className="show_in_progress_brand_mention">
            <div className="wrapperAlert">
              <div className="contentAlert">
                <div className="topHalf">
                  <p>
                    <img src={InprogressIcon} />
                  </p>
                  <h1>{InProgressMsg}</h1>

                  <ul className="bg-bubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}


        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter keyword_edit_form"
        >
          <DialogTitle className="keyword-dialog-header">{"Brand Mention Analysis"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div className="brand_mentions_form">
                  <div>
                    <label>Brand Name:</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={BrandTerm}
                      placeholder="Enter Brand Name"
                      defaultValue={BrandTerm}
                      onChange={(e) => {
                        SetBrandTerm(e.target.value);
                      }}
                    />
                  </div>
                  <div className="err brand_mention_err">{ErrPop}</div>
                  <div style={{ marginTop: "20px" }}>
                    <label>Additional terms:</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={AdditionalTerm}
                      placeholder="Enter Additional Terms"
                      defaultValue={AdditionalTerm}
                      onChange={(e) => {
                        SetAdditionalTerm(e.target.value);
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="err brand_mention_err">{erraddterm}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button className="keyword-cancel" onClick={closeModal}>
              close
            </button>
            <button
              className="keyword-submit"
              onClick={Submit}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <Dialog
        open={OpenModalJob}
        onClose={CloseModalJobTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap">
              <div>
                <div className="">
                  <div >
                    <span>Completed Date</span>
                    <Select
                      className="myClass"
                      value={dEngineoption}
                      options={completedate}
                      placeholder="Select..."
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values);
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validationFiter}</span>
                </div>
              </div>
              <div>
                <div>
                  <label>Sentiment for filter purpose:</label>
                </div>
                <div>
                  <Select
                    value={Sentiment}
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'Positive', label: 'Positive' },
                      { value: 'Negative', label: 'Negative' },
                      { value: 'Neutral', label: 'Neutral' },
                    ]}
                    placeholder="Select..."
                    defaultValue={{ value: 'all', label: 'All' }}
                    onChange={(e) => {
                      SetSentiment(e);
                    }}
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                  >
                  </Select>
                </div>
                <div className="col">
                  <span className="text-error">{sentimentErr}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="keyword-cancel" onClick={CloseModalJobTrigger}>
            close
          </button>
          <button
            className="keyword-submit"
            onClick={TriggerFilter}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </>
  );
};
export default BrandMentions;
