import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import DatagridComp from "../DatagridComp";

export default function BacklinksReport(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate()
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referring_page",
      headerName: "Referring Page/Title",
      minWidth: 600,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "Referring page's URL or referring page's title.",
      renderCell: (cardData) => {
        let baseUrl = cardData?.row?.referring_page.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData?.row?.referring_page.split("/").slice(3).join("/");
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData?.row?.title}</span>
            <br />
            <Tooltip title={cardData.row.referring_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.referring_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "da",
      headerName: "DA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "Domain InLink Rank of the target's root domain.",
    },
    {
      field: "pa",
      headerName: "PA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "InLink rank of the target URL.",
    },
    {
      field: "anchor",
      headerName: "Anchor/Target Page",
      minWidth: 350,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Link anchor text for text links/Destination page's URL.",
      renderCell: (cardData) => {
        let baseUrl = cardData?.row?.target_page.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData?.row?.target_page.split("/").slice(3).join("/");
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.anchor}</span>
            <br />
            <Tooltip title={cardData.row.target_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.target_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "link_type",
      headerName: "Link Type",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'Type of backlink.',
    },
    {
      field: "nofollow",
      headerName: "Follow/No Follow",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'Number of backlinks marked as nofollow and number of backlinks not marked as nofollow.',
    },
    {
      field: "first_seen",
      headerName: "First Seen",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'The date when the backlink was added to the index or the date when our crawlers last visited the referring page.',
    },
    {
      field: "last_visited",
      headerName: "Last Visited",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The date when the backlink was added to the index or the date when our crawlers last visited the referring page.",
    },

  ];

  const rows = cardData ?? [];
  const LoadData = async (tableid = '') => {
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_report/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {
        props.setjobDate(moment(datavalue.data.job_date).format("MMMM DD,YYYY HH:mm"));
      }

      if (datavalue?.data?.report) {
        var TableArray = [];
        TableArray = datavalue.data.report;

        if (TableArray && TableArray.length > 0) {
          const newData = TableArray.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setCardData(newData);
          SetAutoheightval(false)
        }
        else {
          setCardData("");
          SetAutoheightval(true)
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Report</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <DatagridComp
        columns={columns}
        rows={rows}
        autoheightval={autoheightval}
        datagridval={4}
      />
    </>
  );
}
