import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import axios from "axios";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { useSelector } from "react-redux";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import "./accessbilitydesign.css";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import CommonAlert from "../../../Commonalert/commonalert";
import ErrorCountsvg from "../Asset/esocardicon/SourceCodeAnalysis/ErrorCount.svg"
import FatalErrorCountsvg from "../Asset/esocardicon/SourceCodeAnalysis/FatalErrorCount.svg"
import WarningCountsvg from "../Asset/esocardicon/SourceCodeAnalysis/WarningCount.svg"
import Commoncard from "../Commoncard";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


const AccessbilityaxescanDetails = () => {
  let querystring = useParams();
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const ItemId = querystring.id;
  const JobId = querystring.sid;
  const Saudit = querystring.saudit;
  const esodata = useSelector((state) => state);
  const [jobDate, SetjobDate] = useState("");
  const [queryurl, Setqueryurl] = useState("");
  const [issueName, setissueName] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [autoheightval, SetAutoheightval] = useState(true)

  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value;

  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];



  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const columns = [
    {
      field: "nid",
      headerName: "#",
      minWidth: 80,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    }
    ,
    {
      field: "impact",
      headerName: "Impact",
      description: "The severity level of the issue detected.",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "issue_name",
      headerName: "Issue Name",
      description: "The specific accessibility issue found.",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "message",
      headerName: "Message",
      description: "A detailed error message describing the issue.",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "html",
      headerName: "Element",
      description: "The HTML element where the issue occurred.",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
    }

  ];



  const [ErrorCarddata, SetErrorCarddata] = useState([]);



  const rows = ErrorCarddata ?? [];

  const AccessbilityScoreDetail = async () => {
    const datavalue = await axios.get(

      `${process.env.REACT_APP_API_URL}technical/accessibility_score_scan/?user_domain_id=${esodata.domaindata.value.header_selected}&detail_id=${ItemId}&scan_id=${JobId}&audit_type=${Saudit}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });



    if (datavalue?.data) {
      const dataval = datavalue?.data?.result;
      const newData = dataval.map((obj, index) => {
        return { ...obj, nid: index + 1 };
      });


      SetAutoheightval(false)

      SetErrorCarddata(newData)

      if (datavalue?.data?.job_date)
        SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
      Setqueryurl(datavalue?.data?.domain_url)
      setissueName(datavalue?.data?.issue_name)
    }


  };





  useEffect(() => {
    AccessbilityScoreDetail();
  }, []);


  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_score_scan/?user_domain_id=${esodata.domaindata.value.header_selected}&detail_id=${ItemId}&scan_id=${JobId}&audit_type=${Saudit}&formats=pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_score_scan/?user_domain_id=${esodata.domaindata.value.header_selected}&detail_id=${ItemId}&scan_id=${JobId}&audit_type=${Saudit}&formats=csv`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityDetail", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };





  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Link to="/accessibility-axe">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Axe</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Axe Scan Detail Report </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <>
              <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </>
          </div>
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Accessibility Axe Scan Detail Report</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row',flexWrap:'wrap' }} >
              <a target="_blank" href={queryurl} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="spanlink">
                  &nbsp; {queryurl}
                </span>
              </a>
              <span style={{ color: '#044792' }} >&nbsp;&nbsp;&nbsp;{"-" + issueName}</span>
            </div>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
        </div>


        <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
          <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
            <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
              <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                autoHeight={autoheightval ? true : false}
                getRowHeight={() => "auto"}
                getRowId={(row) => row.nid}
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  toolbar: GridToolbar,
                  noResultsOverlay: MyCustomNoRowsOverlay,
                  footer: () => (
                    <Box className="table-footer-container">
                      <Box className="table-footer-pagination">
                        <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                      </Box>
                      <MSelect
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        size="small"
                        className="table-footer-select"
                      >
                        <MenuItem value={10}>10 per page</MenuItem>
                        <MenuItem value={30}>30 per page</MenuItem>
                        <MenuItem value={50}>50 per page</MenuItem>
                        <MenuItem value={75}>75 per page</MenuItem>
                        <MenuItem value={100}>100 per page</MenuItem>
                      </MSelect>
                    </Box>
                  ),
                }}
                slotProps={{
                  toolbar: {

                    showQuickFilter: true,

                  },
                }}
                rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                columns={columns}
                pagination
              />
            </div>
          </div>
        </div>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: false
            }}
            closeAlert={apihandleClose} />
        }
      </div>
    </div>
  );
};

export default AccessbilityaxescanDetails;
