import axios from "axios";

export async function Utils(data, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}accounts/check_feature_availability/?report=${data}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    if (response.data.status) {
      return { status: response.data.status, error: "" }
    } else {
      return response.data
    }


  } catch (error) {
    console.error("Error during API request:", error);
    return { error: "There was an error making the API request" };
  }
}

export const freePlanMsg = 'This feature is available for our premium members only!'
