import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dropdown from "react-dropdown-select";
import Select from 'react-select';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import './keywords.css';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


function KeywordEditForm(props) {
  const [weblinkError, SetweblinkError] = useState("");
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [onPageError, SetonPageError] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CampaignList, SetCampaignList] = useState([]);
  const [SelectCampaignList, SetSelectCampaignList] = useState([]);
  const [KeywordName, SetKeywordName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [campaignerr, setCampaignerr] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [cardmsg, setCardmsg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const [campeditval, setCampeditval] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  let querystring = useParams();
  let plag_id = props.selectedKeyword;
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();


  const [keywordaddpopup, setkeyWordAddPopup] = useState(false);

  const [succespopup, setsuccespopup] = useState();


  const keywordaddpopupCloseOk = () => {
    setkeyWordAddPopup(false);
    props.setSubmitKeyword(true);
    props.setKeywordEditPopup(false)
  }


  const handleClose = () => {
    setOpen(false);
  };

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const handlestateoptions = async (Countrys = "", stateId) => {

    if (Countrys == "" || Countrys == null || !Countrys)
      return false;


    SetCountry(Countrys);

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        var stateData = Sections.find((option) => option.value === stateId);
        setStateValue(stateData);
      }
    }
  };
  const handleCityoptions = async (States = "", cityId) => {
    if (States == "" || States == null || !States) {
      return false;
    }

    SetState(States);

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        var cityData = Sections.find((option) => option.value === cityId);
        setCityValue(cityData);
      }
    }
  };
  const LoadAddFormFilterOptions = async (countryId, searchEngineId) => {
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
          var countryData = Sections.find((option) => option.value === countryId);
          setCountryValue(countryData);
        }
      }
    } catch (err) { }

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
          var countryData = Sections.find((option) => option.value === countryId);
          setCountryValue(countryData);
        }
      }
    } catch (err) { }

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            if (element.id === searchEngineId) {
              SetSearchEngine({ label: element.name, value: element.id })
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setCardData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        setCardData(datavalue.data.keyword_settings);
        if (datavalue.data.keyword_settings == "Country") {
          SetState("");
          SetCity("");
        }
        else if (datavalue.data.keyword_settings == "State") {
          SetCity("");
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}keywords`;
  };
  const finalreportNow = async () => {
    var dataerr = 1;
    setCampaignerr("");
    SetweblinkError("");
    setEngineerr("");
    setCountryerr("");
    setStateerr("");
    setCityerr("");

    if (SelectCampaignList == "") {
      setCampaignerr("Please Select Campaign");
      dataerr = 2;
    }

    if (KeywordName == "") {
      SetweblinkError("Please Enter Keyword");
      dataerr = 2;
    }
    if (dataerr == 1) {

      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/keyword_edit/`,
          {
            user_domain_id: esodata.domaindata.value.header_selected,
            campaign_id: `${SelectCampaignList.value}`,
            keyword_id: plag_id,
            keyword: KeywordName
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            return false;
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });
        if (
          Response?.data?.message ==
          "Keywords and associated details updated successfully"
        ) {
          setsuccespopup(Response.data.message);
          setkeyWordAddPopup(true);
        }
      } catch (Response) {
        setCampaignerr("");
        SetweblinkError("");
        setEngineerr("");
        setCountryerr("");
        setStateerr("");
        setCityerr("");
        setCardmsg(Response?.response?.data?.error);
      }
    } else {
      return false;
    }
  };
  useEffect(() => {

    editDataval();
    GetCampaignData();
  }, []);
  const editDataval = async () => {
    SetCampaignList([]);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/edited_keyword_data/?user_domain_id=${esodata.domaindata.value.header_selected}&keyword_id=${plag_id}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (datavalue?.data) {
      setCampeditval(datavalue.data)
      handlestateoptions(datavalue.data.country_id, datavalue.data.state_id);
      handleCityoptions(datavalue.data.state_id, datavalue.data.city_id)
      SetSelectCampaignList({
        label: datavalue.data.campaign_name,
        value: datavalue.data.campaign_id
      })
      SetKeywordName(datavalue.data.keyword)
      SetSearchEngine(datavalue.data.search_engine_id)
      SetCountry(datavalue.data.country_id)
      SetState(datavalue.data.state_id)
      SetCity(datavalue.data.city_id)

    }
  };
  const GetCampaignData = async () => {
    SetCampaignList([]);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (datavalue?.data) {
      var Sections = [];
      datavalue.data.forEach((element) => {
        Sections.push({
          label: element.campaign_name,
          value: element.campaign_id,
        });
      });
      SetCampaignList(Sections);
    }
  };
  const onwebName = e => {

    SetKeywordName(e.target.value);
  }
  const handleBlur = (e) => {

    SetKeywordName(e.target.value);

  };

  const setCurrentCityValue = (e) => {
    setCityValue(e);
    SetCity(e.value);
  }


  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div >
        <div>
          <span className="error-value">{onPageError}</span>

          <form className="frm_container">


            <div>
              <div className="keyword-col1-fields">
                <div>
                  <label for="city" style={{ marginLeft: 'auto' }}>
                    Campaign Name :
                  </label>
                  <Select
                    name="campaign"
                    value={SelectCampaignList}
                    options={CampaignList}
                    onChange={(e) => { SetSelectCampaignList(e) }}
                    placeholder="Select Campaign"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                    className="keyword-select-container"
                    classNamePrefix="addkeyword"
                  />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{campaignerr}</span>
                </div>
              </div>
              <div>
                <div>
                  <label>Keyword Name :</label>
                  <div>
                    <input
                      type="text"
                      className="keyword"
                      onChange={onwebName}
                      onBlur={handleBlur}
                      defaultValue={campeditval.keyword}
                      name="keywordname"
                    />
                  </div>
                </div>
                <div>
                  <span className="error-value">{weblinkError}</span>
                </div>
              </div>
            </div>
            <div className="keyword-dialog-buttons">
              <button
                className="keyword-cancel"
                type="button"
                onClick={() => props.setKeywordEditPopup(false)}
              >
                Close
              </button>
              <button
                className="keyword-submit"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <CommonErrorAlert
        data={{
          apiopen: open,
          apiErrorMsg: cardmsg,
          isIconStyled: false
        }}
        closeAlert={handleClose} />
      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: false
        }}
        closeAlert={apihandleClose} />
      <CommonErrorAlert
        data={{
          apiopen: keywordaddpopup,
          apiErrorMsg: succespopup,
          isIconStyled: false
        }}
        closeAlert={keywordaddpopupCloseOk} />
    </div>
  );
}

export default KeywordEditForm;
