import React, { useEffect, useState } from "react";

 const ProgressBar = ({ score }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setProgressWidth(score);
    }, 200);
  }, [score]);

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div style={{ position: "relative", width: "80%", height: "40px" }}>
        <div
          style={{
            position: "absolute",
            top: "-30px",
            left: `${progressWidth}%`,
            transform: "translateX(-50%)",
            textAlign: "center",
            fontWeight: "bold",
            color: "#0d1b2a",
            whiteSpace: "nowrap",
            zIndex: 2,
          }}
        >
          <span>{score?score:0}%</span>
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid #ff601f",
              zIndex: 3,
            }}
          ></div>
        </div>

        <div
          style={{
            width: "100%",
            height: "10px",
            background: "#eee",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progressWidth}%`,
              background: "linear-gradient(to right, #ffd1a4, #ff8c42, #ff601f)",
              transition: "width 1.5s ease-in-out",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;