import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from 'react-select';
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import Ranklinksummary from "./Ranklinksummary";
import RankingComparison from "./RankingComparision";
import RankingVisibility from "./RankingVisibility";
import RankingKeywordTrend from "./RankingKeywordTrend";
import { Outlet, Link } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom"
import { Commonfun, datezonechange } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { Utils, freePlanMsg } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import Loader from "../CommonLoader";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Rankingsummarylists(props) {

  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [campaign, Setcampaign] = useState("");

  const [campaignoption, Setcampaignoption] = useState([]);
  const [dcampaignoption, Setdcampaignoption] = useState([]);
  const [dselectjobid, setdselectjobid] = useState([])
  const [prevdselectjobid, setprevdselectjobid] = useState([])
  const [dSearchEngine, SetdSearchEngine] = useState([])
  const [dCountryHandle, SetdCountryHandle] = useState([])
  const [dStateHandle, SetdStateHandle] = useState([])
  const [dCity, SetdCity] = useState([])


  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [System, SetSystem] = useState('desktop');
  const [Device, SetDevice] = useState('')
  const [rankSummaryPlan, setrankSummaryPlan] = useState({ status: true, error: "" });
  const [rankVisibilityScorePlan, setrankVisibilityScorePlan] = useState({ status: true, error: "" });
  const [rankComparisonPlan, setrankComparisonPlan] = useState({ status: true, error: "" });
  const [keywordTrendPlan, setkeywordTrendPlan] = useState({ status: true, error: "" });

  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const navigate = useNavigate()

  const [CurrentMenu, SetCurrentMenu] = useState('ranked_keywords')
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SwitchMenu = (e) => {

    const Id = e.target.getAttribute('data-id')
    SetCurrentMenu(Id)
    faqdatafun(Id)
  }
  const SetCityHandle = async (value) => {
    SetCity(value.value);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    SetdStateHandle([])
    SetState()
    SetCity()
    if (value) {
      SetCountry(value.value);
      var CityPass = '';
      if (City.value) {
        CityPass = City.value
      }
      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${value.value}&filter_type=state`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });
        if (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {

            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element?.search_engine_locale__state__state_name,
                value: element?.search_engine_locale__state__id,
              });
            });
            SetStateoptions(Sections);
            SetdStateHandle(Sections[0])
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    SetdCity([])
    if (value) {
      if (value?.value === undefined) {
        value.value = 0;
      }
      SetState(value);
      var CountryPass = '';
      if (Country.value) {
        CountryPass = Country.value
      }

      try {

        const citydatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${CountryPass}&state_id=${value.value}&filter_type=city`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });

        if (citydatavalue?.data) {
          if (citydatavalue?.data?.cities) {
            var Sections = [];
            citydatavalue?.data?.cities.forEach((element) => {
              Sections.push({
                label: element?.search_engine_locale__city__city_name,
                value: element?.search_engine_locale__city__id,
              });
            });
            SetCityOptions(Sections);
            SetdCity(Sections[0])
          }
        }

      } catch (err) {
        console.log(err)
      }


    }
  };

  const [FilterButtonTriggered, SetFilterButtonTriggered] = useState(false)

  const finalreportNow = async () => {

    setLoading(true);
    SetErrorMsg("");
    if (
      selectjobid !== "" ||
      campaign !== "" ||
      SearchEngine !== "" ||
      Country !== "" ||
      State.value !== "" ||
      City.value !== "" ||
      System !== '' ||
      Device !== ''

    ) {


      SetRankCampaign(campaign)
      setjobDate(dselectjobid);
      SetRankJob(selectjobid)
      SetRankEngine(SearchEngine)
      SetRankCountry(Country)

      if (State?.value) {
        SetRankState(State?.value)
      }
      else {
        SetRankState(0)
      }
      if (City?.value) {
        SetRankCity(City?.value)
      }
      else {
        SetRankCity(0)
      }


      SetRankSystem(System)
      SetRankDevice(Device)
      SetFilterButtonTriggered(true)


    } else {
      setLoading(false);
      return SetErrorMsg("Please Select the All Fields !");
    }
    setIsOpen(false);
    setprevdselectjobid(dselectjobid);
    setLoading(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });


  const [RankCampaign, SetRankCampaign] = useState('')
  const [RankJob, SetRankJob] = useState('')
  const [RankEngine, SetRankEngine] = useState('')
  const [RankCountry, SetRankCountry] = useState('')
  const [RankState, SetRankState] = useState('')
  const [RankCity, SetRankCity] = useState('')
  const [RankSystem, SetRankSystem] = useState('')
  const [RankDevice, SetRankDevice] = useState('')
  const [RankTypeval, SetRankTypeval] = useState('')







  const LoadAddFormFilterOptions = async (jobid = "") => {
    setjobidData([]);

    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=campaign`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (campaigndatavalue?.data?.campaigns) {
        if (campaigndatavalue?.data?.campaigns) {
          var campaignSections = [];
          campaigndatavalue?.data?.campaigns.forEach((element) => {
            campaignSections.push({ label: element?.category__name, value: element?.category__id });
          });
          Setcampaignoption(campaignSections);
          Setdcampaignoption(campaignSections[0])
        }
      }
    } catch (err) { }
    try {
      const jobdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (jobdatavalue?.data?.jobs) {
        if (jobdatavalue?.data?.jobs) {
          var jobSections = [];
          jobdatavalue?.data?.jobs.forEach((element) => {
            jobSections.push({ label: datezonechange(element?.completed_at), value: element?.id });
          });
          if (jobSections.length > 0)
            setdselectjobid(jobSections[0])
          setprevdselectjobid(jobSections[0])
          setjobidData(jobSections);
        }
      }
    } catch (err) { }
    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=search_engine`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (enginedatavalue?.data?.search_engines) {
        if (enginedatavalue?.data?.search_engines) {
          var Sections = [];
          enginedatavalue?.data?.search_engines.forEach((element) => {
            Sections.push({ label: element?.search_engine_locale__search_engine__name, value: element?.search_engine_locale__search_engine__id });
          });
          SetEngineoptions(Sections);
          SetdSearchEngine(Sections[0])
        }
      }
    } catch (err) { }
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&state_id=${State}&city_id=${City}&filter_type=country`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (countrydatavalue?.data?.countries) {
        if (countrydatavalue?.data?.countries) {
          var Sections = [];
          countrydatavalue?.data?.countries.forEach((element) => {

            Sections.push({ label: element?.search_engine_locale__country__name, value: element?.search_engine_locale__country__id });
          });
          SetCountryoptions(Sections);
          SetdCountryHandle(Sections[0])
          SetdStateHandle(Sections[0])
          SetdCity(Sections[0])
        }
      }
    } catch (err) { }



  };

  const arr = [];

  const checkUserPlan = async () => {
    const rankSummaryPlan = await Utils("rank_summary", ApiToken);
    const rankVisibilityScorePlan = await Utils("rank_visibility_score", ApiToken);
    const rankComparisonPlan = await Utils("rank_comparison", ApiToken);
    const keywordTrendPlan = await Utils("keyword_trend", ApiToken);
    setrankSummaryPlan(rankSummaryPlan);
    setrankVisibilityScorePlan(rankVisibilityScorePlan);
    setrankComparisonPlan(rankComparisonPlan);
    setkeywordTrendPlan(keywordTrendPlan);
  };

  useEffect(() => {
    const APITOKEN = localStorage.getItem('header_selected');
    checkUserPlan();
    LoadAddFormFilterOptions()
    faqdatafun();
  }, []);
  useEffect(() => {
  }, [RankTypeval])
  
  const faqdatafun = async (menuval = "") => {
    if (menuval === 'ranked_keywords' || menuval === '')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=4`
    else if (menuval === 'comparison')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=6`
    else if (menuval === 'keyword_trend')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=7`
    else if (menuval === 'visibility_score')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=5`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }

    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  function openModal() {


    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    setdselectjobid(prevdselectjobid);
  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('rankings_summary')
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    if (CurrentMenu === 'ranked_keywords') {
      if (RankTypeval == "")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&timezone=${timezoneval()}&rank_type=`;
      if (RankTypeval == "ranked_keywords")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=ranked_keywords&timezone=${timezoneval()}`;
      if (RankTypeval == "first_page_rank")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=first_page_rank&timezone=${timezoneval()}`;

    }
    else if (CurrentMenu === 'visibility_score') {
      var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_section=visibility_score&timezone=${timezoneval()}`;
    }
    else if (CurrentMenu === 'keyword_trend') {
      var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_section=keyword_trend&timezone=${timezoneval()}`;
    }
    if (CurrentMenu === 'comparison') {
      if (RankTypeval == "ranked_keywords")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=ranked_keywords&timezone=${timezoneval()}`;
      if (RankTypeval == "gained_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=gained_keyword_data&timezone=${timezoneval()}`;
      if (RankTypeval == "dropped_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=dropped_keyword_data&timezone=${timezoneval()}`;
      if (RankTypeval == "lost_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=pdf&devices=${RankDevice}&rank_type=lost_keyword_data&timezone=${timezoneval()}`;

    }


    try {
      const response = await fetch(rankBaseurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("RankingSummary", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;

        
        link.click();


        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    if (CurrentMenu === 'ranked_keywords') {
      if (RankTypeval == "")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&timezone=${timezoneval()}&rank_type=`;
      if (RankTypeval == "ranked_keywords")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=ranked_keywords&timezone=${timezoneval()}`;
      if (RankTypeval == "first_page_rank")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=first_page_rank&timezone=${timezoneval()}`;
    }
    else if (CurrentMenu === 'visibility_score') {
      var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_section=visibility_score&timezone=${timezoneval()}`;
    }
    else if (CurrentMenu === 'keyword_trend') {
      var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_section=keyword_trend&timezone=${timezoneval()}`;
    }
    if (CurrentMenu === 'comparison') {
      if (RankTypeval == "ranked_keywords")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=ranked_keywords&timezone=${timezoneval()}`;
      if (RankTypeval == "gained_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=gained_keyword_data&timezone=${timezoneval()}`;
      if (RankTypeval == "dropped_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=dropped_keyword_data&timezone=${timezoneval()}`;
      if (RankTypeval == "lost_keyword_data")
        var rankBaseurl = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?system=${RankSystem}&country_id=${RankCountry}&state_id=${RankState}&city_id=${RankCity}&campaign_id=${RankCampaign}&search_engine_id=${RankEngine}&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJob}&formats=csv&devices=${RankDevice}&rank_type=lost_keyword_data&timezone=${timezoneval()}`;

    }


    try {
      const response = await fetch(rankBaseurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("RankingSummary", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;

        
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }


  const popupFilter = () => {
    openModal()
  }


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {
        loading === true ? <Loader /> : null
      }

      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Keywords</Typography>
            <Typography sx={{ color: 'grey' }}>Ranking Summary</Typography>
            {CurrentMenu === 'ranked_keywords' && <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Rank Summary</Typography>}
            {CurrentMenu === 'comparison' && <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Comparison</Typography>}
            {CurrentMenu === 'visibility_score' && <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Visibility Score</Typography>}
            {CurrentMenu === 'keyword_trend' && <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Keyword Trend</Typography>}
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {(CurrentMenu === 'ranked_keywords' && rankSummaryPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }

            {(CurrentMenu === 'comparison' && rankComparisonPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }


            {(CurrentMenu === 'visibility_score' && rankVisibilityScorePlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }

            {(CurrentMenu === 'keyword_trend' && keywordTrendPlan.status) &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                  csvdown={csvdown} pdfdown={pdfdown} />
              </>
            }

          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f", display: 'flex' }} >{CurrentMenu === 'ranked_keywords' && "Rank Summary"} {CurrentMenu === 'comparison' && "Comparison"}{CurrentMenu === 'visibility_score' && "Visibility Score"}{CurrentMenu === 'keyword_trend' && "Keyword Trend"} {CurrentMenu === 'ranked_keywords' && <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" onClick={rankSummaryPlan.status ? popupFilter : null} />
            </span>} {CurrentMenu === 'visibility_score' && <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" onClick={rankVisibilityScorePlan.status ? popupFilter : null} />
            </span>} {CurrentMenu === 'comparison' && <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" onClick={rankComparisonPlan.status ? popupFilter : null} />
            </span>} {CurrentMenu === 'keyword_trend' && <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" onClick={keywordTrendPlan.status ? popupFilter : null} />
            </span>} </h2>
            <p className="subheader-timedate">as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>
          </div>

        </div>

        <div className="link_cnt_hdr_blw_wrap googleanlytics">

          <button data-id="ranked_keywords" className={CurrentMenu === 'ranked_keywords' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu}>
            Rank Summary
          </button>

          <button data-id="visibility_score" className={CurrentMenu === 'visibility_score' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu}>
            Visibility Score
          </button>
          <button data-id="comparison" className={CurrentMenu === 'comparison' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu}>
            Comparison
          </button>
          <button data-id="keyword_trend" className={CurrentMenu === 'keyword_trend' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu}>
            Keyword Trend
          </button>
        </div>

        {
          CurrentMenu === 'ranked_keywords' ? (rankSummaryPlan.status ? <Ranklinksummary modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice} SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} RankTypeval={RankTypeval} SetRankTypeval={SetRankTypeval} /> : <Freeaudit planStatus={rankSummaryPlan} />) : null
        }

        {
          CurrentMenu === 'comparison' ? (rankComparisonPlan.status ? <RankingComparison modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice} SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} RankTypeval={RankTypeval} SetRankTypeval={SetRankTypeval} /> : <Freeaudit planStatus={rankComparisonPlan} />) : null
        }

        {
          CurrentMenu === 'visibility_score' ? (rankVisibilityScorePlan.status ? <RankingVisibility modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice} SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} /> : <Freeaudit planStatus={rankVisibilityScorePlan} />) : null
        }

        {
          CurrentMenu === 'keyword_trend' ? (keywordTrendPlan.status ? <RankingKeywordTrend modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} RankCampaign={RankCampaign} SetRankCampaign={SetRankCampaign} RankJob={RankJob} SetRankJob={SetRankJob} RankEngine={RankEngine} SetRankEngine={SetRankEngine} RankCountry={RankCountry} SetRankCountry={SetRankCountry} RankState={RankState} SetRankState={SetRankState} RankCity={RankCity} SetRankCity={SetRankCity} RankSystem={RankSystem} SetRankSystem={SetRankSystem} RankDevice={RankDevice} SetRankDevice={SetRankDevice} CurrentMenu={CurrentMenu} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} /> : <Freeaudit planStatus={keywordTrendPlan} />) : null
        }










        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="error_plagarism_report">{ErrorMsg}</div>
              <div className="four_col">
                <div className="row">
                  <div className="col">
                    <label className="form-label">Campaign</label>
                    <Select
                      name="campaign"
                      value={dcampaignoption}
                      options={campaignoption}
                      onChange={(e) => {
                        Setcampaign(e.value);
                        Setdcampaignoption(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Completed Date</label>
                    <Select
                      value={dselectjobid}
                      options={jobidData}
                      onChange={(e) => {
                        setselectjobid(e.value);
                        setdselectjobid(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="form-label">Search Engine</label>
                    <Select
                      value={dSearchEngine}
                      options={Engineoptions}
                      onChange={(e) => {
                        SetSearchEngine(e.value);
                        SetdSearchEngine(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Country</label>
                    <Select
                      value={dCountryHandle}
                      options={Countryoptions}
                      onChange={(e) => {
                        SetCountryHandle(e);
                        SetdCountryHandle(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <label className="form-label">State</label>
                    <Select
                      value={dStateHandle}
                      options={Stateoptions}
                      onChange={(e) => {
                        SetStateHandle(e);
                        SetdStateHandle(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">City</label>
                    <Select
                      value={dCity}
                      options={CityOptions}
                      onChange={(e) => {
                        SetCity(e);
                        SetdCity(e);
                      }}
                      styles={{ ...customDropdownStyles, container: (provided) => ({ ...provided, width: '100%' }) }}
                    />
                  </div>
                </div>

                <div className="col">
                  <label className="form-label">System</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="system"
                        value="mobile"
                        onChange={(e) => SetSystem(e.target.value)}
                        checked={System === 'mobile'}
                      />
                      Mobile
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="system"
                        value="desktop"
                        onChange={(e) => SetSystem(e.target.value)}
                        checked={System === 'desktop'}
                      />
                      Desktop
                    </label>
                  </div>
                </div>

                {System === 'mobile' && (
                  <div className="col">
                    <label className="form-label">Devices</label>
                    <div className="radio-group">
                      <label>
                        <input
                          type="radio"
                          name="devices"
                          value="android"
                          checked={Device === 'android'}
                          onChange={(e) => SetDevice(e.target.value)}
                        />
                        Android
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="devices"
                          value="ios"
                          checked={Device === 'ios'}
                          onChange={(e) => SetDevice(e.target.value)}
                        />
                        iOS
                      </label>
                    </div>
                  </div>
                )}
              </div>

            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">

            <button
              className="form_submit_btn"
              onClick={finalreportNow}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button
              className="form_submit_btn"
              style={{ cursor: "pointer" }}
              onClick={closeModal}
            >
              close
            </button>


          </DialogActions>
        </Dialog>




      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />


      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }

    </div>


  );
}
