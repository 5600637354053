import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CommonAlert from "../../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../../DatagridComp";
import { CommonErrorAlert } from "../../../../Commonalert/commonErrorAlert";

export default function LostLinks(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [LinkUrl, SetLinkUrl] = useState('')
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value

  useEffect(() => {
    callUserdataApi();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_new_lost/?link_type=lost&user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (datavalue?.data) {
        if (datavalue?.data?.job_date) {

          props.setjobDate(moment(datavalue.data.job_date).format("MMMM DD,YYYY HH:mm"));
        }
      }

      SetLinkUrl(datavalue.data.link_url)
      const dataval = datavalue?.data?.lost_links_table?.lost_links;
      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });


        setCardData(newData);
        SetAutoheightval(false)
      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }
    } catch (err) {
      console.log('err', err)
    }
    setLoading(false);
  };
  const columns =  [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date_found",
      headerName: "Date Found",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "Recent referring domains date.",
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 400,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Referring page's title.",
      renderCell: (cardData) => {

        let firstEightLetters = cardData?.row?.referring_page;
        let whoedata = firstEightLetters;

        let baseUrl = cardData?.row?.referring_page.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData?.row?.referring_page.split("/").slice(3).join("/");
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.title}</span>
            <br />
            <Tooltip title={cardData?.row?.referring_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.referring_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "da",
      headerName: "DA",
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Domain InLink Rank of the target's root domain.",
    },
    {
      field: "pa",
      headerName: "PA",
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Link anchor text for text links/Destination page's URL.",
    },
    {
      field: "anchor",
      headerName: "Anchor/Target Page",
      minWidth: 400,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Link anchor text for text links/Destination page's URL.",
      renderCell: (cardData) => {
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.anchor}</span>
            <br />
            <div className="prefered_url_external02">
              <a target="_blank" href={cardData?.row?.target_page} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="spanlink">
                  <LinkIcon /> {cardData?.row?.target_page}
                </span>
              </a>
            </div>
          </div>
        );
      },
    },
    {
      field: "link_type",
      headerName: "Link Type",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Type of backlink.",
    },
    {
      field: "nofollow",
      headerName: "Follow/No Follow",
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Number of backlinks marked as nofollow and number of backlinks NOT marked as nofollow.",
    },
    {
      field: "first_seen",
      headerName: "First Seen",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "The date when the backlink was discovered and added to the index.",
    },
    {
      field: "reason",
      headerName: "Reason",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The reason why the backlink's status was changed from live to lost.",
    },
  ];
  const rows = cardData ?? [];
  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      callUserdataApi()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }
  }, [props])
  return (
    <div >
      <div >
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={4}
        />
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
      </div>
    </div>
  );
}
