import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./../style.css";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from 'react-select';
import { color } from "d3-color";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import NewLoader from "../NewLoader";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import InprogressIcon from "../../../Icons/work-in-progress-unscreen.gif";
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Standardreport() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [ShowInProgress, SetShowInProgress] = useState(false);
  const [InProgressMsg, SetInProgressMsg] = useState("Report is Loading....");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [locationval, setlocationval] = useState([
    { value: 'rankingSummary', label: 'Ranking Summary' },
    { value: 'competitorRankings', label: 'Competitor Rankings' },
    { value: 'missedOpportunities', label: 'Missed Opportunities' },
    { value: 'analysisSummary', label: 'Analysis Summary' },
    { value: 'spellChecker', label: 'Spell Checker' },
    { value: 'siteAnalysis', label: 'Site Analysis - Site Audit' },
    { value: 'schemaAnalysis', label: 'Schema Analysis' },
    { value: 'performance', label: 'Performance' },
    { value: 'sourceCodeAnalysis', label: 'Source Code Analysis' },
    { value: 'accessibilityReport', label: 'Accessibility Report' },
    { value: 'internalLinks', label: 'Links - Internal Links' },
    { value: 'externalLinks', label: 'Links - External Links' },
    { value: 'pageIndexReport', label: 'Page Index Report' },
    { value: 'backlinksSummary', label: 'Backlinks Summary' },
    { value: 'analyticsReport', label: 'Analytics Report' }
  ]);
  const [checkD, setcheckD] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2");
  const [initialDateval, SetInitialDateval] = useState([]);
  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SetSectionNameHandle = (passvalue) => {
    setSelectval(passvalue)
  }

  const AnalyzeReport = async () => {
    setLoading(true)
    SetErrorMsg("")
    SetShowInProgress(true);
    if (selectval == []) {
      SetErrorMsg("Please select a report");
      SetShowInProgress(false);
    }
    else {
      var dataval = ""
      if (selectval?.label == 'Ranking Summary') {

        dataval = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&formats=pdf&timezone=${timezoneval()}`
      }

      else if (selectval?.label == "Competitor Rankings")
        dataval =
          `${process.env.REACT_APP_API_URL}keywords/v2/competitor_ranking_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Missed Opportunities")
        dataval =
          `${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Analysis Summary")
        dataval =
          `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Spell Checker")
        dataval =
          `${process.env.REACT_APP_API_URL}content/v2/spell_check/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Site Analysis - Site Audit")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Schema Analysis")
        dataval =
          `${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Performance")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Source Code Analysis")

        dataval = `${process.env.REACT_APP_API_URL}technical/v2/sourcecode_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`

      else if (selectval?.label == "Accessibility Report")
        dataval = `${process.env.REACT_APP_API_URL}technical/accessibility2_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Links - Internal Links")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Links - External Links")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Page Index Report")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Backlinks Summary")
        dataval =
          `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Analytics Report")
        dataval =
          `${process.env.REACT_APP_API_URL}googleanalytics/google_organic_search_traffic/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=landingPagePlusQueryString&selected_metrics=organicGoogleSearchClicks&formats=pdf&timezone=${timezoneval()}`
      try {

        const datavalerr = await fetch(
          dataval,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });

        if (datavalerr.ok) {
          setSelectval("")
          const blob = await datavalerr.blob();

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var filedata = await Commonfun(selectval?.label, "pdf", ApiToken, esodata.domaindata.value.header_selected);

          link.download = filedata;

          link.click();

          window.URL.revokeObjectURL(link.href);
        } else {
          SetShowInProgress(false);


          if (datavalerr?.status == 599 || datavalerr?.status == 500 || datavalerr?.status == 400 || datavalerr?.status == 404) {
            datavalerr.json().then(data => {
              setapiOpen(true);
              setapierrorMsg(data?.error)
            })


          }
          else if (datavalerr?.status == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          console.error(
            "Failed to fetch PDF:",
            datavalerr.status,
            datavalerr.statusText
          );
        }

        SetShowInProgress(false);
      } catch (err) {
        console.log(err)
      }
    }
    setLoading(false)
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const campaignreportNow = async () => {
    setsearchselectedRows(selectedRows)
    setfaqData(2)
    setToggle(false)
    if (selectedRows.length > 0) setIsOpen(true);
    else setsearchopen(true);
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectcamptval.length <= 0 || selectsectionval.length <= 0) {
      errors.dateerror = "This field is required.";
      errs = 2;
      setLoading(false);
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      setValidation(errors);
      return false;
    }
    else {

      var keywordselectdata = searchselectedRows.map(
        (dataselectval) => dataselectval.keyword
      );
      setLoading(false);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  
  const freeAudit = async () => {
    const check = await Utils("standard_report", ApiToken);
    setfreesiteaudit(check);

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=59`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  useEffect(() => {
    freeAudit();
    faqdatafun();
    GetuserDatavalue();

  }, [])
  return (
    <div className={`over_all_container search_prnt ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Standard Report </title>
      </Helmet>
      {
        loading === true ? <NewLoader /> : null
      }
      <div className="cnt_audit_outer_wrap search_key_wrap searchvolume_key_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Standard Report

            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> Standard Report</h2>

          </div>

          <Helmet>
            <meta charSet="utf-8" />
            <title> Standard Report </title>
          </Helmet>
        </div>


        {ShowInProgress == false && freesiteaudit.status ?
          <div className="plagarism_analyzer_row_standard">
            <div className="input_col search_location_with_tool">

              <label className="reportName">Report:</label>


              <div className="dummyselect">
                <Select
                  name="report"
                  options={locationval}
                  onChange={(e) => { SetSectionNameHandle(e) }}
                  placeholder="Select Report"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  className="standardreport_dropdown"
                  classNamePrefix="standardreport_dropdown"
                />
                <span style={{ color: "red" }}>{ErrorMsg}</span>
              </div>

            </div>
            <div className="button_col">
              <button className="runreportnew" onClick={() => {
                if (first.statusDomain) {

                  setcheckD(first.statusDomain);
                } else {
                  AnalyzeReport()
                }
              }}>
                Download
              </button>
            </div>

          </div> : <Freeaudit planStatus={freesiteaudit} />}



        <div className="error_plagarism_report msg_errormsg">

        </div>
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts &&


        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />

      }
      {ShowInProgress == true ? (
        <div className="show_in_progress_brand_mention">
          <div className="wrapperAlert">
            <div className="contentAlert">
              <div className="topHalf">
                <p>
                  <img src={InprogressIcon} />
                </p>
                <h1>{InProgressMsg}</h1>

                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>
      ) : null}

      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                lineHeight: "1px",
                width: "max-content",
              }}
            >
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url}>
                {domainNameUrl.domain_url}
              </a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button
            onClick={() => {
              navigate(first.path);
            }}
          >
            {first.name}
          </Button>
          <Button onClick={() => setcheckD(false)}>{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
