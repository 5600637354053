import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import DonutChart from "react-donut-chart";
import Loader from "../CommonLoader";
import { lazy, Suspense } from "react";
import { NavLink, Link } from "react-router-dom";
import { ChartContainer, BarPlot } from "@mui/x-charts";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom"
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import CommonAlert from "../../../Commonalert/commonalert";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";



export default function RankingListDetail(props) {

  let querystring = useParams();
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const ItemId = querystring.id;
  const jobitemid = querystring.jobid;

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const [LabelData, SetLabelData] = useState([])
  const [DeskTopData, SetDeskTopData] = useState([])
  const [MobileData, SetMobileData] = useState([])


  const [LabelData1, SetLabelData1] = useState([])
  const [DeskTopData1, SetDeskTopData1] = useState([])
  const [MobileData1, SetMobileData1] = useState([])


  const [System, SetSystem] = useState('');
  const [Device, SetDevice] = useState('')

  const [RankCampaign, SetRankCampaign] = useState('')
  const [RankJob, SetRankJob] = useState('')
  const [RankEngine, SetRankEngine] = useState('')
  const [RankCountry, SetRankCountry] = useState('')
  const [RankState, SetRankState] = useState('')
  const [RankCity, SetRankCity] = useState('')
  const [RankSystem, SetRankSystem] = useState('')
  const [RankDevice, SetRankDevice] = useState('')
  const [FilterButtonTriggered, SetFilterButtonTriggered] = useState(false)
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate()

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  var startColor = "#6495ed";
  var endColor = "#dc143c";
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const finalreportNow = async () => {

    setLoading(true);
    SetErrorMsg("");
    if (

      selectjobid !== "" ||
      campaign !== "" ||
      SearchEngine !== "" ||
      Country !== "" ||

      System !== '' ||
      Device !== '' ||
      typeof State.value !== 'undefined' || typeof City.value !== 'undefined'
    ) {
      SetRankCampaign(campaign)

      SetRankJob(selectjobid)
      SetRankEngine(SearchEngine)
      SetRankCountry(Country)

      if (State.value) {
        SetRankState(State.value)
      }
      if (City.value) {
        SetRankCity(City.value)
      }


      SetRankSystem(System)
      SetRankDevice(Device)
      SetFilterButtonTriggered(true)


    }

    else {
      setLoading(false);
      return SetErrorMsg("Please Select the All Fields !");
    }
    closeModal();
    setLoading(false);
  };
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: 'Earlier Occurence',
        data: seooccurData,
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: ["rgb(255,	174,	31)"],
      },
    ],
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;

  const [columns, Setcolumns] = useState([])


  const columnsIni = [

    {
      field: "id",
      headerName: "#",
      minWidth: 90,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "type",
      headerName: "Device Type",
      minWidth: 300,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },




  ];

  const rows = cardData ?? [];






  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }







  const LoadData = async (tableid = '', jobid = "") => {
    setjobidData([]);

    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&job_id=${jobitemid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      var ResultData = [];
      if (datavalue?.data) {
        if (datavalue?.data?.result) {
          ResultData = datavalue.data.result
          const labelsIn = ResultData.map(entry => entry.job_date);
          const DestktopIn = ResultData.map(entry => entry.desktop_preferred_url_position === null ? 0 : entry.desktop_preferred_url_position);
          const MobileIn = ResultData.map(entry => entry.mobile_preferred_urk_position === null ? 0 : entry.mobile_preferred_urk_position);
          const labelsIn1 = ResultData.map(entry => entry.job_date).reverse();
          const DestktopIn1 = ResultData.map(entry => entry.desktop_preferred_url_position === null ? 0 : entry.desktop_preferred_url_position).reverse();
          const MobileIn1 = ResultData.map(entry => entry.mobile_preferred_urk_position === null ? 0 : entry.mobile_preferred_urk_position).reverse();
          var ColumnsMerge = [];

          labelsIn.forEach(element => {


            ColumnsMerge.push({

              field: element,
              headerName: element,
              width: 300,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",





            })


          });

          ColumnsMerge = ColumnsMerge.reverse();


          ColumnsMerge = columnsIni.concat(ColumnsMerge)
          Setcolumns(ColumnsMerge)

          var LoadTableValue = [];

          LoadTableValue.push({
            id: 1,
            type: '' + datavalue.data.keyword + ' - Web Rank'

          })

          LoadTableValue.push({
            id: 2,
            type: '' + datavalue.data.keyword + ' - Mobile Rank'
          })

          let firstObject = LoadTableValue[0];
          let secondObject = LoadTableValue[1];
          setCardData(LoadTableValue)

          ResultData.forEach(element => {
            firstObject[element.job_date] = element.desktop_preferred_url_position;
            secondObject[element.job_date] = element.mobile_preferred_urk_position;

          });
          SetLabelData(labelsIn)
          SetDeskTopData(DestktopIn)
          SetMobileData(MobileIn)

          SetLabelData1(labelsIn1)
          SetDeskTopData1(DestktopIn1)
          SetMobileData1(MobileIn1)
        }
      }
      if (datavalue?.data) {
        setjobDate(moment(datavalue.data.job_date).format("MMMM DD,YYYY HH:mm"));

      }



    } catch (err) {
      console.log(err)
    }
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=campaign`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (campaigndatavalue.data.campaigns) {
        if (campaigndatavalue.data.campaigns) {
          var campaignSections = [];
          campaigndatavalue.data.campaigns.forEach((element) => {
            campaignSections.push({ label: element.category__name, value: element.category__id });
          });
          Setcampaignoption(campaignSections);
        }
      }
    } catch (err) { }

    try {
      const jobdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (jobdatavalue.data.jobs) {
        if (jobdatavalue.data.jobs) {
          var jobSections = [];
          jobdatavalue.data.jobs.forEach((element) => {
            jobSections.push({ label: element.completed_at, value: element.id });
          });
          setjobidData(jobSections);
        }
      }
    } catch (err) { }
    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=search_engine`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (enginedatavalue.data.search_engines) {
        if (enginedatavalue.data.search_engines) {
          var Sections = [];
          enginedatavalue.data.search_engines.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__search_engine__name, value: element.search_engine_locale__search_engine__id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }



  }





  const chartData = {
    labels: LabelData1,
    datasets: [
      {
        label: "Desktop Rank",
        data: DeskTopData1,
        borderColor: "orange",
        backgroundColor: "rgb(255, 235, 219)",
        fill: {
          target: "origin",
          above: "rgba(255, 235, 219, 0.3)"
        }
      },
      {
        label: "Mobile Rank",
        data: MobileData1,
        borderColor: "Yellow",
        backgroundColor: "rgb(255, 242, 128)",
        fill: {
          target: "origin",
          above: "rgba(255, 242, 128, 0.3)"
        }
      }


    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        position: "bottom",
        ticks: {
          color: "#0b2033",
          maxRotation: window.innerWidth < 600 ? 20 : 45,
          minRotation: 0,
          autoSkip: true,
          maxTicksLimit: window.innerWidth < 600 ? 5 : 10,
        },
      },
      y: {
        ticks: {
          color: "#0b2033",
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        display: window.innerWidth > 600,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    layout: {
      padding: window.innerWidth < 600 ? 5 : 10,
    },
  };

  const chartOptions2 = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        position: 'bottom',
        ticks: {
          color: '#0b2033',
          maxRotation: 45,
          minRotation: 0
        }
      },
      y: {
        ticks: {
          color: '#0b2033',
          beginAtZero: true,
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      }
    },
    layout: {
      padding: 10
    }
  };

  useEffect(() => {
    LoadData()
    faqdatafun();
  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {
      LoadData()
      props.SetFilterButtonTriggered(false)
    }

  }, [props])
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=4`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&job_id=${jobitemid}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });

      if (response.ok) {

        const blob = await response.blob();


        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("RankingListdetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;


        link.click();


        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&job_id=${jobitemid}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });

      if (response.ok) {

        const blob = await response.blob();


        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("RankingListdetail", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;


        link.click();


        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  }

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  return (
    <> <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />

      {loading === true ? <Loader /> : null}

      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Keywords</Typography>
              <Link to="/ranking-summary">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Ranking Summary</Typography>
              </Link>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Ranking Summary Detail Report</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>
            {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}

          </div>

          <div className="cnt_hdr_top_wraps" style={{ marginBottom: "50px" }} >
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Ranking Summary Detail Report</h2>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
          </div>



          <div className="site_regress_chart_info">
            <div className="chart-container" style={{ height: '400px', width: '100%', minheight: "250px" }}>
              <Line data={chartData} options={chartOptions2} />
            </div>
            <p style={{ color: "rgb(255 96 31)", textAlign: "center", fontSize: "16px", fontWeight: "600" }}>Keyword Trends</p>
          </div>



          <div
            className="data_table internal_link_checker_table rankinglistdetail"
            style={{ width: "100%", padding: "20px 0" }}
          >
            <div style={{ height: rows.length > 5 ? 500 : 'unset', width: "100%" }}>
              <DataGrid
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  noResultsOverlay: MyCustomNoRowsOverlay,
                  footer: () => (
                    <Box className="table-footer-container">
                      <Box className="table-footer-pagination">
                        <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                      </Box>
                      <MSelect
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        size="small"
                        className="table-footer-select"
                      >
                        <MenuItem value={10}>10 per page</MenuItem>
                        <MenuItem value={30}>30 per page</MenuItem>
                        <MenuItem value={50}>50 per page</MenuItem>
                        <MenuItem value={75}>75 per page</MenuItem>
                        <MenuItem value={100}>100 per page</MenuItem>
                      </MSelect>
                    </Box>
                  ),
                }}
                rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                columns={columns}
                pagination

                gridRowId={(row) => row.id}
                getRowHeight={() => "auto"}
                autoHeight
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick

              />
            </div>
          </div>
        </div>
      </div>
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </>
  );
}
