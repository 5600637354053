import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import DomainDataReducer from './Store/DomainData'
import DomianListReducer from './Store/ListOfDomains'
import SectionDomainData from './Store/SectionDomainData';
import CompetitiorJobId from './Store/CompetitiorJobId';
import CompetitiorDomainId from './Store/CompetitiorDomainId';
import CompetitorInJobId from './Store/CompetitorInJobId';
import PageredirectSlice from './Store/PageredirectSlice';

import OnpageJobId from './Store/OnpageJobId';
import ApiAuthKey from './Store/ApiAuthKey';
import UserTokenReducer from './Store/UserTokenSlice';
import UserSubscriptionReducer from './Store/SubscriptionSlice'
import UserFirstTImeLoginReducer from './Store/FirstTimeLoginSlice'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppProvider } from './Context/Context';


const store=configureStore({
  reducer:{
    domaindata:DomainDataReducer,
    domainlist:DomianListReducer,
    sectiondomaindata:SectionDomainData,
    competitorjobids:CompetitiorJobId,
    competitordomainids:CompetitiorDomainId,
    competitorinjobids:CompetitorInJobId,
    onpagejobids:OnpageJobId,
    apiauthkey:ApiAuthKey,
    usertoken:UserTokenReducer,
    usersubscription:UserSubscriptionReducer,
    PageredirectSlice:PageredirectSlice,
    firsttimelogin:UserFirstTImeLoginReducer
  }
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
       <Provider store={store}>
       <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY}>
        <AppProvider>
          <App />
        </AppProvider>
       </GoogleOAuthProvider>
       </Provider>
  
  </React.StrictMode>
);

reportWebVitals();
