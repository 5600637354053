import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import  { useLayoutEffect,  useContext } from "react";
import axios from "axios";
import { AppContext } from "../../Context/Context";

const Userlogincontext=()=>{

    const esodata = useSelector((state) => state);
    const location = useLocation();
  
    const [apiopen, setapiOpen] = React.useState(false);
    const [apierrorMsg,setapierrorMsg]=useState();
  
    const navigate = useNavigate();
    const ApiToken=esodata.apiauthkey.value

  const {topactionnotify, setTopactionnotify} = useContext(AppContext);
    

    return(
        <>
        </>
    )


}

export default Userlogincontext