import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from 'react-select';
import Loader from "../CommonLoader";
import EditKeywordAddForm from "./EditCampaignForm";
import GooglePng from "../../../Icons/google.png";
import BingPng from "../../../Icons/bing.png";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

const CampaignSettingsList = (props) => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)

  const [freesiteaudit, setfreesiteaudit] = useState({});
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [regionalLevel, setRegionalLevel] = useState(null);
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [columns, setColumns] = useState([]);
  const [CamPopTitle, SetCamPopTitle] = useState(null);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  useEffect(() => {
    LoadAddFormFilterOptions();
    callUserdataApi();
    getRegionalLevelSetting();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (CamPopTitle && Engineoptions) {
      setIsOpen(true);
    }
  }, [Engineoptions])

  useEffect(() => {

    if (props.EnableKeywordAddForm === true) {
      SetEditCampaignData()
    }

    if (props.CallCampaignTable === true) {
      callUserdataApi()
      props.SetCallCampaignTable(false)
    }


    if (props.Auditstatus) {
      setfreesiteaudit(props.Auditstatus)
    }

  }, [props])

  const [checkingD, setcheckingD] = useState(false);


  useEffect(() => {
    if (props.checkingD === true) {
      addcampaign();
    }
  }, [props.checkingD])



  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const rows = cardData ?? [];

  function addcampaign() {
    SetEditBrandTerm('');
    SetSearchEngine('');
    setCountryValue('');
    setStateValue('');
    setCityValue('');
    openModal();
  }

  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setCampIsOpen(true);
  };

  const ShowNearbyUl = (e) => {
    const Element = e.target.closest(".engines");
    const nextElement = Element.nextElementSibling;
    if (nextElement) {
      nextElement.classList.toggle("hidden");
    }


    const lastChild = Element.lastElementChild;

    const spanToChange = lastChild.querySelector("span.show");
    const spanToChangeHide = lastChild.querySelector("span.hide");

    if (spanToChange) {
      spanToChange.classList.remove("show");
      spanToChange.classList.add("hide");
    }

    if (spanToChangeHide) {
      spanToChangeHide.classList.remove("hide");
      spanToChangeHide.classList.add("show");
    }

  };

  const DeleteCampaignSettings = async (e) => {
    setCampIsOpen(false)
    const Id = deleteId;

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_delete/?user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
    } catch (err) {
      console.log(err);
    }

    callUserdataApi();
  };

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });



      if (datavalue.data && datavalue.data.length > 0) {
        if (datavalue.data) {
          const newData = datavalue.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const getRegionalLevelSetting = async () => {
    setLoading(true);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        setRegionalLevel(datavalue.data.keyword_settings);
        const column1 = [
          {
            field: "sid",
            headerName: "#",
            minWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
            description: 'Index of the row',
          },

          {
            field: "campaign_name",
            headerName: "Campaign Name",
            minWidth: 250,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: true,
            disableClickEventBubbling: true,
            description: 'Campaign names that have been added to the domain.',
          },
          {
            field: "total_keywords_tracked",
            headerName: "Total Keywords",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: true,
            description: 'Total number of keywords tracked in the campaign',
            disableClickEventBubbling: true,
          },
          {
            field: "search_engine",
            headerName: "Search Engine Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            sortable: true,
            disableClickEventBubbling: true,
            description: 'List of Search engine configured in the Campaign settings.',
          },
          {
            field: "country",
            headerName: "Country Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            sortable: true,
            disableClickEventBubbling: true,
          },
        ];
        const columnState = [
          {
            field: "state",
            headerName: "State Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: true,
            disableClickEventBubbling: true,
          }
        ]
        const columnCity = [
          {
            field: "city",
            headerName: "City Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: true,
            disableClickEventBubbling: true,
          }
        ]
        const columnEditDelete = [
          {
            field: "edit",
            headerName: "Edit",
            minWidth: 140,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (cardData) => {
              return (
                <Tooltip title={'Click here to edit the campaign settings'}>
                  <a
                    className="EditBrandedTermClass"
                    href="javascript:void(0)"
                    data-id={cardData.row.campaign_id}
                  >
                    <button
                      className="buttonView edit_icon keyword-edit-icon"
                      onClick={() => EditCampaignSettings(cardData.row)}
                    >


                      <EditIcon className="edit-icon-svg"/>
                    </button>
                  </a>
                </Tooltip>
              );
            },
          },
          {
            field: "delete",
            headerName: "Delete",
            minWidth: 140,
            flex: 1,
            headerAlign: "center",
            align: "center",
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (cardData) => {
              return (
                <Tooltip title={'Click here to delete the campaign'}>
                  <a className="DeleteBrandedtermClass" href="javascript:void(0)" >
                    <button className="buttonView trash_icon keyword-delete-icon"
                      onClick={() => handleDeleteClick(cardData.row.campaign_id)}


                      id={cardData.row.campaign_id}>

                      <DeleteIcon className="delete-icon-svg"/>
                    </button>
                  </a>
                </Tooltip>
              );
            },
          },
        ]
        if (datavalue.data.keyword_settings == 'State') {
          let finalColumn = column1.concat(columnState, columnEditDelete);
          setColumns(finalColumn);
        } else if (datavalue.data.keyword_settings == 'City') {
          let finalColumn = column1.concat(columnState, columnCity, columnEditDelete);
          setColumns(finalColumn);
        } else {
          let finalColumn = column1.concat(columnEditDelete);
          setColumns(finalColumn);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [campIsOpen, setCampIsOpen] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [ErrorMsg, SetErrorMsg] = useState('')
  const [EditBrandTerm, SetEditBrandTerm] = useState('')
  function closeModal() {
    setIsOpen(false);
    setCampIsOpen(false);
    SetEditCamId('')
    SetErrorMsg('')
    props.setcheckingD(false);
  }
  function delopenModal(e) {
    setCampIsOpen(true)
    SetDeleteId(e.target.id)
  }
  function openModal(passRow = '') {

    if (passRow === '') {
      SetCamPopTitle('Add Campaign Data')
      setIsOpen(true);
    }
    else {
      SetCamPopTitle('Edit Campaign Data')
      setIsOpen(true);
    }
  }

  const SubmitEditBrand = async () => {

    var data_val = 1;
    if (EditBrandTerm === null || EditBrandTerm === '') {
      SetErrorMsg('Please enter the campaign name')
    }
    if (!SearchEngine) {
      setEngineerr("Please select search engine");
      data_val = 2;
    }
    if (!countryValue) {
      setCountryerr("Please select country");
      data_val = 2;
    }
    if (!stateValue && (regionalLevel == 'State' || regionalLevel == 'City')) {
      setStateerr("Please select state");
      data_val = 2;
    }
    if (!cityValue && regionalLevel == 'City') {
      setCityerr("Please select city");
      data_val = 2;
    }
    if (data_val == 1) {
      SetErrorMsg('');
      setEngineerr('');
      setCountryerr('');
      setStateerr('');
      setCityerr('');

      try {

        var response;

        if (EditCamId === '') {

          response = await axios.post(
            `${process.env.REACT_APP_API_URL}keywords/add_new_campaign/`,
            {
              user_domain_id: parseInt(esodata.domaindata.value.header_selected),
              campaign_name: EditBrandTerm,
              search_engine_id: SearchEngine.value,
              country_id: countryValue.value,
              state_id: stateValue.value,
              city_id: cityValue.value
            },
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate('/errorpages')
            }

          });
          if (response.status == 200) {
            setapiOpen(true);
            setapierrorMsg("Campaign Added Successfully")
          }
        }
        else {

          response = await axios.post(
            `${process.env.REACT_APP_API_URL}keywords/campaign_edit/`,
            {
              user_domain_id: parseInt(esodata.domaindata.value.header_selected),
              category_id: EditCamId,
              campaign_name: EditBrandTerm,
              search_engine_id: SearchEngine.value,
              country_id: countryValue.value,
              state_id: stateValue.value,
              city_id: cityValue.value
            },
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          ).catch((error) => {
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate('/errorpages')
            }
          });

        }



        if (response?.data) {
          callUserdataApi()
          closeModal()
        }

      } catch (err) {
        if (err?.response?.data?.error) {
          SetErrorMsg(err.response.data.error)
        }
        console.log(err)
      }

      props.GetCampaignData()

    } else {
      setLoading(false);

      return false;
    }





  }
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const regionalsetting = () => {
    navigate('/regional-level-settings');
  }

  const LoadAddFormFilterOptions = async (tableRow = '') => {
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            if (element.name === tableRow.country) {
              setCountryValue({ label: element.name, value: element.id })
              if (!regionalLevel || regionalLevel == 'State' || regionalLevel == 'City') {
                handlestatedataoptions({ label: element.name, value: element.id }, tableRow)
              }
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            if (element.name === tableRow.search_engine) {
              SetSearchEngine({ label: element.name, value: element.id })
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }
  };

  const handlestatedataoptions = async (e, tableRow = '') => {

    setCountryValue(e);

    setStateValue("");
    setCityValue("");

    var Countrys = e.value;
    handlestateoptions(Countrys, tableRow)
  }

  const handlestateoptions = async (Countrys = "", tableRow) => {

    if (Countrys == "" || Countrys == null || !Countrys)
      return false;



    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          if (element.state_name === tableRow.state) {
            setStateValue({ label: element.state_name, value: element.id });
            if (!regionalLevel || regionalLevel == 'State' || regionalLevel == 'City') {
              handleCityoptionsdata({ label: element.state_name, value: element.id }, tableRow);
            }
          }
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
      }
    }
  };
  const handleCityoptionsdata = (e, tableRow = '') => {
    setStateValue(e);
    var States = e.value;
    handleCityoptions(States, tableRow)
  }
  const handleCityoptions = async (States = "", tableRow) => {
    if (States == "" || States == null || !States) {
      return false;
    }

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          if (element.city_name === tableRow.city) {
            setCityValue({ label: element.city_name, value: element.id });
          }
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
      }
    }
  };

  const setCurrentCityValue = (e) => {
    setCityValue(e);
  }

  const [EditCampaignData, SetEditCampaignData] = useState();
  const [EditCampaignDataId, SetEditCampaignDataId] = useState('')
  const [EditCamId, SetEditCamId] = useState('')

  const EditCampaignSettings = async (tableRow) => {
    SetEditCampaignData();
    SetEditBrandTerm(tableRow.campaign_name);
    SetEditCamId(tableRow.campaign_id);
    await LoadAddFormFilterOptions(tableRow);
    openModal(tableRow)

  };


  return (
    <div>
      {(EditCampaignData && props.EnableKeywordAddForm === false) ? (
        <EditKeywordAddForm SetEditCampaignData={SetEditCampaignData} EditCampaignData={EditCampaignData} SetEnableKeywordAddForm={props.SetEnableKeywordAddForm} EditCampaignDataId={EditCampaignDataId} callUserdataApi={callUserdataApi} />
      ) : null}

      {
        (true) ?

          <DatagridComp
            columns={columns}
            rows={rows}
            autoheightval={autoheightval}
            datagridval={2}
          />

          : null
      }

      {CamPopTitle && modalIsOpen && <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter keyword_edit_form"
      >
        <DialogTitle className="keyword-dialog-header" style={{ marginBottom: "1em" }}>{CamPopTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <form className="frm_container">
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                        Campaign Name :
                      </label>
                      <input
                        type="text"
                        className="campaign-name"
                        defaultValue={EditBrandTerm}
                        onChange={(e) => { SetEditBrandTerm(e.target.value) }}
                        placeholder="Campaign Name"
                      />
                    </div>
                    <div>
                      <span className="error-value">{ErrorMsg}</span>
                    </div>
                  </div>
                  <div className="keyword-col2-fields">
                    <div>
                      <label for="city">
                        Search Engine :
                      </label>
                      <Select
                        name="engine"
                        value={SearchEngine}
                        options={Engineoptions}
                        placeholder="Select..."
                        onChange={(e) => { SetSearchEngine(e) }}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{engineerr}</span>
                    </div>
                  </div>
                </div>
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                        Country :
                      </label>
                      <Select
                        id="yourElementId"
                        name="country"
                        value={countryValue}
                        options={Countryoptions}
                        placeholder="Select..."
                        onChange={(e) => { handlestatedataoptions(e) }}
                        isSearchable
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{countryerr}</span>
                    </div>
                  </div>
                  {(regionalLevel == "State" || regionalLevel == "City") &&
                    <div className="keyword-col2-fields">
                      <div>
                        <label for="city">
                          State :
                        </label>
                        <Select
                          name="state"
                          value={stateValue}
                          options={Stateoptions}
                          placeholder="Select..."
                          onChange={handleCityoptionsdata}
                          isSearchable
                          styles={customDropdownStyles()}
                          menuPortalTarget={document.body}
                          className=""
                          classNamePrefix="addkeyword"
                        />
                      </div>
                      <div>
                        {" "}
                        <span className="error-value">{stateerr}</span>
                      </div>
                    </div>}
                </div>
                <div className="campaigns-addedit-row">
                  {regionalLevel === "City" && <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                        City :
                      </label>
                      <Select
                        name="city"
                        value={cityValue}
                        options={CityOptions}
                        placeholder="Select..."
                        onChange={setCurrentCityValue}
                        isSearchable
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{cityerr}</span>
                    </div>
                  </div>}
                  <div className="keyword-col2-fields">
                    <div>{' '}</div>
                    <div>{' '}</div>
                  </div>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="keyword-cancel" onClick={closeModal}>close</button>
          <button className="keyword-submit" style={{ marginTop: "auto" }} onClick={SubmitEditBrand}>
            Submit
          </button>
        </DialogActions>
      </Dialog>}
      <Dialog
        open={campIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this Campaign Name?

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={DeleteCampaignSettings} style={{ cursor: 'pointer' }} >
            OK
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CampaignSettingsList;
