import React, { useState, useEffect, useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import "./settings.css";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


const LinkCheckerSettings = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);

  const [TotalPageLoadTime, SetTotalPageLoadTime] = useState(0);
  const [PageLoadTime, SetPageLoadTime] = useState(0);
  const [SampleSpeed, SetSampleSpeed] = useState(0);
  const [Url, Seturl] = useState("");
  const [UserExperienceSettingsErr, SetUserExperienceSettingsErr] =
    useState("");
  const [UpdateId, SetUpdateId] = useState("");
  const [Apdex, SetApdex] = useState("");
  const [loading, Setloading] = useState(false);

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [totalpageerror, SetTotalpageerror] = useState()
  const [pageloaderror, SetPageloaderror] = useState()
  const [seederror, SetSeederror] = useState()
  const [uxscoreerror, SetUxscoreerror] = useState()
  const [userExpSettingPlan, setuserExpSettingPlan] = useState({ status: true, error: "" });

  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });

  const GetUserExperienceSettings = async () => {
    Setloading(true);
    SetTotalPageLoadTime(1);
    SetPageLoadTime(1);
    SetSampleSpeed(1);
    Seturl("");
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}settings/get_link_checker_settings/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      Setloading(false);
      if (datavalue?.data) {
        SetTotalPageLoadTime(datavalue.data?.internal_link_connection_timeout_secs);
        SetPageLoadTime(datavalue.data?.external_link_connection_timeout_secs);
        SetSampleSpeed(datavalue.data?.internal_link_javascript_execution_time_secs);
      }
    } catch (err) {
      Setloading(false);
      console.log(err);
    }
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const SaveSettings = async () => {
    SetUserExperienceSettingsErr("");
    SetTotalpageerror("")
    SetPageloaderror("")
    SetSeederror("")
    SetUxscoreerror("")
    var datavals = 2;

    if (TotalPageLoadTime === 0 || TotalPageLoadTime === "" || TotalPageLoadTime > 300) {
      SetTotalpageerror("Please fill the value between 1 to 300")

      datavals = 1
    }
    if (PageLoadTime === 0 || PageLoadTime === "" || PageLoadTime > 300) {
      SetPageloaderror("Please fill the value between 1 to 300")
      datavals = 1
    }
    if (SampleSpeed === 0 || SampleSpeed === "" || SampleSpeed > 300) {
      SetSeederror("Please fill the value between 1 to 300")
      datavals = 1
    }

    if (datavals != 1) {
      Setloading(true);
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}settings/set_link_checker_settings/`,
            {
              user_domain_id: esodata.domaindata.value.header_selected,
              internal_link_connection_timeout_secs: TotalPageLoadTime,
              internal_link_javascript_execution_time_secs: SampleSpeed,
              external_link_connection_timeout_secs: PageLoadTime,
            },

            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500 ||
              error?.response?.status == 405
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK") {
              navigate("/errorpages");
            }
          });
        setapiOpen(true);
        setapierrorMsg(response?.data?.message);
        Setloading(false);
      } catch (err) {
        Setloading(false);

      }
    }
  };

  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if (userExpSettingP.status) {
        GetUserExperienceSettings();
        faqdatafun();
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
    GetuserDatavalue();
  }, []);


  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkUserPlan = async () => {
      const userExpSettingP = await Utils("performance_settings", ApiToken);
      if (userExpSettingP.status) {
        var DomainList = esodata.domainlist.value;
        DomainList = DomainList.find(
          (obj) => obj.id == esodata.domaindata.value.header_selected
        );
        if (DomainList) {
          Seturl(DomainList.domain__url);
        }
      }
      setuserExpSettingPlan(userExpSettingP);
    };
    checkUserPlan();
  }, [esodata]);

  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input" ||
      event.target.classList[0] === "run_rpt_btn" ||
      event.target.classList[0] === "input_col" ||
      event.target.classList[0] === "plagarism_analyzer_row" ||
      event.target.classList[0] === "MuiDataGrid-cell--withRenderer" || event.target.classList[0] !== "MuiSvgIcon-root"
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=62`;

    try {
      const doaminsectiondatavalue = await axios
        .get(datavar, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Link Checker Settings</title>
      </Helmet>
      <div className={`over_all_container user_experieceover_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {loading === true ? <Loader /> : null}
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Settings</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Link Checker Settings</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>

            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font"> Link Checker Settings</h2>
            </div>


          </div>
          {userExpSettingPlan.status ?

            <div className="user_experince_form_container_wraps">
              <div className="user_experince_form_container link_checker_settings">

                <div className="form_row internal_form_settings" style={{}}>

                  <div className="form_column" style={{ flex: 1 }}>
                    <div className="label">
                      <h3 style={{ color: "#ff601f" }} className="correction_input_header">Internal Link Checker</h3>
                    </div>

                    <div className="label">
                      <label>Connection Time Out<span>(s)</span></label>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        defaultValue={TotalPageLoadTime}
                        maxLength={3}
                        value={TotalPageLoadTime}
                        onChange={(e) => SetTotalPageLoadTime(e.target.value)}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="err user_experince_form_err">{totalpageerror}</div>
                    </div>

                    <div className="label" style={{ marginTop: "10px" }}>
                      <label>Javascript Execution Time<span>(s)</span></label>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        defaultValue={SampleSpeed}
                        onChange={(e) => SetSampleSpeed(e.target.value)}
                        maxLength={3}
                        value={SampleSpeed}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="err user_experince_form_err">{seederror}</div>
                    </div>
                  </div>

                  <div className="form_column" style={{ flex: 1 }}>
                    <div className="label">
                      <h3 style={{ color: "#ff601f" }} className="correction_input_header">External Link Checker</h3>
                    </div>

                    <div className="label">
                      <label>Connection Time Out<span>(s)</span></label>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        defaultValue={PageLoadTime}
                        value={PageLoadTime}
                        maxLength={3}
                        onChange={(e) => SetPageLoadTime(e.target.value)}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="err user_experince_form_err">{pageloaderror}</div>
                    </div>
                  </div>

                </div>

                <div className="err user_experince_form_err">
                  {UserExperienceSettingsErr}
                </div>

                <div className="user_experince_form_button">
                  <button className="run_rpt_btn rn_rpt_btn over_all_accessibility runreportnew" onClick={first.statusDomain ? () => setcheckD(first.statusDomain) : SaveSettings}>
                    Save
                  </button>
                </div>

              </div>
            </div>



            : <Freeaudit planStatus={userExpSettingPlan} />
          }
        </div>
        <CommonAlert />
        

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />

        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
            <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  );
};

export default LinkCheckerSettings;
