import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import "./spellcheckstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from "@mui/material/Pagination";
import MSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from "./../Commonfun";
import { timezonechange } from "./../Commonfun";
import { timezoneval } from "./../Commonfun";
import { Breadcrumbs, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import { datezonechange } from "../Commonfun";
import spellcheckersvg from "../Asset/esocardicon/spellchecker.svg";
import PdfandCsv from "../PdfandCsv";
import DatagridComp from "../DatagridComp";

export default function CodeauditDetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [spelldata, setSpelldata] = useState("");
  const [tablecardData, setTableCardData] = useState([]);
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDownloading, setIsDownloading] = useState(false);
  const [autoheightval, SetAutoheightval] = useState(true);
  const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);

  const navigate = useNavigate();
  let querystring = useParams();
  let spell_audit_id = querystring.detailid;
  let spell_job_id = querystring.jobid;

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        },
      )
      .catch((error) => {
        if (
          error?.response?.status == 599 ||
          error?.response?.status == 400 ||
          error?.response?.status == 500
        ) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      });
    const date_dataval = datavalue?.data;
    setSpelldata(date_dataval);
    if (date_dataval?.job_date)
      setjobDate(moment(date_dataval?.job_date).format("MMMM DD,YYYY HH:mm"));
    else setjobDate(moment(date_dataval?.job_date).format("MMMM DD,YYYY"));
    const dataval = datavalue?.data?.table_data[0];
    const table_dataval = datavalue?.data?.table_data[0]?.result;
    setCardData(dataval);

    if (table_dataval) {
      const newData = table_dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setTableCardData(newData);
      SetAutoheightval(false);
    } else {
      SetAutoheightval(true);
    }

    setLoading(false);
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Index of the row",
    },
    {
      field: "original_sentence",
      headerName: "Existing Sentence",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: false,
      sortable: true,
      description: "Suggested words for the misspelled words.",
    },
    {
      field: "highlighted_sentence",
      headerName: "Suggested Sentence",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: false,
      sortable: true,
      description: "Suggested words for the misspelled words.",
      renderCell: (tablecardData) => {
        const text = tablecardData.row.highlighted_sentence;
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
      },
    },
    {
      field: "modification_type",
      headerName: "Modification Type",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: false,
      sortable: true,
      description: "Suggested words for the misspelled words.",
      renderCell: (params) => params.value ?? "N/A",
    },
  ];

  const rows = tablecardData ?? [];

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=pdf&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(
          "SpellcheckerDetail",
          "pdf",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error(
          "Failed to fetch PDF:",
          response.status,
          response.statusText,
        );
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=csv&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/csv",
            Authorization: `${ApiToken}`,
          },
        },
      );
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(
          "SpellcheckerDetail",
          "csv",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error(
          "Failed to fetch PDF:",
          response.status,
          response.statusText,
        );
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error during PDF download:", error);
    }
    setLoading(false);
  };
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=12`;

    try {
      const doaminsectiondatavalue = await axios
        .get(datavar, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input" ||
      event.target.classList[0] === "data_table" ||
      event.target.classList[0] === "plagiarisam_missing_waring_wrap_databox" ||
      event.target.classList[0] === "MuiDataGrid-footerContainer" ||
      event.target.classList[0] === "ignore_button_active"
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });

  return (
    <div
      className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spell Checker Detail</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer">
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography
                sx={{
                  color: "#ff601f",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Dashboard
              </Typography>
            </Link>
            <Typography sx={{ color: "grey" }}>Content</Typography>

            <Link to="/spell-checker">
              <Typography
                sx={{
                  color: "#ff601f",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Spell Checker
              </Typography>
            </Link>
            <Typography
              sx={{
                color: "#ff601f",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Spell Checker Details
            </Typography>
          </Breadcrumbs>

          <div className="exportpdf_outer">
            <button
              onClick={handleToggle}
              className="faq_class exportpdf"
              title="Frequently Asked Question"
            >
              <QuestionMarkIcon />
            </button>
            <>
              <PdfandCsv
                Downloadval={2}
                isDownloading={isDownloading}
                csvdown={csvdown}
                pdfdown={pdfdown}
              />
            </>
          </div>
          {toggle && (
            <Rightsidebar
              close={() => setToggle(false)}
              initialfaqDateval={initialfaqDateval}
            />
          )}
        </div>

        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div>
              <h2 className="heading_font">Detailed Spell Checker Report </h2>

              <a
                target="_blank"
                href={cardData?.link__url}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <span className="spanlink" style={{ flexDirection: "column" }}>
                  {cardData?.link__url}
                </span>
              </a>
              <p className="subheader-timedate">
                as of {timezonechange(jobDate)}{" "}
                <span style={{ fontWeight: 700 }}> {timezoneval()} </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className="common-card-container"
          style={{ justifyContent: "center" }}
        >
          <div className={`common-card-wrapper-style cardactivecontainer`}>
            <div className={`common-card-icon-container`}>
              <div className={`common-card-icons`}>
                <img
                  src={spellcheckersvg}
                  className={`common-card-icon-img`}
                  alt="Ranked Keywords"
                />
              </div>
            </div>
            <div className={`common-card-subtitle cardcontentactivecontainer`}>
              <span className="common-card-title">
                Number of Suggested Sentences
              </span>
              <span className="common-card-value">
                {cardData?.sentences_with_suggestions_count}
              </span>
            </div>
          </div>
        </div>
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />
      </div>
    </div>
  );
}
