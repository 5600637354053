import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import "./spellcheckstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import wordwrap from "wordwrapjs";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import RunReportNow from "../RunReportNow";
import { Helmet } from "react-helmet";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from "./../Commonfun";
import { timezonechange } from "./../Commonfun";
import { timezoneval } from "./../Commonfun";
import { datezonechange } from "./../Commonfun";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import Loader from "../CommonLoader";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import PdfandCsv from "../PdfandCsv";
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Spellcheckerlist() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [autoheightval, SetAutoheightval] = useState(true);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [spellCheckerPlan, setspellCheckerPlan] = useState({
    status: true,
    error: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });

  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at: "Select Date",
    id: "Select Date",
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at: "Select Date",
    id: "Select Date",
  });
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}spell-checker-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const checkUserPlan = async () => {
    const spellCheckerP = await Utils("spell_checker", ApiToken);
    if (spellCheckerP.status) {
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
    }
    setspellCheckerPlan(spellCheckerP);
  };

  const arr = [];

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "link__url",
      headerName: "URL",
      description: "URL for which the spell check has been accomplished.",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let baseUrl =
          cardData.row.link__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData?.row?.link__url}>
              <div className="prefered_url_external02">
                <a
                  className="table_links"
                  target="_blank"
                  href={cardData?.row?.link__url}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink">{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "sentences_with_suggestions_count",
      headerName: "Number of Sentences with Suggestions",
      description:
        "The total number of sentences with suggestions in the webpage URL provided.",
      minWidth: 270,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "View",
      headerName: "View",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + jobidValue}>
            <button className="view-report-button">View Report</button>
          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    setLoading(true);
    if (domainsecdata == "") domainsecdata = Domainsectiondata;
    if (jobid == "") jobid = jobidData;
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        },
      )
      .catch((error) => {
        if (
          error?.response?.status == 599 ||
          error?.response?.status == 400 ||
          error?.response?.status == 500
        ) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (
          error?.code == "ERR_NETWORK" ||
          error?.response?.status == 404
        ) {
          navigate("/errorpages");
        }
      });

    const completevalues = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=new_spell_checker&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        },
      )
      .catch((error) => {
        if (
          error?.response?.status == 599 ||
          error?.response?.status == 400 ||
          error?.response?.status == 500
        ) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (
          error?.code == "ERR_NETWORK" ||
          error?.response?.status == 404
        ) {
          navigate("/errorpages");
        }
      });
    var Selectedmets = [];
    completevalues?.data?.jobs.forEach((element) => {
      Selectedmets.push({
        value: element?.id,
        label: datezonechange(element?.completed_at),
      });
    });
    if (datavalue?.data?.job_date)
      setjobDate(
        moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"),
      );
    else setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));
    if (Selectedmets.length != 0) {
      SetInitialDateval(Selectedmets[0]);
      if (jobidData == "") {
        setSelectval(Selectedmets);
        if (dEngineoption.completed_at === "Select Date") {
          SetdEngineoption([Selectedmets[0]]);
          setprevdEngineoption([Selectedmets[0]]);
        }
      }
      setcompletedate(Selectedmets);

      setjobValue(datavalue?.data?.job_id);
    }
    const dataval = datavalue?.data?.table_data;

    if (dataval && dataval.length > 0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setCardData(newData);
      SetAutoheightval(false);
    } else {
      setCardData("");
      SetAutoheightval(true);
    }

    setLoading(false);
  };

  const reportNow = async () => {
    var errs = 1;
    let errors = {};

    if (!selectval || selectval.value == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
      setLoading(true);
      setjobidData(selectval.value);
      setIsOpen(false);
      setprevdEngineoption(dEngineoption);
      setjobValue(selectval.value);
      var domainsec = "";
      callUserdataApi(selectval.value, (domainsec = ""));
    }
    if (errs == 2) {
      return setValidation(errors);
    }
  };
  function openModal() {
    setfaqData(2);
    setToggle(false);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false);
  const [RunReportType, SetRunReportType] = useState("spell_check");
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&is_format=pdf&timezone=${timezoneval()}&domain_section_id=${Domainsectiondata}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun(
          "Spellchecker",
          "pdf",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&is_format=csv&timezone=${timezoneval()}&domain_section_id=${Domainsectiondata}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun(
          "Spellchecker",
          "csv",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  };
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          },
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (
            error?.code == "ERR_NETWORK" ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  };
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value);
    callUserdataApi("", passvalue.value);
  };
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=65`;

    try {
      const doaminsectiondatavalue = await axios.get(datavar, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById("root");
  e.addEventListener("click", function handleClick(event) {
    if (
      event.target.classList[0] === "cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "header_wrap" ||
      event.target.classList[0] === "nav_key_txt" ||
      event.target.classList[0] === "cnt_hdr_top_wrap" ||
      event.target.classList[0] === "react-dropdown-select" ||
      event.target.classList[0] === "react-dropdown-select-content" ||
      event.target.classList[0] === "react-dropdown-select-item" ||
      event.target.classList[0] === "site_cnt_hdr_blw_wrap" ||
      event.target.classList[0] === "App" ||
      event.target.classList[0] === "cnt_audit_outer_wrap" ||
      event.target.classList[0] === "MuiDataGrid-columnHeaderTitleContainer" ||
      event.target.classList[0] === "MuiDataGrid-toolbarContainer" ||
      event.target.classList[0] === "MuiDataGrid-row" ||
      event.target.classList[0] === "MuiDataGrid-cell" ||
      event.target.classList[0] === "MuiDataGrid-cellContent" ||
      event.target.classList[0] === "header_inner_btm_wrap" ||
      event.target.classList[0] === "over_all_container" ||
      event.target.classList[0] === "input_domain_field" ||
      event.target.classList[0] === "react-dropdown-select-input"
    ) {
      setfaqData(2);
      setToggle(false);
    }
  });
  return (
    <div
      className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spell Checker Report</title>
      </Helmet>

      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {loading === true ? <Loader /> : null}

      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography
                  sx={{
                    color: "#ff601f",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Dashboard
                </Typography>
              </Link>
              <Typography sx={{ color: "grey" }}>Content</Typography>
              <Typography
                sx={{
                  color: "#ff601f",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Spell Checker
              </Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button
                onClick={handleToggle}
                className="faq_class exportpdf"
                title="Frequently Asked Question"
              >
                <QuestionMarkIcon />
              </button>
              {spellCheckerPlan.status && (
                <PdfandCsv
                  Downloadval={1}
                  isDownloadingcsv={isDownloadingcsv}
                  isDownloading={isDownloading}
                  csvdown={csvdown}
                  pdfdown={pdfdown}
                />
              )}
            </div>
            {toggle && (
              <Rightsidebar
                close={() => setToggle(false)}
                setToggle={setToggle}
                initialfaqDateval={initialfaqDateval}
              />
            )}
          </div>

          {spellCheckerPlan.status ? (
            <>
              <div className="cnt_hdr_top_wraps">
                <div className="cnt_hdr_top_lft_wrap">
                  <div style={{ display: "flex" }}>
                    <h2 className="heading_font">Spell Checker Report</h2>
                    <span className="_btn_icon_calender">
                      <img
                        src={calendarpng}
                        alt="calender logo"
                        onClick={openModal}
                      />
                    </span>
                  </div>
                  <p
                    style={{
                      fontSize: "14px",
                      letterSpacing: "0.8px",
                      color: "#ff601fc2",
                    }}
                  >
                    as of {timezonechange(jobDate)}{" "}
                    <span style={{ fontWeight: 700 }}> {timezoneval()} </span>
                  </p>
                </div>
                <div className="cnt_hdr_top_rgt_wrap">
                  <div title="Domain Section">
                    <Select
                      options={SectionOptions}
                      name="name"
                      onChange={SetSectionNameHandle}
                      placeholder="Domain Section"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      classNamePrefix="common-domain-dropdown"
                    />
                  </div>
                  <button
                    className="runreportnew run_report_button runreportnew"
                    style={{
                      letterSpacing: "0.8px",
                      width: "150px",
                      lineHeight: "12px",
                    }}
                    onClick={() => {
                      if (first.statusDomain) {
                        setcheckD(first.statusDomain);
                      } else {
                        setfaqData(2);
                        setToggle(false);
                        SetRunReportPopShow(true);
                      }
                    }}
                  >
                    Run Report Now
                  </button>
                </div>
              </div>

              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
            </>
          ) : (
            <Freeaudit planStatus={spellCheckerPlan} />
          )}
          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter job_date_filter"
          >
            <DialogTitle className="keyword-dialog-header">
              {"Filter By"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">
                  <div>
                    <div className="">
                      <div>
                        <span>Completed Date</span>
                        <Select
                          value={dEngineoption}
                          options={completedate}
                          placeholder="Select..."
                          onChange={(values) => {
                            setSelectval(values);
                            SetdEngineoption(values);
                          }}
                          styles={customDropdownStyles()}
                          menuPortalTarget={document.body}
                        />
                      </div>
                    </div>

                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <button
                className="form_submit_btn"
                onClick={reportNow}
                style={{ cursor: "pointer" }}
              >
                Submit
              </button>
              <button style={{ cursor: "pointer" }} onClick={closeModal}>
                close
              </button>
            </DialogActions>
          </Dialog>

          {!openCommonAlerts &&
            <>
              <CommonErrorAlert
                data={{
                  apiopen: open,
                  apiErrorMsg: 'No Data Found',
                  isIconStyled: true
                }}
                closeAlert={handleClose} />

              <CommonErrorAlert
                data={{
                  apiopen: apiopen,
                  apiErrorMsg: apierrorMsg,
                  isIconStyled: true
                }}
                closeAlert={apihandleClose} />
            </>
          }
          <Dialog
            open={checkD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    lineHeight: "1px",
                    width: "max-content",
                  }}
                >
                  <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                  <a href={domainNameUrl.domain_url}>
                    {domainNameUrl.domain_url}
                  </a>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button
                onClick={() => {
                  navigate(first.path);
                }}
              >
                {first.name}
              </Button>
              <Button onClick={() => setcheckD(false)}>
                {first.closeName}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <RunReportNow
        RunReportPopShow={RunReportPopShow}
        SetRunReportPopShow={SetRunReportPopShow}
        RunReportType={RunReportType}
      />
    </div>
  );
}
