import React, { useState, useEffect, useContext, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { SetredirectValue } from "../../../Store/PageredirectSlice";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import Loader from "../CommonLoader";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import DatagridComp from "../DatagridComp";
import PdfandCsv from "../PdfandCsv";
export default function TopactionItems() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [notifycount, setNotifycount] = useState("");
  const [topActionItemPlan, settopActionItemPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate()
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const dispatch = useDispatch();

  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const pageRedirectfun = (e) => {
    if (e.currentTarget.id == 1)
      countdecreaseUrl("Broken Links")
    else
      countdecreaseUrl("Server Error")
    localStorage.setItem("eso_redirect_value", e.currentTarget.id);
    dispatch(SetredirectValue(e.currentTarget.id));
    navigate('/internal-link-checker')
  }
  const countdecreaseUrl = async (urldecval) => {
    const notifydecdatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}top_action_items/reduce_notification/?user_domain_id=${esodata.domaindata.value.header_selected}&to_redirect=${urldecval}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    notificationapi();

  }
  const redirectFun = (e) => {
    if (e.currentTarget.id == "google_analytics") {
      countdecreaseUrl('High Bounce Rate')
      navigate('/organic-search-traffic')
    }
    else if (e.currentTarget.id == "Performance") {
      countdecreaseUrl(e.target.getAttribute('dataid'))
      navigate('/performance')
    }
    else if (e.currentTarget.id == "Content") {
      navigate('/analysis-summary')
    }
    else if (e.currentTarget.id == "missing_title") {
      countdecreaseUrl('Missing Titles')
      navigate('/onpage-crawl-data/2/2')
    }
    else if (e.currentTarget.id == "duplicate_tItles") {
      countdecreaseUrl('Duplicate Titles')
      navigate('/onpage-crawl-data/2/4')
    }
    else if (e.currentTarget.id == "missing_canonical_url") {
      countdecreaseUrl('Missing Canonical URLs')
      navigate('/onpage-crawl-data/2/14')
    }
    else if (e.currentTarget.id == "missing_alt_tags") {
      countdecreaseUrl('Missing ALT Tags')
      navigate('/onpage-crawl-data/2/18')
    }
    else if (e.currentTarget.id == "duplicate_descriptions") {
      countdecreaseUrl('Duplicate Descriptions')
      navigate('/onpage-crawl-data/2/5')
    }
    else if (e.currentTarget.id == "Hreflang") {
      countdecreaseUrl('Hreflang')
      navigate('/onpage-crawl-data/2/9')
    }
    else if (e.currentTarget.id == "Keyword") {
      navigate('/google-console/search-queries')
    }
    else if (e.currentTarget.id == "Pages") {
      navigate('/google-console/top-performing-pages')
    }
    else if (e.currentTarget.id == "Missing_H1_Tags") {
      countdecreaseUrl("Missing H1 Tags")
      navigate('/onpage-crawl-data/2/16')
    }
    else if (e.currentTarget.id == "Missing_Descriptions") {
      countdecreaseUrl("Missing Descriptions")
      navigate('/onpage-crawl-data/2/15')
    }
    else if (e.currentTarget.id == "Duplicate_H1_Tags") {
      countdecreaseUrl('Duplicate H1 Tags')
      navigate('/onpage-crawl-data/2/7')
    }
    else if (e.currentTarget.id == "Accessibility_Score") {
      countdecreaseUrl("Accessibility Score")
      navigate('/accessibility-score')
    }
    else if (e.currentTarget.id == "Accessibility_Report") {
      countdecreaseUrl("Accessibility Report")
      navigate('/accessibility-report')
    }
    else if (e.currentTarget.id == "Misspelled_Sentence") {
      countdecreaseUrl("Suggested Sentences")
      navigate('/spell-checker')
    }
    else if (e.currentTarget.id == "Missed_Oppurtunities") {
      countdecreaseUrl()
      navigate('/missed-opportunity')
    }
    else if (e.currentTarget.id == "Keyword_need_optimization") {
      countdecreaseUrl('Keywords that need optimization')
      navigate('/google-console/search-queries')
    }
    else if (e.currentTarget.id == "Multiple_H1_Tags") {
      countdecreaseUrl('Multiple H1 Tags')
      navigate('/onpage-crawl-data/2/19')
    }


  }
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}source-code-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const checkUserPlan = async () => {
    const topActionItemP = await Utils("top_action_items", ApiToken);
    if (topActionItemP.status) {
      callUserdataApi();
      faqdatafun();
      notificationapi();
    }
    settopActionItemPlan(topActionItemP);
  };

  const arr = [];

  useEffect(() => {
    checkUserPlan();
  }, []);
  const columns =  [
    {
      field: "priority",
      headerName: "#",
      description: 'priority',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },

    {
      field: "category",
      headerName: "Category",
      description: 'Category',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        if (cardData.row.category && cardData.row.category === 'Keyword') {
          if (cardData.row.action_items == "Missed Oppurtunities") {
            return (
              <div>
                <span onClick={redirectFun} id="Missed_Oppurtunities">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Keywords that need optimization") {
            return (
              <div>
                <span onClick={redirectFun} id="duplicate_tItles">{cardData.row.category}</span>
              </div>
            )
          }

        }
        else if (cardData.row.category && cardData.row.category === 'Meta data') {
          if (cardData.row.action_items == "Missing Titles") {
            return (
              <div>
                <span onClick={redirectFun} id="missing_title">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Duplicate Titles") {
            return (
              <div>
                <span onClick={redirectFun} id="duplicate_tItles">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing Canonical URLs") {
            return (
              <div>
                <span onClick={redirectFun} id="missing_canonical_url">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing ALT Tags") {
            return (
              <div>
                <span onClick={redirectFun} id="missing_alt_tags">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Duplicate Descriptions") {
            return (
              <div>
                <span onClick={redirectFun} id="duplicate_descriptions">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Pages") {
            return (
              <div>
                <span onClick={redirectFun} id="Pages">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Hreflang") {
            return (
              <div>
                <span onClick={redirectFun} id="Hreflang">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing H1 Tags") {
            return (
              <div>
                <span onClick={redirectFun} id="Missing_H1_Tags">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing Descriptions") {
            return (
              <div>
                <span onClick={redirectFun} id="Missing_Descriptions">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Duplicate H1 Tags") {
            return (
              <div>
                <span onClick={redirectFun} id="Duplicate_H1_Tags">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Multiple H1 Tags") {
            return (
              <div>
                <span onClick={redirectFun} id="Multiple_H1_Tags">{cardData.row.category}</span>
              </div>
            )
          }
        }
        else if (cardData.row.category && cardData.row.category === 'Domain Performance') {
          return (
            <div>
              <span onClick={redirectFun} id="Performance" dataid={cardData.row.action_items}>{cardData.row.category}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Pages') {
          return (
            <div>
              <span onClick={redirectFun} id="Pages">{cardData.row.category}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Links') {
          if (cardData.row.action_items == "Server Error") {
            return (
              <div>
                <span onClick={pageRedirectfun} id="2">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Broken Links") {
            return (
              <div>
                <span onClick={pageRedirectfun} id="1">{cardData.row.category}</span>
              </div>
            )
          }

        }
        else if (cardData.row.category && cardData.row.category === 'Visitor') {
          return (
            <div>
              <span onClick={redirectFun} id="google_analytics">{cardData.row.category}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Performance') {
          return (
            <div>
              <span onClick={redirectFun} id="Performance" dataid={cardData.row.action_items}>{cardData.row.category}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Content') {
          if (cardData.row.action_items == "Suggested Sentences") {
            return (
              <div>
                <span onClick={redirectFun} id="Misspelled_Sentence">{cardData.row.category}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing Descriptions") {
            return (
              <div>
                <span onClick={redirectFun} id="Misspelled_Descriptions">{cardData.row.category}</span>
              </div>
            )
          }
          else {
            return (
              <div>
                <span onClick={redirectFun} id="Content">{cardData.row.category}</span>
              </div>
            )
          }

        }
        else if (cardData.row.category && cardData.row.category === 'Accessibility') {
          if (cardData.row.action_items == "Accessibility Score") {
            return (
              <div>
                <span onClick={redirectFun} id="Accessibility_Score">{cardData.row.category}</span>
              </div>
            )
          }
          if (cardData.row.action_items == "Accessibility Report") {
            return (
              <div>
                <span onClick={redirectFun} id="Accessibility_Report">{cardData.row.category}</span>
              </div>
            )
          }
        }

      },
    },
    {
      field: "action_items",
      headerName: "Action Items",
      description: 'action items',
      minWidth: 320,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        if (cardData.row.category && cardData.row.category === 'Keyword') {
          if (cardData.row.action_items == "Keywords that need optimization") {
            return (

              <div className="table-link-url" onClick={redirectFun} id="Keyword_need_optimization">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          if (cardData.row.action_items == "Missed Oppurtunities") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Missed_Oppurtunities">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">{cardData.row.action_items}</span>
              </div>
            )
          }

        }
        else if (cardData.row.category && cardData.row.category === 'Meta data') {
          if (cardData.row.action_items == "Missing Titles") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="missing_title">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">{cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Duplicate Titles") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="duplicate_tItles">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing Canonical URLs") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="missing_canonical_url">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Missing ALT Tags") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="missing_alt_tags">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Duplicate Descriptions") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="duplicate_descriptions">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">{cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.category && cardData.row.action_items === 'Hreflang') {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Hreflang">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.category && cardData.row.action_items === 'Missing H1 Tags') {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Missing_H1_Tags">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.category && cardData.row.action_items === 'Missing Descriptions') {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Missing_Descriptions">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.category && cardData.row.action_items === 'Duplicate H1 Tags') {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Duplicate_H1_Tags">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.category && cardData.row.action_items === 'Multiple H1 Tags') {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Multiple_H1_Tags">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
        }
        else if (cardData.row.category && cardData.row.category === 'Domain Performance') {
          return (
            <div className="table-link-url" onClick={redirectFun} id="Performance" dataid={cardData.row.action_items}>
              <LinkIcon style={{ fill: "#044792" }} />
              <span className="spanlink">  {cardData.row.action_items}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Pages') {
          return (
            <div className="table-link-url" onClick={redirectFun} id="Pages">
              <LinkIcon style={{ fill: "#044792" }} />
              <span className="spanlink">  {cardData.row.action_items}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Links') {
          if (cardData.row.action_items == "Server Error") {
            return (
              <div className="table-link-url" onClick={pageRedirectfun} id="2">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink"> {cardData.row.action_items}</span>
              </div>
            )
          }
          else if (cardData.row.action_items == "Broken Links") {
            return (
              <div className="table-link-url" onClick={pageRedirectfun} id="1">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }

        }
        else if (cardData.row.category && cardData.row.category === 'Visitor') {
          return (
            <div className="table-link-url" onClick={redirectFun} id="google_analytics">
              <LinkIcon style={{ fill: "#044792" }} />
              <span className="spanlink">   {cardData.row.action_items}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Performance') {
          return (
            <div className="table-link-url" onClick={redirectFun} id="Performance" dataid={cardData.row.action_items}>
              <LinkIcon style={{ fill: "#044792" }} />
              <span className="spanlink">   {cardData.row.action_items}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Content') {
          return (
            <div className="table-link-url" onClick={redirectFun} id="Misspelled_Sentence">
              <LinkIcon style={{ fill: "#044792" }} />
              <span className="spanlink">{cardData.row.action_items}</span>
            </div>
          )
        }
        else if (cardData.row.category && cardData.row.category === 'Accessibility') {
          if (cardData.row.action_items == "Accessibility Score") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Accessibility_Score">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
          if (cardData.row.action_items == "Accessibility Report") {
            return (
              <div className="table-link-url" onClick={redirectFun} id="Accessibility_Report">
                <LinkIcon style={{ fill: "#044792" }} />
                <span className="spanlink">  {cardData.row.action_items}</span>
              </div>
            )
          }
        }

      },
    },
    {
      field: "severity",
      headerName: "Severity",
      description: 'severity',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },
    {
      field: "impact",
      headerName: "Impact",
      description: 'impact',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },
    {
      field: "percentage_to_total",
      headerName: "% Total",
      description: 'percentage_to_total',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        return cardData.row.percentage_to_total + "%";
      }
    },

  ];

  const rows = cardData ?? [];
  const notificationapi = async () => {
    const notifydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}top_action_items/top_action_notification/?user_domain_id=${esodata.domaindata.value.header_selected}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    setNotifycount(notifydatavalue?.data?.notification_count)
  }
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}top_action_items/top_action_table_view/?user_domain_id=${esodata.domaindata.value.header_selected}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    if (datavalue?.data.top_actions && datavalue?.data?.top_actions.length > 0) {
      const dataval = datavalue?.data?.top_actions;
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      SetAutoheightval(false)

      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setCardData(newData);
    }
    else {
      SetAutoheightval(true)
      setCardData("");
    }

    setLoading(false);
  };
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}top_action_items/top_action_table_view/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Topaction", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}top_action_items/top_action_table_view/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'topactionItem.csv';

        link.click();


        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=41`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const getRowClassName = (params) => {
    if (params.row.notification === true) {
      return 'row-odd-con';
    }

  };

  return (
    <div className={`over_all_container top_action_list ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Action Items</title>
      </Helmet>

      {loading === true ? <Loader /> : null}


      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none' }}>Top Action Items</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {topActionItemPlan.status &&
              <>
                <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading} csvdown={csvdown} pdfdown={pdfdown} />

              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>

        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Top Action Items </h2>
            </div>
          </div>
        </div>


        {topActionItemPlan.status ?
          <DatagridComp
            columns={columns}
            rows={rows}
            autoheightval={autoheightval}
            datagridval={2}
          />
          : <Freeaudit planStatus={topActionItemPlan} />
        }
      </div>
    </div>
  );
}
