import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { ChartContainer, BarPlot, ChartsTooltip } from "@mui/x-charts";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinkIcon from '@mui/icons-material/Link';
import GooglePng from "../../../Icons/google.png";
import BingPng from "../../../Icons/bing.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import SquareIcon from '@mui/icons-material/Square';
import { Tooltip } from "@mui/material";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import CommonAlert from "../../../Commonalert/commonalert";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import testIcon from "../Asset/ESO/testingIcon.svg";
import visibilityicon1 from "../Asset/ESO/visibility1.png";
import visibilityicon from "../Asset/ESO/visibility.png";
import searchEngine from "../Asset/ESO/search-engine.svg";
import First_Page_Ranked_keywordssvg from "../Asset/esocardicon/RankingSummary/First_Page_Ranked_keywords.svg"
import Ranked_Keywordssvg from "../Asset/esocardicon/RankingSummary/Ranked_Keywords.svg"
import Total_keywordssvg from "../Asset/esocardicon/RankingSummary/Total_keywords.svg"
import Visibility_Scoresvg from "../Asset/esocardicon/RankingSummary/Visibility_Score.svg"
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Ranklinksummary(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate()
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [activeVisibilityIcon, setActiveVisibilityIcon] = useState(visibilityicon);

  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  let querystring = useParams();
  let idval = querystring.idval;

  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;

  const columns =  [

    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "keyword",
      headerName: "Keywords",
      description: 'The list of keywords is provided in campaign settings.',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "searchvolume",
      headerName: "SV",
      description: "A keyword's total search volume in search results.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "sv_trend_chart_data",
      headerName: "Sv Trend",
      description: "The last 12 months' search volume is shown visually as a simple small bar graph for the keyword. ",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var sr = cardData.row.sv_trend_chart_data;
        if (sr != null && Array.isArray(sr) && sr.length > 0) {
          var monthval = sr.map((item) => {
            const monthName = new Date(item.year, item.month - 1).toLocaleString("en-US", { month: "long" });
            return `${monthName} ${item.year}`;
          });
          var chartdataval = sr.map((fruit) => fruit.search_volume);

          return (
            <ChartContainer
              width={200}
              height={150}
              series={[{ data: chartdataval, label: "Search Volume", type: "bar" }]}
              xAxis={[{ scaleType: "band", data: monthval }]}
            >
              <BarPlot />
              <ChartsTooltip />
            </ChartContainer>
          );
        }
      },
    },
    {
      field: "difficulty",
      headerName: "Diff",
      description: "The value-based display of keyword difficulty for the respective keyword",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "search_engine",
      headerName: "Search Engine",
      description: "List of search engines configured in the campaign settings.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        if (cardData.row.search_engine === 'Bing') {
          return (
            <div className="search_engine_row">
              <img src={BingPng} />{cardData.row.search_engine}
            </div>
          )
        }
        else if (cardData.row.search_engine === 'Google') {
          return (
            <div className="search_engine_row">
              <img src={GooglePng} />{cardData.row.search_engine}
            </div>
          )
        }
        else {
          return (
            cardData.row.search_engine
          )
        }
      }
    },
    {
      field: "country",
      headerName: "Location",
      description: "The list of locations is configured in the campaign settings.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <>
            <div> {cardData.row.country} {cardData.row.state ? `,` : ""}<br />
              {cardData.row.state}{cardData.row.city ? `,` : ""}<br />
              {cardData.row.city ? `${cardData.row.city}` : ""}</div>
          </>
        )
      }
    },
    {
      field: "prefered_url",
      headerName: "Pref.URL",
      description: "URL for which the keyword is ranked. The preferred keyword is set up along with the keyword setup in campaign settings.",
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        var firstEightLetters = "";
        var wholedata = "";
        firstEightLetters = cardData.row.prefered_url;
        wholedata = firstEightLetters;

        let baseUrl = cardData.row.prefered_url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.prefered_url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData.row.prefered_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.prefered_url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        )
      }
    },
    {
      field: "prefered_url_position",
      headerName: "POS",
      description: "The position is the ranking of the keyword it has obtained in a particular search engine. ",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        if (cardData.row.prefered_url_position.change === 'Dropped') {
          return (
            <div className="gain_drop_row" style={{ color: '#ffa6a6' }}>
              <ArrowDropDownIcon />
              <span>{cardData.row.prefered_url_position.value}</span>
            </div>
          )
        }

        if (cardData.row.prefered_url_position.change === 'Gained') {
          return (
            <div className="gain_drop_row" style={{ color: '#00ab00' }}>
              <ArrowDropUpSharpIcon />
              <span>{cardData.row.prefered_url_position.value}</span>
            </div>
          )
        }

        if (cardData.row.prefered_url_position.change === 'No Change') {
          return (
            <div className="gain_drop_row no_change_row" style={{ color: 'orange' }}>
              <SquareIcon />
              <span>{cardData.row.prefered_url_position.value}</span>
            </div>
          )
        }
      }
    },
    {
      field: "prev_pref_pos",
      headerName: "Prev.POS",
      description: "The position of the keyword in the previous crawl",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "View",
      headerName: "View",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={`${process.env.REACT_APP_DOMAIN}ranking-summary/detail/${cardData.row.id}/${props.RankJob}`} >
            <button className="view-report-button">
              View Report
            </button>
          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];

  const [total_keyword_count, Settotal_keyword_count] = useState(0)
  const [ranked_keyword_count, Setranked_keyword_count] = useState(0)
  const [first_ranked_keyword_count, Setfirst_ranked_keyword_count] = useState(0)
  const [visibility_count, Setvisibility_count] = useState(0)

  const [RankSummaryIntab, SetRankSummaryIntab] = useState(null)

  const SwitchRankIntab = (Id) => {
    if (Id === 'visibility_score') {
      navigate(`/visibility-score-detail?system=${props.RankSystem}&campaign_id=${props.RankCampaign}&search_engine_id=${props.RankEngine}&country_id=${props.RankCountry}&state_id=${props.RankState}&city_id=${props.RankCity}&job_id=${props.RankJob}&devices=${props.RankDevice}`)
    }
    else {
      SetRankSummaryIntab(Id)
      LoadData(Id)
    }
  }


  const LoadData = async (tableid = '') => {
    setLoading(true)
    props.SetRankTypeval(tableid)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${props.RankJob}&rank_section=rank_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (datavalue?.data) {
        Settotal_keyword_count(datavalue.data.total_keyword_count)
        Setranked_keyword_count(datavalue.data.ranked_keyword_count)
        Setfirst_ranked_keyword_count(datavalue.data.first_page_keyword_count)
        Setvisibility_count(datavalue.data.visibility_score + '%')

      }

    } catch (err) {

      Settotal_keyword_count(0)
      Setranked_keyword_count(0)
      Setfirst_ranked_keyword_count(0)
      Setvisibility_count(0 + '%')
      console.log(err)
    }

    props.setjobDate('')

    try {
      const datavalueTable = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata.domaindata.value.header_selected}&system=${props.RankSystem}&campaign_id=${props.RankCampaign}&search_engine_id=${props.RankEngine}&country_id=${props.RankCountry}&state_id=${props.RankState}&city_id=${props.RankCity}&job_id=${props.RankJob}&rank_type=${tableid}&devices=${props.RankDevice}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const dataval = datavalueTable?.data?.result;
      if (datavalueTable?.data?.job_date)
        props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY"))
      if (dataval) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        SetAutoheightval(false)
        setCardData(newData);
        if (dataval.length <= 0) {
          SetAutoheightval(true)
          setCardData("");
        }
        props.SetRankJob(datavalueTable?.data?.job_id)
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }
    } catch (err) {
      setCardData([])
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false)
  }




  useEffect(() => {
    if (idval == 2) {
      SetRankSummaryIntab("ranked_keywords")
      LoadData("ranked_keywords")
    }
    else {
      idval = ""
      LoadData()
    }

  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {
      idval = "";
      LoadData(RankSummaryIntab)
      props.SetFilterButtonTriggered(false)
    }

  }, [props])


  const Card = ({ id, title, value, iconSrc, activeTab, onClick }) => {
    const isActive = activeTab === id;
    return (
      <div
        className={`common-card-wrapper-style cardactivecontainer ${isActive ? "active" : ""}`}
        data-id={id}
        onClick={onClick}
      >
        <div className={`common-card-icon-container ${isActive ? "active" : ""}`}>
          <div className={`common-card-icons ${isActive ? "active" : ""}`}>
            <img
              src={iconSrc}
              className={`common-card-icon-img ${isActive ? "active" : ""}`}
              alt={title}
            />
          </div>
        </div>
        <div className={`common-card-subtitle ${isActive ? "active" : ""} cardcontentactivecontainer`}>
          <span className="common-card-title">{title}</span>
          <span className="common-card-value">{value}</span>
        </div>
      </div>
    );
  };


  const cardsData = [
    { id: "", title: "Total Keywords", value: total_keyword_count, iconSrc: Total_keywordssvg },
    { id: "ranked_keywords", title: "Ranked Keywords", value: ranked_keyword_count, iconSrc: Ranked_Keywordssvg },
    { id: "first_page_rank", title: "First Page Ranked Keywords", value: first_ranked_keyword_count, iconSrc: First_Page_Ranked_keywordssvg },
    { id: "visibility_score", title: "Visibility Score", value: visibility_count, iconSrc: Visibility_Scoresvg },
  ];


  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rank Checker</title>
        </Helmet>
        <div className="common-card-container">
          {cardsData.map(({ id, title, value, iconSrc }) => (
            <Card
              key={id}
              id={id}
              title={title}
              value={value}
              iconSrc={iconSrc}
              activeTab={RankSummaryIntab}
              onClick={() => SwitchRankIntab(id)}
            />
          ))}
        </div>
      </div>
      <DatagridComp
        columns={columns}
        rows={rows}
        autoheightval={autoheightval}
        datagridval={3}
      />

      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts &&

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
    </>
  );
}
