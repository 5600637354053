import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { AppContext } from "../../../Context/Context";
import questionpng from "./../Asset/question.svg";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

function KeywordAddForm(props) {
  const [weblinkError, SetweblinkError] = useState("");
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [onPageError, SetonPageError] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CampaignList, SetCampaignList] = useState([]);
  const [SelectCampaignList, SetSelectCampaignList] = useState([]);
  const [KeywordName, SetKeywordName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [campaignerr, setCampaignerr] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [cardmsg, setCardmsg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const [keywordaddpopup, setkeyWordAddPopup] = useState(false);

  const [succespopup, setsuccespopup] = useState();


  const keywordaddpopupCloseOk = () => {
    setkeyWordAddPopup(false);
    props.setSubmitKeyword(true);
    props.setkeyWordAddPopup(false);
  }


  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const finalreportNow = async () => {
    var dataerr = 1;
    setCampaignerr("");
    SetweblinkError("");
    setEngineerr("");
    setCountryerr("");
    setStateerr("");
    setCityerr("");

    if (SelectCampaignList == "") {
      setCampaignerr("Please Select Campaign");
      dataerr = 2;
    }

    if (KeywordName == "") {
      SetweblinkError("Please Enter Keyword");
      dataerr = 2;
    }

    if (dataerr == 1) {
      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/add_keyword/`,
          {
            user_domain_id: esodata.domaindata.value.header_selected,
            campaign_id: SelectCampaignList,
            keyword: KeywordName
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          return false;
        });
        if (
          Response?.data?.message ==
          "Keyword added successfully!"
        ) {

          setsuccespopup(Response.data.message);
          setkeyWordAddPopup(true);;
        }
      } catch (Response) {
        setCampaignerr("");
        SetweblinkError("");
        setEngineerr("");
        setCountryerr("");
        setStateerr("");
        setCityerr("");
        setCardmsg(Response?.response?.data?.error);
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    GetCampaignData();
  }, []);
  const GetCampaignData = async () => {
    SetCampaignList([]);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (datavalue?.data) {
      var Sections = [];
      datavalue.data.forEach((element) => {
        Sections.push({
          label: element.campaign_name,
          value: element.campaign_id,
        });
      });
      SetCampaignList(Sections);
    }
  };
  

  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div>
        <div>

          <div>

            <span className="error-value">{onPageError}</span>

            <form className="frm_container">

              <div>
                <div className="keyword-col1-fields">
                  <div>
                    <label for="city" style={{ marginLeft: 'auto' }}>
                      Campaign Name :
                    </label>
                    <Select
                      name="campaign"
                      options={CampaignList}
                      onChange={(e) => { SetSelectCampaignList(e.value) }}
                      placeholder="Select Campaign"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className="keyword-select-container"
                      classNamePrefix="addkeyword"
                    />
                  </div>
                  <div>
                    {" "}
                    <span className="error-value">{campaignerr}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>Keyword Name :</label>
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Keyword Name"
                        className="keyword"
                        onChange={(e) => {
                          SetKeywordName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <span className="error-value">{weblinkError}</span>
                  </div>
                  <div>
                    <span onClick={props.keywordSuggestionTab} style={{ color: "#ff601f", "display": "flex", cursor: "pointer" }} title="Suggest Keywords">View Keyword Suggestion
                      <img src={questionpng} alt="calender logo" style={{ "width": "40px", "height": "25px", objectFit: "contain" }} title="Suggest Keywords" />
                    </span>
                  </div>
                </div>
              </div>
              
              <div className="keyword-dialog-buttons">
                <button
                  className="keyword-cancel"
                  type="button"
                  onClick={() => { props.setkeyWordAddPopup(false) }}
                >
                  Close
                </button>
                <button
                  className="keyword-submit"
                  type="button"
                  onClick={finalreportNow}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
      <CommonErrorAlert
        data={{
          apiopen: open,
          apiErrorMsg: cardmsg,
          isIconStyled: false
        }}
        closeAlert={handleClose} />
      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: false
        }}
        closeAlert={apihandleClose} />
      <CommonErrorAlert
        data={{
          apiopen: keywordaddpopup,
          apiErrorMsg: succespopup,
          isIconStyled: false
        }}
        closeAlert={keywordaddpopupCloseOk} />
    </div>
  );
}

export default KeywordAddForm;
