import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./Siteauditstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useSelector, useDispatch } from "react-redux";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import Loader from "../CommonLoader";
import { useNavigate } from 'react-router-dom';
import { Commonfun, datezonechange } from './../Commonfun';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Pagehealthlists(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const [jobidvalData, setjobidvalData] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const [autoheightval, SetAutoheightval] = useState(true)
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const ApiToken = esodata.apiauthkey.value;


  const [value, setValue] = useState("1");


  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        if (doaminsectiondatavalue.data.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
      if (completevalues?.data?.jobs.length > 0) {
        var jobSections = [];
        completevalues?.data?.jobs.forEach((element) => {
          jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
        });
        setcompletedate(jobSections);
        SetJobId(jobSections[0].value);
        SetdEngineoption(jobSections[0]);
        setprevdEngineoption(jobSections[0]);
      }


    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}pagehealth-detail/`;

  useEffect(() => {
    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify === "true" ? true : false);
    CloseModalTrigger();
    callUserdataApi();
    LoadAddFormFilterOptions()
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));
  }, []);
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "url",
      headerName: "URL",
      description: "The URLs added under URL management",
      minWidth: 300,
      flex: 1,
      sortable: true,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");

        return (
          <>

            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>

            </Tooltip>

          </>
        );
      },
    },
    {
      field: "high_count",
      headerName: "High Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: ''

    },
    {
      field: "medium_count",
      headerName: "Medium Count",
      minWidth: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: ''

    },
    {
      field: "low_count",
      headerName: "Low Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: ''

    },
    {
      field: "view",
      headerName: "View",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.url_id}/` + `${jobidvalData}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        );
      },
    },
  ];
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    if (jobid == "")
      jobid = JobId
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;

    setCardData([])
    setLoading(true);
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/pagehealth/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsecdata}&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsecdata}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
      if (completevalues?.data?.jobs.length > 0) {
        var jobSections = [];
        completevalues?.data?.jobs.forEach((element) => {
          jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
        });
        setcompletedate(jobSections);
      }
    }

    if (datavalue?.data?.job_date);

    const dataval = datavalue?.data?.table?.urls;
    setjobidvalData(datavalue?.data?.job_id);
    setjobDate(moment(datavalue?.data?.jod_date).format("MMMM DD,YYYY HH:mm"));

    props.setIsOpen(false);
    props.setJobdate(moment(datavalue?.data?.jod_date).format("MMMM DD,YYYY HH:mm"));

    if (dataval && dataval.length > 0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }

    setLoading(false);
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval.value);
    closeModal();
    callUserdataApi(selectval.value);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  const dispatch = useDispatch();

  const [JobId, SetJobId] = useState("");

  const CloseModalTrigger = () => {
    SetJobId("");
    SetdEngineoption(prevdEngineoption);
    props.setIsOpen(false);
  };

  const SetSectionNameHandle = (passvalue) => {
    props.setDomainSecvalue(passvalue?.value)
    SetDomainsectiondata(passvalue?.value)
    callUserdataApi(JobId, passvalue?.value);
  }

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (JobId === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }

    if (JobId !== "") {
      callUserdataApi(JobId, Domainsectiondata);
    }
    setprevdEngineoption(dEngineoption);
  };


  return (
    <>
      {SectionOptions?.length > 0 &&
        <div
          style={{
            width: "calc(100% - 40px)",
            marginRight: "40px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="listdropdown_drop_site">
            <div>
              <div>
                <span className="listdropdown_titl">Domain Section</span>
                <Select
                  options={SectionOptions}
                  name="name"
                  onChange={SetSectionNameHandle}
                  placeholder="Domain Section"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  classNamePrefix="common-domain-dropdown"
                />
              </div>
            </div>
          </div>
        </div>
      }
      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />
      </div>
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div className="col">
                <div>
                  <span>Completed Date</span>
                  <Select
                    options={completedate}
                    placeholder="Select..."
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={reportNow}>
            Submit
          </button>
          <button onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span> Completed Date</span>
                    <Select
                      value={dEngineoption}
                      placeholder="Select Date"
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        SetJobId(values?.value)
                        props.setJobIdvalue(values?.value)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button
            className="form_submit_btn"
            onClick={FetchCompetitorList}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalTrigger}>
            close
          </button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts &&

        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />

      }
    </>
  );
}
