import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import "./../style.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import { datezonechange } from './../Commonfun';
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function Reporthistory() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate()
  const apihandleClose = () => {
    setapiOpen(false);
  };


  const columns =  [
    {
      field: "sid",
      headerName: "S.No",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },
    {
      field: "domain__name",
      headerName: "Domain Name",
      description: 'Domain Name',
      minWidth: 250,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },

    {
      field: "type",
      headerName: "Type",
      description: 'Type',
      minWidth: 220,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,

    },
    {
      field: "status",
      headerName: "Status",
      description: 'Status',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,

    },
    {
      field: "created_at",
      headerName: "Created At",
      description: 'Created At',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        if (
          cardData.row.created_at !== null &&
          cardData.row.created_at !== ""
        ) {
          return datezonechange(cardData?.row?.created_at)
        } else {
          return "-";
        }
      },
    },
    {
      field: "completed_at",
      headerName: "Completed At",
      description: 'Completed At',
      minWidth: 200,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (cardData) => {
        if (
          cardData.row.completed_at !== null &&
          cardData.row.completed_at !== ""
        ) {
          return datezonechange(cardData?.row?.completed_at)
        } else {
          return "-";
        }
      },
    },

  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}domain/get_report_history/?user_domain_id=${esodata.domaindata.value.header_selected}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });

    if (datavalue?.data?.jobs && datavalue?.data?.jobs.length > 0) {
      const dataval = datavalue?.data?.jobs;
      SetAutoheightval(false)
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setCardData(newData);
    }
    else {
      SetAutoheightval(true)
      setCardData("");
    }
    setLoading(false);
  };
  

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const freeAudit = async () => {
    const check = await Utils("report_history", ApiToken);
    setfreesiteaudit(check);
  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=60`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    freeAudit();
    faqdatafun();
    callUserdataApi();
  }, []);

  return (
    <div className={`over_all_container top_action_list ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Report History</title>
      </Helmet>

      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Report Histor
            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question">
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> Report History</h2>
          </div>

        </div>

        <div className="data_table topaciton_data" style={{ width: "100%" }}>
          <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
            {freesiteaudit.status && freesiteaudit.status ?
              <DatagridComp
                columns={columns}
                rows={rows}
                autoheightval={autoheightval}
                datagridval={2}
              />
              : <Freeaudit planStatus={freesiteaudit} />}
          </div>
          <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />



          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        </div>
      </div>
    </div>
  );
}
