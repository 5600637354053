import React, { useState, useEffect, useContext, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import isURL from 'validator/lib/isURL';
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import DomainAddForm from "./DomainAddForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Breadcrumbs, Typography } from "@mui/material";
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function UserDomainsecList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [selectval, setSelectval] = useState("");

  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [pagename, setPagename] = useState("Domain Section Management")
  const [selectedRows, setSelectedRows] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apisuccessopen, Setapisuccessopen] = useState(false)
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apisuccessMsg, setapisuccessMsg] = useState();
  const [domainSectionListPlan, setdomainSectionListPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [CamPopTitle, SetCamPopTitle] = useState("");
  const [formOpen, setformOpen] = useState(false);
  const [onPageError, SetonPageError] = useState("")
  const [Userformdataid, SetUserformdataid] = useState("")

  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });


  const navigate = useNavigate();
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
    Setapisuccessopen(false)
  };

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;
  const finalreportclose = () => {
    setformOpen(false)
  }
  const checkUserPlan = async () => {
    const domainSectionListP = await Utils("domain_section_management", ApiToken);
    if (domainSectionListP.status) {
      callUserdataApi();
      faqdatafun()
    }
    setdomainSectionListPlan(domainSectionListP);
  };
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")

    setLoading(true);
    var data_val = 1;
    if (websiteName === "") {
      SetWebnameError("Enter Domain Section Name")
      data_val = 2;
    }
    if (webLink === "") {
      SetweblinkError("Enter the Website Link")
      data_val = 2;
    }
    if (webLink !== "") {
      if (!isURL(webLink)) {
        SetweblinkError("Enter Valid Link Address")
        data_val = 2;
      }
    }

    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {
        if (Userformdataid == "")
          var domainurl = `${process.env.REACT_APP_API_URL}domain/add_domain_section/`
        else
          var domainurl = `${process.env.REACT_APP_API_URL}domain/edit_domain_section/`
        setLoading(true);
        var user_domain_id = esodata.domaindata.value.header_selected;
        const response = await axios.post(
          domainurl,
          {
            section_id: Userformdataid,
            user_domain_id: user_domain_id,
            section_name: websiteName,
            section_url: webLink,
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        if (response?.data?.status == "Domain section added successfully" || response?.data?.status == "Domain section updated successfully") {
          Setapisuccessopen(true)
          setapisuccessMsg(response?.data?.status)
          setformOpen(false)
          callUserdataApi();
        }
      } catch (err) {
        setLoading(false);
        SetonPageError(err.response.data.error)
      }
    }

  }

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);



  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data?.domain_sections) {
        if (datavalue?.data?.domain_sections) {
          const newData = datavalue.data.domain_sections.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });


          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetCamPopTitle('Add Domain Sections')
    setformOpen(true)
    SetWebsiteName("")
    SetwebLink("")
    SetUserformdataid("")
  }

  const DeleteCampaignSettings = async (e) => {
    setLoading(true);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/delete_domain_section/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${deleteId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
    callUserdataApi();
    setIsOpen(false);
  };

  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }
  const handleeditClick = async (id) => {
    SetWebnameError("")
    SetweblinkError("")
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_section/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (datavalue?.data) {
        SetWebsiteName(datavalue?.data?.name)
        SetwebLink(datavalue?.data?.url)
        SetUserformdataid(datavalue?.data?.id)
        SetCamPopTitle('Edit Domain Sections')
        setformOpen(true)
      }
    } catch (err) {
      console.log(err)
    }

  }
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "name",
      headerName: "Website Name",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "url",
      headerName: "Website URL",
      minWidth: 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      description: 'Website Url that have been added for the domain',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let firstEightLetters = cardData.row.url;
        let whoedata = firstEightLetters;
        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <button
            className="buttonView trash_icon"
            onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => handleeditClick(cardData.row.id)}
            id={cardData.row.id}
          >
            <EditIcon />
          </button>

        );
      },

    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <button
            className="buttonView trash_icon"
            onClick={first.statusDomain ? () => { setcheckD(first.statusDomain) } : () => {
              SetDeleteId(cardData.row.id)
              setIsOpen(true);
            }}
            id={cardData.row.id}
          >
            <DeleteIcon />
          </button>
        );
      },

    },
  ];
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  const [CallCampaignTable, SetCallCampaignTable] = useState(false)

  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=45`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Domain Section Management</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Domain Section Management</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> Domain Section Management</h2>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">

            {domainSectionListPlan.status &&
              <button
                title="Add Domain Section"
                className="runreportnew"
                onClick={() => { first.statusDomain ? setcheckD(first.statusDomain) : openModal() }}


              >
                Add Domain Section
              </button>
            }
          </div>

        </div>
        {domainSectionListPlan.status ?
          <>
            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </> : <Freeaudit planStatus={domainSectionListPlan} />
        }

      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this Domain Section Name?

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="keyword-cancel" onClick={closeModal}>close</button>
          <button className="keyword-submit" style={{ marginTop: "auto" }} onClick={DeleteCampaignSettings} >
            OK
          </button>
        </DialogActions>
      </Dialog>

      {!openCommonAlerts &&




        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: false
          }}
          closeAlert={apihandleClose} />


      }

      <Dialog
        open={apisuccessopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><CheckCircleOutlineIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apisuccessMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {CamPopTitle && formOpen && <Dialog
        open={formOpen}
        onClose={finalreportclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter keyword_edit_form"
      >
        <DialogTitle className="keyword-dialog-header"
        >{CamPopTitle}</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <form className="frm_container" id="reset_form">
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                        Domain Section Name:
                      </label>
                      <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={onwebName} placeholder="Domain Section Name" className="campaign-name" defaultValue={websiteName} />
                    </div>
                    <div>
                      <span className="error-value">{webnameError}</span>
                    </div>
                  </div>
                  <div className="keyword-col2-fields">
                    <div>
                      <label for="city">
                        Website Link:
                      </label>
                      <input className="campaign-name" type="text" title="URL of your website (eg: www.example.com)" name="websitelink" onChange={onwebLink} placeholder="Enter Url" defaultValue={webLink} />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{weblinkError}</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={finalreportNow} style={{ cursor: 'pointer' }} >
            {Userformdataid == "" ? "Submit" : "Update"}
          </button>
          <button style={{ cursor: 'pointer' }} onClick={finalreportclose}>close</button>
        </DialogActions>
      </Dialog>}


      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>

    </div>


  );
}
