import React, { useState, useEffect, useContext } from "react";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import ExternalLinkListData from "./ExternalLinkLists";
import { Outlet, Link } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import axios from "axios";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PdfandCsv from "../PdfandCsv";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


const ExternalLinkLists = () => {


  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [jobDate, SetjobDate] = useState('')
  const [modalIsOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [externalLinkPlan, setexternalLinkPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [jobIdvalue, setJobIdvalue] = React.useState("");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);

  function openModal() {
    if (externalLinkPlan.status) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('link_checker')
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobIdvalue}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Externallink", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {

    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      
      if (response.ok) {
        
        const blob = await response.blob();

       
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Externallink", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      if (error?.status == 599 || error?.status == 500 || error?.status == 400 || error?.status == 404) {
        error.json().then(data => {
          setapiOpen(true);
          setapierrorMsg(data?.error)
        })
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
    }
    setLoading(false);
    setIsDownloadingcsv(false);


  }

  const checkUserPlan = async () => {
    const externalLinkP = await Utils("external_links", ApiToken);
    if (externalLinkP.status) {
      faqdatafun();
    }
    setexternalLinkPlan(externalLinkP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=23`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>External Link Checker</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {externalLinkPlan.status &&
                <>
                  <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading}
                    csvdown={csvdown} pdfdown={pdfdown} />
                </>
              }
            </div>
            {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>External Link Checker</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <button
                title="Run Report"
                className="runreportnew over_all_accessibility runreportnew"
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    SetRunReportPopShow(true)
                  }
                }}

                disabled={!externalLinkPlan.status}
              >
                Run Report Now
              </button>
            </div>
          </div>

          <div className="link_cnt_hdr_blw_wrap">

            <Link to="/internal-link-checker"> <button className="runreportnews">
              Internal Link Checker
            </button></Link>

            <Link to="/external-link-checker"> <button className="runreportnews active">
              External Link Checker
            </button></Link>

          </div>


          <div className="site_blw_wrapss domain_link_page">
          </div>






          {externalLinkPlan.status ?
            <ExternalLinkListData SetjobDate={SetjobDate} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} jobIdvalue={jobIdvalue} setJobIdvalue={setJobIdvalue} /> : <Freeaudit planStatus={externalLinkPlan} />
          }



        </div>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts &&
        <CommonErrorAlert
          data={{
            apiopen: apiopen,
            apiErrorMsg: apierrorMsg,
            isIconStyled: true
          }}
          closeAlert={apihandleClose} />
      }
      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please verify your domain to access these features
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>

    </>
  )
}
export default ExternalLinkLists