import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import { Breadcrumbs, Typography } from "@mui/material";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

const SiteAuditConfig = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [PriorityOptions, SetPriorityOptions] = useState([])
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [siteAuditPriorityPlan, setsiteAuditPriorityPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });


  const navigate = useNavigate();

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const columns =  [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,

    },
    {
      field: "seo_metrics",
      headerName: "SEO Metrics",
      description: 'On page elements present in the website',
      minWidth: 500,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {
        var ReturnString = '';
        ReturnString = cardData.row.seo_metrics
        ReturnString = ReturnString.replaceAll('_', ' ')
        const words = ReturnString.split(' ');
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
      }
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies',
      minWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var DefaultValue = cardData.row.priority
        var Tag = cardData.row.seo_metrics
        return (
          <select style={{ border: "1px solid #ff601f" }} className="site_audit_settings_priority" onChange={(e) => { ChangeSettings(e, Tag) }}>

            {PriorityOptions.map(obj => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return (<option key={value} value={value} selected={DefaultValue == value ? true : ''}>{key}</option>)
            })}
          </select>
        )

      }

    },

  ];

  const ChangeSettings = async (e, tag) => {
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/user_priority_update/${tag}/?user_domain_id=${esodata.domaindata.value.header_selected}&new_priority=${e.target.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      setLoading(false)

      if (datavalue?.data) {
        GetDefaultSettings()
      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const GetDefaultSettings = async () => {
    setLoading(true)
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/site_settings_default/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false)
      if (datavalue?.data && datavalue?.data.length > 0) {

        var FinalAObj = [];

        datavalue.data.forEach((element, index) => {
          var Obj = {};
          Obj.sid = index + 1;
          Obj.seo_metrics = element.fields.seo_metrics
          Obj.priority = element.fields.priority
          Obj.is_active = element.fields.is_active
          Obj.pk = element.pk
          FinalAObj.push(Obj)
        });
        setCardData(FinalAObj)
        SetAutoheightval(false)
      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }




  }

  const GetPriorityOptions = async () => {
    setLoading(true)
    SetPriorityOptions([])
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/top_action_severity_types/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false)

      if (datavalue?.data) {
        const outputArray = [];

        for (const key in datavalue.data) {
          if (datavalue.data.hasOwnProperty(key)) {
            outputArray.push({ [key]: datavalue.data[key] });
          }
        }

        SetPriorityOptions(outputArray)

      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }




  }

  const checkUserPlan = async () => {
    const siteAuditPriorityP = await Utils("priority_settings", ApiToken);
    if (siteAuditPriorityP.status) {
      GetDefaultSettings()
      GetPriorityOptions()
      faqdatafun()
    }
    setsiteAuditPriorityPlan(siteAuditPriorityP);
  };


  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, [])

  
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=43`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Site Audit Priority</title>
      </Helmet>
      {
        loading === true ? <Loader /> : null
      }
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Settings</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Site Audit Priority Settings</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>

            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Site Audit Priority Settings</h2>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <Link to={first.statusDomain ? "#" : "/site-audit-priority"} onClick={first.statusDomain ? () => setcheckD(first.statusDomain) : null} ><button type="button" className="site_rn_rpt_btn active runreportnew"   >Site Audit Priority</button></Link>
              <Link to={first.statusDomain ? "#" : "/top-action-priority"} onClick={first.statusDomain ? () => setcheckD(first.statusDomain) : null} ><button type="button" className={`site_rn_rpt_btn`} style={{ color: "#ff601f !important", width: 150, height: 37, padding: 8 }} >Top Action Priority</button></Link>
            </div>

          </div>
          {siteAuditPriorityPlan.status ?

            <DatagridComp
              columns={columns}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />

            : <Freeaudit planStatus={siteAuditPriorityPlan} />
          }





        </div>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

        {!openCommonAlerts &&

          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }

        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert!!!!!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                <p>{"Please verify your domain ("}<a href={domainNameUrl.domain_url} title={`go to this link ${domainNameUrl.domain_url}`} >{domainNameUrl.domain_name}</a>{") to access these features"}</p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
            <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
          </DialogActions>
        </Dialog>

      </div>
    </>
  )
}
export default SiteAuditConfig