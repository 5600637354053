import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import "./style.css";
import calendarpng from "./Asset/New_Assert/ESO_Icon/Filter.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import RunReportNow from "./RunReportNow";
import { Domain } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from "./Commonfun";
import { AppContext } from "../../Context/Context";
import { timezonechange } from "./Commonfun";
import { timezoneval } from "./Commonfun";
import { datezonechange } from "./Commonfun";
import { Utils } from "../../Utils/Utils";
import Freeaudit from "../../Utils/Freeaudit";
import Loader from "./CommonLoader";
import CommonAlert from "../../Commonalert/commonalert";
import { customDropdownStyles } from "../../Commonalert/commonDropdown";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DatagridComp from "./DatagridComp";
import PdfandCsv from "./PdfandCsv";
import { CommonErrorAlert } from "../../Commonalert/commonErrorAlert";
export default function Auditsummary() {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [autoheightval, SetAutoheightval] = useState(true);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [analysisSummaryPlan, setanalysisSummaryPlan] = useState({
    status: true,
    error: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });
  const [dEngineoption, SetdEngineoption] = useState({
    label: "Select Date",
    value: "Select Date",
  });
  const [prevdEngineoption, setprevdEngineoption] = useState({
    label: "Select Date",
    value: "Select Date",
  });
  const [faqData, setfaqData] = useState("2");
  const [datagridval, setDatagridval] = useState(1);
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let detailurl = `${process.env.REACT_APP_DOMAIN}content-detail/`;

  const checkUserPlan = async () => {
    const analysisSummaryP = await Utils("analysis_summary", ApiToken);
    if (analysisSummaryP.status) {
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
    }
    setanalysisSummaryPlan(analysisSummaryP);
  };

  const arr = [];

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          },
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (
            error?.code == "ERR_NETWORK" ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue.data.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  };
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "link__url",
      headerName: "Web Page URL",
      description: "URL of the web page.",
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        let baseUrl =
          cardData.row.link__url.split("/").slice(0, 3).join("/") + "/";
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");
        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external02">
                <a
                  target="_blank"
                  href={cardData?.row?.link__url}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span
                    className="spanlink"
                    style={{
                      color: "#044792",
                      wordWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                  >
                    {remaining}
                  </span>
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "read_score",
      headerName: "Readability Score",
      description: "Readability score of the web page.",
      minWidth: 210,
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
      renderCell: (cardData) => {
        var ColorToRender = "";
        var fleschScore = cardData.row.read_score;

        if (fleschScore >= 90 && fleschScore <= 100) {
          ColorToRender = "#006400";
        } else if (fleschScore >= 80 && fleschScore < 90) {
          ColorToRender = "#008000";
        } else if (fleschScore >= 70 && fleschScore < 80) {
          ColorToRender = "#FFA500";
        } else if (fleschScore >= 60 && fleschScore < 70) {
          ColorToRender = "#FFD700";
        } else if (fleschScore >= 50 && fleschScore < 60) {
          ColorToRender = "#D2B48C";
        } else if (fleschScore >= 30 && fleschScore < 50) {
          ColorToRender = "#A52A2A";
        } else {
          ColorToRender = "#FF0000";
        }

        return (
          <>
            <div class="MuiDataGrid-cellContent">{`${cardData.row.read_score}`}</div>
            <div
              class="MuiDataGrid-cellContent read_score_org"
              title={`${cardData.row.read_score}`}
              role="presentation"
              style={{ background: ColorToRender }}
            ></div>
          </>
        );
      },
    },
    {
      field: "read_ease",
      headerName: "Reading Easiness",
      description: "Reading ease level of the content of the web page.",
      minWidth: 210,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "grad_level",
      headerName: "Grade Level",
      description:
        "Grade level is required for an average reader to understand the page content.",
      minWidth: 170,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "number_of_sentances",
      headerName: "Total Sentences",
      description: "The total number of sentences present on the web page.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "total_words",
      headerName: "Total Words",
      description: "The total number of words present on the web page.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "avg_sentance_count",
      headerName: "Avg Sentence Length",
      description: "Average sentence length on the web page.",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "View",
      headerName: "View",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + `${jobidData}`}>
            <button className="view-report-button">View Report</button>
          </a>
        );
      },
    },
  ]

  const rows = cardData ?? [];
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value);
    callUserdataApi("", passvalue.value);
  };
  const callUserdataApi = async (jobid = "", domainsecdata = "") => {
    setLoading(true);
    if (domainsecdata == "") domainsecdata = Domainsectiondata;
    if (jobid == "") jobid = jobidData;
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&domain_section_id=${domainsecdata}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          },
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (
            error?.code == "ERR_NETWORK" ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          }
        });
      if (jobidData == "") {
        const completevalues = await axios
          .get(
            `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=analysis_summary&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            },
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (
              error?.code == "ERR_NETWORK" ||
              error?.response?.status == 404
            ) {
              navigate("/errorpages");
            }
          });
        var Selectedmets = [];

        completevalues?.data?.jobs.forEach((element) => {
          Selectedmets.push({
            value: element.id,
            label: datezonechange(element.completed_at),
          });
        });
        SetInitialDateval(Selectedmets[0]);
        setSelectval(Selectedmets[0]);
        SetdEngineoption(Selectedmets[0]);
        setprevdEngineoption(Selectedmets[0]);
        setcompletedate(Selectedmets);
      }
      const dataval = datavalue?.data?.table_data;

      if (datavalue?.data?.job_date)
        setjobDate(
          moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"),
        );
      else setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));

      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setjobidData(datavalue?.data?.job_id);
        SetAutoheightval(false);
        setCardData(newData);
      } else {
        SetAutoheightval(true);
        setCardData("");
        setjobDate();
      }
    } catch (err) {
      setCardData("");
      if (err.code == "ERR_NETWORK") {
        navigate("/*");
      }
    }

    setLoading(false);
  };

  const reportNow = async () => {
    var errs = 1;
    let errors = {};

    if (selectval.value == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setLoading(true);
    setjobidData(selectval.value);
    setIsOpen(false);
    setprevdEngineoption(dEngineoption);
    var domainsec = "";
    callUserdataApi(selectval.value, (domainsec = ""));
  };
  function openModal() {
    setfaqData(2);
    setToggle(false);
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);
  }
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(
          "AnalysisSummary",
          "pdf",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;

        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404 ||
          response?.status == 502
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);
  };
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&job_id=${jobidData}&domain_section_id=${Domainsectiondata}&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        },
      );
      if (response.ok) {
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const now = new Date();
        const datetimeval = now.toLocaleDateString();
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        const seconds = now.getSeconds().toString().padStart(2, "0");
        var filedata = await Commonfun(
          "AnalysisSummary",
          "csv",
          ApiToken,
          esodata.domaindata.value.header_selected,
        );
        link.download = filedata;

        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        if (
          response?.status == 599 ||
          response?.status == 500 ||
          response?.status == 400 ||
          response?.status == 404 ||
          response?.status == 502
        ) {
          response.json().then((data) => {
            setapiOpen(true);
            setapierrorMsg(data?.error);
          });
        } else if (response?.code == "ERR_NETWORK") {
          navigate("/errorpages");
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);
  };
  const [RunReportPopShow, SetRunReportPopShow] = useState(false);
  const [RunReportType, SetRunReportType] = useState("analysis_summary");
  const [toggle, setToggle] = useState(false);
  const wrapperRef = useRef(null);
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=11`;

    try {
      const doaminsectiondatavalue = await axios
        .get(datavar, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (
            error?.code == "ERR_NETWORK" ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          }
        });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs);
      }
    } catch (err) { }
  };





  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1);
    } else {
      setfaqData(2);
    }
    setToggle((pre) => !pre);


  };

  console.log("WORKING",toggle);

  return (
    <div
      className={`over_all_container over_all_accessibility ${isMenuExpanded ? "blur overflow-hidden" : ""}`}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Analysis Summary</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {loading === true ? <Loader /> : null}
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer">
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography
                sx={{
                  color: "#ff601f",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Dashboard
              </Typography>
            </Link>
            <Typography sx={{ color: "grey" }}>Content</Typography>
            <Typography
              sx={{
                color: "#ff601f",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Analysis Summary
            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button
              onClick={handleToggle}
              className="faq_class exportpdf"
              title="Frequently Asked Question"
            >
              <QuestionMarkIcon />
            </button>
            {analysisSummaryPlan.status && (
              <PdfandCsv Downloadval={1} isDownloadingcsv={isDownloadingcsv} isDownloading={isDownloading} csvdown={csvdown} pdfdown={pdfdown} />
            )}
          </div>
          {toggle && (
            <Rightsidebar
              close={() => setToggle(false)}
              setToggle={setToggle}
              initialfaqDateval={initialfaqDateval}
            />
          )}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2
                className="heading_font"
                style={{
                  letterSpacing: "0.8px",
                  fontWeight: 900,
                  color: "#ff601f",
                }}
              >
                Analysis Summary{" "}
              </h2>
              <span className="_btn_icon_calender">
                <img
                  src={calendarpng}
                  alt="calender logo"
                  onClick={openModal}
                />
              </span>
            </div>
            <p
              style={{
                fontSize: "14px",
                letterSpacing: "0.8px",
                color: "#ff601fc2",
              }}
            >
              as of {timezonechange(jobDate)}{" "}
              <span style={{ fontWeight: 700 }}> {timezoneval()} </span>
            </p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div title="Domain Section">
              <Select
                options={SectionOptions}
                name="name"
                onChange={SetSectionNameHandle}
                placeholder="Domain Section"
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                classNamePrefix="common-domain-dropdown"
              />
            </div>
            <button
              title="Run Report"
              className="runreportnew over_all_accessibility runreportnew"
              onClick={() => {
                if (first.statusDomain) {
                  setcheckD(first.statusDomain);
                } else {

                  setfaqData(2);
                  setToggle(false);
                  SetRunReportPopShow(true);
                }
              }}
              disabled={!analysisSummaryPlan.status}
            >
              Run Report Nows
            </button>
          </div>
        </div>
        {analysisSummaryPlan.status ? (
          <DatagridComp
            columns={columns}
            rows={rows}
            autoheightval={autoheightval}
            datagridval={datagridval}
          />
        ) : (
          <Freeaudit planStatus={analysisSummaryPlan} />
        )}
        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter over_all_accessibility"
        >
          <DialogTitle className="keyword-dialog-header">
            {"Filter By"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div>
                  <div className="">
                    <div>
                      <span>Completed Date</span>
                      <Select
                        value={dEngineoption}
                        options={completedate}
                        placeholder="Select..."
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          setSelectval(values);
                          SetdEngineoption(values);
                        }}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button
              className="form_submit_btn"
              onClick={reportNow}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>

        {!openCommonAlerts && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="keyword-dialog-header">
              {"Error Message "}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                No Data Found
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
        {!openCommonAlerts && (
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        )}
        <Dialog
          open={checkD}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  lineHeight: "1px",
                  width: "max-content",
                }}
              >
                <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                <a href={domainNameUrl.domain_url}>
                  {domainNameUrl.domain_url}
                </a>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button
              onClick={() => {
                navigate(first.path);
              }}
            >
              {first.name}
            </Button>
            <Button onClick={() => setcheckD(false)}>{first.closeName}</Button>
          </DialogActions>
        </Dialog>
      </div>

      <RunReportNow
        RunReportPopShow={RunReportPopShow}
        SetRunReportPopShow={SetRunReportPopShow}
        RunReportType={RunReportType}
      />
    </div >
  );
}
