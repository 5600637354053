import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Keywordbar from "./Rightsidebar/Keywordbar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import 'chartjs-adapter-moment';
import InprogressIcon from "../../Icons/work-in-progress-unscreen.gif";
import { AppContext } from "../../Context/Context";
import { timezoneval } from './Commonfun';
import { timezonechange } from './Commonfun';
import { Utils } from "../../Utils/Utils";
import Freeaudit from "../../Utils/Freeaudit";
import CommonAlert from "../../Commonalert/commonalert";
import { customDropdownStyles, customMultiDropdownStyles } from "../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatagridComp from "./DatagridComp";
import moment from "moment";
import NewLoader from "../NavBar/NewLoader";

const Customreport = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [ColumnOptions, SetColumnOptions] = useState([]);
  const [JobOptions, SetJobOptions] = useState([]);
  const [noData, setNodata] = useState(1)
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState();
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [apiopen, setapiOpen] = useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [autoheightval, SetAutoheightval] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [jobselectedValues, setJobselectedValues] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [errors, setErrors] = useState({});
  const [responsedata, SetResponsedata] = useState([]);
  const [columndata, Setcolumndata] = useState([])
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [InProgressMsg, SetInProgressMsg] = useState("Report is Loading....");
  const [domainNameUrl, setDomainNameUrl] = useState({
    domain_name: "",
    domain_url: "",
  });
  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });
  const [initialDateval, SetInitialDateval] = useState([]);
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);
  const [checkD, setcheckD] = useState(false);
  const [first, setfirst] = useState({
    name: "",
    path: "",
    closeName: "Close",
    statusDomain: false,
  });

  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };
  useEffect(() => {
    LoadAddFormFilterOptions();
    faqdatafun();
  }, []);

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=58`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_name/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.customize_report_names) {
          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.customize_report_names);
          var Sections = [];
          doaminsectiondatavalue?.data?.customize_report_names[0].forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  };

  const columnFilterOptions = async (reportid) => {
    SetColumnOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_column_name/?user_domain_id=${esodata.domaindata.value.header_selected}&report_id=${reportid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.names) {
          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.names);
          var Sections = [];
          doaminsectiondatavalue?.data?.names.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          Sections.unshift({ value: 'selectAll', label: 'Select All' });

          SetColumnOptions(Sections);


        }
      }
    } catch (err) { }
  };
  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios
        .get(`${process.env.REACT_APP_API_URL}accounts/get_user_details/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        })
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 500 ||
            error?.response?.status == 400 ||
            error?.response?.status == 404
          ) {
            navigate("/errorpages");
          } else if (error?.code == "ERR_NETWORK") {
            navigate("/errorpages");
          }
        });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({
          ...domainNameUrl,
          domain_name: datavalue?.data?.dor_domain_name,
          domain_url: datavalue?.data?.dor_domain_url,
        });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Userdata",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Impadddomain",
            statusDomain: true,
          });
          return;
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({
            ...first,
            name: "Verify Now",
            path: "/Domainownership",
            statusDomain: true,
          });
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const jobFilterOptions = async (reportid) => {
    SetJobOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_job_dates/?user_domain_id=${esodata.domaindata.value.header_selected}&report_id=${reportid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.jobs) {
          var Sections = [];
          doaminsectiondatavalue?.data?.jobs.forEach((element) => {
            Sections.push({ label: element.completed_at, value: element.id });
          });
          SetJobOptions(Sections);

        }
      }
    } catch (err) { }
  };

  const SetSectionNameHandle = async (passvalue) => {
    setSelectedSection(passvalue.label)
    SetDomainsectiondata(passvalue.value);
    columnFilterOptions(passvalue.value);
    jobFilterOptions(passvalue.value);
    setSelectedValues([]);
    setJobselectedValues([]);
  };
  const onJobSelect = (selectedList) => {
    if (selectedList.length <= 3) {
      setJobselectedValues(selectedList);
    }
    else {
      const newErrords = {};
      newErrords.jobs = 'Only Three Values Only Allowed'
      setErrors(newErrords);
    }
  };
  const handleColumnSelect = (selectedList) => {
    const isSelectAllSelected = selectedList.some(item => item.value === 'selectAll');
    if (isSelectAllSelected) {
      setSelectedValues(ColumnOptions.filter(option => option.value !== 'selectAll'));
      return false;
    }
    const filteredList = selectedList.filter(item => item.value !== 'selectAll');
    if (filteredList.length === ColumnOptions.length - 1 && !isSelectAllSelected) {
      const newSelectedList = [...filteredList, { value: 'selectAll', label: 'Select All' }];
      setSelectedValues(filteredList);
    } else {
      setSelectedValues(filteredList);
    }
  };


  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };


  const validateFields = () => {
    const newErrors = {};
    if (!Domainsectiondata) newErrors.section = 'Report Type must be selected.';
    if (selectedValues.length === 0) newErrors.columns = 'At least one column name must be selected.';
    if (jobselectedValues.length === 0) newErrors.jobs = 'At least one Completed date must be selected.';
    if (jobselectedValues.length > 3) newErrors.jobs = 'Only Three Values Only Allowed'
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setLoading(true)
    if (validateFields()) {
      var passpostapi = {
        report_id: "" + Domainsectiondata,
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: selectedValues.map((item) => item.value),
        job_ids: jobselectedValues.map((item) => item.value),
      };
      setLoading(true);
      try {
        const responsedatavalue = await axios
          .post(
            `${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`,
            passpostapi,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate("/errorpages");
            }
            return false;
          });
        if (responsedatavalue?.data) {
          setNodata(2)
          SetResponsedata(responsedatavalue?.data);
          setCardData(responsedatavalue?.data?.table_data)
          setLoading(false);
          setIsCardVisible(false)
          var ColumnsMerge = [];
          const columnsIni = [
            {
              field: "sid",
              headerName: "#",
              minWidth: 100,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            },
            {
              field: "type",
              headerName: "Type",
              minWidth: 250,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            },
          ]

          responsedatavalue?.data?.headers.forEach(element => {
            ColumnsMerge.push({

              field: element,
              headerName: `${timezonechange(element)} - ${timezoneval()} `,
              minWidth: 250,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            })
          })
          ColumnsMerge = columnsIni.concat(ColumnsMerge)

          Setcolumndata(ColumnsMerge)
          var LoadTableValue = [];

          responsedatavalue?.data?.row_names.map((element, i) => {
            LoadTableValue.push({
              sid: i + 1,
              type: element

            })
          })
          LoadTableValue.map((elements, i) => {
            responsedatavalue?.data?.headers.map((element, j) => {
              if (responsedatavalue?.data?.table_data[j] !== undefined)
                LoadTableValue[i][element] = responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]]
              else
                LoadTableValue[i][element] = "-"
            })
          })
          setCardData(LoadTableValue)
        }
      } catch (err) { }
    }
    setLoading(false)
  };
  const getDataset = (key) => {
    return {
      label: key.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
      data: responsedata?.table_data.map((item) => item[key]),
      fill: false,
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
      borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    };
  };
  const keys = (responsedata?.table_data && responsedata.table_data.length > 0)
    ? Object.keys(responsedata.table_data[0]).filter(key => key !== 'id' && key !== 'job__id' && key !== 'job__completed_at' && key !== 'job__completed_date')
    : [];

  const datasets = keys.map((key) => getDataset(key));
  const chartData = {
    labels: responsedata?.table_data?.length
      ? responsedata.table_data.map(item => moment(item.job__completed_at).utc().format("YYYY-MM-DD HH:mm"))
      : [],
    datasets: datasets,
  };


  const chartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: true,
        text: selectedSection,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "DD-MM-YYYY",
        },
      },
    },
  };


  const rows = cardData ?? [];
  const freeAudit = async () => {
    const check = await Utils("custom_report", ApiToken);
    setfreesiteaudit(check);
  }
  useEffect(() => {
    freeAudit();
    GetuserDatavalue();

  }, [])

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="over_all_inner" >
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Custom Report

            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} setToggle={setToggle} initialDateval={initialDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap" >
            <h2 className="heading_font" style={{ color: "#000" }}>Custom Report</h2>
          </div>

          <Helmet>
            <meta charSet="utf-8" />
            <title>      Custom Report                </title>
          </Helmet>
        </div>
        {freesiteaudit.status && <>
          {isCardVisible ? (
            <Button variant="contained" style={{ backgroundColor: '#ff601f' }} onClick={toggleCardVisibility}>
              Hide Filters
            </Button>
          ) : (
            <Button variant="contained" style={{ backgroundColor: '#ff601f' }} onClick={toggleCardVisibility}>
              Show Filters
            </Button>
          )}
        </>
        }

        {freesiteaudit.status && <>
          {isCardVisible && (
            <div className="card_custom">
              <div className="filter-section" style={{ flexDirection: "column" }}>
                <div className="filter-item">
                  <span className="listdropdown_titl">Report Type</span>
                  <Select
                    classNamePrefix="custom-reporttype-dropdown"
                    id="section-dropdown"
                    options={SectionOptions}
                    placeholder={Domainsectiondata ? selectedSection : "Select Report Type"}
                    values={SectionOptions.value}
                    onChange={SetSectionNameHandle}
                    defaultValue={Domainsectiondata}
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                  />
                  {errors.section && <div className="customerrormsg">{errors.section}</div>}
                </div>

                <div className="filter-item">
                  <span className="listdropdown_titl">Column Name</span>
                  <Select
                    classNamePrefix="custom-report-dropdown"
                    isMulti
                    closeMenuOnSelect={false}
                    options={ColumnOptions}
                    value={selectedValues}
                    onChange={handleColumnSelect}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    placeholder="Select Column Name"
                    styles={customMultiDropdownStyles()}
                    menuPortalTarget={document.body}
                  />

                  {errors.columns && <div className="customerrormsg">{errors.columns}</div>}
                </div>
                <div className="filter-item">
                  <span className="listdropdown_titl">Completed Date</span>

                  <Select
                    classNamePrefix="custom-report-dropdown"
                    isMulti
                    menuPlacement="auto"
                    closeMenuOnSelect={false}
                    menuShouldScrollIntoView={false}
                    options={JobOptions}
                    value={jobselectedValues}
                    onChange={onJobSelect}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    placeholder="Select Completed Date"
                    styles={customMultiDropdownStyles()}
                    menuPortalTarget={document.body}
                  />


                  {errors.jobs && <div className="customerrormsg">{errors.jobs}</div>}
                </div>

                <Button variant="contained" style={{ backgroundColor: '#ff601f' }} className="run_rpt_btn rn_rpt_btn over_all_accessibility runreportnew" onClick={() => {
                  if (first.statusDomain) {

                    setcheckD(first.statusDomain);
                  } else {
                    handleSubmit()
                  }
                }}>
                  Submit
                </Button>
              </div>
            </div>)}
        </>
        }
      </div>

      {
        !freesiteaudit.status && <div style={{ paddingLeft: "20px", paddingRight: "20px" }} > <Freeaudit planStatus={freesiteaudit} /> </div>
      }
      {
        loading === true ? <NewLoader /> : null
      }
      {loading ? (
        <div className="show_in_progress_brand_mention">
          <div className="wrapperAlert">
            <div className="contentAlert">
              <div className="topHalf">
                <p>
                  <img src={InprogressIcon} />
                </p>
                <h1>{InProgressMsg}</h1>

                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>

      ) : (
        Array.isArray(responsedata?.table_data) && responsedata.table_data.length > 0 ? (
          <>
            <div className="customchart-card" style={{ height: "300px", overflowX: "auto", overflowY: "hidden" }}>
              <Line data={chartData} options={chartOptions} loading={loading} height={300} width={800} />
            </div>
            <DatagridComp
              columns={columndata}
              rows={rows}
              autoheightval={autoheightval}
              datagridval={2}
            />
          </>
        )

          : (
            noData == 2 && <div className="custom-error-message">No Data</div>
          )
      )}
      <CommonAlert />

      <Dialog
        open={checkD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                lineHeight: "1px",
                width: "max-content",
              }}
            >
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url}>
                {domainNameUrl.domain_url}
              </a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button
            onClick={() => {
              navigate(first.path);
            }}
          >
            {first.name}
          </Button>
          <Button onClick={() => setcheckD(false)}>{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Customreport;
