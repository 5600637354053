import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import LiquidFillGauge from "react-liquid-gauge";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DatagridComp from "../DatagridComp";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";

export default function BackLinksSummary(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState("1");
  const [chartvalue, setChartvalue] = useState("4");
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value;
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns =  [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },


    {
      field: "headkey",
      headerName: "Particulars",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Domain and their competitors."
    },
    {
      field: "domain_authority",
      headerName: "Domain Authority",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Domain InLink Rank of the target's root domain.",
      renderCell: (cardData) => {
        var sr = cardData.row.domain_authority;
        var s = sr
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s}
            percent=""
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: '#0b2033',
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "page_authority",
      headerName: "Page Authority",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "InLink rank of the target URL.",
      renderCell: (cardData) => {
        var sr = cardData.row.page_authority;
        var s = sr
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s}
            percent=""
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: "#0b2033",
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "total_backlinks",
      headerName: "Total Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Total number of live backlinks.",
    },
    {
      field: "total_referring_domains",
      headerName: "Total Referring Domains",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of unique domains linking to a target.",
    },
    {
      field: "total_referring_ips",
      headerName: "Total Referring IPs",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of unique IPs linking to a target.",
    },
    {
      field: "follow_links",
      headerName: "Follow Vs No-Follow Links",
      minWidth: 270,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks marked as nofollow and number of backlinks NOT marked as nofollow.",
      renderCell: (cardData) => {
        return (

          <div>

            <div className=" dt-left" id="td-backlink-0" data-sort={1}>
              <span>Follow {'('}{cardData.row.follow_links}{')'}</span>
              <div className="stacked-bar-graph">
                <span style={{ width: cardData.row.follow_linksp }} className="bardata" />
              </div>
            </div>

            <div className=" dt-left" id="td-backlink-0" data-sort={1}>
              <span>No follow  {'('}{cardData.row.nofollow_links}{')'}</span>
              <div className="stacked-bar-graph">
                <span style={{ width: cardData.row.nofollow_linksp }} className="bardata white" />
              </div>



            </div>

          </div>

        );
      },
    },
    {
      field: "edu_backlinks",
      headerName: "Educational Website Backlinks",
      minWidth: 300,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks coming from .edu domains.",

    },

    {
      field: "gov_backlinks",
      headerName: "Government Website Backlinks",
      minWidth: 300,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks coming from .gov domains.",
    },
    {
      field: "top_ten_refdomains",
      headerName: "Top 10 Referring Domains",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Top 10 number of unique domains linking to a target.",
    },
    {
      field: "new_backlinks",
      headerName: "Count Of New Backlinks",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of new backlinks that were found.",
    },
    {
      field: "lost_backlinks",
      headerName: "Count Of Lost Backlinks",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks that were lost.",

    }
  ]
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  const rows = cardData ?? [];
  useEffect(() => {
    callUserdataApi();
  }, []);

  useEffect(() => {
    if (props.FilterButtonTriggered === true) {
      callUserdataApi()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }
  }, [props])

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    try {
      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
        });
      var RowValues = [];
      if (datavalue) {
        if (datavalue?.data) {
          if (datavalue?.data?.domain_names) {
            var DomainNames = datavalue.data.domain_names;
            var domain_authority = [];
            var page_authority = [];
            var total_backlinks = [];
            var total_referring_domains = [];
            var total_referring_ips = [];
            var follow_links = [];
            var nofollow_links = [];
            var follow_linksp = [];
            var nofollow_linksp = []
            var edu_backlinks = [];
            var gov_backlinks = [];
            var top_ten_refdomains = [];
            var new_backlinks = [];
            var lost_backlinks = [];
            if (datavalue.data.table.domain_authority) {
              domain_authority = datavalue.data.table.domain_authority
            }
            if (datavalue.data.table.page_authority) {
              page_authority = datavalue.data.table.page_authority
            }
            if (datavalue.data.table.total_backlinks) {
              total_backlinks = datavalue.data.table.total_backlinks
            }
            if (datavalue.data.table.total_referring_domains) {
              total_referring_domains = datavalue.data.table.total_referring_domains
            }
            if (datavalue.data.table.total_referring_ips) {
              total_referring_ips = datavalue.data.table.total_referring_ips
            }
            if (datavalue.data.table.follow_links) {
              follow_links = datavalue.data.table.follow_links
            }
            if (datavalue.data.table.nofollow_links) {
              nofollow_links = datavalue.data.table.nofollow_links
            }
            if (datavalue.data.table.follow_links_percentage) {
              follow_linksp = datavalue.data.table.follow_links_percentage
            }
            if (datavalue.data.table.nofollow_links_percentage) {
              nofollow_linksp = datavalue.data.table.nofollow_links_percentage
            }
            if (datavalue.data.table.edu_backlinks) {
              edu_backlinks = datavalue.data.table.edu_backlinks
            }
            if (datavalue.data.table.gov_backlinks) {
              gov_backlinks = datavalue.data.table.gov_backlinks
            }
            if (datavalue.data.table.top_ten_refdomains) {
              top_ten_refdomains = datavalue.data.table.top_ten_refdomains
            }
            if (datavalue.data.table.new_backlinks) {
              new_backlinks = datavalue.data.table.new_backlinks
            }
            if (datavalue.data.table.lost_backlinks) {
              lost_backlinks = datavalue.data.table.lost_backlinks
            }
            DomainNames.forEach((element, index) => {
              RowValues.push({
                id: index,
                headkey: element,
                domain_authority: domain_authority[element],
                page_authority: page_authority[element],
                total_backlinks: total_backlinks[element],
                total_referring_domains: total_referring_domains[element],
                total_referring_ips: total_referring_ips[element],
                follow_links: follow_links[element],
                nofollow_links: nofollow_links[element],
                follow_linksp: follow_linksp[element],
                nofollow_linksp: nofollow_linksp[element],
                edu_backlinks: edu_backlinks[element],
                gov_backlinks: gov_backlinks[element],
                top_ten_refdomains: top_ten_refdomains[element],
                new_backlinks: new_backlinks[element],
                lost_backlinks: lost_backlinks[element]
              })

            });
            if (RowValues && RowValues.length > 0) {
              const newData = RowValues.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });
              setCardData(newData);
              SetAutoheightval(false)
            }
            else {
              setCardData("");
              SetAutoheightval(true)
            }
            if (datavalue?.data?.job_date)
              props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
            else
              props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  var startColor = "#6495ed";
  var endColor = "#dc143c";
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Summary</title>
      </Helmet>
      <div >

        <DatagridComp
          columns={columns}
          rows={rows}
          autoheightval={autoheightval}
          datagridval={2}
        />
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: true
            }}
            closeAlert={apihandleClose} />
        }
      </div>

    </div>
  );
}
