import React, { useState, useRef, useEffect } from 'react';
import "./login.css";
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import Eso_cnfrm_mail_img from './Asset/On_Board/confirmMail.gif';
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CommonErrorAlert } from '../../Commonalert/commonErrorAlert';
function ApprovePassword() {

  const [apiopen, setapiOpen] = React.useState(false);
  const [apiserrorMsg, setapiserrorMsg] = useState("");
  const [apierrorMsg, setApiErrorMsg] = useState("");

  const [alertapiopen, setAlertapiOpen] = useState(false);

  const navigate = useNavigate();

  const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  function testPasswordStrength(password) {
    return strongPasswordRegex.test(password);
  }
  const alerthandleApiClose = () => {
    setAlertapiOpen(false);
    navigate("/login");
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  let querystring = useParams();
  let uid = querystring.uid;
  let utoken = querystring.utoken;

  const otpdataref = useRef(null);


  const newpassref = useRef(null);
  const cnewpassref = useRef(null);

  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;


  const [errors, setErrors] = useState({
    newpass: '',
    cnewpass: '',
  });
  const [formData, setFormData] = useState({
    newpass: '',
    cnewpass: '',
  });
  const SmthandleChangeotp = (e) => {


    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };
  const otp_valid = async (e) => {
    e.preventDefault();


    const { newpass, cnewpass } = formData;

    const newErrors = {};
    var ErrorCheck = 0;

    if (!newpass || newpass.length === 0 || newpass === undefined) {
      newErrors.newpass = "Please enter your new password";
      ErrorCheck = 1;

      newpassref.current.focus();
    }
    else if (newpass !== '') {
      if (testPasswordStrength(newpass) === false) {
        newErrors.newpass = "Password does not meet the criteria. It should contain at least one uppercase letter, one digit, and one special character (@, $, !, %, *, ?, &), and be at least 8 characters long";
        ErrorCheck = 1;
      }
    }

    if (!cnewpass || cnewpass.length === 0 || cnewpass === undefined) {
      newErrors.cnewpass = "Please confirm your new password";
      ErrorCheck = 1;
      cnewpassref.current.focus();
    }


    if (newpass !== undefined && cnewpass !== undefined) {

      if (newpass.length > 0 && cnewpass.length > 0) {

        if (newpass !== cnewpass) {
          newErrors.cnewpass = "Your passwords does not match!";
          ErrorCheck = 1;

          cnewpassref.current.focus();
        }

      }
    }

    setErrors(newErrors);

    if (ErrorCheck === 0) {

      setLoading(true)
      const Response = await axios.post(
        `${process.env.REACT_APP_API_URL}settings/user_management_set_password/`,
        {
          new_password: cnewpass,
          uid: uid,
          token: utoken
        },
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapiserrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });

      if (Response?.data) {
        if (Response?.data?.status_code == '200') {
          setAlertapiOpen(true);
          setApiErrorMsg(Response.data.message);

        }
      }


      setLoading(false)

    }
  };
  /**timer start */
  const [timeRemaining, setTimeRemaining] = useState(180);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(prevTime => prevTime - 1);
      } else {
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [timeRemaining]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  const [showPasswordlgn, setShowPasswordlgn] = useState(false);
  const [showPasswordlgn1, setShowPasswordlgn1] = useState(false);

  const handleTogglePasswordlgn = () => {
    setShowPasswordlgn(!showPasswordlgn);
  };
  const handleTogglePasswordlgn1 = () => {
    setShowPasswordlgn1(!showPasswordlgn1);
  };

  return (
    <>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="outer-container"
        style={{
          width: "calc(100% - 60px)",
          marginRight: "60px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="rest_scs_outer_container confirm_sec_outer_container ">
          <div className="rest_scs_ins_container">
            <div className="Eso_cnfrm_mail_outer_wrap">
              <img className="Eso_logo" src={Logo} alt="logo" />
              <img className="Eso_rst_img" src={Eso_cnfrm_mail_img} alt="animation gif" />
            </div>
            <h1 className="scs_tt">Set Password</h1>
            <div className="rest_inst_wrap">
              <p>Enter your passwords here</p>
              <form onSubmit={otp_valid} className='frm_container' method="post">
                <div class="form-group email_grp change_password_eye" style={{ marginBottom: '30px' }}>
                  <input type={showPasswordlgn ? "text" : "password"} id="otppas" name="newpass" placeholder='Enter your new password' value={formData.newpass} onChange={SmthandleChangeotp} ref={newpassref} />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordlgn}
                  >
                    {showPasswordlgn ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                  <div className="frm_error">{errors.newpass}</div>
                </div>

                <div class="form-group email_grp change_password_eye">
                  <input type={showPasswordlgn1 ? "text" : "password"} id="otppas" name="cnewpass" placeholder='Confirm your new password' value={formData.cnewpass} onChange={SmthandleChangeotp} ref={cnewpassref} />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={handleTogglePasswordlgn1}
                  >
                    {showPasswordlgn1 ? (
                      <FaEye className="FaEy" />
                    ) : (
                      <FaEyeSlash className="FaEyeSlash" />
                    )}
                  </button>
                  <div className="frm_error">{errors.cnewpass}</div>
                </div>

                <button type="submit" className='sbt_btn'>Save Password</button>
              </form>
            </div>
            <div className='cnfrm'>
              <p>Haven't received an email? Please check your spam folder or contact us at <a href="mailto:contact@elitesiteoptimizer.com">contact@elitesiteoptimizer.com</a></p>
              <p><a href="/login">Click here to login</a></p>
            </div>
          </div>

        </div>
      </div>
      <CommonErrorAlert
        data={{
          apiopen: apiopen,
          apiErrorMsg: apiserrorMsg,
          isIconStyled: false
        }}
        closeAlert={apihandleClose} />
      <CommonErrorAlert
        data={{
          apiopen: alertapiopen,
          apiErrorMsg: apierrorMsg,
          isIconStyled: false
        }}
        closeAlert={alerthandleApiClose} />

    </>

  );
}
export default ApprovePassword;