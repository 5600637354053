import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import "./searchkeyvolumestyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from 'react-select';
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AppleIcon from "@mui/icons-material/Apple";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { ChartContainer, BarPlot, ChartsTooltip } from "@mui/x-charts";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { useParams } from "react-router-dom";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";


export default function Keywordsuggestiondetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [locationval, setlocationval] = useState("");
  const [campaignval, setcampaignval] = useState([]);
  const [sectionval, setsectionval] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [location_id, setLocation_id] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [locationvaldata, setlocationvaldata] = useState("");
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [searchval, setSearchval] = useState("");
  const [selectsearchval, setSelectsearchval] = useState([]);
  const [countryval, setCountryval] = useState("");
  const [selectcountryval, setSelectcountryval] = useState([]);
  const [stateval, setStateval] = useState([]);
  const [selectstateval, setSelectstateval] = useState([]);
  const [cityval, setCityval] = useState([]);
  const [selectcityval, setSelectcityval] = useState([]);
  const [searchcarddata, setSearchcarddata] = useState([]);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [keywordsuggest, setkeywordsuggest] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  let querystring = useParams();
  let plag_id = querystring.id;
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  var startColor = "#6495ed";
  var endColor = "#dc143c"; 
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [autoheightval, SetAutoheightval] = useState(true)
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const SetSectionNameHandle = (passvalue) => {
    setSelectval(passvalue?.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setsearchopen(false)
    setOpen(false);
  };

  const AnalyzeReport = async () => {
    setLoading(true);

    if (selectval) {

      var location_val = selectval;
    }
    else var location_val = "";
    if (location_val === "") {
      SetErrorMsg("Please select the location");
      setLoading(false);
    }
    else if (AnalyzeUrl === "") {
      SetErrorMsg("Please enter your keyword");
      setLoading(false);
    }
    else {

      SetErrorMsg("");
      setlocationvaldata(location_val)
      calltabledataApi(location_val);

    }
  };
  const calltabledataApi = async (location_val = "") => {
    setLoading(true);
    SetErrorMsg('')
    setCardData([])
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${location_val}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });

      var dataval = []

      if ((AnalyzeUrl.startsWith("http")) || (AnalyzeUrl.startsWith("https"))) {

        if (datavalue?.data?.result?.tasks[0]?.result_count == 0) {
          SetErrorMsg("No information available");
        }
        var NewlyPushArray = [];

        if (datavalue?.data?.result?.tasks[0]?.result[0]?.items) {
          dataval = datavalue?.data?.result?.tasks[0]?.result[0]?.items

          dataval.forEach(element => {
            var NewObject = [];
            NewObject['keyword'] = element.keyword_data.keyword
            NewObject['search_volume'] = element.keyword_data.keyword_info.search_volume
            NewObject['monthly_searches'] = element.keyword_data.keyword_info.monthly_searches
            NewObject['competition'] = element.keyword_data.keyword_info.competition
            NewObject = { ...NewObject }
            NewlyPushArray.push(NewObject)
          });
        }
        dataval = NewlyPushArray
      }
      else {
        dataval = datavalue?.data?.result?.tasks[0]?.result;
      }
      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index + 1 };
        });
        SetAutoheightval(false)
        setCardData(newData);
      }
      else {
        setCardData("");
        SetAutoheightval(true)
        SetErrorMsg("No data found for this keyword");
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  useEffect(() => {

    callUserdataApi();
    faqdatafun();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: 'S.No',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: true,
    },
    {
      field: "suggested_keyword",
      headerName: "Keywords",
      description: "List of keywords relevant to the user's requirement",
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "search_volume",
      headerName: "Search Volume",
      description: "A keyword's total search volume in search results.",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {

        var LoadData = '';

        if (cardData.row.search_volume === null || cardData.row.search_volume == 0) {
          LoadData = 'N/A'
        }
        else {
          LoadData = cardData.row.search_volume
        }
        return (LoadData)



      }
    },
    {
      field: "monthly_searches",
      headerName: "Monthly Searches",
      description: 'The estimated monthly search volume for the keyword over the past twelve months',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var sr = cardData.row.monthly_searches;

        if (sr != null && Array.isArray(sr) && cardData.row.search_volume != 0) {
          var monthval = sr.map((item) => {
            const monthName = new Date(item.year, item.month - 1).toLocaleString("en-US", { month: "long" });
            return `${monthName} ${item.year}`;
          });
          var chartdataval = sr.map((fruit) => fruit.search_volume);

          return (
            <ChartContainer
              width={200}
              height={150}
              series={[{ data: chartdataval, label: "Search Volume", type: "bar" }]}
              xAxis={[{ scaleType: "band", data: monthval }]}
            >
              <BarPlot />
              <ChartsTooltip />
            </ChartContainer>
          );
        }
        else {
          return ('N/A')
        }
      },
    },
    {
      field: "competition_index",
      headerName: "Keyword Difficulty",
      description: 'A value between 0 and 100 representing the relative competition for the keyword in paid search ads',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        var LoadData = '';
        if (cardData?.row?.competition_index === null) {
          LoadData = 'N/A'
        }
        else
          return cardData?.row?.competition_index
      }
    },
    {
      field: "competition",
      headerName: "Competition",
      description: ' Indicates the level of competition for the keyword in paid search ads, categorized as HIGH, MEDIUM, or LOW.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "high_top_of_page_bid",
      headerName: "High Top Of Page Bid",
      description: 'The maximum bid required for an ad to appear at the top of the first search results page',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "low_top_of_page_bid",
      headerName: "Low Top Of Page Bid",
      description: 'The minimum bid required for an ad to appear at the top of the first search results page',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },

    {
      field: "cpc",
      headerName: "CPC",
      description: 'The average cost per click for the keyword in paid search ads',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "view",
      headerName: "Action",
      description: "The 'Add to Campaign' feature lets users add selected keywords to a targeted keyword.",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <button
            className="view-report-button"
            onClick={() => { campaignreportNow(params) }}
          >
            Add To Campaign
          </button>
        )
      },
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
    window.location.reload();

  };
  const rows = keywordsuggest ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    try {

      const locationdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/keyword_suggestions_detail/?id=${plag_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (locationdatavalue?.data && locationdatavalue?.data.length > 0) {
        const newData = locationdatavalue?.data.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setkeywordsuggest(newData)
        SetAutoheightval(false)
      }

    } catch (err) {
      console.log(err)
    }

    try {

      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_campaigns/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const campaigndataval = campaigndatavalue?.data.map(element => ({
        label: element.name,
        value: element.id
      }));
      setcampaignval(campaigndataval);

    } catch (err) {
      console.log(err)
    }

    try {

      const sectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const sectiondataval = sectiondatavalue?.data?.domain_sections.map(element => ({
        label: element.name,
        value: element.id
      }));
      setsectionval(sectiondataval);

    } catch (err) {
      console.log(err)
    }

    setLoading(false);
  };
  const campaignreportNow = async (data) => {
    if (first.statusDomain) {
      setcheckD(first.statusDomain);
    } else {
      callUserdataApi();
      if (data.row) {
        setsearchselectedRows([data.row])
        setfaqData(2)
        setToggle(false)
        if ([data.row].length > 0) setIsOpen(true);
        else setsearchopen(true);
      } else {
        setsearchselectedRows(selectedRows)
        setfaqData(2)
        setToggle(false)
        if (selectedRows.length > 0) setIsOpen(true);
        else setsearchopen(true);

      }
    }
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectcamptval.length <= 0) {
      errors.selectval = "Campaign Name is required.";
      errs = 2;
      setLoading(false);
    }
    if (selectsectionval.length <= 0) {
      errors.dateerror = "Section field is required.";
      errs = 2;
      setLoading(false);
    }
    if (errs == 2) {
      setValidation(errors);
      return false;
    }
    else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
      var keywordselectdata = searchselectedRows.map(
        (dataselectval) => dataselectval?.suggested_keyword
      );
      savetargetkeydata(keywordselectdata);
      setLoading(false);
    }
  };
  const savetargetkeydata = async (keywordselectdata) => {
    setLoading(true);
    setIsOpen(false);
    try {
      const datavalapi = await axios.post(
        `${process.env.REACT_APP_API_URL}keywords/keyword_research_save/`,
        {
          user_domain_id: esodata.domaindata.value.header_selected,
          domain_section_id: selectsectionval,
          category_id: selectcamptval,
          keywords: keywordselectdata,
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setSelectsectionval([])
      setSelectsearchval([])
      setCountryval([])
      setSelectcamptval([])
      setSelectedRows([])
      if (datavalapi?.status == 200) {
        setLoading(false);
        setapiOpen(true);
        setapierrorMsg(datavalapi?.data?.status)
      }

    }
    catch (err) {

      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(err)
    }
  };
  function closeModal() {
    setSelectcamptval("")
    setSelectsectionval("")
    setSelectsearchval("")
    setCountryval([])
    setStateval([])
    setCityval([])
    setValidation("")
    setIsOpen(false);
  }

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=61`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "space-between", padding: "8px", alignItems: "end" }}>
        <div className="below_note_ranking icon over_all_accessibility">

          <div >
            <button
              title="Add To Campaigns"
              className="runreportnew over_all_accessibility runreportnew"
              onClick={campaignreportNow}
            >
              Add To Campaigns
            </button>
          </div>
        </div>

        <GridToolbarQuickFilter
          className="datagrid-search"
        />
      </GridToolbarContainer>
    );
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };


  return (
    <div className={`over_all_container search_prnt ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Keyword Suggestion Details</title>
      </Helmet>
      {
        loading === true ? <Loader /> : null
      }

      <div className="cnt_audit_outer_wrap">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Keywords</Typography>
            <Typography sx={{ color: 'grey' }}>Targeted Keywords</Typography>
            <Link to="/keywords">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Keyword Suggestion</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Keyword Suggestion Detail</Typography>



          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && (
            <Keywordbar
              close={() => setToggle(false)}
              initialDateval={initialDateval}
            />
          )}

        </div>

        <div className=" search_key_wrap searchvolume_key_wrap">
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Keyword Suggestion Detail</h2>
            </div>
            <div>
            </div>

          </div>



          <div className="error_plagarism_report" style={{ textAlign: 'center' }}>{ErrorMsg}</div>

          <div className="search_volumn_data">
            <div className="data_table search_volume_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: CustomToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columns}

                  gridRowId={(row) => row.id}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    const selectedRowsData = ids.map((id) =>
                      rows.find((row) => row.id === id)
                    );
                    setSelectedRows(selectedRowsData);
                  }}
                />
              </div>
            </div>
          </div>

          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ alignItems: 'flex-start' }}
          >
            <DialogTitle className="keyword-dialog-header">Add To Campaign</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="">
                  <div>
                    <span>Campaign Name </span>
                    <Tooltip
                      className="moda_pop_search_volume_svg"
                      title="Select the campaign name to which the selected keyword(s) should be added."
                      arrow
                    >
                      <HelpIcon style={{ width: "0.5em" }} />
                    </Tooltip>
                    <Select
                      options={campaignval}
                      labelField="name"
                      valueField="id"
                      onChange={(e) => setSelectcamptval(e.value)}
                      placeholder="Select Campaign"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className="searchvolume-dropdown"
                      classNamePrefix="custom-select"
                    />
                    <span className="error_msg" style={{ "color": "red" }}>{validation.selectval}</span>
                  </div>
                  <div>
                    <span>Sections </span>
                    <Tooltip
                      className="moda_pop_search_volume_svg"
                      title="Select the section name(Prefered Url) to which the selected keyword(s) should be added to the campaign name"
                      arrow
                    >
                      <HelpIcon style={{ width: "0.5em" }} />
                    </Tooltip>
                    <Select
                      options={sectionval}
                      labelField="name"
                      valueField="id"
                      onChange={(e) => setSelectsectionval(e.value)}
                      placeholder="Select Sections"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className="searchvolume-dropdown"
                      classNamePrefix="custom-select"
                    />
                    <span className="error_msg" style={{ "color": "red" }}>{validation.dateerror}</span>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <div className="submitbutton">
                <button className="form_submit_btn" onClick={reportNow}>
                  Submit
                </button>
                <button className="form_submit_btn" style={{ marginLeft: '20px' }} onClick={closeModal}>
                  Close
                </button>
              </div>
            </DialogActions>
          </Dialog>
          <CommonErrorAlert
            data={{
              apiopen: open,
              apiErrorMsg: 'A new keyword has been added to the Targeted Keywords',
              isIconStyled: false
            }}
            closeAlert={handleClose} />
          <CommonErrorAlert
            data={{
              apiopen: searchopen,
              apiErrorMsg: 'Please Select Data !',
              isIconStyled: true
            }}
            closeAlert={handleClose} />

          {!openCommonAlerts &&
            <CommonErrorAlert
              data={{
                apiopen: apiopen,
                apiErrorMsg: apierrorMsg,
                isIconStyled: true
              }}
              closeAlert={apihandleClose} />
          }
          <Dialog
            open={checkD}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                  <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                  <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
              <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>
  );
}