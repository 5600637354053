import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import axios from "axios";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { useSelector } from "react-redux";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import trueimages from "./../Asset/true-images.png";
import failimages from "./../Asset/fail-images.png";
import nutralimages from "./../Asset/nutrals.png";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';

import warningimages from "./../Asset/warning-icon.png";
import { ToastContainer, toast } from 'react-toastify';
import { Bar } from "react-chartjs-2";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import SuccessChart from "./SuccessChart"
import "./Performancestyle.css";
import ResetPng from "../../../Icons/reset.png";
import RestorePng from "../../../Icons/restore.png";
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import CommonAlert from "../../../Commonalert/commonalert";
import { CommonErrorAlert } from "../../../Commonalert/commonErrorAlert";
const AccessbilityScoreDetails = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  let querystring = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [DetailRunReportPopShow, SetDetailRunReportPopShow] = useState()
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const navigate = useNavigate();
  const CloseModalTrigger = () => {
    SetDetailRunReportPopShow(false);
  };
  const handleChanged = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const barChartData = {
    labels: ["Success", "Failure"],
    datasets: [
      {

        data: [5, 7],
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: ["#FE6020", "#103658"],
      },

    ],
  };

  const ItemId = querystring.id;
  const JobId = querystring.jobid;
  const esodata = useSelector((state) => state);
  const detail_Render_url = `${process.env.REACT_APP_DOMAIN}performance/render-blocking/${ItemId}`
  const leverage_browser_url = `${process.env.REACT_APP_DOMAIN}performance/leverage/${ItemId}`

  const [jobDate, SetjobDate] = useState("");
  const [queryurl, Setqueryurl] = useState("");
  const ApiToken = esodata.apiauthkey.value;
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  var state = {
    value: totalpercecntwords,
  };

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const [ErrorCarddata, SetErrorCarddata] = useState([]);
  const [WarningsCarddata, SetWarningsCarddata] = useState([]);
  const [NoticesCarddata, SetNoticesCarddata] = useState([]);
  const [ErrorCardcount, SetErrorCardcount] = useState("");
  const [WarningCardcount, SetWarningCardcount] = useState("");
  const [NoticeCardcount, SetNoticeCardcount] = useState("");
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);

  const AccessbilityScoreDetail = async () => {
    setLoading(true)
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/accessibility2_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&job_id=${JobId}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setLoading(false)
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
    });
    if (datavalue?.data) {
      setLoading(false)
      const dataval = datavalue?.data;
      SetWarningsCarddata(dataval)
      SetNoticesCarddata(dataval?.table_data[0].details_data)
      SetErrorCardcount(dataval?.table_data[0])
      SetjobDate(dataval?.job_date)
    }


  };

  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  useEffect(() => {
    AccessbilityScoreDetail();
    faqdatafun();
  }, []);
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      SetDetailRunReportPopShow(false)
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility2_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessbilityDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  const pdfsummarydown = async () => {
    setLoading(true);
    setIsDownloading(false);
    try {
      SetDetailRunReportPopShow(false)
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility2_summary_pdf/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${ItemId}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessbilityDetailSummary", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);
  }
  function functionstatus(statusval) {
    if (statusval == "success") {
      return (<><img
        src={trueimages}
        className="view_per_img dataimg"
        alt="view report icon"

      /></>);
    }
    else if (statusval == "failure") {
      return (<><img
        src={failimages}
        className="view_per_img dataimg"
        alt="view report icon"

      /></>);
    }
    else if (statusval == "warning") {
      return (<img
        src={warningimages}
        className="view_per_img dataimg"
        alt="view report icon"

      />);
    }

    else if (statusval == "neutral") {
      return (<img
        src={nutralimages}
        className="view_per_img dataimg"
        alt="view report icon"

      />);
    }




  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=54`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  

  const openMOdaltrigger = () => {
    SetDetailRunReportPopShow(true);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Link to="/accessibility-report">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Report List</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Detail Report </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
            <>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : openMOdaltrigger} title="PDF Download" ><GetAppIcon />PDF</button>
            </>
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} setToggle={setToggle} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Accessibility Detail Report </h2>
            </div>
            <a target="_blank" href={ErrorCardcount?.link__url} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="spanlink">
                &nbsp; {ErrorCardcount?.link__url}
              </span>
            </a>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
        </div>

        <div className="cnt_hdr_content accessbility">
          <div className="box_wrap">
            <div>
              <h3> </h3>
              <p></p>
            </div>
            {NoticesCarddata.map((object, i) =>

              <div id="performance-metric" className="container">
                <div class="scan-result__header">
                  <h2>    {object?.title}</h2>
                  <h2>  Score :  {object?.score}</h2>
                </div>

                {object?.rows.map((obj, i) =>
                  <div className="performance_accordion_div dummydivdata ">


                    <Accordion expanded={expanded === 'panel' + obj.title + i} onChange={handleChanged('panel' + obj.title + i)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                          <b>{obj.title}  </b>
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', margin: "0px auto" }} className="dummyimg">

                          {functionstatus(obj.status)}

                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="error_result_data">
                          <center><h3>{ErrorCarddata?.uses_optimized_image_view_report?.optimized_images_title?.title}</h3></center>
                        </Typography>
                        <Typography >
                          <div className="scan-results">
                            <div className="scan-results__section">
                              <div class="scan-result__expanded">

                                <div className="scan-result__content">

                                  <strong className="scan-title">Requirement:</strong>


                                  <p className="scan-text">
                                    {obj?.Requirements}
                                  </p>


                                  <div className="scan-code-example">

                                    <span className="scan-title"> {obj?.failure_elements_count} Failed Elements</span>
                                    <ul className="scan-list">
                                      {obj.failure_elements_count > 0 ? obj?.failure_elements.map((fails, i) =>
                                        <li className="scan-list__item">
                                          <code className="scan-code">
                                            <p> 	{fails}    															</p>
                                          </code>
                                        </li>
                                      ) : ""}
                                    </ul>
                                  </div>
                                  <div className="scan-code-example">
                                    <span className="scan-title">{obj?.success_elements_count} Successful Elements</span>
                                    <ul className="scan-list">
                                      {obj.success_elements_count > 0 ? obj?.success_elements.map((successe, i) =>
                                        <li className="scan-list__item">
                                          <code className="scan-code">
                                            <p> {successe}
                                            </p>
                                          </code>
                                        </li>
                                      ) : ""}


                                    </ul>
                                  </div>

                                </div>
                              </div>
                              <div class="scan-result__info">


                                <div class="scan-title">
                                  <Bar
                                    type="bar"
                                    width={40}
                                    height={30}
                                    data={{
                                      labels: ["Success", "Failure"],
                                      datasets: [
                                        {

                                          data: [obj?.success_elements_count != 0 ? obj?.success_elements_count : 0, obj?.failure_elements_count],
                                          barPercentage: 0.1,
                                          categoryPercentage: 4,
                                          borderWidth: 0,
                                          backgroundColor: ["#FE6020", "#103658"],
                                        },

                                      ],
                                    }}
                                    options={{
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                        title: {
                                          display: true,
                                          text: "",
                                          color: "#0b2033",
                                          font: {
                                            size: 18,
                                            family: 'Open Sans',
                                          },
                                        },
                                      },
                                      scales: {
                                        y: {
                                          ticks: {
                                            suggestedMin: 0,
                                            stacked: true,
                                            color: "#0b2033",
                                            font: {
                                              size: 14,
                                              family: 'Poppins',
                                            },
                                            beginAtZero: true,
                                          },
                                        },
                                        x: {
                                          ticks: {
                                            color: "#0b2033",
                                            font: {
                                              size: 12,
                                              family: 'Poppins',
                                            },
                                            beginAtZero: true,
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>


                                <div class="scan-graphs">


                                  <div class="scan-graph fail">


                                    <h5 class="scan-title">
                                      <center>	<b>	Total <span class="scan-elements">
                                        {obj?.total_elements_count}
                                      </span></b></center>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                  </div>
                )}
              </div>
            )}

          </div>
        </div>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts &&
          <CommonErrorAlert
            data={{
              apiopen: apiopen,
              apiErrorMsg: apierrorMsg,
              isIconStyled: false
            }}
            closeAlert={apihandleClose} />
        }

        <Dialog
          open={DetailRunReportPopShow}
          onClose={CloseModalTrigger}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter run_report_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Choose The Pdf Report Type"}</DialogTitle>
          <DialogContent>
            <div className="pdf-download-card-container">
              <div className="pdf-download-type" onClick={() => pdfdown()}>
                <img style={{ height: '150px' }} src={ResetPng} />
                <div className="">DETAIL REPORT</div>
              </div>
              <div className="pdf-download-type" onClick={() => pdfsummarydown()}>
                <img style={{ height: '143px', marginBottom: '7px' }} src={RestorePng} />
                <div className="">SUMMARY REPORT</div>
              </div>
            </div>
          </DialogContent>


        </Dialog>

      </div>
    </div>
  );
};

export default AccessbilityScoreDetails;
